import qs from 'qs'

import {
  CLIENT_ID,
  EXTERNAL_AUTH_SERVICE,
  GRANT_TYPE,
  REQUEST_FIELD_NAME,
} from '../constants'

import { BaseBuilder } from './base-builder.service'

/** билдер для внешнего сервиса авторизации Yandex */
export class YandexBuilder {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.baseBuilder = new BaseBuilder(gaApp, EXTERNAL_AUTH_SERVICE.YANDEX)
  }

  /**
   * Формирует параметры для URL перехода на внешний сервис
   */
  buildRedirectParams() {
    const params = this.baseBuilder.getParams()

    // обогощаем необходимыми динамическими параметрами:
    params.redirect_uri = this.baseBuilder.buildRedirectUri()

    return params
  }

  /**
   * Формирует URL перехода на внешние сервисы авторизации
   */
  buildRedirectUrl() {
    return this.baseBuilder.buildUrl(this.buildRedirectParams())
  }

  /**
   * Формирует параметры для отправки на бэк для авторизации
   *
   * @param {Object} queryParams
   */
  buildRequestPayload(queryParams) {
    return qs.stringify({
      [REQUEST_FIELD_NAME.CLIENT_ID]: CLIENT_ID,
      [REQUEST_FIELD_NAME.CHALLENGE_CODE]: queryParams.code,
      [REQUEST_FIELD_NAME.GRANT_TYPE]: GRANT_TYPE[EXTERNAL_AUTH_SERVICE.YANDEX],
    })
  }
}
