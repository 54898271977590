import { useContext } from '@nuxtjs/composition-api'

import { computed, toRefs, unref } from 'vue'

import { GaIconPrimaryGlobe } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaIcon } from '@ga/ui-components/icon'

import { GaLangSwitcherListItem } from './children/list-item'
import { THEME } from './scripts/const'

// @vue/component
export default {
  name: 'ga-app-lang-switcher',

  components: {
    GaLangSwitcherListItem,
    GaAction,
    GaIcon,
    GaIconPrimaryGlobe,
  },

  props: {
    /**
     * Включает отображение иконки глобуса
     */
    showIcon: {
      type: Boolean,
      default: false,
    },
    /**
     * Показывает все языки, включая текущий, доступные для стора
     */
    showAllLanguages: {
      type: Boolean,
      default: false,
    },
    /**
     * Цветовая тема, влияет на цвет неактивного языка
     */
    theme: {
      type: String,
      default: THEME.PRIMARY,
      validator: (value) => Object.values(THEME).includes(value),
    },
  },

  setup(props) {
    const { $gaApp } = useContext()
    const { showAllLanguages, theme } = toRefs(props)

    const availableLanguages = computed(
      () => $gaApp.services.app.langSwitcher.availableLanguages,
    )

    const switchLocale = (code) => {
      $gaApp.services.app.langSwitcher.switchLocale(code)
    }

    const allLanguages = computed(() =>
      $gaApp.services.app.langSwitcher.allLanguages
        .map((lang) => {
          return {
            ...lang,
            current: $gaApp.services.app.langSwitcher.isCurrentLanguage(
              lang.code,
            ),
          }
        })
        .sort((lang) => (lang.current ? -1 : 1)),
    )

    const languages = computed(() => {
      return unref(showAllLanguages)
        ? allLanguages.value
        : availableLanguages.value
    })

    /**
     * Вычисляет необходимость модификатора inactive для языка
     * @param {Object} language - Объект языка, содержащий информацию о текущем языке.
     * @returns {boolean}
     */
    const languagueIsInactive = (language) =>
      (showAllLanguages.value || theme.value === THEME.SECONDARY) &&
      !language.current

    return {
      languages,

      switchLocale,
      languagueIsInactive,
    }
  },
}
