import { ApiService } from './api.service'
import { CityModalService } from './city-modal.service'
import { DigitalService } from './digital.service'
import { DigitalCounterpartyService } from './digital-counterparty.service'
import { DigitalCoverSelectorService } from './digital-cover-selector.service'
import { DigitalFelicitationPreviewService } from './digital-felicitation-preview.service'
import { DigitalSetsService } from './digital-sets.service'
import { LandingService } from './landing.service'
import { LocationService } from './location.service'
import { PageService } from './page.service'
import { PlasticService } from './plastic.service'
import { PlasticCounterpartyService } from './plastic-counterparty.service'
import { PlasticDeliveryService } from './plastic-delivery.service'
import { PlasticFormStepsService } from './plastic-form-steps.service'
import { PlasticSetsService } from './plastic-sets.service'
import { StepsService } from './steps.service'
import { SuccessModalService } from './success-modal.service'

export const services = (gaApp) => ({
  api: new ApiService(gaApp),
  cityModal: new CityModalService(gaApp),
  digital: new DigitalService(gaApp),
  digitalCounterparty: new DigitalCounterpartyService(gaApp),
  digitalCoverSelector: new DigitalCoverSelectorService(gaApp),
  digitalFelicitationPreview: new DigitalFelicitationPreviewService(gaApp),
  digitalSets: new DigitalSetsService(gaApp),
  landing: new LandingService(gaApp),
  location: new LocationService(gaApp),
  page: new PageService(gaApp),
  plastic: new PlasticService(gaApp),
  plasticCounterparty: new PlasticCounterpartyService(gaApp),
  plasticDelivery: new PlasticDeliveryService(gaApp),
  plasticSets: new PlasticSetsService(gaApp),
  plasticFormSteps: new PlasticFormStepsService(gaApp),
  steps: new StepsService(gaApp),
  successModal: new SuccessModalService(gaApp),
})
