import { getAddressId } from '../../utils'

export const getters = (gaApp) => ({
  /**
   * Сохраненные адреса в виде массива
   */
  savedAddresses({ mixedAddresses }) {
    return mixedAddresses?.filter((item) => getAddressId(item).saved) || []
  },

  /**
   * Сохраненные адреса + временный адрес в виде объекта
   */
  mixedAddressesAsObject({ mixedAddresses }) {
    const result = {}

    mixedAddresses?.forEach((item) => {
      result[getAddressId(item).mixed] = item
    })

    return result
  },

  /**
   * Cохраненные адреса в виде объекта
   */
  savedAddressesAsObject({ savedAddresses }) {
    const result = {}

    savedAddresses.forEach((item) => {
      result[getAddressId(item).saved] = item
    })

    return result
  },

  /**
   * Флаг наличия сохраненных курьерских адресов
   */
  hasSavedAddresses: ({ savedAddresses }) => Boolean(savedAddresses.length),

  /**
   * Получение сохраненного адреса по id
   */
  getSavedAddressById: ({ savedAddressesAsObject }) => {
    return (savedAddressId) => (savedAddressesAsObject || {})[savedAddressId]
  },

  /**
   * Один из сохраненных адресов, который выбран как дефолтный
   */
  addressDefault({ savedAddresses }) {
    return savedAddresses.find((item) => item.isDefaultAddress)
  },

  /**
   * Определяет id дефолтного адреса из массива сохраненных и временного
   */
  defaultAddressId({ mixedAddresses, addressDefault }) {
    // временный или единственный из сохраненных
    if (mixedAddresses.length === 1) {
      const address = mixedAddresses[0]

      return getAddressId(address).mixed || null
    }

    // дефолтный из сохраненных
    if (mixedAddresses.length > 1) {
      return getAddressId(addressDefault).mixed || null
    }

    return null
  },

  isFormValid() {
    return Object.values(this.form).every((field) => !field.error)
  },

  defaultId() {
    return gaApp.services.location.main.getDeliveryAddress()?.saveId || ''
  },
})
