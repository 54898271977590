import { STEP, WELCOME_TYPE } from '../constants'

export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async prepareSteps() {
    try {
      this.gaApp.stores.auth.modal.pending = true

      if (this.gaApp.features.get('authorizationTypesEnabled')) {
        await this.gaApp.services.auth.modalAuthTypes.fetchAuthTypes()
      }

      this.gaApp.services.auth.modal.goToStep(
        this.gaApp.stores.auth.modal.steps[0],
      )
    } finally {
      this.gaApp.stores.auth.modal.pending = false
    }
  }

  goToStep(step) {
    this.gaApp.stores.auth.modal.step = step
  }

  goToPreviousStep() {
    const currentIndex = this.gaApp.stores.auth.modal.steps.indexOf(
      this.gaApp.stores.auth.modal.step,
    )
    const previousIndex = Math.max(0, currentIndex - 1)

    const previousStep = this.gaApp.stores.auth.modal.steps[previousIndex]

    this.goToStep(previousStep)
  }

  onCodeSendSuccess({ phone, phoneCode, phoneFormatted }) {
    this.gaApp.stores.auth.modal.phone = phone
    this.gaApp.stores.auth.modal.phoneCode = phoneCode
    this.gaApp.stores.auth.modal.phoneFormatted = phoneFormatted

    this.goToStep(STEP.CONFIRM)
  }

  isFormFieldError(parameters) {
    return (
      Object.keys(parameters).length &&
      (parameters.verificationToken || parameters.phoneNumber)
    )
  }

  handleError(error) {
    this.gaApp.analytics.modules.auth.onError()

    const invalidParameters = error.invalidParameters || {}
    const metadata = error.metadata || {}
    const message =
      Object.values(invalidParameters)[0]?.localizedMessage ||
      error.localizedMessage ||
      this.gaApp.i18n.t('auth.error.default')

    if (metadata.next_request_delay_ms) {
      return this.handleMetaDataError(error)
    }

    if (this.isFormFieldError(invalidParameters)) {
      this.handleFieldError(error)
    } else {
      this.handleGlobalError(message)
    }
  }

  setStepError(error) {
    this.gaApp.stores.auth.modal.stepError = error
  }

  handleGlobalError(message) {
    this.gaApp.services.notification.main.open(message)
  }

  handleFieldError(error) {
    this.setStepError(error)
  }

  handleMetaDataError(error) {
    this.setStepError(error)
  }

  onLocaleChangingConfirmationConfirm() {
    const { localeChangingConfirmationCountry } = this.gaApp.stores.auth.modal

    const storeContent = this.gaApp.services.app.main.getStoreContent(
      localeChangingConfirmationCountry,
    )
    const storeUrl = new URL(storeContent?.storeDomain)

    // Если в storeDomain есть параметр store - берем его в качестве домена. Иначе - домен целиком
    const domain =
      storeUrl.searchParams.get('store') ?? decodeURIComponent(storeUrl.origin)

    const { path } = this.gaApp.route

    // Формируем урлу с учетом относительного пути, чтобы редиректнуть на ту же страницу
    const url = new URL(path, domain)

    // Добавляем query параметр language, чтобы после редиректа язык был такой же, как в текущем сторе
    const language = this.gaApp.i18n.locale.code
    url.searchParams.set('language', language)

    // Добавляем хеш auth, чтобы открыть модалку авторизации после редиректа
    url.hash = 'auth'

    window.location.href = decodeURIComponent(url.href)
  }

  onUpdateCountry(country) {
    if (country === this.gaApp.i18n.locale?.country) {
      return
    }

    this.gaApp.stores.auth.modal.localeChangingConfirmationCountry = country

    this.goToStep(STEP.LOCALE_CHANGING)
  }

  async onCodeConfirmSuccess({ isRegistered }, emit) {
    this.gaApp.stores.auth.modal.logged = true

    // Проверяем, включена ли фича нового сценария выдачи карты лояльности
    if (this.gaApp.features.get('showLoyaltyCardScreens')) {
      await this.handleNewWelcomeScreen(emit)

      return
    }

    if (isRegistered) {
      emit('success')
    } else {
      if (this.gaApp.features.get('newWelcomeScreen')) {
        await this.setWelcomeType()
      }

      this.goToStep(STEP.WELCOME)
    }
  }

  reset() {
    this.gaApp.stores.auth.modal.$reset()
  }

  handleIsNotLogged() {
    if (!this.gaApp.stores.auth.modal.logged) {
      this.reset()

      this.gaApp.services.user.main.resetUserAndRefresh()
    }
  }

  handleBackAction(emit) {
    if (this.gaApp.stores.auth.modal.isCurrentStepInitial) {
      emit('back')
    } else {
      this.goToPreviousStep()
    }
  }

  onBackAction(emit) {
    this.gaApp.stores.auth.modal.localeChangingConfirmationCountry = null
    this.setStepError(null)
    this.handleBackAction(emit)
  }

  updateNextRequestDelay(delay) {
    this.gaApp.stores.auth.modal.nextRequestDelay = delay
  }

  updateVerificationCode(code) {
    this.gaApp.stores.auth.modal.verificationCode = code || ''
  }

  async onOpenModal(data) {
    this.gaApp.analytics.modules.auth.onOpenAuthModal()
    this.gaApp.services.auth.localStorage.setLocalStorageAuthModalOpenReason(
      data.reason,
    )
    await this.gaApp.services.auth.modal.prepareSteps()
  }

  onCloseModal() {
    this.gaApp.services.auth.main.removeAuthLocalStorageData()
  }

  async setWelcomeType() {
    const otherCountriesUser = await this.checkUserInOtherCountry()

    this.gaApp.stores.auth.modal.welcomeType = otherCountriesUser
      ? WELCOME_TYPE.OTHER_COUNTRIES_USER
      : WELCOME_TYPE.NEW_USER
  }

  async checkUserInOtherCountry() {
    const isExist =
      await this.gaApp.services.user.main.checkOtherCountriesPhoneExistence({
        phoneCode: this.gaApp.stores.auth.modal.phoneCode,
        phone: this.gaApp.stores.auth.modal.phone,
      })

    return isExist
  }

  async handleNewWelcomeScreen(emit) {
    try {
      // Получаем данные для модалки
      const otherCountryExist = await this.checkUserInOtherCountry()
      await this.gaApp.services.customer.api.getWelcomeScreen(otherCountryExist)

      // Вызываем метод для показа новой велком-модалки
      this.gaApp.services.customer.notificationModal.onAuth()
    } catch (error) {
    } finally {
      emit('success')
    }
  }
}
