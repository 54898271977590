import { TARGET } from '../../constants/common'

/**
 * Общий сервис для работы с данными продукта и доставки.
 * Является приватным абстрактный классом для сервисов main и preview.
 *
 * @class CommonService
 */
export class CommonService {
  constructor(gaApp, view) {
    this.gaApp = gaApp
    this.view = view ?? TARGET.MAIN
  }

  /**
   * Возвращает выбранный продукт для текущего представления.
   *
   * @return {Object} - Объект выбранного продукта.
   */
  get productSelected() {
    return this.gaApp.stores.pdp[this.view].productSelected
  }

  /**
   * Возвращает данные о продукте для текущего представления.
   *
   * @return {Object} - Объект с данными продукта.
   */
  get productData() {
    return this.gaApp.stores.pdp[this.view].productData
  }

  /**
   * Подписывается на данные продукта.
   *
   * @param {Object} data - Объект с данными для подписки.
   * @return {Promise<Object>} - Promise с объектом данных подписки.
   */
  async subscribe(data = {}) {
    if (this.gaApp.features.get('notifyProductInStockPlaid')) {
      const normalizedData = await this.processDataForSubscription(data)

      return this.gaApp.repositories.pdp.main.subscribeOnProductInStock(
        normalizedData,
      )
    } else {
      return this.gaApp.repositories.pdp.main.subscribe(data)
    }
  }

  /**
   * Получает данные о доставке для указанного продукта.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @return {Promise<Object>} - Promise с данными о доставке.
   */
  getDeliveryData({ itemId = null } = {}) {
    const params = { view: this.view, itemId }

    return this.gaApp.services.pdp.api.getDelivery(params)
  }

  /**
   * Получает данные о продукте.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @return {Promise<Object>} - Promise с данными о продукте.
   */
  getProductData({ itemId = null } = {}) {
    return this.gaApp.services.pdp.api.fetchProduct({ view: this.view, itemId })
  }

  /**
   * Перезагружает данные о продукте.
   *
   * @return {Promise<Object>} - Promise с обновленными данными о продукте.
   */
  reloadProductData() {
    this.getProductData()
  }

  /**
   * Перезагружает данные о доставке.
   *
   * @return {Promise<Object>} - Promise с обновленными данными о доставке.
   */
  reloadDeliveryData() {
    return this.getDeliveryData()
  }

  /**
   * Возвращает форматированные данные для подписки на товар чере plaid.
   * @param {Object} data - Объект с данными для подписки.
   * @return {Object} - Форматированный объект с данными для подписки через plaid
   */
  async processDataForSubscription(data = {}) {
    if (typeof data !== 'object') {
      return {}
    }

    const normalizedData = {
      itemId: data.sku,
      email: data.email,
    }

    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    if (captchaToken) {
      normalizedData.captchaToken = captchaToken
    }

    return normalizedData
  }
}
