export class ModalConfirmService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async confirmCode(code, phone, meta) {
    try {
      this.gaApp.analytics.modules.auth.onSubmitTel()

      this.gaApp.stores.auth.modal.pending = true

      const response = await this.gaApp.services.auth.main.login({
        code,
        phone,
        meta,
      })

      const payload = await this._onConfirmCodeSuccess(response, meta)

      return payload
    } catch (error) {
      this._onConfirmCodeError(error)
    } finally {
      this.gaApp.stores.auth.modal.pending = false
    }
  }

  async _onConfirmCodeSuccess(response, meta) {
    // eslint-disable-next-line camelcase
    const isRegistered = response.data.customer_is_registered

    const payload = {
      isRegistered: Boolean(+isRegistered),
    }

    if (isRegistered) {
      await meta.callbacks?.login?.()
    } else {
      await meta.callbacks?.register?.()
    }

    return payload
  }

  _onConfirmCodeError(error) {
    this.gaApp.services.auth.modal.handleError(error)
  }

  async resendCode(phone, phoneCode) {
    this.gaApp.analytics.modules.auth.onResubmitSms()

    this.gaApp.stores.auth.modal.pending = true

    try {
      const token = await this.gaApp.services.captcha.main.generateToken()

      const response = await this.gaApp.services.auth.main.resendAuthCode({
        phone,
        phoneCode,
        captchaToken: token,
      })

      this._onResendCodeSuccess(response)

      return response
    } catch (error) {
      this._onResendCodeError(error)
    } finally {
      this.gaApp.stores.auth.modal.pending = false
    }
  }

  _onResendCodeSuccess(response) {
    this.gaApp.services.auth.modal.updateNextRequestDelay(
      response.data.next_request_delay_ms,
    )
  }

  _onResendCodeError(error) {
    if (error.statusCode === 429) {
      this.gaApp.services.auth.modal.updateNextRequestDelay(
        error.metadata?.next_request_delay_ms,
      )
    }

    this.gaApp.services.auth.modal.handleError(error)
  }
}
