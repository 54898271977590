export class PaymentCardService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Обновление ошибок для реактивности.
   *
   * Альтернатива Vue.set без зависимости Vue.
   */
  updateErrors() {
    this.gaApp.stores.giftCards.payment.card.errors = Object.assign(
      {},
      this.gaApp.stores.giftCards.payment.card.errors,
    )
  }

  /**
   * Установка ошибки для карты.
   *
   * Ошибки будут отображаться под установленной картой.
   *
   * @param {string} cardId id карты, для которой устанавливается ошибка
   */
  setError(cardId) {
    this.gaApp.stores.giftCards.payment.card.errors[cardId] = this.gaApp.i18n.t(
      'giftCards.payment.errors.deleteCard',
    )
    this.updateErrors()
  }

  /**
   * Удаление ошибки для определенной карты.
   *
   * @param {string} cardId id карты, для которой удаляется ошибка
   */
  clearError(cardId) {
    if (!(cardId in this.gaApp.stores.giftCards.payment.card.errors)) {
      return undefined
    }

    delete this.gaApp.stores.giftCards.payment.card.errors[cardId]
    this.updateErrors()
  }

  /**
   * Удаление всех ошибок карт
   */
  resetErrors() {
    this.gaApp.stores.giftCards.payment.card.errors = {}
  }

  /**
   * Получение сохраненных карт для пользователя из BFF.
   *
   * Устанавливает сохраненные карты и возможность сохранения новых, если пользователь авторизован и фича включена.
   */
  async getSaved() {
    if (
      !this.gaApp.stores.user.main.isAuthorized ||
      !this.gaApp.features.get('giftCardsNewPayment')
    ) {
      return undefined
    }

    try {
      await this.gaApp.services.giftCards.api.getPaymentSavedCards()
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('giftCards.payment.errors.getSavedCards'),
      )
    }
  }

  /**
   * Получение конфигурации банковских карт.
   *
   * Используется как зависимость для сервиса оплаты при обновлении конфигурации
   */
  async getData() {
    await this.getSaved()
  }

  /**
   * Удаление сохраненной карты пользователя.
   *
   * Удаляет карту у агрегатора карт и обновляет локальные дынные.
   *
   * @param {string} cardId id карты, которую необходимо удалить из сохраненных
   */
  async delete(cardId) {
    try {
      this.gaApp.stores.giftCards.payment.card.loading = true
      await this.gaApp.services.giftCards.api.removePaymentSavedCard(cardId)

      const savedIndex =
        this.gaApp.stores.giftCards.payment.card.saved.findIndex(
          (saved) => saved.id === cardId,
        )
      this.gaApp.stores.giftCards.payment.card.saved.splice(savedIndex, 1)
      this.clearError(cardId)
    } catch (error) {
      console.error(error)
      this.setError(cardId)
    } finally {
      this.gaApp.stores.giftCards.payment.card.loading = false
    }
  }

  /**
   * Начало оплаты картой.
   *
   * Используется как зависимость для сервиса оплаты – `gaApp.services.giftCards.payment.startPayment()`.
   */
  async startPayment() {
    const bindingId = this.gaApp.stores.giftCards.payment.cardSelectedOption?.id
    const completeOrderData = {
      ...this.gaApp.services.giftCards.payment.orderData,
      bindingId,
    }

    if (!bindingId) {
      completeOrderData.isBindable =
        this.gaApp.stores.giftCards.payment.card.save
    }

    const { data } =
      await this.gaApp.services.giftCards.api.paymentOrderCard(
        completeOrderData,
      )

    const { paymentUrl, salesId } = data

    this.gaApp.services.giftCards.payment.setOrderId(salesId)

    if (!paymentUrl) {
      throw new Error('There is no paymentURL in response')
    }

    window.open(paymentUrl, '_top')
  }
}
