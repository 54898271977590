import { ApiService } from './api.service'
import { ListingService } from './listing.service'
import { MainService } from './main.service'
import { MediaService } from './media.service'
import { PageService } from './page.service'
import { ProductAdvDisclaimerService } from './product-adv-disclaimer.service'
import { ProductRatingService } from './product-rating.service'
import { PublicService } from './public.service'
import { SeoService } from './seo.service'
import { TextTransformService } from './text-transform.service'

export const services = (gaApp) => ({
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  main: new MainService(gaApp),
  seo: new SeoService(gaApp),
  listing: new ListingService(gaApp),
  textTransform: new TextTransformService(gaApp),
  productAdvDisclaimer: new ProductAdvDisclaimerService(gaApp),
  productRating: new ProductRatingService(gaApp),
  media: new MediaService(gaApp),
  public: new PublicService(gaApp),
})
