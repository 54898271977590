import { DIALOG } from '../../constants'

export class DialogService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openDialog({ type }) {
    this.gaApp.stores.customer.modal.typeDialog = type
    this.gaApp.stores.customer.modal.isOpenDialog = true
  }

  closeDialog() {
    this.gaApp.stores.customer.modal.isOpenDialog = false
  }

  openOrderCancelConfirmation() {
    this.openDialog({ type: DIALOG.CONFIRMATION })
  }

  openOrderCancelledError() {
    this.openDialog({ type: DIALOG.CANCELLED })
  }

  openOrderCompletedError() {
    this.openDialog({ type: DIALOG.COMPLETED })
  }

  openOrderDeliveringError() {
    this.openDialog({ type: DIALOG.DELIVERING })
  }

  openOrderCancelDefaultError() {
    this.openDialog({ type: DIALOG.DEFAULT })
  }
}
