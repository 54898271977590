import { computed, ref, toRefs, watch } from 'vue'

import { doubleRAF } from '@ga/shared-browser'

import { GaField } from '../field'
import { GaNewInput } from '../new-input'

import { GaInputPhoneSelectCode } from './children/select'
import { usePhone } from './scripts/composables'
import { useCursorPosition } from './scripts/composables/cursor-position'
import {
  ATTRIBUTE_AUTOCOMPLETE,
  MIN_PHONE_CODES_LENGTH,
  PHONE_INITIAL,
  SIZE,
} from './scripts/constants'

// @vue/component
export default {
  name: 'ga-input-phone',

  components: {
    GaField,
    GaNewInput,
    GaInputPhoneSelectCode,
  },
  inheritAttrs: false,

  props: {
    /**
     * Номер телефона
     */
    phone: {
      type: String,
      default: null,
      required: true,
    },
    /**
     * Отформатированный телефона
     */
    phoneFormatted: {
      type: String,
      default: null,
    },
    /**
     * Имя компонента
     */
    phoneName: {
      type: String,
      required: true,
    },
    /**
     * Ошибка валидации номера телефона
     */
    phoneError: {
      type: String,
      default: null,
    },
    /**
     * Подсказка под полем ввода телефона
     */
    phoneHint: {
      type: String,
      default: null,
    },
    /**
     * Код номера телефона
     */
    phoneCode: {
      type: String,
      default: null,
    },
    /**
     * Лейбл для кода номера телефона
     */
    phoneCodeLabel: {
      type: String,
      default: null,
      required: true,
    },
    /**
     * Имя кода номера телефона
     */
    phoneCodeName: {
      type: String,
      required: true,
    },
    /**
     * Массив опций доступных телефонных кодов
     */
    phoneCodes: {
      type: Array,
      default: () => [],
      required: true,
    },
    /**
     * Доступна ли возможность редактирования
     */
    readonly: {
      type: Boolean,
      default: false,
    },
    /**
     * Размер компонента
     */
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    /**
     * Является ли RTL-версией компонента
     */
    rtl: {
      type: Boolean,
      default: false,
    },
    /**
     * Открывается ли модальное окно при нажатии на маску выбора кода телефона (или bottom sheet)
     */
    withModal: {
      type: Boolean,
      default: false,
    },
    /**
     * z-index модального окна выбора телефонного кода
     */
    modalZIndex: {
      type: Number,
      default: null,
    },
    /**
     * Нужно ли отменить форматирование подсказки и ошибки в lowercase
     */
    disableLowerCase: {
      type: Boolean,
      default: false,
    },
    /**
     * Нужно ли отменить сохранение позиции курсора на вводе телефона
     */
    disablePositionCursorSave: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit, attrs }) {
    const {
      phone,
      phoneCode,
      phoneCodes,
      phoneFormatted,
      disablePositionCursorSave,
    } = toRefs(props)

    const { phoneInternal, phoneCodeInternal, placeholder } = usePhone(
      {
        phone,
        phoneCode,
        phoneCodes,
        phoneFormatted,
      },
      emit,
    )

    const rootRef = ref(null)
    const rootWidth = computed(() => rootRef.value?.clientWidth || 0)

    const inputRef = ref(null)

    const inputAttrs = computed(() => ({
      placeholder: placeholder.value,
      autocomplete: ATTRIBUTE_AUTOCOMPLETE.ON,
      ...attrs,
    }))

    const enablePhoneCodes = computed(() => {
      return props.phoneCodes.length > MIN_PHONE_CODES_LENGTH
    })

    const focus = () => {
      if (inputRef.value) {
        inputRef.value.focus()
      }
    }

    function onPaste(e) {
      e.preventDefault()
      const data = (e.clipboardData || window.clipboardData)
        .getData('text')
        .trim()

      // Сбрасываем текущее значение чтобы отработало форматирование
      phoneInternal.value = PHONE_INITIAL
      phoneInternal.value = data
    }

    const onKeypress = (e) => {
      if (e.ctrlKey || e.metaKey || e.key === 'Enter') {
        return true
      }

      const char = Number(e.key)

      if (isNaN(char)) {
        e.preventDefault()
      }
    }

    const onSelectCodeModalOpen = () => {
      emit('select-code-modal-open')
    }

    const onSelectCodeModalClose = () => {
      if (enablePhoneCodes.value && props.size === SIZE.S) {
        doubleRAF(focus)
      }

      emit('select-code-modal-close')
    }

    watch(phoneCodeInternal, () => {
      doubleRAF(focus)
    })

    const { onCursorPositionChange } = useCursorPosition(
      disablePositionCursorSave.value,
    )

    return {
      phoneInternal,
      phoneCodeInternal,

      rootRef,
      rootWidth,

      inputRef,
      inputAttrs,

      enablePhoneCodes,

      focus,

      onPaste,
      onKeypress,
      onCursorPositionChange,

      onSelectCodeModalOpen,
      onSelectCodeModalClose,
    }
  },
}
