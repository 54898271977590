/**
 * Сервис для управления cookie-уведомлениями и разрешениями
 * Используется в компонентах:
 * - cookie-panel
 * - cookie-modal
 * - cookie-settings-modal
 * - cookie-notification-panel
 * Инициализируется при старте приложения в client.js
 */
import {
  COOKIES_NOFIFICATION_ANSWERED_KEY,
  IS_ACCEPTED_COOKIES_KEY,
} from '../constant'
export class CookiePermissionService {
  /**
   * @param {Object} gaApp - Глобальный объект приложения
   */
  constructor(gaApp) {
    this.gaApp = gaApp

    const unsubscribe = this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      ({ meta }) => {
        this.onPageCreated({ meta })
        unsubscribe() // Отписываемся после первого вызова
      },
    )
  }

  /**
   * Обработчик создания новой страницы
   * Используется для сброса состояния уведомлений при навигации
   * Вызывается через eventBus при событии 'module/app/router/page-created'
   * @param {Object} meta - Метаданные страницы
   * @param {boolean} meta.cookiePermissionDisabled - Если true, то уведомления о cookies и панель с настройками не будут показываться на странице
   */
  onPageCreated({ meta }) {
    if (!meta.cookiePermissionDisabled) return
    this.gaApp.stores.app.cookie.notification.isAnswered = true
    this.gaApp.stores.app.cookie.permission.shouldRenderCookieModal = false
    this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
  }

  /**
   * Открывает базовое модальное окно с настройками cookies
   * Используется в cookie-modal.script.js
   */
  openModal() {
    this.gaApp.stores.app.cookie.permission.modalOpened = true
  }

  /**
   * Закрывает базовое модальное окно
   * Используется в cookie-modal.script.js при принятии/отклонении cookies
   */
  closeModal() {
    this.gaApp.stores.app.cookie.permission.modalOpened = false
  }

  /**
   * Открывает расширенное модальное окно с настройками cookies
   * Используется в:
   * - footer/groups.scripts.js
   * - cookie-panel.script.js
   * - cookie-settings-modal.script.js
   */
  openSettingsModal() {
    this.gaApp.stores.app.cookie.permission.modalSettingsOpened = true

    if (!this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
      /**
       * Предотвращаем включение скролла при открытии из другой модалки
       */
      this.gaApp.services.modal.register.add('cookie-modal')
    }
  }

  /**
   * Закрывает расширенное модальное окно с настройками
   * Используется в cookie-settings-modal.script.js
   */
  closeSettingsModal() {
    this.gaApp.stores.app.cookie.permission.modalSettingsOpened = false

    if (!this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
      /**
       * Включаем скролл обратно
       */
      this.gaApp.services.modal.register.remove('cookie-modal')

      if (!this.gaApp.stores.app.cookie.permission.isAlreadyAnswered) {
        this.openModal()
      }
    }
  }

  /**
   * Показывает индикатор загрузки в модальном окне настроек
   * Используется при загрузке контента настроек
   */
  showSettingsModalLoading() {
    this.gaApp.stores.app.cookie.permission.modalSettingsLoading = true
  }

  /**
   * Скрывает индикатор загрузки в модальном окне настроек
   */
  hideSettingsModalLoading() {
    this.gaApp.stores.app.cookie.permission.modalSettingsLoading = false
  }

  /**
   * Загружает текстовый контент для модального окна настроек
   * Вызывается в cookie-settings-modal.script.js при открытии окна
   * @returns {Promise<void>}
   */
  async getSettingsModalContent() {
    if (this.gaApp.stores.app.cookie.permission.modalSettingsText) return

    try {
      this.showSettingsModalLoading()

      const { messageHtml } =
        await this.gaApp.services.app.api.getCookieAgreementText()

      this.gaApp.stores.app.cookie.permission.modalSettingsText = messageHtml
    } catch (error) {
    } finally {
      this.hideSettingsModalLoading()
    }
  }

  /**
   * Устанавливает состояние принятия cookies
   * Вызывается из компонентов при принятии/отклонении cookies
   * При принятии включает сервисы аналитики:
   * - analytics
   * - metrika
   * - facebook
   * - vkPixel
   * - myTracker
   * - amplitude
   * - mindboxAnalytics
   * @param {boolean} value - true для принятия, false для отклонения
   */
  setAcceptedCookies(value) {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    this.gaApp.cookies.set(IS_ACCEPTED_COOKIES_KEY, value, {
      expires: expiresDate,
      path: '/',
      sameSite: 'lax',
    })
    this.gaApp.stores.app.cookie.permission.isAccepted = value

    /**
     * Если пользователь в дальнейшем отключил использование сторонних кук,
     * перезагружаем страницу для сброса инициализированной аналитики
     */
    if (this.gaApp.stores.app.cookie.permission.isAlreadyAnswered && !value) {
      window.location.reload()

      return
    }

    this.gaApp.stores.app.cookie.permission.isAlreadyAnswered = true
    if (this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
      this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
    }

    this.gaApp.analytics.libs.initAllAfterPermissionsChecks()
  }

  /**
   * Отмечает что пользователь ответил на уведомление о cookies
   * Используется в cookie-notification-panel.script.js
   */
  acceptNotificaionCookies() {
    this.gaApp.services.app.browserStorage.local.set(
      COOKIES_NOFIFICATION_ANSWERED_KEY,
      1,
    )

    this.gaApp.stores.app.cookie.notification.isAnswered = true
  }

  /**
   * Инициализирует панель с запросом разрешения на cookies
   * Проверяет:
   * - Включена ли фича modalCookiesPermission
   * - Были ли уже приняты cookies
   * - Открыто ли окно настроек
   */
  initPermission() {
    const isAcceptedCookies = this.gaApp.cookies.get(IS_ACCEPTED_COOKIES_KEY)
    const shouldAccept = this.gaApp.features.get('modalCookiesPermission')

    if (isAcceptedCookies === undefined && shouldAccept) {
      if (!this.gaApp.features.get('modalCookiesPermissionSwitchToPanel')) {
        this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
        this.openModal()
      } else {
        this.gaApp.stores.app.cookie.permission.shouldRenderCookieModal = false
      }
      return
    }

    if (shouldAccept) {
      this.gaApp.stores.app.cookie.permission.shouldRenderCookieModal = false
      this.gaApp.stores.app.cookie.permission.shouldRenderCookiePanel = false
      this.gaApp.stores.app.cookie.permission.isAlreadyAnswered = true
      this.gaApp.stores.app.cookie.permission.isAccepted =
        isAcceptedCookies || false
    }
  }

  /**
   * Инициализирует уведомление о cookies
   * Проверяет:
   * - Включена ли фича сookiesNotificationPanel
   * - Отвечал ли пользователь на уведомление ранее
   */
  initNotification() {
    const shouldAccept = this.gaApp.features.get('сookiesNotificationPanel')

    if (!shouldAccept) {
      return
    }

    const isAnswered = this.gaApp.services.app.browserStorage.local.get(
      COOKIES_NOFIFICATION_ANSWERED_KEY,
    )

    this.gaApp.stores.app.cookie.notification.isAnswered = !!isAnswered
  }

  /**
   * Основной метод инициализации сервиса
   * Вызывается при старте приложения в client.js
   * Запускает initPermission() и initNotification()
   */
  init() {
    this.initPermission()
    this.initNotification()
  }
}
