import { filters as ar } from './ar.json'
import { filters as en } from './en.json'
import { filters as kk } from './kk.json'
import { filters as ru } from './ru.json'

export const locales = {
  'ru-RU': ru,
  'kk-KZ': kk,
  'ar-AE': ar,
  'ar-QA': ar,
  'ar-SA': ar,
  'en-AE': en,
  'en-QA': en,
  'en-SA': en,
  'ar-RU': ar,
  'en-RU': en,
}
