import { EXTERNAL_AUTH_SERVICE } from '../constants'

/** билдер для внешних сервисов авторизации */
export class ExternalAuthBuilder {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Формирует URL перехода на внешние сервисы авторизации
   *
   * @param {string} serviceName
   * @returns {Promise<string>}
   */
  async buildRedirectUrlByService(serviceName) {
    switch (serviceName) {
      case EXTERNAL_AUTH_SERVICE.YANDEX:
        return this.gaApp.services.auth.yandexBuilder.buildRedirectUrl()
      case EXTERNAL_AUTH_SERVICE.VK:
        return await this.gaApp.services.auth.vkBuilder.buildRedirectUrl()
    }
  }

  /**
   * Формирует параметры для отправки на бэк для авторизации через внешние сервисы
   *
   * @param {string} serviceName
   * @param {Object} params
   */
  buildRequestPayloadByService(serviceName, params) {
    switch (serviceName) {
      case EXTERNAL_AUTH_SERVICE.YANDEX:
        return this.gaApp.services.auth.yandexBuilder.buildRequestPayload(
          params,
        )
      case EXTERNAL_AUTH_SERVICE.VK:
        return this.gaApp.services.auth.vkBuilder.buildRequestPayload(params)
    }
  }
}
