export class AddressesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Отдает текст для нотификации при обновлении/удалении/установке дефолтным
   * @param { string } savedAddressId - id сохраненного адреса
   */
  formatAddressNotification(savedAddressId) {
    const savedAddress =
      this.gaApp.stores.location.addresses.getSavedAddressById(savedAddressId)

    return this.gaApp.services.location.formatter.formatStreetHouse(
      savedAddress,
    ).result
  }
}
