export class GtmGa4ActionModel {
  constructor({ id, actionName, actionType }) {
    this.event = 'user_interaction'
    this.event_category = 'pdp_info'
    this.click = actionName
    this.item_id = id
    if (actionType) {
      this.action_type = actionType
    }
  }
}
