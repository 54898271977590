/**
 * Генерирует Code Verifier для PKCE (Proof Key for Code Exchange)
 * ссылка на спецификацию: https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
 *
 * @returns {string}
 */
const generateCodeVerifier = () => {
  const array = new Uint8Array(32)
  window.crypto.getRandomValues(array)
  return btoa(String.fromCharCode.apply(null, array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

/**
 * Генерирует Code Challenge для PKCE (Proof Key for Code Exchange)
 * ссылка на спецификацию: https://datatracker.ietf.org/doc/html/rfc7636#section-4.2
 *
 * @returns {Promise<string>}
 */
const generateCodeChallenge = async (codeVerifier) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const hash = await window.crypto.subtle.digest('SHA-256', data)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(hash)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

/**
 * Генерирует пару PKCE (Proof Key for Code Exchange)
 * ссылка на спецификацию: https://datatracker.ietf.org/doc/html/rfc7636#section-4
 *
 * @returns {Promise<{codeChallenge: string, codeVerifier: string}>}
 */
export const generatePkcePair = async () => {
  const codeVerifier = generateCodeVerifier()
  const codeChallenge = await generateCodeChallenge(codeVerifier)

  return {
    codeVerifier,
    codeChallenge,
  }
}
