export const INFO_ORDER = ['inn', 'kpp', 'ognip', 'name', 'address']
export const COVER_SELECTOR_SLIDES_PER_VIEW = 5

export const SUPPORT = {
  EMAIL: 'email',
  PHONE: 'phone',
  WHATS_APP: 'whatsApp',
  TELEGRAM: 'telegram',
}

export const SUPPORT_LIST = [
  SUPPORT.EMAIL,
  SUPPORT.PHONE,
  SUPPORT.WHATS_APP,
  SUPPORT.TELEGRAM,
]

export const DAYS_UNTIL_NEAREST_DELIVERY_DATE = 3

// Временное решение, пока идет разработка backend
// TODO: убрать, когда появится метод backend
export const HOLIDAY_DATES = [
  '2024-12-30',
  '2024-12-31',
  '2025-12-29',
  '2025-12-30',
  '2025-12-31',
  '2026-12-28',
  '2026-12-29',
  '2026-12-30',
  '2026-12-31',
]

export const LANDING_BENEFITS_OFFSET = 40

export const LANDING_MENU_ITEM = {
  FAQ: 'faq',
}

export const SCREEN_TYPE = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
}

export const STEPS = {
  SETS: 'sets',
  DELIVERY: 'delivery',
  COUNTERPARTY: 'counterparty',
  CONTACTS: 'contacts',
  CHECKOUT: 'checkout',
}

export const PAGE = {
  DIGITAL: 'digital',
  PLASTIC: 'plastic',
  PLASTIC_NEW: 'plasticNew',
}

export const DELIVERY_TYPE = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
}

export const FORM_THEME = {
  MVP: 'mvp',
  TARGET: 'target',
}

export const DELIVERY_INFO_TYPE = {
  PREVIEW: 'preview',
  SUMMARY: 'summary',
}

export const FORM_STEP = {
  DELIVERY: 'delivery',
  COUNTERPARTY: 'counterparty',
  CONTACTS: 'contacts',
}

/** Порядок шагов второго экрана */
export const FORM_STEPS = [
  FORM_STEP.DELIVERY,
  FORM_STEP.COUNTERPARTY,
  FORM_STEP.CONTACTS,
]

export const ERROR_NAME = FORM_STEP

/** Хардкод данных для Москвы - https://jira01.goldapple.ru/browse/B2BGC-82 */
export const MOSCOW_CITY_ID = '0c5b2444-70a0-4932-980c-b4dc0d3f02b5'
export const MOSCOW_PICKUP_DATA = {
  id: '902',
  name: 'РЦ Золотое Яблоко',
  address: 'ул. Дорожная, дом 1, корпус 5, строение 4',
  subwayInfo: [
    {
      station: 'Южная',
      distance: '',
    },
  ],
  phone: '+7 968 439 85 98\n+7 903  503 22 81',
  schedule: {
    label: 'режим работы',
    text: 'пн-пт: 10:00-20:00',
  },
}

/** Маска для инпутов номинал и количество в КЭПК и КППК */
export const SET_INPUT_MASK = {
  mask: Number,
  thousandsSeparator: ' ',
  unmask: true,
  min: 1,
  max: 1e6 - 1, // 999999
  scale: 0,
  prepare: (value, masked) => {
    /* текущее значение поля **/
    const currentValue = masked.rawInputValue

    /* Если вводим первую цифру и это "0" — игнорируем её **/
    if (!currentValue && value === '0') {
      return
    }

    return value
  },
}

/** Константы для аналитики */
export const ANALYTICS_EVENT_TYPE = {
  KEPK: 'kepk',
  KPPK: 'kppk',
}

export const GTM_PLACE = {
  HEADER: 'header',
  STEP_2: 'step_2',
}

export const GTM_DELIVERY_TYPE = {
  COURIER: 'courier',
  PICKUP: 'pickup',
}
