import { endpoints as commonEndpoints } from '../../../repositories/common/endpoints'

export const endpoints = {
  ...commonEndpoints,
  getRecommendations: () => ({
    url: 'catalog/placements',
  }),
  getBenefits: () => ({
    url: 'catalog/benefits',
  }),
  getBenefitsV2: () => ({
    url: 'catalog/benefits/v2',
  }),
  getSlotsV2: () => ({
    url: 'catalog/product/slots-v2',
  }),
  getSlotsV3: () => ({
    url: 'catalog/product/slots-v3',
  }),
  getCategorySlots: () => ({
    url: 'catalog/product/category-slots',
  }),
  getCityStores: () => ({
    url: 'retail-stores/city/stores',
  }),
  getStoresStock: () => ({
    url: 'retail-stores/city/stores/stock',
  }),
}
