/* eslint-disable no-useless-call */
export class InterceptorsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Перехватчик для запроса
   *
   * Хедер "Bearer %token%" устанавливается на BFF
   *
   * @returns {object|Promise} config запроса | Promise.reject()
   */
  callbackRequestAuth() {
    return {
      success: async (config) => {
        // Проверяем, нужно ли обновить токен
        await this._refreshTokensOnRequest(config)

        return config
      },

      error: (error) => {
        return Promise.reject(error)
      },
    }
  }

  /**
   * Перехватчик для ответа
   *
   * @returns {object|Promise} config запроса | Promise.reject()
   */
  callbackResponseAuth() {
    return {
      success: (response) => {
        return response
      },

      error: async (error) => {
        // Если ошибка обновления токена НЕ при инициализации
        // то показываем модалку для входа
        if (
          error.config?._isRefreshRequest &&
          this.gaApp.stores.auth.main.isInit
        ) {
          this.gaApp.services.auth.main.onAuthFailed()
        }

        // Если пришла 401, и это не обновление токена,
        // то открываем модалку для входа
        if (
          !error.config?._isRefreshRequest &&
          error.response?.status === 401
        ) {
          this.gaApp.eventBus.publish('module/auth/logout')

          // задержка на выполнение подписчиков события логаута
          await new Promise((resolve) => setTimeout(resolve, 1000))

          window.location.assign('/#auth')

          await new Promise(() => {})
        }

        return Promise.reject(error)
      },
    }
  }

  register() {
    this.gaApp.api.addRequestCallback(this.callbackRequestAuth())
    this.gaApp.api.addResponseCallback(this.callbackResponseAuth())
  }

  /**
   * Перехватчик для ответа на запрос
   * Обновляем токен если потребуется
   */
  async _refreshTokensOnRequest(config) {
    const { auth } = this.gaApp.services

    if (
      !config._isRefreshRequest &&
      this.gaApp.stores.auth.main.isInit && // Проинциализирована ли авторизация
      !this.gaApp.stores.auth.main.isTokenPending // Ожидается ли ответ от сервера на запрос рефреша токена
    ) {
      const { hasAccessToken, hasRefreshToken } = auth.core.checkTokens()

      if (!hasAccessToken && hasRefreshToken) {
        await auth.core.doRefreshTokens()
      }
    }
  }
}
