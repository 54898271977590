import { PAGE_TYPE } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    try {
      // Так как Флакон раскатан не на всех странах,
      // то не нужно его показывать там где его нет
      if (!this.gaApp.features.get('flaconStart')) {
        return this.gaApp.redirectError({ statusCode: 404 })
      }

      switch (redirect.pageType) {
        // Статьи
        case PAGE_TYPE.ARTICLE_LISTING:
          return await this.gaApp.services.articles.listing.loadData()

        case PAGE_TYPE.PREVIEW_ARTICLE_LISTING:
          return await this.gaApp.services.articles.listing.loadPreviewListing(
            redirect.id,
          )

        case PAGE_TYPE.ARTICLE_ONE:
          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.one.fetchArticleByUrl(
            redirect.url,
          )

        case PAGE_TYPE.PREVIEW_ARTICLE:
          return await this.gaApp.services.articles.one.fetchPreviewArticle(
            redirect.id,
          )

        case PAGE_TYPE.ARTICLE_LISTING_FILTER:
          return await this.gaApp.services.articles.listing.loadFilterArticles(
            redirect,
          )

        // Стримы
        case PAGE_TYPE.STREAM_LISTING:
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.streams.loadData()

        case PAGE_TYPE.PREVIEW_STREAM_LISTING:
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.streams.loadPreviewListingData(
            redirect.id,
          )

        case PAGE_TYPE.STREAM_ONE:
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return this.gaApp.services.articles.stream.fetchDataByUrl(
            redirect.url,
          )

        case PAGE_TYPE.STREAM_LIVE: {
          const useLiveIframe =
            this.gaApp.features.get('flaconUseStreamers') &&
            this.gaApp.features.get('flaconUseStreamIframe')

          if (!useLiveIframe) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          this.gaApp.stores.articles.streamLive.externalId = redirect.externalId

          return
        }

        case PAGE_TYPE.PREVIEW_STREAM:
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.stream.fetchDataById(
            redirect.id,
          )

        case PAGE_TYPE.STREAM_LISTING_FILTER:
          if (!this.gaApp.features.get('flaconUseStreamers')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          return await this.gaApp.services.articles.streams.fetchStreamsFilterListing(
            redirect,
          )

        // баннер
        case PAGE_TYPE.PREVIEW_BANNER:
          if (!this.gaApp.features.get('flaconPromo')) {
            return this.gaApp.redirectError({ statusCode: 404 })
          }

          this.gaApp.services.header.theme.setHeaderRegularColorScheme()
          return await this.gaApp.services.articles.advBanners.loadBannerPreview(
            {
              campaignId: redirect.campaignId,
              bannerId: redirect.bannerId,
            },
          )

        default:
          return undefined
      }
    } catch (error) {
      this.redirectError(error)
    }
  }

  redirectError(error = null) {
    if (error.statusCode >= 500) {
      return this.gaApp.redirectError({
        statusCode: 500,
        message: error.message,
      })
    }

    return this.gaApp.redirectError({
      statusCode: 404,
      message: error.message,
    })
  }
}
