import {
  LISTING_API_SERVICE_KEY,
  LISTING_SERVICE_KEY,
  LISTING_STORE_KEY,
} from '../constants'

export const getListingKeys = (listingType) => {
  const key = listingType.toUpperCase()

  return {
    storeKey: LISTING_STORE_KEY[key],
    serviceKey: LISTING_SERVICE_KEY[key],
    serviceApiKey: LISTING_API_SERVICE_KEY[key],
  }
}
