<template>
  <svg fill="none" stroke="none" viewBox="0 0 40 40">
    <path fill="#EDEDED" d="M0 0h28l12 12v28H0V0Z" />
    <path fill="#D9D9D9" d="m28 0 12 12H28V0Z" />
    <path
      fill="#000"
      stroke="none"
      d="M13.95 25.105h-1.32v6.18h-2.26v-14.88h3.58c1.6 0 2.32.68 2.32 2.46v3.52c0 1.76-.78 2.72-2.32 2.72Zm-1.32-6.68v4.54h.58c.68 0 .78-.48.78-1.08v-2.44c0-.58-.12-1.02-.78-1.02h-.58Zm4.821 12.86v-14.88h3.46c1.94 0 2.54 1.1 2.54 2.86v9.06c0 1.82-.68 2.96-2.54 2.96h-3.46Zm2.26-12.86v10.7h.54c.68 0 .88-.36.88-1.16v-8.38c0-.86-.22-1.16-.78-1.16h-.64Zm5.037 12.86v-14.88h5.14v2.16h-2.88v3.92h2.28v2.26h-2.28v6.54h-2.26Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-file-pdf',
}
</script>
