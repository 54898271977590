import { ApiService } from './api.service'
import { BrandsService } from './brands.service'
import { FiltersService } from './filters.service'
import { InfiniteScrollService } from './infinite-scroll.service'
import { LocalStorageService } from './local-storage.service'
import { NotificationsService } from './notifications.service'
import { PlpService } from './plp.service'
import { ProductsService } from './products.service'

export const services = (gaApp) => ({
  notifications: new NotificationsService(gaApp),
  products: new ProductsService(gaApp),
  brands: new BrandsService(gaApp),
  api: new ApiService(gaApp),
  plp: new PlpService(gaApp),
  infiniteScroll: new InfiniteScrollService(gaApp),
  filters: new FiltersService(gaApp),
  localStorage: new LocalStorageService(gaApp),
})
