export const CAN_VIDEO_AUTOPLAY_DEFAULT = true
export const LAYOUT_VISIBLE_DEFAULT = true
export const LAYOUT_TRANSPARENT_DEFAULT = false

export const state = () => {
  return {
    isSSR: false,

    init: false,
    isErrorInit: false,
    mounted: false,
    locale: {},
    locales: [],

    canVideoAutoplay: CAN_VIDEO_AUTOPLAY_DEFAULT,

    withFocusKeyboard: false,
    focusSource: null,
    withHover: false,

    zIndexBase: 9999,
    zIndexes: {},

    headerVisible: false,
    headerTransparent: LAYOUT_TRANSPARENT_DEFAULT,

    footerVisible: false,
    footerTransparent: LAYOUT_TRANSPARENT_DEFAULT,

    mainVisible: LAYOUT_VISIBLE_DEFAULT,
    mainTransparent: LAYOUT_TRANSPARENT_DEFAULT,

    mobileAppBannerVisible: LAYOUT_VISIBLE_DEFAULT,

    router: {
      from: null,
      to: null,
    },
    routeChange: false,

    deviceId: null,

    hasForceUpdate: false,
  }
}
