export const state = () => {
  return {
    reviews: {},
    matchedReviewsCount: 0,
    maxReviewsCount: 0,
    productId: null,
    pageNumber: 1,
    pending: false,
    hasError: false,
  }
}
