import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { GaIconPrimaryGlobe } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaCountryFlag } from '@ga/ui-components/country-flag'
import { GaIcon } from '@ga/ui-components/icon'
import { GaImage } from '@ga/ui-components/image'

import { GaHeaderNavMinimizedArrow } from '../nav-minimized-arrow'

import { SWITCHER_COMPONENT } from './scripts/const'

// @vue/component
export default {
  name: 'ga-header-nav-minimized-lang-switcher',
  components: {
    GaHeaderNavMinimizedArrow,
    GaAction,
    GaIcon,
    GaIconPrimaryGlobe,
    GaCountryFlag,
    GaImage,
  },
  setup() {
    const { $gaApp } = useContext()

    const isNewFlagComponent = $gaApp.features.get('newFlagComponent')

    const currentStore = computed(
      () => $gaApp.services.app.langSwitcher.currentStore,
    )

    const currentLanguage = computed(
      () => $gaApp.services.app.langSwitcher.currentLanguage,
    )

    const storeSwitcherFeatureOn = computed(() =>
      $gaApp.features.get('storeSwitcher'),
    )

    const langSwitcherFeatureOn = computed(() =>
      $gaApp.features.get('langSwitcher'),
    )

    const langSwitcherComponent = computed(
      () => SWITCHER_COMPONENT[langSwitcherFeatureOn.value],
    )

    const storeSwitcherComponent = computed(
      () => SWITCHER_COMPONENT[storeSwitcherFeatureOn.value],
    )

    const onLangClick = () => {
      if (langSwitcherFeatureOn.value) {
        $gaApp.services.app.langSwitcher.openModal()
      }
    }
    const onCountryClick = () => {
      if (storeSwitcherFeatureOn.value) {
        $gaApp.services.app.langSwitcher.openCountryModal()
      }
    }

    return {
      storeSwitcherFeatureOn,
      langSwitcherFeatureOn,

      currentStore,
      currentLanguage,

      onLangClick,
      onCountryClick,

      isNewFlagComponent,
      langSwitcherComponent,
      storeSwitcherComponent,
    }
  },
}
