export class LocationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Установка массива городов для заказа КППК
   * @param {Array} cities города, в которых возможен заказ КППК
   */
  setCities(cities) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.cities = cities
  }

  /**
   * Установка выбранного города для заказа КППК
   * @param {string} cityId идентификатор города
   */
  setCurrentCityId(cityId) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.city = cityId
  }

  /**
   * Смена города для заказа КППК
   * @param {string} cityId идентификатор города
   */
  async changeCurrentCityId({ cityId } = {}) {
    if (this.gaApp.stores.giftCardsB2b.plasticNew.config.city === cityId) return

    /** Установка id города в store */
    this.setCurrentCityId(cityId)

    /** Получение конфигурации по выбранному городу */
    const response = await this.updateConfigData(cityId)
    if (!response) return

    /** Вывод уведомления о сбросе выбранных наборов в заказе */
    this.gaApp.services.notification.main.open(
      this.gaApp.i18n.t('giftCardsB2b.plasticNew.city.notification'),
    )

    /** Сброс наборов */
    this.gaApp.services.giftCardsB2b.plasticSets.reset()

    /** Сброс чекбокса подтверждения данных контрагента */
    this.gaApp.services.giftCardsB2b.plasticCounterparty.clearCheckbox()

    /** Сброс значений доставки */
    this.gaApp.services.giftCardsB2b.plasticDelivery.resetAddressData()

    /** Сброс данных шагов формы */
    this.gaApp.services.giftCardsB2b.plasticFormSteps.clearStepsForm()
  }

  /** Установка города по умолчанию из конфигурации */
  setDefaultCity() {
    const defaultCityId =
      this.gaApp.stores.giftCardsB2b.plasticNew.defaultCityId

    if (!defaultCityId) return

    this.setCurrentCityId(defaultCityId)
  }

  /**
   * Поиск города в базовой конфигурации (проверка по city_fias_id)
   *
   * @param {string} cityId
   * @returns
   */
  getCityIdInConfig(cityId) {
    return this.gaApp.stores.giftCardsB2b.plasticNew.config.cities.find(
      (city) => city.value === cityId,
    )?.value
  }

  /**
   *  Определение города на основе координат пользователя
   */
  setCityByCoordinates(cityId) {
    /** Поиск города в базовой конфигурации */
    const cityIdInConfig = this.getCityIdInConfig(cityId)
    const geoCity = this.gaApp.stores.giftCardsB2b.plasticNew.geoCity

    /** Вызов модалки с инфо и предложением смены города */
    if (!cityIdInConfig && geoCity) {
      this.changeSelectModalVisibility(true)

      return undefined
    }

    /** Установка города по координатам в store */
    this.setCurrentCityId(cityIdInConfig)

    /** Возвращаем значение для метода инициализации города – this.initializeCity() */
    return cityIdInConfig
  }

  /**
   * Инициализация города
   * а) по координатам геопозиции
   * б) дефолтный из базовой конфигурации кппк, если город по координатам не найден
   */
  initializeCity(cityId) {
    /** Если город уже установлен ранее, не меняем его при инициализации */
    if (this.gaApp.stores.giftCardsB2b.plasticNew.config.city) {
      return
    }

    /** Устанавливаем город по координатам, только если ФТ вкл */
    const cityByCoordinates =
      this.gaApp.features.get('giftCardsCorpGetUserGeo') &&
      this.setCityByCoordinates(cityId)

    /** Установка дефолтного города из конфигурации, если город по геопозиции не найден */
    if (!cityByCoordinates) {
      this.setDefaultCity()
    }
  }

  /**
   * Повторный запрос для обновления конфигурации по выбранному городу
   * @param {string} cityId идентификатор города
   */
  async updateConfigData(cityId) {
    return await this.gaApp.services.giftCardsB2b.plastic.getPlasticV2Config(
      cityId,
    )
  }

  /**
   * Получение адреса пользователя по координатам геопозиции
   * Использует методы из public.service модуля location
   */
  async getUserGeoAddress() {
    /** Если ФТ выключен, возвращаем null */
    if (!this.gaApp.features.get('giftCardsCorpGetUserGeo')) return null

    try {
      /** Получение координат геопозиции */
      const { latitude, longitude } =
        await this.gaApp.services.location.public.getCoordsByBrowser()

      if (latitude && longitude) {
        /** Получение адреса по координатам */
        const data =
          await this.gaApp.services.location.public.getReverseGeocode({
            lon: longitude,
            lat: latitude,
          })

        if (!data?.address?.cityId) {
          throw new Error('Error receiving location data')
        }

        this.setGeoCityToStore(data)

        return data
      }
    } catch (error) {
      console.error(error)
    }

    return null
  }

  setGeoCityToStore(geoData) {
    if (!geoData?.address?.city) return

    this.gaApp.stores.giftCardsB2b.plasticNew.geoCity = geoData?.address?.city
  }

  /**
   * Управление модальным окном невозможности доставки в определенный город
   * @param {boolean} visibility видимость модального окна выбора реакции.
   */
  changeSelectModalVisibility(visibility) {
    this.gaApp.stores.giftCardsB2b.plasticNew.isNoDeliveryModalOpen = visibility
  }

  /**
   * Установка региона в store на основе cityId
   */
  async setCityRegion() {
    if (!this.gaApp.features.get('giftCardsCorpGetUserGeo')) return

    this.gaApp.stores.giftCardsB2b.plasticNew.config.region = ''

    try {
      const objectId = this.gaApp.stores.giftCardsB2b.plasticNew.config.city
      const showNotification = false

      const { location } =
        await this.gaApp.services.location.public.getGeocodeCities(
          objectId,
          '',
          showNotification,
        )
      const { city, region, regionTypeFull } = location.address

      const regionName =
        this.gaApp.services.location.public.composeRegionWithType(
          region,
          regionTypeFull,
        )

      if (regionName === city) return

      this.gaApp.stores.giftCardsB2b.plasticNew.config.region = regionName
    } catch (error) {
      console.error(error)
    }
  }
}
