export const TYPE = {
  GTM_4: 'gtm_4',
}
export const MODAL = {
  DOWNLOAD_APP: 'download_app',
}
export const EVENT = {
  USER_INTERACTION: 'user_interaction',
}
export const EVENT_CATEGORY = {
  MODALS: 'modals',
}
export const ACTION_TYPE = {
  SHOW: 'modal_show',
  CLICK: 'click_button',
}
