import { ORDER_TOTAL_NAMES } from '../../../../constants'
import { EVENT } from '../../../constants'
import { DigiBaseModel } from '../digi-base.model/digi-base.model'
import { DigiProductBaseModel } from '../digi-poduct-base.model/digi-poduct-base.model'

export class DigiThankYouViewModel extends DigiBaseModel {
  constructor(data) {
    super({
      event: EVENT.THANK_YOU_VIEW.DIGI,
    })

    const subtotal = data.orderTotal.list.find(
      ({ name }) => name === ORDER_TOTAL_NAMES.SUBTOTAL,
    )

    this.data.cartId = data.cartId
    this.data.orderId = data.id
    this.data.total = `${data.orderTotal.total.amount}`
    this.data.subtotal = `${subtotal?.value.amount}`

    this.data.cartItems = data.products.map(
      (product) => new DigiProductBaseModel(product),
    )
  }
}
