import ar from './ar.json'
import arAeOptions from './ar-AE.options.json'
import arQaOptions from './ar-QA.options.json'
import arSaOptions from './ar-SA.options.json'
import en from './en.json'
import enAeOptions from './en-AE.options.json'
import enQaOptions from './en-QA.options.json'
import enSaOptions from './en-SA.options.json'
import kk from './kk.json'
import ru from './ru.json'
import ruBY from './ru-BY.json'
import ruBYOptions from './ru-BY.options.json'
import ruKZ from './ru-KZ.json'
import ruKZOptions from './ru-KZ.options.json'
import ruRUOptions from './ru-RU.options.json'

export const locales = {
  'ar-AE': [ar, arAeOptions],
  'ar-QA': [ar, arQaOptions],
  'ar-SA': [ar, arSaOptions],
  'ar-RU': ar,
  'en-AE': [en, enAeOptions],
  'en-QA': [en, enQaOptions],
  'en-SA': [en, enSaOptions],
  'en-RU': en,
  'ru-RU': [ru, ruRUOptions],
  'ru-BY': [ru, ruBY, ruBYOptions],
  'ru-KZ': [ru, ruKZ, ruKZOptions],
  'kk-KZ': kk,
}
