/**
 * Удаляет в объекте все пустые поля
 * @param {object} obj - исходный объект
 * @returns {object} - объект, без пустых полей
 */
export const removeEmptyFields = (obj) => {
  return Object.entries(obj)
    .filter(
      ([_, value]) => value !== null && value !== undefined && value !== '',
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
}
