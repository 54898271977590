export class LikesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получить данные об идентификаторе пользователя или устройсва
   *
   * @returns {string} - идентификатор пользователя или устройсва
   */
  getUserId() {
    const userId = this.gaApp.services.user.main.data.id
    const deviceId = this.gaApp.services.app.main.deviceId

    return userId || deviceId
  }

  /**
   * Получить данные по лайкам для заданной статьи
   *
   * @param {string} articleId - идентификатор статьи, информацию по лайкам которой хотим получить
   * @returns {Promise<UserLikesInfo>} - кол-во лайков у статьи и проставлен ли лайк текущим пользователем
   */
  getArticleLikesInfo(articleId) {
    if (this.gaApp.isServer) {
      return
    }

    return this.gaApp.services.articles.apiLikes.getArticleLikesInfo({
      articleId,
      userId: this.getUserId(),
    })
  }

  /**
   * Установить лайк для заданной статьи
   *
   * @param {string} articleId - идентификатор статьи, информацию по лайкам которой хотим получить
   * @returns {Promise<LikesInfo>} - кол-во лайков у статьи после установки лайка текущим пользователем
   */
  async setArticleLike(articleId) {
    try {
      const { data } =
        await this.gaApp.services.articles.apiLikes.setArticleLike({
          articleId,
          userId: this.getUserId(),
        })

      this.gaApp.stores.articles.one.likesCount = data.likesCount
    } catch (error) {
      this.gaApp.stores.articles.one.likesCount += 1
    } finally {
      this.gaApp.stores.articles.one.liked = true
    }
  }

  /**
   * Снять лайк с заданной статьи
   *
   * @param {string} articleId - идентификатор статьи, информацию по лайкам которой хотим получить
   * @returns {Promise<LikesInfo>} - кол-во лайков у статьи после снятия лайка текущим пользователем
   */
  async removeArticleLike(articleId) {
    try {
      const { data } =
        await this.gaApp.services.articles.apiLikes.removeArticleLike({
          articleId,
          userId: this.getUserId(),
        })

      this.gaApp.stores.articles.one.likesCount = data.likesCount
    } catch (error) {
      // если часто нажимать при 0 кол-ве лайков, попался кейс что лайки уходили в минус
      this.gaApp.stores.articles.one.likesCount = Math.max(
        this.gaApp.stores.articles.one.likesCount - 1,
        0,
      )
    } finally {
      this.gaApp.stores.articles.one.liked = false
    }
  }
}
