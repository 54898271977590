import { TYPE } from '../../constants'
import { GtmGa4DownloadAppClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class DownloadAppClickBuilder extends BaseBuilder {
  constructor() {
    const models = new Map([[TYPE.GTM_4, GtmGa4DownloadAppClickModel]])
    super(models)
  }
}
