import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { SWIPE_DIRECTION } from '../../children/notifications-swiper/scripts/const'

export const useNotificationsMods = (axis, direction, stacked) => {
  const { $gaApp } = useContext()

  const swipe = computed(() => {
    const axisName = SWIPE_DIRECTION[axis.value]
    return axisName[direction.value]
  })

  const queued = computed(() => getQueuedValue())

  const getQueuedValue = () => {
    try {
      /**
       * Проверка на undefined нужна для того, что бы в браузере не стреляла ошибка
       * Cannot read properties of undefined (reading 'value')
       *
       * В каких кейсах stacked может быть undefined, на момент написания комментария, установить не удалось
       */
      return !stacked.value
    } catch (error) {
      /**
       * Обрабатываем ошибку, что бы получить больше информации для отладки
       */
      const errorApm = new Error('Ошибка stacked в notifications')
      errorApm.errorData = JSON.stringify(error)
      errorApm.name = 'pkgUI-notify-stacked'

      $gaApp.services.app.apm.captureError(errorApm)

      return false
    }
  }

  return {
    swipe,
    queued,
  }
}
