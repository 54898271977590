export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  formatDateForView(date, format) {
    return this.gaApp.libs.dateWrapper(date).format(format)
  }

  isNewApiForListingTitles() {
    return this.gaApp.features.get('flaconUseNewApiForListingTitles')
  }

  isNewApiForFilters() {
    return this.gaApp.features.get('flaconUseNewFilters')
  }

  async fetchArticlePreviewListing(id) {
    try {
      const response = this.isNewApiForListingTitles()
        ? await this.gaApp.repositories.articles.main.fetchPreviewListingV2(id)
        : await this.gaApp.repositories.articles.main.fetchPreviewListing(id)

      return this.prepareListingData(response)
    } catch {
      return {
        currentPage: 0,
        totalCount: 0,
        articles: [],
      }
    }
  }

  fetchArticle(id) {
    return this.gaApp.repositories.articles.main.fetchArticle(id)
  }

  fetchArticleByUrl(url) {
    return this.gaApp.repositories.articles.main.fetchArticleByUrl(url)
  }

  async fetchFilters() {
    try {
      const response =
        await this.gaApp.repositories.articles.main.fetchListingFilters()
      return response.data
    } catch {
      return { filters: [] }
    }
  }

  // Запрос списка статей
  async fetchArticles(params) {
    try {
      // Новые фильтры
      if (this.isNewApiForFilters()) {
        const response =
          await this.gaApp.repositories.articles.main.fetchArticlesListingV3(
            params,
          )
        return this.prepareListingData(response)
      }

      // Новые заголовки
      if (this.isNewApiForListingTitles()) {
        const response =
          await this.gaApp.repositories.articles.main.fetchArticlesListingV2(
            params,
          )
        return this.prepareListingData(response)
      }

      // Базовая/старая версия
      const response =
        await this.gaApp.repositories.articles.main.fetchArticlesListing(params)
      return this.prepareListingData(response)
    } catch {
      return {
        currentPage: 0,
        totalCount: 0,
        articles: [],
      }
    }
  }

  // Запрос списка статей конкретной рубрики
  async fetchRubricArticles(params) {
    try {
      const response =
        await this.gaApp.repositories.articles.main.fetchRubricArticles(params)
      return this.prepareListingData(response)
    } catch {
      return {
        currentPage: 0,
        totalCount: 0,
        articles: [],
      }
    }
  }

  prepareListingData(response) {
    const { currentPage, totalCount, articles, title, seo } = response.data

    const mappedArticles = articles.map((item) => ({
      image: item.imageUrl,
      date: item.publishedDate
        ? this.formatDateForView(item.publishedDate, 'D MMMM YYYY')
        : '',
      subtitle: item.subTitle,
      ...item,
    }))

    return {
      currentPage,
      totalCount,
      title,
      seo,
      articles: mappedArticles,
    }
  }

  // fetch products for content
  async fetchProductsBySku(skuArray = []) {
    const user = this.gaApp.services.user.main.data
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const params = {
      itemIds: skuArray,
      customerGroupId: user.customerGroupId || user.groupId || '0',
      ...addressParams,
    }

    try {
      const { data } =
        await this.gaApp.repositories.articles.main.fetchProductsBySku(params)
      return data.products || []
    } catch (e) {
      console.error('fetchProductsBySku error: ' + e.message)
      return []
    }
  }

  async fetchProductsByCategoryId({ categoryId, filters, pageNumber }) {
    const user = this.gaApp.services.user.main.data
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    try {
      const { data } =
        await this.gaApp.repositories.articles.main.fetchProducts({
          categoryId,
          pageNumber,
          filters,
          customerGroupId: user.customerGroupId || user.groupId || '0',
          ...addressParams,
        })

      return {
        products: data.products,
        count: data.count,
      }
    } catch (e) {
      console.error('fetchProductsByCategoryId error: ' + e.message)
      throw e
    }
  }

  /**
   * Получает список продуктов по заданному url категории
   *
   * @param url - url категории, продукты которой необходимо получить
   * @param pageNumber - номер страницы
   *
   * @return {Promise<{count: number, products: *[]} - список продуктов и их кол-во
   */
  async fetchProductsByUrl(url, pageNumber = 1) {
    try {
      const { data } =
        await this.gaApp.repositories.articles.main.fetchCategoryDataByUrl(url)

      // если пришел редирект, перезапросить данные листинга
      if (data.redirect) {
        return this.fetchProductsByUrl(data.redirect, pageNumber)
      }

      // если не пришел entityId - значит такой категории нет
      if (!data.entityId) {
        throw new Error('некорректный url категории ' + url)
      }

      // зная entityId категории и фильтры, запросить продуктуы
      const { products, count } = await this.fetchProductsByCategoryId({
        categoryId: data.entityId,
        filters: data.filters,
        pageNumber,
      })

      return {
        products: products || [],
        count,
      }
    } catch (e) {
      console.error('fetchProductsUrl error: ' + e.message)
      return {
        products: [],
        count: 0,
      }
    }
  }

  async fetchArticleViewed({ captchaToken, id }) {
    try {
      await this.gaApp.repositories.articles.main.fetchArticleViewed({
        captchaToken,
        id,
      })
    } catch (e) {
      console.error(e.message)
    }
  }
}
