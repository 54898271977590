import ar from './ar.json'
import arQA from './ar-QA.json'
import en from './en.json'
import enQA from './en-QA.json'
import kk from './kk.json'
import ru from './ru.json'
import ruBY from './ru-BY.json'
import ruKZ from './ru-KZ.json'
import ruRU from './ru-RU.json'

export const locales = {
  'ru-RU': [ru, ruRU],
  'ru-BY': [ru, ruBY],
  'ru-KZ': [ru, ruKZ],
  'kk-KZ': kk,
  'en-AE': [en, enQA],
  'en-QA': [en, enQA],
  'en-SA': [en, enQA],
  'en-RU': en,
  'ar-AE': [ar, arQA],
  'ar-QA': [ar, arQA],
  'ar-SA': [ar, arQA],
  'ar-RU': ar,
}
