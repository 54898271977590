export class GtmGa4CardItemBaseModel {
  constructor({ name, position, promotionName, newOffers, filters }) {
    this.creative_name = name
    this.creative_slot = `card_${position}`
    this.promotion_name = newOffers ? 'акции' : promotionName // уберется как только дропнутся старые акции

    if (newOffers) {
      // условие уберется как только дропнутся старые акции
      // ищем выбранные фильтры
      const selectedFilters = filters?.filter((filter) => filter.isSelected)

      // маппим их в строку к виду "подарки,3=2,анонс"
      this.filters = selectedFilters
        ?.map((selectedFilter) => selectedFilter.name)
        .join(',')
    }

    this.items = [
      {
        creative_name: name,
        creative_slot: `card_${position}`,
        promotion_name: newOffers ? 'акции' : promotionName,
      },
    ]
  }
}
