import { MODULE_TYPE, PAGE_TYPE } from '~/modules/customer'
import { AUTH_MODAL_REASON_TO_OPEN } from '~/modules/modal'

export class ProductsService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.setSubscriptions()
  }

  setItem(id) {
    if (!this.gaApp.stores.favorites.main.products.includes(id)) {
      this.gaApp.stores.favorites.main.products.push(id)
    }
  }

  setItems(ids) {
    this.gaApp.stores.favorites.main.products = Array.from(
      new Set([...this.gaApp.stores.favorites.main.products, ...ids]),
    )
  }

  removeItem(id) {
    const index = this.gaApp.stores.favorites.main.products.indexOf(id)

    if (index !== -1) {
      this.gaApp.stores.favorites.main.products.splice(index, 1)
    }
  }

  addItem(item, hasNotification) {
    return this.gaApp.services.favorites.api.addProduct(item, hasNotification)
  }

  addItems(ids) {
    return this.gaApp.services.favorites.api.addProducts(ids)
  }

  /**
   * * Подписываемся на ивенты
   */
  setSubscriptions() {
    // на событие login
    // добавляем последний "отложенный" товар в избранное
    const loginUnsubscriber = this.gaApp.eventBus.subscribe(
      'module/auth/login',
      ({ meta } = {}) => {
        const item = this.gaApp.features.get('authorizationTypesEnabled')
          ? this.gaApp.services.favorites.localStorage.getLocalStorageAuthProductDeferredKey()
          : this.gaApp.stores.favorites.main.productDeferred

        const isReasonAddToFavorite =
          meta?.reason === AUTH_MODAL_REASON_TO_OPEN.ADD_PRODUCT_TO_FAVORITES

        if (item && isReasonAddToFavorite) {
          this.addItem(item, true)
        }

        this.clearItemDeferred()
        loginUnsubscriber()
      },
    )
  }

  setItemDeferred(item) {
    if (this.gaApp.features.get('authorizationTypesEnabled')) {
      this.gaApp.services.favorites.localStorage.setLocalStorageAuthProductDeferredKey(
        item,
      )
    } else {
      this.gaApp.stores.favorites.main.productDeferred = item
    }
  }

  clearItemDeferred() {
    if (this.gaApp.features.get('authorizationTypesEnabled')) {
      this.gaApp.services.favorites.localStorage.removeLocalStorageAuthProductDeferredKey()
    } else {
      this.gaApp.stores.favorites.main.productDeferred = null
    }
  }

  deleteItem(item) {
    this.gaApp.services.favorites.api.deleteProduct(item)
  }

  setHasProducts(productsData) {
    const { countSelectedFilters, products } = productsData
    if (countSelectedFilters) {
      this.gaApp.stores.favorites.plp.hasProducts = true
    } else {
      this.gaApp.stores.favorites.plp.hasProducts = Boolean(products.length)
    }
  }

  /**
   * Перенаправляем на страницу избранного
   */
  redirectToFavorites() {
    this.gaApp.services.app.router.pushToRoute({
      moduleType: MODULE_TYPE,
      pageType: PAGE_TYPE.FAVORITES,
    })
  }
}
