export class PriceService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Считает вес цены и возвращает объект, единственным ключом
   * которого является строка вида «weight-*weight_value*»
   *
   * {
   *  [weight-*weight_value*]: true
   * }
   *
   * @param {object} weight объект цены, который приходит с BFF
   * @returns Стилевой модификатор вида weight-*weight_value*
   */
  getPriceSizeModifier(weight) {
    if (!weight) {
      return {}
    }

    // Формируем ключ модификатора вида weight-7 / weight-8-5 / weight-10
    const key = 'weight-' + weight.toString().split('.').join('-')

    return {
      [key]: true,
    }
  }

  setTotalsWeight() {
    const { total, discount, currency } = this.gaApp.stores.cart.main.totals

    const totalWeight = this.gaApp.services.app.currency.getPriceWeight({
      amount: total,
      currency,
      withSymbol: true,
    })?.withCurrency

    const discountWeight = this.gaApp.services.app.currency.getPriceWeight({
      amount: discount,
      currency,
      withSymbol: true,
    })?.withCurrency

    this.gaApp.stores.cart.main.totalsWeight =
      discountWeight > totalWeight ? discountWeight : totalWeight
  }
}
