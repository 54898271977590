import { analytics } from './analytics'
import { locales } from './locales'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './store'
export * from './constants'

export const checkout = {
  meta: {
    name: 'checkout',
    locales,
    routes,
  },
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}
