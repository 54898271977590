import { SEO_ATTRS_KEY, STREAM_STATUS } from '../../constants'

export const getters = () => ({
  titleSeo() {
    return this.seo.find((item) => item.key === SEO_ATTRS_KEY.TITLE)?.value
  },

  descriptionSeo() {
    return this.seo.find((attr) => attr.key === SEO_ATTRS_KEY.DESCRIPTION)
      ?.value
  },

  additionalAttributesSeo() {
    return this.seo.filter(
      (attr) =>
        attr.key !== SEO_ATTRS_KEY.DESCRIPTION &&
        attr.key !== SEO_ATTRS_KEY.TITLE &&
        attr.value,
    )
  },

  isStreamStatusSoon() {
    return this.status === STREAM_STATUS.SOON
  },

  isStreamStatusLive() {
    return this.status === STREAM_STATUS.LIVE
  },

  hasTranslationDate() {
    return !!this.translationDate
  },
})
