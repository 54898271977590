import { ApiService } from './api.service'
import { CleanupService } from './cleanup.service'
import { CurrencyService } from './currency.service'
import { MainService } from './main.service'
import { PageService } from './page.service'
import { RecipientService } from './recipient.service'
import { ShippingCourierComment } from './shipping-courier-comment'
import { ThankYouService } from './thank-you'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  cleanup: new CleanupService(gaApp),
  currency: new CurrencyService(gaApp),
  api: new ApiService(gaApp),
  page: new PageService(gaApp),
  recipient: new RecipientService(gaApp),
  thankYou: new ThankYouService(gaApp),
  shippingCourierComment: new ShippingCourierComment(gaApp),
})
