export const CARD_SIZES = {
  BIG: 'big',
}

export const CARD_ALIGN = {
  END: 'end',
  START: 'start',
}

export const TITLE_VIEW = {
  SHORT: 'short',
  MEDIUM: 'medium',
  LARGE: 'large',
}

export const DATE_FORMAT = {
  BASE: 'D MMMM',
}

export const SHORT_MAIN_TITLE_LENGTH_DESKTOP = 25
export const SHORT_MAIN_TITLE_LENGTH_TABLET = 17
export const SHORT_MAIN_TITLE_LENGTH_MOBILE = 25

export const MEDIUM_MAIN_TITLE_LENGTH_DESKTOP = 55
export const MEDIUM_MAIN_TITLE_LENGTH_TABLET = 40
export const MEDIUM_MAIN_TITLE_LENGTH_MOBILE = 62

export const SHORT_LISTING_TITLE_LENGTH_MOBILE = 13
