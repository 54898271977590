<template>
  <svg fill="inherit" stroke="none" viewBox="0 0 15 15">
    <path
      fill="inherit"
      d="M2.424 6.396c.539-2.233 2.183-3.718 4.463-4.022 1.922-.256 3.844.623 5.093 2.472l-1.69-.324-.234 1.228 3.606.69.697-3.574-1.226-.24-.258 1.32C11.385 1.883 9.1.818 6.722 1.136c-2.793.371-4.853 2.233-5.513 4.967l1.215.293Z"
    />
    <path
      fill="inherit"
      d="M12.549 8.582c-.529 2.236-2.166 3.728-4.444 4.043-1.92.265-3.847-.605-5.105-2.448l1.69.316.23-1.23-3.61-.673-.68 3.578 1.228.233.251-1.32c1.5 2.056 3.79 3.11 6.167 2.782 2.79-.385 4.842-2.256 5.49-4.994l-1.217-.287Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-loader',
}
</script>
