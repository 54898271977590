import { isAndroid, isIOS } from '@ga/shared-browser'

export class AdvModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  openModal() {
    this.gaApp.stores.modal.advModal.opened = true

    this.gaApp.analytics.modules.modal.onDownloadAppView()
  }

  closeModal() {
    this.gaApp.stores.modal.advModal.opened = false
  }

  /**
   * Метод для инициализации модального окна "скачать приложение" при входе на сайт
   */
  init() {
    const isDownloadAppModal = this.gaApp.features.get('downloadAppModal')

    if (!process.client || !isDownloadAppModal) return

    // открываем только если это мобильный девайс
    if (isAndroid() || isIOS()) {
      this.gaApp.stores.modal.advModal.shouldRender = true
      this.openModal()
    }
  }
}
