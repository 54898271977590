export const SIZE = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
}

export const RATIO = {
  SQUARE: '1_1',
  RECT: '16_15',
}
