import { INVALID_SLOTS_ERROR_REASON } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    try {
      switch (redirect.pageType) {
        case 'main':
          return await this.getBrandzoneInitialData(redirect)

        case 'landing':
          return await this.getBrandzoneLandingInitialData(redirect)
      }
    } catch (error) {
      return this.redirectError(error)
    }
  }

  setDataOnPageCreated(data) {
    const setData = () => {
      this.gaApp.stores.brandzone.slots.items = data.blocks
      this.gaApp.services.brandzone.slots.fillStores()
    }

    if (!this.gaApp.isClient) {
      return setData()
    }

    this.gaApp.services.brandzone.slots.resetAll()

    const unsubscribe = this.gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      () => {
        setData()
        unsubscribe()
      },
    )
  }

  async getBrandzoneLandingInitialData(redirect) {
    const brandName = this.gaApp.services.brandzone.main.getBrandName(
      redirect.path,
    )

    const categoryRedirect =
      await this.gaApp.services.brandzone.api.fetchRedirect({
        url: `/brands/${brandName}`,
      })

    this.gaApp.stores.brandzone.page.data = {
      params: { categoryId: +categoryRedirect.entityId },
      type: redirect.pageType,
    }

    return Promise.all([
      this.gaApp.services.brandzone.api.fetchCategory(),
      this.gaApp.services.brandzone.landing.fetchLandingData(),
    ])
  }

  getBrandzoneInitialData(redirect) {
    this.gaApp.stores.brandzone.page.data = {
      params: { categoryId: +redirect.entityId },
      type: redirect.pageType,
    }

    const [, brandName] = this.gaApp.route.path.split('/brands/')
    this.gaApp.stores.brandzone.page.meta = {
      brandName,
    }

    return Promise.all([
      this.gaApp.services.brandzone.api.fetchCategory(),
      this.gaApp.services.brandzone.slots.fetchSlotsData(),
    ])
  }

  redirectError(error) {
    const statusCode =
      !error.statusCode || error.statusCode >= 500 ? 500 : error.statusCode

    if (error.reason === INVALID_SLOTS_ERROR_REASON) {
      return this.gaApp.redirect('/brands')
    }

    return this.gaApp.redirectError({ statusCode, message: error.message })
  }
}
