/**
 * Сервис для обработки и уточнения адресов пользователя
 * @class
 * @description
 * Краткая схема работы:
 * Сравнивает адреса (хедер, дефолтный, гео), показывает подтверждение при необходимости.
 */
export class ClarificationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запускает процесс уточнения адреса
   *
   * @description
   * Проверяет подтверждение адреса и состояние блока.
   * Если адрес подтвержден или отключен — завершает выполнение.
   * Если включен адресный блок — запускает уточнение, иначе показывает окно подтверждения.
   */
  start() {
    if (this.gaApp.services.location.confirm.isClarificationDisabled) {
      return
    }

    if (this.gaApp.stores.app.common.toggle?.addressBlockEnabled) {
      this.processAddressClarification()
      return
    }

    this.gaApp.services.location.confirm.requestOpening()
  }

  /**
   * Обрабатывает уточнение адреса в зависимости от версии логики
   */
  processAddressClarification() {
    const address = this.gaApp.stores.location.main?.location
    const addressDefault = this.gaApp.stores.location.addresses.addressDefault

    // Новая логика отображения плашки, закрыта под ФТ
    if (this.gaApp.features.get('addressCustomerClarificationV3')) {
      this.newProcessClarificationAddress(address, addressDefault)
      return
    }

    // Старая логика, чтоб ничего не сломать
    this.oldProcessClarificationAddress(address, addressDefault)
  }

  async newProcessClarificationAddress(address, addressDefault) {
    const addressGeo = await this.getGeoAddress()

    if (!addressDefault) {
      this.processAddressesWithoutDefaultAddress({ address, addressGeo })

      return
    }

    // Если у пользователя разрешено остлеживание, то запускаем через него.
    if (addressGeo) {
      await this.processAddressesWithGeo({
        address,
        addressGeo,
        addressDefault,
      })

      return
    }

    /*
     * Нужно учитывать что cityDistrict, geoPolygons есть только в selectedAddress
     * поэтому событие смены адреса нужно триггерить после setSelectedAddress,
     * иначе может быть неправильно рассчитана доставка
     * */

    /*
     * поскольку не вызываем confirm.open(), который так или иначе ведет к вызову setSelectedAddress()
     * то, делаем это тут, чтобы на бэке и фронте в жил один адрес
     * иначе это вылезет при переходе на чекаут
     * */
    if (address?.exactId !== addressDefault.exactId) {
      await this.gaApp.services.location.main.setSelectedAddress(
        addressDefault,
        false,
      )
    }
  }

  async oldProcessClarificationAddress(address, addressDefault) {
    if (addressDefault) {
      /*
       * Нужно учитывать что cityDistrict, geoPolygons есть только в selectedAddress
       * поэтому событие смены адреса нужно триггерить после setSelectedAddress,
       * иначе может быть неправильно рассчитана доставка
       * */

      /*
       * поскольку не вызываем confirm.open(), который так или иначе ведет к вызову setSelectedAddress()
       * то, делаем это тут, чтобы на бэке и фронте в жил один адрес
       * иначе это вылезет при переходе на чекаут
       * */

      if (address.exactId !== addressDefault.exactId) {
        await this.gaApp.services.location.main.setSelectedAddress(
          addressDefault,
          false,
        )
      }

      return
    }

    const addressGeo = await this.getGeoAddress()

    this.processAddressesWithoutDefaultAddress({
      address,
      addressGeo,
    })
  }

  /**
   * Обрабатывает адреса при наличии геолокации
   * @async
   * @param {Object} params - Параметры обработки
   * @param {Object} params.address - Текущий адрес из хедера
   * @param {Object} params.addressGeo - Адрес по геолокации
   * @param {Object} params.addressDefault - Адрес по умолчанию из сохраненных. Сохраненные адреса - это те, на которые делали заказ.
   */
  async processAddressesWithGeo({ address, addressGeo, addressDefault }) {
    // exactId - это наиболее точный фиас, т.е. сравнение по exactId - это проверка на полное совпадение адреса
    if (address?.exactId === addressGeo.exactId) return

    // id - это фиас города/поселения, соответственно при сравнении id улица и дом не имеет значение
    if (addressGeo.id === addressDefault.id) {
      await this.gaApp.services.location.main.setSelectedAddress(
        addressDefault,
        false,
      )
      return
    }

    this.gaApp.services.location.main.setLocation(addressGeo)
    this.gaApp.services.location.confirm.requestOpening()
  }

  /**
   * Обрабатывает адреса без адреса по умолчанию
   * @param {Object} params - Параметры обработки
   * @param {Object} params.address - Текущий адрес из хедера
   * @param {Object} params.addressGeo - Адрес по геолокации
   */
  processAddressesWithoutDefaultAddress({ address, addressGeo }) {
    if (address?.isSelectedAddress && addressGeo) {
      if (address.exactId !== addressGeo.exactId) {
        this.gaApp.services.location.confirm.requestOpening()
      }
      return
    }

    if (!address?.isSelectedAddress && addressGeo) {
      this.gaApp.services.location.main.setLocation(addressGeo)
      this.gaApp.services.location.confirm.requestOpening()
      return
    }

    if (!addressGeo) {
      this.gaApp.services.location.confirm.requestOpening()
    }
  }

  /**
   * Получает адрес по геолокации через браузер или координаты
   */
  async getGeoAddress() {
    let addressGeo = null

    const { latitude, longitude } =
      await this.gaApp.services.location.geolocation.browserDetection()
    if (latitude && longitude) {
      try {
        addressGeo = await this.gaApp.services.location.api.getGeolocation(
          {
            latitude,
            longitude,
          },
          { hasAlertError: false },
        )
      } catch {}
    }

    return addressGeo
  }
}
