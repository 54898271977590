import { MODULE_TYPE, PAGE_PATH, PAGE_TYPE } from '../constants'

export const moduleArticlesSsrRoutes = [
  // articles
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.ARTICLE_ONE,
    path: PAGE_PATH.ARTICLE_ONE,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/main' */
        '~/modules/articles/components/pages/article.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.ARTICLE_LISTING,
    path: PAGE_PATH.ARTICLE_LISTING,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/list' */
        '~/modules/articles/components/pages/articles.vue'
      ),
  },

  // live
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.STREAM_ONE,
    path: PAGE_PATH.STREAM_ONE,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/stream' */
        '~/modules/articles/components/pages/stream.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.STREAM_LISTING,
    path: PAGE_PATH.STREAM_LISTING,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/streams' */
        '~/modules/articles/components/pages/streams.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.STREAM_LIVE,
    path: PAGE_PATH.STREAM_LIVE,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
      headerVisible: false,
      footerVisible: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/streams' */
        '~/modules/articles/components/pages/stream-iframe.vue'
      ),
  },

  // filters
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.ARTICLE_LISTING_FILTER,
    path: PAGE_PATH.ARTICLE_LISTING_FILTER,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/list' */
        '~/modules/articles/components/pages/articles.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.STREAM_LISTING_FILTER,
    path: PAGE_PATH.STREAM_LISTING_FILTER,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/list' */
        '~/modules/articles/components/pages/streams.vue'
      ),
  },
]

export const moduleArticlesNoSsrRoutes = [
  // previews
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.PREVIEW_ARTICLE,
    path: PAGE_PATH.PREVIEW_ARTICLE,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/main' */
        '~/modules/articles/components/pages/article.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.PREVIEW_ARTICLE_LISTING,
    path: PAGE_PATH.PREVIEW_ARTICLE_LISTING,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/main' */
        '~/modules/articles/components/pages/articles.vue'
      ),
  },

  // preview live
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.PREVIEW_STREAM,
    path: PAGE_PATH.PREVIEW_STREAM,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/stream' */
        '~/modules/articles/components/pages/stream.vue'
      ),
  },
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.PREVIEW_STREAM_LISTING,
    path: PAGE_PATH.PREVIEW_STREAM_LISTING,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/streams' */
        '~/modules/articles/components/pages/streams.vue'
      ),
  },

  // preview banner
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.PREVIEW_BANNER,
    path: PAGE_PATH.PREVIEW_BANNER,
    meta: {
      addressDisabled: true,
      authRequired: false,
      analyticDisabled: false,
      webview: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/articles/page/preview-banner' */
        '~/modules/articles/components/pages/preview-banner.vue'
      ),
  },
]

export const routes = [...moduleArticlesNoSsrRoutes, ...moduleArticlesSsrRoutes]
