import { removeEmptyFields } from '../../../../utils'

export class GtmGa4ProductBaseModel {
  constructor({ product }) {
    const creativeSlot = `slide_${product.slidePosition}`

    this.currency = product.currency

    this.items = [
      removeEmptyFields({
        item_name: product.itemName,
        item_id: product.itemId,
        item_brand: product.itemBrand,
        item_variant: product.itemVariant,
        item_list_name: product.itemListName,
        item_list_id: product.itemListId,
        creative_name: product.creativeName,
        creative_slot: creativeSlot,
        promotion_name: product.promoName,
        price: product.price,
        index: product.index,
        stock: product.stock,
        rating: product.rating,
        recommend: product.recommend,
        reviews: product.reviews,
      }),
    ]
  }
}
