import { DELIVERY_TYPE } from '../constants'

export class PlasticDeliveryService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.closeAddressModal()
      this.closeStoreModal()
    })
  }

  /** Открывает модальное окно выбора адреса доставки */
  openAddressModal() {
    this.gaApp.stores.giftCardsB2b.plasticNew.isAddressModalOpen = true
  }

  /** Закрывает модальное окно выбора адреса доставки */
  closeAddressModal() {
    this.gaApp.stores.giftCardsB2b.plasticNew.isAddressModalOpen = false
  }

  /** Открывает модальное окно информации о ПВЗ */
  openStoreModal() {
    /** При открытии модального окна магазина всегда отображаем информационный блок */
    this.showInfoBlock()

    this.gaApp.stores.giftCardsB2b.plasticNew.isStoreModalOpen = true
  }

  /** Закрывает модальное окно информации о ПВЗ */
  closeStoreModal() {
    this.gaApp.stores.giftCardsB2b.plasticNew.isStoreModalOpen = false
  }

  /** Сохранение введенных значений в форму заявки */
  setFormAddressData(data) {
    Object.assign(
      this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.addressForm,
      data,
    )
  }

  /** Формирование и установка информации о выбранном городе в стор */
  setAddressInfoBlockData(address) {
    if (!address) return

    const addressString = this.formatAddress(address)

    this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.addressString =
      addressString
  }

  /** Форматирование строки адреса */
  formatAddress(address) {
    const { city, street, apt, intercom, entrance, floor } = address

    const {
      cityShort,
      aptShort,
      intercom: intercomShort,
      entrance: entranceShort,
      floor: floorShort,
    } = this.gaApp.i18n.t('giftCardsB2b.plasticNew.address')

    const addressString = [
      `${cityShort} ${city}`,
      street,
      apt && `${aptShort} ${apt}`,
      intercom && `${intercomShort}: ${intercom}`,
      entrance && `${entranceShort} ${entrance}`,
      floor && `${floorShort} ${floor}`,
    ]
      .filter(Boolean)
      .join(', ')

    return addressString
  }

  /** Установка опций доставки в стор */
  setDeliveryOptions() {
    const options = [DELIVERY_TYPE.DELIVERY, DELIVERY_TYPE.PICKUP]

    const deliveryOptions = options.map((option) => ({
      name: option,
      label: this.gaApp.i18n.t(
        `giftCardsB2b.plasticNew.deliveryOptions.${option}`,
      ),
      description: this.gaApp.i18n.t(
        `giftCardsB2b.plasticNew.deliveryDescription.${option}`,
      ),
    }))

    this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.options =
      deliveryOptions
  }

  /** Отображение информационного блока */
  showInfoBlock() {
    this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.isInfoBlockVisible = true
  }

  /** Скрытие информационного блока */
  hideInfoBlock() {
    this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.isInfoBlockVisible = false
  }

  resetAddressData() {
    /** Сброс значений из формы доставки курьером */
    this.gaApp.stores.giftCardsB2b.plasticNew.resetAddress()

    /** Сброс строки доставки */
    this.gaApp.stores.giftCardsB2b.plasticNew.resetAddressString()

    /** Сброс значений из формы самовывоза */
    this.gaApp.stores.giftCardsB2b.plasticNew.resetDeliveryType()

    /** Скрытие блока адреса доставки */
    this.hideInfoBlock()
  }
}
