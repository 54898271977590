import { TYPE } from '../../constants'
import { GtmGa4DownloadAppViewModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class DownloadAppViewBuilder extends BaseBuilder {
  constructor() {
    const models = new Map([[TYPE.GTM_4, GtmGa4DownloadAppViewModel]])
    super(models)
  }
}
