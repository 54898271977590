import capitalize from 'lodash/capitalize'

const REGION_TYPES = {
  CITY: 'город',
  REPUBLIC: 'республика',
  CHUVASHIA: 'Чувашия',
}

const DIRECT_WORD_ORDER_REPUBLICS = [
  'Донецкая Народная',
  'Кабардино-Балкарская',
  'Карачаево-Черкесская',
  'Луганская Народная',
  'Удмуртская',
  'Чеченская',
]

export class FormatterService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get comma() {
    return this.gaApp.i18n.t('location.comma')
  }

  get commaSeparator() {
    return `${this.comma} `
  }

  get commaSeparatorNbsp() {
    return `${this.comma}&nbsp;`
  }

  /**
   * Собирает строку с домом и блоком
   * @param { object } data - данные адреса
   * @param { string } data.house - номер строения с типом/без типа
   * @param { string } data.houseType - тип строения
   * @param { string } data.blockType - тип блока
   * @param { string } data.block - блок
   */
  composeHouse({ house, houseType, blockType, block }) {
    const addHouseType = !house?.startsWith(houseType)

    // todo: убедиться действительно ли может быть тип без house/block
    //  если нет - убрать доп. проверки
    return [
      house
        ? [addHouseType && houseType, house].filter(Boolean).join('. ')
        : null,
      block ? [blockType, block].filter(Boolean).join('. ') : null,
    ]
      .filter(Boolean)
      .join(this.commaSeparator)
  }

  composeRegionWithType(region, regionType) {
    let directWordOrder = true
    let separator = null
    let composeRegionType = regionType

    /*
     * region содержит regionType
     * region: Ханты-Мансийский Автономный округ - Югра, regionType: aвтономный округ
     * region: Кемеровская область - Кузбасс, regionType: область
     * */
    if (
      String(region).toLowerCase().includes(String(regionType).toLowerCase())
    ) {
      composeRegionType = null
    }

    /*
     * Города федерального значения без "город"
     * "город Москва" -> "Москва"
     * */
    if (regionType === REGION_TYPES.CITY) {
      composeRegionType = null
    }

    /*
     * Правильная очередность для республик
     * "Республика Хакасия"
     * "Чеченская Республика"
     * */
    if (
      regionType === REGION_TYPES.REPUBLIC &&
      !DIRECT_WORD_ORDER_REPUBLICS.includes(region)
    ) {
      directWordOrder = false
    }

    /* Республика с большой буквы
     * "Чеченска Республика"
     * */
    if (regionType === REGION_TYPES.REPUBLIC) {
      composeRegionType = capitalize(regionType)
    }

    /*
     * Только для Чувашии
     * region: 'Чувашская республика'
     * regionType: 'Чувашия'
     * Чувашская Республика — Чувашия
     * */
    if (regionType === REGION_TYPES.CHUVASHIA) {
      separator = '—'
    }

    return (
      directWordOrder
        ? [region, separator, composeRegionType]
        : [composeRegionType, separator, region]
    )
      .filter(Boolean)
      .join(' ')
  }

  /*
   * Адрес доставки одной строкой
   * */
  formatLocationDeliveryName(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    return [street || composeCity, composeHouse]
      .filter(Boolean)
      .join(this.commaSeparator)
  }

  /*
   * Адрес доставки в мобильном меню, пдп, фильтрах на категории
   * разделенный на дом и остальное
   * */
  formatLocationDeliveryNameDivided(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    const streetHouseSeparator =
      (street || composeCity) && composeHouse ? this.commaSeparatorNbsp : ''

    return {
      main: [street || composeCity, streetHouseSeparator].join(''),
      house: composeHouse,
    }
  }

  /*
   * Нужен в хедере на десктопе
   * разделение на house и все остальное нужно чтобы отображать в адресе ..., если адрес длинный, например:
   * Москва · Екатерины Буданов...5
   * */
  formatLocationDeliveryNameExtended(location) {
    const { city: composeCity, street, house, block, blockType } = location
    const composeHouse = this.composeHouse({
      house,
      blockType,
      block,
    })

    const streetHouseSeparator =
      street && composeHouse ? this.commaSeparatorNbsp : ''
    const citySeparator =
      composeCity && (street || composeHouse) ? '&nbsp;&nbsp;·&nbsp;&nbsp;' : ''

    return {
      main: [composeCity, citySeparator, street, streetHouseSeparator].join(''),
      house: composeHouse,
    }
  }

  /**
   * Список сохраненных адресов
   * @param { object } location
   */
  formatLocationAddressList(location) {
    const { street, house, houseType, block, blockType, address } = location
    const { region, regionTypeFull, city, settlement } = address

    const localityHouse = this.composeHouse({
      house,
      houseType,
      block,
      blockType,
    })
    const regionWithType = this.composeRegionWithType(region, regionTypeFull)

    const items = [settlement, city, regionWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    const localityMain = localityHouse || street ? street : uniqueItems.shift()
    const area = uniqueItems.shift()

    const localitySeparator =
      localityMain && localityHouse ? this.commaSeparatorNbsp : ''

    return {
      area,
      localityMain: [localityMain, localitySeparator].join(''),
      localityHouse,
    }
  }

  /**
   * Собирает из адреса строку с улицей и домом
   * - Инпут улицы в форме редактирования адреса
   * - Нотификация при редактировании/удалении/смене дефолтного адреса
   * @param { object } location
   * @returns {{result: string, itemsCount: number}}
   */
  formatStreetHouse(location) {
    const { street, streetType, house, houseType, block, blockType } =
      location || {}

    const composedHouse = this.composeHouse({
      house,
      houseType,
      block,
      blockType,
    })

    const items = [
      [streetType, street].filter(Boolean).join('. '),
      composedHouse,
    ].filter(Boolean)

    return {
      result: items.join(this.commaSeparator),
      itemsCount: items.length,
    }
  }

  /**
   * Форматирует уточнения по дому
   * @param { object } obj
   * @param { string } obj.apartment - квартира
   * @param { string } obj.entrance - подъезд
   * @param { string } obj.floor - этаж
   * @param { string } obj.intercom - домофон
   * @returns {string}
   */
  formatBuildingDetails({ apartment, entrance, floor, intercom }) {
    const prefix = this.gaApp.i18n.t('location.addressPrefix')

    const items = [
      apartment ? `${prefix.apartment} ${apartment}` : null,
      intercom ? `${prefix.intercom} ${intercom}` : null,
      entrance ? `${prefix.entrance} ${entrance}` : null,
      floor ? `${prefix.floor} ${floor}` : null,
    ]

    return items.filter(Boolean).join(this.commaSeparator)
  }

  /**
   * Отдает полное название города, полученное с бэка
   * @param { object } location - адрес в формате location
   */
  formatAddressFull(location) {
    return location?.address?.addressNameFull || ''
  }

  /**
   * Собирает строку из поселения, города и региона
   * Используется в:
   * - ЛК модалка редактирования адреса на мобилках
   * - ЛК список адресов нижняя строка
   * @param { object } location - адрес в формате LocationEntity
   * @returns {string}
   */
  formatCityRegion(location) {
    const { region, regionTypeFull, city, settlement } = location?.address || {}

    const regionWithType = this.composeRegionWithType(region, regionTypeFull)

    const items = [settlement, city, regionWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    return uniqueItems.join(this.commaSeparator)
  }

  /**
   * Собирает массив из 2х срок: поселение/город и регион для отображения в столбик
   * Используется в:
   * - ЛК модалка редактирования адреса на десктопе
   * @param { object } location - адрес в формате LocationEntity
   * @returns { Array }
   */
  formatCityRegionCascade(location) {
    const { region, regionTypeFull, city, settlement } = location?.address || {}

    const regionWithType = this.composeRegionWithType(region, regionTypeFull)

    const items = [settlement, city, regionWithType]
    const uniqueItems = [...new Set(items.filter(Boolean))]
    const [noLastItems, lastItem] = [
      uniqueItems.slice(0, -1),
      uniqueItems.at(-1),
    ]

    return [noLastItems.join(this.commaSeparator), lastItem]
  }

  /*
   * Параметр query для запроса подсказок улицы (плейд)
   * */
  formatSuggestQuery(location = {}, street) {
    const { address } = location

    const {
      region: regionWithoutType,
      regionTypeFull,
      city,
      settlement,
      settlementWithType: settlementWithShortType,
    } = address || {}

    const regionWithType = this.composeRegionWithType(
      regionWithoutType,
      regionTypeFull,
    )

    const queryItems = [
      regionWithType,
      city,
      settlementWithShortType || settlement,
    ]

    const uniqueQueryItems = [...new Set(queryItems)]

    return [...uniqueQueryItems, street]
      .filter(Boolean)
      .join(this.commaSeparator)
  }

  /*
   * Параметры фильтрации подсказок для запроса улицы (плейд)
   * если герион и город одинаковые, то передаем только город. касается и query и отдельного параметра
   * */
  formatSuggestFilterParams(location = {}) {
    const {
      address,
      city: cityOrSettlement,
      region: regionWithShortType, // тут может быть localityRegion с полным типом если мы на ручках маги, у нас не Питер и не Москва и dadataJson пуст
    } = location

    const { region: regionWithoutType } = address || {}

    // должен быть регион без типа, либо регион с кратким типом
    // если регион с полным типом, то бэк ничего не найдет
    const region = regionWithoutType || regionWithShortType

    const items = [region, cityOrSettlement]
    const uniqueItems = [...new Set(items.filter(Boolean))]

    return {
      cityName: uniqueItems.pop(),
      regionName: uniqueItems.pop(),
    }
  }

  /**
   * Собирает и возвращает suggest подобный объект из данных локации
   * @param {object} location - адрес в формате LocationEntity
   * @returns {object} suggestLike - suggest подобный объект
   */
  formatLocationAsSuggest(location = {}) {
    return {
      value: location.exactId,
      text: this.formatStreetHouse(location).result,
    }
  }

  /**
   * Форматирует на чекауте shippingAddress в единую строку
   * @param { object } shippingAddress
   */
  formatShippingAddressLine(shippingAddress = {}) {
    const {
      apartment,
      intercomCode: intercom,
      entrance,
      floor,
      settlement,
      area,
      addressLine,
      street,
      house,
      block,
    } = shippingAddress

    const buildingDetails = this.formatBuildingDetails({
      apartment,
      intercom,
      entrance,
      floor,
    })

    const items = [settlement, area, street, house, block, buildingDetails]

    const uniqueItems = [...new Set(items.filter(Boolean))]
    const composedAddressLine = uniqueItems.join(this.commaSeparator)

    return composedAddressLine || addressLine
  }

  /**
   * Форматирует на чекауте shippingAddress в единую строку в кратком виде
   * @param { object } shippingAddress
   */
  formatShippingAddressLineShort(shippingAddress = {}) {
    const { area, addressLine, street, house, block } = shippingAddress

    const items = [area, street, house, block]

    const uniqueItems = [...new Set(items.filter(Boolean))]
    const composedAddressLine = uniqueItems.join(this.commaSeparator)

    return composedAddressLine || addressLine
  }

  /**
   * Форматирует на чекауте shippingAddress в строку для отображения на превью (свернутый шаг)
   * @param { object } shippingAddress
   */
  formatShippingAddressPreview(shippingAddress = {}) {
    if (!shippingAddress) {
      return null
    }

    const { locality, street, house, block, apartment, addressLine } =
      shippingAddress

    const parts = [locality, street, house, block]

    if (apartment) {
      const formattedApartment = this.formatBuildingDetails({ apartment })
      parts.push(formattedApartment)
    }

    const filteredParts = parts.filter(Boolean)

    // может быть такое, что данных нет, кроме поля locality
    // в таком случае выводим addressLine
    return filteredParts.length > 1
      ? filteredParts.join(this.commaSeparator)
      : addressLine
  }

  /**
   * Форматирует адрес ПВЗ на чекауте
   */
  formatPickupAddress(address, locality) {
    if (!locality) {
      return address || ''
    }

    // todo: вернуть при запуске в ME странах, необходимо уточнить
    //  может быть случай, когда текст будет начинаться с запятой
    //  почему обратный порядок?
    // if (isAE) {
    //   return `${address || ''}${this.commaSeparator}${locality}`
    // }

    return [locality, address].filter(Boolean).join(this.commaSeparator)
  }

  /**
   * Префикс города из локалей
   */
  get cityPrefix() {
    return this.gaApp.i18n.t('location.addressPrefix.city')
  }

  /**
   * Добавляем subtitle неразрывный пробел с префиксом города
   *
   * @param { string } subtitle - подзаголовок к городу из поискового саджеста
   */
  formatSuggestSubtitle(subtitle) {
    const regex = new RegExp(`${this.cityPrefix}\\s`, 'g')
    return subtitle.replace(regex, `${this.cityPrefix}&nbsp;`)
  }

  /**
   * Формирует строку-значение для подсказки
   * Используется в модуле checkout
   *
   * @param {object} suggest - объект подсказки
   * @return {string}
   */
  // TODO: доработать для Middle East
  formatSuggestAddress(suggest) {
    const parts = [suggest.text]

    if (this.gaApp.features.get('suggestTwoLines')) {
      parts.push(suggest.subtitle)
    }

    return parts.join(this.commaSeparator).trim()
  }
}
