export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async logout() {
    if (this.gaApp.stores.customer.main.logoutPending) return undefined

    this.gaApp.stores.customer.main.logoutPending = true

    this.gaApp.analytics.modules.customer.onLogoutClick()
    try {
      await this.gaApp.services.auth.main.logoutWithClearData()

      // задержка на выполнение подписчиков события логаута
      await new Promise((resolve) => setTimeout(resolve, 1000))

      window.location.assign('/')
    } catch {
    } finally {
      this.gaApp.stores.customer.main.logoutPending = false
    }
  }
}
