// TODO: Перенести остальную логику по работе с продуктами

export class ProductService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  convertParamsToProducts(params) {
    const streamId = params.stream_id

    return params.products.map((product) => {
      const { id, qty } = product

      const result = {
        sku: id,
        quantity: Number(qty) || 1,
      }

      if (streamId) {
        result.analyticsDetails = {
          itemListName: 'streamerce',
          itemListId: streamId,
        }
      }

      return result
    })
  }

  async addItemsFromUrlParams(params) {
    const products = this.convertParamsToProducts(params)

    await this.gaApp.services.cart.api.addItems(products)
  }

  addAllItemsToFavorite() {
    const skus = [...this.gaApp.stores.cart.main.itemsId]

    return this.addItemsToFavorite(skus)
  }

  async addItemsToFavorite(skus) {
    if (!skus?.length) {
      return
    }

    this.gaApp.services.cart.api.setLoadingItems(skus)

    await this.gaApp.services.favorites.products.addItems(skus)

    this.gaApp.services.cart.api.setLoadingItems()
  }

  /**
   * Обновляем количество товара в корзине или удаляем его
   *
   * Нужно для того, что бы в каунтере карточки продукта изменять количество
   *
   * @param {object} itemId - itemId продукта
   * @param {number} quantity - новое количество товара
   */
  updateQuantityOrDeleteProduct(itemId, quantity) {
    const productInCart = this.gaApp.services.cart.main.getProductItem(itemId)

    try {
      const id = productInCart?.id

      if (quantity > 0) {
        return this.gaApp.services.cart.api.updateItemQty({ id, qty: quantity })
      }

      return this.gaApp.services.cart.api.deleteItems({ ids: [id] })
    } catch (error) {
      const newError = new Error(
        'Ошибка запроса обновления количества продукта в корзине',
      )
      newError.errorData = JSON.stringify(error)
      this.gaApp.services.app.apm.captureError(error)
    }
  }
}
