import {
  LOCAL_STORAGE_BRAND_DEFERRED_KEY,
  LOCAL_STORAGE_PRODUCT_DEFERRED_KEY,
} from '../constants'

/**
 * Медоды для работы с ключами в local storage:
 * get - забирает значение по ключу,
 * set - устанавливает значение по ключу
 * remove - удаляет запись по ключу
 */
export class LocalStorageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * @returns {string}
   */
  getLocalStorageAuthProductDeferredKey() {
    return this.gaApp.services.app.browserStorage.local.get(
      LOCAL_STORAGE_PRODUCT_DEFERRED_KEY,
    )
  }

  /**
   * @param {string} productDeferredKey
   */
  setLocalStorageAuthProductDeferredKey(productDeferredKey) {
    this.gaApp.services.app.browserStorage.local.set(
      LOCAL_STORAGE_PRODUCT_DEFERRED_KEY,
      productDeferredKey,
    )
  }

  removeLocalStorageAuthProductDeferredKey() {
    this.gaApp.services.app.browserStorage.local.remove(
      LOCAL_STORAGE_PRODUCT_DEFERRED_KEY,
    )
  }

  /**
   * @returns {string}
   */
  getLocalStorageAuthBrandDeferredKey() {
    return this.gaApp.services.app.browserStorage.local.get(
      LOCAL_STORAGE_BRAND_DEFERRED_KEY,
    )
  }

  /**
   * @param {string} brandDeferredKey
   */
  setLocalStorageAuthBrandDeferredKey(brandDeferredKey) {
    this.gaApp.services.app.browserStorage.local.set(
      LOCAL_STORAGE_BRAND_DEFERRED_KEY,
      brandDeferredKey,
    )
  }

  removeLocalStorageAuthBrandDeferredKey() {
    this.gaApp.services.app.browserStorage.local.remove(
      LOCAL_STORAGE_BRAND_DEFERRED_KEY,
    )
  }
}
