import { APPLE_PAY_VERSION } from '../constants'

/**
 * Конфигурация сессии Apple Pay
 * @typedef {Object} PaymentConfig
 * @property {string} countryCode - код страны (например, 'RU')
 * @property {string} currencyCode - код валюты (например, 'RUB')
 * @property {string[]} supportedNetworks - массив сетей, поддерживаемых мерчантом (например, ['visa', 'mastercard'])
 * @property {string[]} merchantCapabilities - массив возможностей мерчанта (например, ['supports3DS'])
 * @property {Object} total - объект, содержащий информацию о сумме заказа
 * @property {string} total.label - лейбл внутри попапа перед оплатой заказа (например, 'Gold Apple')
 * @property {string} total.amount - сумма заказа (например, 1000)
 */

export class ApplePayService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /** Флаг доступности Apple Pay */
  get isApplePayAvailable() {
    return window.ApplePaySession && window.ApplePaySession.canMakePayments()
  }

  /**
   * Инициализирует платёжную сессию Apple Pay
   * @param {PaymentConfig} config конфигурация платёжной сессии
   */
  init(config) {
    if (!config || typeof config !== 'object') {
      throw new Error('Missing Apple Pay session config')
    }

    const session = new window.ApplePaySession(APPLE_PAY_VERSION, config)

    return session
  }

  /**
   * Начинает оплату Apple Pay
   * @param {PaymentConfig} sessionConfig данные для конфигурации сессии
   * @param {Object} orderData данные о заказе
   * @param {Function} onValidateMerchant колбэк для валидации мерчанта
   * @param {Function} onPayment колбэк для размещения заказа
   * @param {Function} onSuccessPayment колбэк для обработки успеха оплаты
   * @param {Function} onPaymentFailed колбэк для обработки ошибки оплаты
   */
  startPayment({
    sessionConfig,
    orderData,
    onValidateMerchant,
    onPayment,
    onSuccessPayment,
    onPaymentFailed,
  }) {
    const requiredParams = {
      sessionConfig,
      orderData,
      onValidateMerchant,
      onPayment,
      onSuccessPayment,
      onPaymentFailed,
    }

    for (const [paramName, paramVal] of Object.entries(requiredParams)) {
      if (!paramVal) {
        throw new Error(`Cannot start payment, missing ${paramName}`)
      }
    }

    const session = this.init(sessionConfig)

    // валидация мерчанта, событие вызывается при отображении попапа Apple Pay
    session.onvalidatemerchant = async (event) => {
      try {
        const data = await onValidateMerchant({
          validationUrl: event.validationURL,
        })
        session.completeMerchantValidation(data)
      } catch {
        session.abort()
      }
    }

    // платёж подтверждён touch id, face id или паролем
    session.onpaymentauthorized = async (event) => {
      // получаем токен платёжной сессии и передаём его на бэк вместе с другими данными о заказе для размещения заказа
      const paymentData = {
        ...orderData,
        paymentToken: JSON.stringify(event.payment?.token?.paymentData),
      }

      try {
        await onPayment(paymentData)
        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        onSuccessPayment()
      } catch {
        session.completePayment(window.ApplePaySession.STATUS_FAILURE)
        onPaymentFailed()
      }
    }

    session.begin()
  }
}
