export const actions = () => ({
  resetAddress() {
    /** Очистка заполненного адреса */
    Object.entries(this.deliveryInfo?.addressForm).forEach(([key]) => {
      this.deliveryInfo.addressForm[key] = ''
    })
  },

  /** Очистка адреса курьерской доставки */
  resetAddressString() {
    this.deliveryInfo.addressString = ''
  },

  /** Сброс выбранного способа доставки */
  resetDeliveryType() {
    this.deliveryInfo.type = ''
  },
})
