import { STREAM_UPDATE_STATUS_INTERVAL } from '../constants'
import { sectionChunks } from '../utils'

export class StreamService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.updateStatusesIntervalId = null
  }

  formatStreamData(data) {
    return {
      ...data,
      sections: data.sections?.map((section) => ({
        ...section,
        chunks: sectionChunks(section),
      })),
    }
  }

  async prepareStreamPage(data) {
    // Установить skus сразу так как необходимо грузить листинг продуктов
    this.gaApp.stores.articles.stream.listing.skus = data.listingSkus || []
    // Нужно для обновления статусов
    this.gaApp.stores.articles.stream.externalId = data.externalId

    // Загрузить листинг и обновить статусы
    await Promise.all([
      this.fetchListingWidgetProducts({ isNewStreamLoad: true }),
      this.updateStatus(),
    ])

    // Сначала обновляем данные в хранилище без рекомендаций
    // так как если обновить рекомендации возникает ситуация когда контент старницы уже подменился
    // и пользователь видит новые рекоендации, но еще не проскроллен наверх.
    // Если сначала делать скрол - то пользователь будет видеть старые данные, что тоже не хорошо
    const recommendations = data.recommendations
    delete data.recommendations
    await this.gaApp.stores.articles.stream.$patch(this.formatStreamData(data))
    this.scrollToTop()

    // После скрола обновляем рекомендации в хранилище
    this.gaApp.stores.articles.stream.recommendations = recommendations

    this.gaApp.stores.articles.stream.isLoadedStream = true
  }

  async fetchDataByUrl(url) {
    this.gaApp.stores.articles.stream.isPreview = false

    const { data } =
      await this.gaApp.services.articles.apiStreams.fetchStreamInfoByUrl(url)
    await this.prepareStreamPage(data)
  }

  async fetchDataById(id) {
    this.gaApp.stores.articles.stream.isPreview = true
    const { data } =
      await this.gaApp.services.articles.apiStreams.fetchStreamInfoById(id)
    await this.prepareStreamPage(data)
  }

  // Обновляем статус стрима
  // запрашиваем у АПИ список статусов, находим нужный стрим, меняем статус
  async updateStatus() {
    try {
      // Запрашиваем массив статусов от АПИ - [{id, statusType}]
      const { statuses } =
        await this.gaApp.services.articles.apiStreams.fetchStreamsStatuses()

      if (!statuses || !statuses.length) {
        return
      }

      // находим текущий стрим
      const newStatus = statuses.find(
        (status) =>
          status.externalId === this.gaApp.stores.articles.stream.externalId,
      )

      if (!newStatus) {
        return
      }

      // обновляем статус текущего стрима
      this.gaApp.stores.articles.stream.status = newStatus.statusType
    } catch (error) {
      console.log(error)
    }
  }

  // Устанавливаем статус стрима в ручном режиме
  // Необходимо в момент когда таймер заканчивается и нужно сменить статус "скоро" на "лайв",
  // для того чтобы не слать дополнительный запрос за всеми статусами,
  // так как он будет выполнен немного позже. Запрос статусов выполняется раз в 30 секунд
  setStatus(statusType) {
    this.gaApp.stores.articles.stream.status = statusType
  }

  // перед загрузкой новых данных - поднимаем страницу наверх чтобы не было скачка контента
  scrollToTop() {
    // Должен срабатывать только при переходах между стримами
    // Так как очистка хранилища происходит после ухода со страницы стрима
    // и только в случае если мы перейдем на другой тип страницы - то тут
    // будет храниться информация о том что страница стрима была загружена ранее
    if (!this.gaApp.stores.articles.stream.isLoadedStream) {
      return
    }

    window?.scrollTo?.({ top: 0 })
  }

  // isNewStreamLoad - загружается ли новый стрим, или подгружаются след страницы в листинге
  // если грузим новый стрим, не показываем лоадер и не очищаем данные до их загрузки
  // Очистку стора не используем, чтобы не было вспышки очишенных товаров
  async fetchListingWidgetProducts({ isNewStreamLoad }) {
    if (!isNewStreamLoad) {
      this.setListingLoading(true)
    }

    try {
      await this.fetchBySku({ isNewStreamLoad })
    } finally {
      this.setListingLoading(false)
    }
  }

  setListingLoading(value) {
    this.gaApp.stores.articles.stream.listing.pagination.show = value
    this.gaApp.stores.articles.stream.listing.pagination.pending = value
  }

  async fetchBySku({ isNewStreamLoad }) {
    const { productsRaw } =
      await this.gaApp.services.articles.one.fetchProductsBySku(
        this.gaApp.stores.articles.stream.listing.skus,
      )

    if (isNewStreamLoad) {
      this.gaApp.stores.articles.stream.listing.pages = []
    }

    // Добавляем все товары в одну страницу и отрисовываем далее одну страницу
    this.addPage(productsRaw)
  }

  addPage(productsRaw, pageNumber = 0) {
    if (!productsRaw || !productsRaw.length) {
      return
    }

    this.gaApp.stores.articles.stream.listing.pages.push({
      items: productsRaw,
      number: pageNumber + 1,
    })

    this.gaApp.stores.articles.stream.listing.currentPageNumber = pageNumber + 1
  }

  // Отправляет запрос к АПИ на увеличение кол-ва просмотров
  // информации о стриме
  async fetchStreamViewed() {
    // Если это превью страница - не считаем просмотры
    if (this.gaApp.stores.articles.stream.isPreview) {
      return
    }

    const id = this.gaApp.stores.articles.stream.id

    if (this.gaApp.isWebview) {
      return this.gaApp.services.articles.apiStreams.fetchStreamViewedWebview({
        id,
      })
    }

    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    await this.gaApp.services.articles.apiStreams.fetchStreamViewed({
      captchaToken,
      id,
    })
  }

  // Обновление статуса стримов по интервалу
  fetchStatusesByInterval() {
    if (this.updateStatusesIntervalId) {
      return
    }

    // обновляем статусы стримов раз в 30 секунд
    this.updateStatusesIntervalId = setInterval(
      () => this.updateStatus(),
      STREAM_UPDATE_STATUS_INTERVAL,
    )
  }

  // Удаление интервала обновления стримов
  clearUpdateStatusesInterval() {
    clearInterval(this.updateStatusesIntervalId)
    this.updateStatusesIntervalId = null
  }

  // Текст для просмотра стрима в зависимости от статуса стрима и времени до его начала
  getStreamStatusWatchText({ timeToLive, timeToLiveString }) {
    // Лайв или закончился таймер (бывают случаи когда таймер прошел, а статус еще не сменился)
    const isLive =
      this.gaApp.stores.articles.stream.isStreamStatusLive ||
      this.isSoonTimeEnd(timeToLive)

    if (isLive) {
      return this.gaApp.i18n.t('articles.stream.showLive')
    }

    // статус скоро + таймер все еще есть
    if (this.gaApp.stores.articles.stream.isStreamStatusSoon) {
      return this.getTextForSoon({ timeToLive, timeToLiveString })
    }

    // Запись стрима
    return this.gaApp.i18n.t('articles.stream.showRecord')
  }

  // Если обычный режим - проверяем таймер и статус
  // необходимо для смены текста кнопки "подключиться/смотреть стрим"
  isSoonTimeEnd(timeToLive) {
    return (
      !this.gaApp.stores.articles.stream.isPreview &&
      this.gaApp.stores.articles.stream.isStreamStatusSoon &&
      !timeToLive
    )
  }

  getTextForSoon({ timeToLive, timeToLiveString }) {
    const isPreviewWithDateInPast =
      this.gaApp.stores.articles.stream.isPreview &&
      this.gaApp.stores.articles.stream.hasTranslationDate &&
      !timeToLive

    // для статуса скоро у которого дата стрима указана в прошлом
    if (isPreviewWithDateInPast) {
      return this.gaApp.i18n.t('articles.stream.showLive')
    }

    // классический статус скоро + обратный отсчет таймера
    return `${this.gaApp.i18n.t('articles.stream.showSoon')} ${timeToLiveString}`
  }

  reset() {
    this.gaApp.stores.articles.stream.$reset()
  }
}
