/**
 * Модуль API для взаимодействия с серверными эндпоинтами.
 *
 * Архитектура модуля:
 * - Использует шаблон обертки (wrapper) для HTTP-клиента (предположительно axios)
 * - Реализует механизм колбэков для обработки запросов и ответов
 * - Поддерживает отмену запросов с помощью CancelToken
 * - Использует шину событий (EventBus) для оповещения о событиях API
 *
 * Файлы в папке API:
 * - callbacks/ - обработчики запросов и ответов
 *   - request/default.js - дефолтный обработчик запросов (публикует событие 'start')
 *   - response/default.js - дефолтный обработчик ответов (публикует события 'end' и 'error')
 * - configs/ - конфигурации для HTTP клиента
 *   - axios.js - базовая конфигурация для axios клиента
 * - utils/ - утилитарные классы
 *   - cancel-request.js - управление отменой запросов
 * - wrappers/ - обертки для HTTP клиента, ответов и ошибок
 *   - http.js - обертка HTTP клиента с поддержкой интерцепторов
 *   - response.js - обертка для ответов API
 *   - error/ - обертка для обработки ошибок API
 */
import { EventBus } from '@ga/utils'

import { callbackRequestDefault } from './callbacks/request'
import { callbackResponseDefault } from './callbacks/response'
import { RequestAbort } from './utils/request-abort'
import { ErrorWrapper, HttpWrapper, ResponseWrapper } from './wrappers'

/**
 * Класс API для работы с HTTP запросами.
 * Предоставляет унифицированный интерфейс для взаимодействия с различными типами API.
 */
export class Api {
  /**
   * @param {Object} options - Параметры инициализации API клиента
   * @param {Object} options.client - HTTP клиент (предположительно, экземпляр axios)
   * @param {string|Object} options.urlPrefix - Префикс URL для запросов. Может быть строкой или объектом с разными префиксами
   * @param {Object} options.baseHeaders - Базовые заголовки, которые будут добавлены к каждому запросу
   */
  constructor({ client, clientWrapper, urlPrefix, config }) {
    this.baseConfig = config || {}
    this.clientWrapper = clientWrapper
    // Определяем префиксы URL в зависимости от типа переданного значения
    this.prefixUrls =
      typeof urlPrefix === 'string'
        ? { front: urlPrefix, web: urlPrefix }
        : urlPrefix

    this.defaultApiType = 'frontBff'

    this.httpClient = client

    // Инициализация системы отмены запросов
    this.requestAbort = new RequestAbort(this.httpClient)
    // Инициализация шины событий для оповещения о событиях API
    this.eventBus = new EventBus()
    // Регистрация стандартных обработчиков запросов и ответов
    this.callbacks = {
      request: [callbackRequestDefault(this.eventBus)],
      response: [callbackResponseDefault(this.eventBus)],
    }
  }

  /**
   * Создает новый экземпляр HTTP клиента с заданной конфигурацией.
   *
   * @param {Object} configuration - Конфигурация запроса
   * @param {string} [configuration.apiType] - Тип API (влияет на используемый префикс URL)
   * @param {string} [configuration.abortKey] - Ключ для возможности отмены запроса
   * @returns {Object} - Объект с методами для выполнения HTTP запросов
   */
  request(configuration = {}) {
    const { apiType, ...restConfig } = configuration

    // Определение используемого типа API и соответствующего префикса
    const currentApiType = apiType || this.defaultApiType
    const prefixToUse = this.prefixUrls[currentApiType] || this.prefixUrls.front

    // Объединение базовой конфигурации и переданных настроек
    const config = {
      ...this.baseConfig,
      ...restConfig,
    }

    // Настройка токена отмены запроса, если указан ключ abortKey
    if (config.abortKey) {
      config.signal = this.requestAbort.addRequest(config.abortKey)
      delete config.abortKey
    }

    // Создание экземпляра HTTP обертки и добавление колбэков
    const http = new HttpWrapper(this.httpClient, this.eventBus)
    http.addRequestСallbacks(this.callbacks.request)
    http.addResponseСallbacks(this.callbacks.response)

    // Создание HTTP клиента с заданной конфигурацией
    const client =
      typeof this.clientWrapper === 'function'
        ? this.clientWrapper(http.create(config))
        : http.create(config)

    // Возвращаем объект с методами для выполнения различных типов HTTP запросов
    // каждый метод через замыкание может получить контекст метода request
    // и дальше вызвать методы аксоса исходя из контекста request
    // например выбрать нужны prefixToUse для запроса, фронтБфф или вебБфф

    return {
      get: (url, options) => client.get(`${prefixToUse}/${url}`, options),
      post: (url, data, options) =>
        client.post(`${prefixToUse}/${url}`, data, options),
      put: (url, data, options) =>
        client.put(`${prefixToUse}/${url}`, data, options),
      delete: (url, options) => client.delete(`${prefixToUse}/${url}`, options),
      // Закомментированные методы, которые могут быть раскомментированы при необходимости
      // patch: (url, data, options) => client.patch(`${prefixToUse}/${url}`, data, options),
      // options: (url, options) => client.options(`${prefixToUse}/${url}`, options),
      // head: (url, options) => client.head(`${prefixToUse}/${url}`, options)
    }
  }

  /**
   * Оборачивает ответ от API в объект ResponseWrapper для удобной работы с данными.
   *
   * @param {Object} response - Ответ от API
   * @returns {ResponseWrapper} - Экземпляр обертки для ответа
   */
  response(response = {}) {
    return new ResponseWrapper(response)
  }

  /**
   * Создает экземпляр обертки ошибки для более удобной обработки ошибок API.
   *
   * @param {...any} parameters - Параметры для создания обертки ошибки
   * @returns {ErrorWrapper} - Экземпляр обертки ошибки
   */
  error(...parameters) {
    return new ErrorWrapper(...parameters)
  }

  /**
   * Утилитарный метод для создания объекта эндпоинтов.
   * Не изменяет объект, просто возвращает его для удобства организации кода.
   *
   * @param {Object} obj - Объект с эндпоинтами
   * @returns {Object} - Тот же объект, но явно указанный как объект эндпоинтов
   */
  endpoints(obj) {
    return obj
  }

  /**
   * Подписывается на событие в шине событий API.
   *
   * @param {string} eventType - Тип события ('start', 'end', 'error' и т.д.)
   * @param {Function} listener - Функция-обработчик события
   */
  subscribe(eventType, listener) {
    this.eventBus.subscribe(eventType, listener)
  }

  /**
   * Публикует событие в шину событий API.
   *
   * @param {string} eventType - Тип события
   * @param {any} arg - Аргументы события
   */
  publish(eventType, arg) {
    this.eventBus.publish(eventType, arg)
  }

  /**
   * Добавляет колбэк для обработки запросов.
   * Колбэки выполняются перед отправкой запроса.
   *
   * @param {Function|Object} callback - Функция или объект с методами success и error
   */
  addRequestCallback(callback) {
    this.callbacks.request.push(callback)
  }

  /**
   * Добавляет колбэк для обработки ответов.
   * Колбэки выполняются после получения ответа.
   *
   * @param {Function|Object} callback - Функция или объект с методами success и error
   */
  addResponseCallback(callback) {
    this.callbacks.response.push(callback)
  }
}
