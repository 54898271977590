import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmPickThematic extends GtmBaseModel {
  constructor({ category }) {
    super({ event: 'click_category_design' })

    this.payload = {
      category,
    }
  }
}
