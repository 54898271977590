import { MODULE_TYPE, PAGE_TYPE } from '../constants'

export const moduleCustomerRoutes = [
  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.ORDERS,
    pageKey: 'profile',
    path: '/customer/account/orders',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/orders' */
        '~/modules/customer/components/pages/orders.vue'
      ),
  },

  {
    moduleType: 'customer',
    pageType: 'order',
    pageKey: 'profile',
    path: '/customer/account/order/:id',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/order' */
        '~/modules/customer/components/pages/order.vue'
      ),
  },

  {
    moduleType: MODULE_TYPE,
    pageType: PAGE_TYPE.FAVORITES,
    pageKey: 'favorites',
    path: '/wishlist',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/favorites' */
        '~/modules/customer/components/pages/favorites.vue'
      ),
  },

  {
    moduleType: 'customer',
    pageType: 'edit',
    pageKey: 'profile',
    path: '/customer/account/edit',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/edit' */
        '~/modules/customer/components/pages/edit.vue'
      ),
  },

  {
    moduleType: 'customer',
    pageType: 'giftcards',
    pageKey: 'profile',
    path: '/customer/account/giftcards',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/giftcards' */
        '~/modules/customer/components/pages/giftcards.vue'
      ),
  },

  {
    moduleType: 'customer',
    pageType: 'settings',
    pageKey: 'profile',
    path: '/customer/account/settings',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/settings' */
        '~/modules/customer/components/pages/settings.vue'
      ),
  },

  {
    moduleType: 'customer',
    pageType: 'addresses',
    pageKey: 'profile',
    path: '/customer/account/addresses',
    meta: {
      authRequired: true,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/location/page/account-addresses' */
        '~/modules/location/components/pages/account-addresses.vue'
      ),
  },

  {
    moduleType: 'customer',
    pageType: 'emailConfirmed',
    path: '/customer/account/confirm',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/customer/page/email-confirmed' */
        '~/modules/customer/components/pages/email-confirmed.vue'
      ),
  },
]

export const routes = [...moduleCustomerRoutes]
