import { DESIGN_TYPES } from '../../../../constants/digital'
import { GTM_SELECT_DESIGN_TYPES } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmSelectDesignModel extends GtmBaseModel {
  constructor({ designType, imageId, backgroundId, category }) {
    super({ event: 'select_card_design' })
    this.parameters = this.buildParameters(
      designType,
      imageId,
      backgroundId,
      category,
    )
  }

  buildParameters(designType, imageId, backgroundId, category) {
    const parameters = {
      type: GTM_SELECT_DESIGN_TYPES[designType],
    }

    if (designType !== DESIGN_TYPES.GA) {
      return parameters
    }

    if (imageId) {
      parameters.imageId = imageId
    }

    if (backgroundId) {
      parameters.backgroundId = backgroundId
    }

    if (category) {
      parameters.category = category
    }

    return parameters
  }
}
