import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

export const useAdult = () => {
  const { $gaApp } = useContext()

  const adultAge = computed(() => $gaApp.stores.app.common.adultAge)

  return {
    adultAge,
  }
}
