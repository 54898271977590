import {
  attributes,
  coupon,
  disclaimer,
  hide,
  labelsAdapter,
  media,
  price,
  prices,
  reviews,
} from '../../../../../utils/schemas'

export const product = {
  type: 'object',
  properties: {
    itemId: { type: 'string', required: true },
    name: { type: 'string', required: true },
    brand: { type: 'string', required: true },
    type: { type: 'string', required: true },
    url: { type: 'string', required: true },
    media: { ...media, required: true },
    attributes: {
      oneOf: [attributes, hide],
      required: true,
    },
    dolyame: {
      oneOf: [price, hide],
      required: true,
    },
    prices: {
      oneOf: [prices, hide],
      required: true,
    },
    labels: {
      oneOf: [labelsAdapter, hide],
      required: true,
    },
    disclaimer: {
      oneOf: [disclaimer, hide],
      required: true,
    },
    reviews: {
      oneOf: [reviews, hide],
      required: true,
    },
    inStock: { type: 'boolean', required: true },
    favourite: { type: 'boolean', required: true },
    configurable: { type: 'boolean', required: true },
    needOnlineConsultation: { type: 'boolean', required: true },
    isAdult: { type: 'boolean', required: true },
    coupon: {
      oneOf: [coupon, hide],
      required: false,
    },
  },
  additionalProperties: false,
}
