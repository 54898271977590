export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getSearchResult(query) {
    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()
      const customerGroupId = this.gaApp.stores.user.main.data.groupId

      const requestBody = {
        query,
        customerGroupId,
        ...addressParams,
      }

      const { data } =
        await this.gaApp.repositories.search.main.getSearchResult(requestBody)
      this.gaApp.stores.search.main.searchResult = data
    } catch (error) {
      throw new Error(error)
    }
  }
}
