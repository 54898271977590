import isEqual from 'lodash/isEqual'

export class FiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async requestFilters() {
    try {
      this.gaApp.stores.filters.main.setFiltersPending(true)

      const filtersSelected = this.gaApp.stores.filters.main.filtersSelected

      this.gaApp.stores.filters.main.setFiltersHash({
        filters: filtersSelected,
      })
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const response = await this.gaApp.services.favorites.api.getFilters({
        filters: filtersSelected,
        ...addressParams,
      })

      this.setFiltersData(response.data)

      return response
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('favorites.error.filters'),
      )

      this.gaApp.stores.filters.main.setFiltersPending(false)
    }
  }

  setFiltersData(data) {
    const { analytics, filters, productsCount } = data

    const filtersNormalized = filters.map((filter) => ({
      ...filter,
    }))

    this.gaApp.stores.filters.main.setFilters(filtersNormalized)
    this.gaApp.stores.filters.main.setCountProductsFiltered(productsCount)
    this.gaApp.stores.filters.main.setAnalytics(analytics)

    this.gaApp.stores.filters.main.setFiltersPending(false)
  }

  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { plp } = this.gaApp.stores.favorites

    return isEqual(filtersHash, plp.favoritesHash)
  }

  /**
   * Запрос списка продуктов при изменении селекта
   */
  async refreshFavorites({ select }) {
    this.gaApp.stores.favorites.plp.pages = []

    await Promise.all([
      this.gaApp.services.filters.main.pageNumber.updateFilter(1),

      this.gaApp.services.filters.main.updateFilter({
        filter: { ...select },
      }),

      this.gaApp.services.favorites.plp.fetchFavoriteProducts(),
    ])
  }

  /**
   * Метод нужен, чтобы получить актуальный список выбранных фильтров на основе тех,
   * которые пришли с бека и которые находятся в query params
   * @returns {Promise<Array<object>>}
   */
  async getFilteredQueryFilters() {
    // Сбрасываем стейст, чтобы получить фильтры, относящиеся только к текущей локации
    this.gaApp.services.filters.main.resetState()
    // Устанавливаем ссылку на функцию запроса фильтров, т.к. после ресета
    // эта ссылка теряется
    this.gaApp.services.filters.main.setRequestFiltersFn(
      this.gaApp.services.favorites.filters.requestFilters.bind(
        this.gaApp.services.favorites.filters,
      ),
    )

    const { data: filtersData } =
      await this.gaApp.services.favorites.filters.requestFilters()

    const query = this.gaApp.route.query

    // Получаем список только тех фильтров из query, которые есть и в query, и в фильтрах,
    // пришедших с бека
    const filtersFromQuery =
      this.gaApp.services.filters.queryParse.getFiltersFromQuery(
        filtersData.filters,
        query,
      )

    // Устанавливаем актуальные выбранные фильтры
    this.gaApp.stores.filters.main.setFiltersSelected(filtersFromQuery)

    this.gaApp.stores.filters.main.setFiltersHash({
      filters: filtersFromQuery,
    })

    // Устанавливаем актуальное кол-во выбранных фильтров
    this.gaApp.stores.filters.main.setCountSelectedFilters(
      this.gaApp.stores.filters.main.getFiltersMainSelected.length,
    )

    return filtersFromQuery
  }

  /**
   * Нужно для того, чтобы обновить query параметры, убрав оттуда
   * те фильтры, которых нет в текущей локации пользака
   */
  updateFiltersUrlPostTask() {
    this.gaApp.services.app.scheduler.postTasks([
      {
        task: () => {
          this.gaApp.services.filters.main.updateFiltersURL()
        },
      },
    ])
  }
}
