export const TYPE = {
  GTM_4: 'gtm_4',
  DIGI: 'digi_analytics',
  GAA: 'ga_analytics',
  ADVCAKE: 'advcake',
}

export const EVENT = {
  DIGI: {
    view: 'HOME_PAGE_VIEW',
  },
}

export const ACTIONS = {
  VIEW_PROMOTION: {
    GA4: 'view_promotion',
  },
  SELECT_PROMOTION: {
    GA4: 'select_promotion',
  },
  USER_INTERACTION: {
    GA4: 'user_interaction',
  },
}

export const ACTION_CATEGORIES = {
  MAIN: 'main',
  STORIES: 'stories',
}

export const ACTION_TYPES = {
  CATEGORIES_BLOCK_CLICK: 'categories_block_click',
  INFO_BLOCK_VIEW: 'info_block_view',
  INFO_BLOCK_CLICK_BUTTON: 'info_block_click_button',
  STORIES_OPEN: 'stories_open',
  STORIES_VIEW: 'stories_view',
  STORIES_VIEWED: 'stories_viewed',
  CONTENT_TITLE_CLICK: 'click_widget_header',
}

export const STORIES_VIEWED_STATE = {
  CLOSE: 'close',
  SWIPE: 'swipe',
  COMPLETE: 'complete',
}

export const PLACEMENT_TYPES = {
  HOME: 'главная',
  STORIES: 'stories',
}
