/*
 * TODO:
 *  - вынести view_plp в отдельный компонент modules/plp
 *  - добавить состояние disabled по необходимости
 *  - добавить состояние loading по необходимости
 */
import escapeRegExp from 'lodash/escapeRegExp'

import { computed, ref, unref } from 'vue'

import { KEY_CODES } from '@ga/constants/key-codes'
import { focusable } from '@ga/mixins'
import { usePopper } from '@ga/use/popper'
import { queryHighlight } from '@ga/utils'

import { GaIconAdditionalSelectArrow } from '@ga/icons'

import { GaIcon } from '../icon'
import { GaPortal } from '../portal'

import { GaSelectList } from './children/list'
import {
  DROPDOWN_OFFSET_X,
  DROPDOWN_PADDING,
  DROPDOWN_WIDTH,
  SELECT_DROPDOWN_STRATEGY,
  VIEW,
} from './scripts/const'

// @vue/component
export default {
  name: 'ga-select',

  components: {
    GaIcon,
    GaIconAdditionalSelectArrow,
    GaSelectList,
    GaPortal,
  },

  mixins: [focusable('control')],

  model: {
    event: 'change',
    prop: 'value',
  },

  props: {
    view: {
      type: String,
      default: VIEW.REGULAR,
      validator: (value) => Object.values(VIEW).includes(value),
    },

    dropdownPlacement: {
      type: String,
      default: 'bottom-start',
    },

    dropdownWidth: {
      type: String,
      default: DROPDOWN_WIDTH.BOX_WIDTH,
      validator: (value) => Object.values(DROPDOWN_WIDTH).includes(value),
    },

    boxWidth: {
      type: Number,
      default: null,
    },

    boxClass: {
      type: String,
      default: '',
    },
    listClass: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      default: null,
    },

    label: {
      type: String,
      required: true,
    },
    labelIntoOptions: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },

    uppercase: {
      type: Boolean,
      default: false,
    },
    borderThick: {
      type: Boolean,
      default: false,
    },
    borderPale: {
      type: Boolean,
      default: false,
    },
    borderWhenSelecting: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },
    withModal: {
      type: Boolean,
      default: false,
    },

    withSearch: {
      type: Boolean,
      default: false,
    },

    withInlineSearch: {
      type: Boolean,
      default: false,
    },

    withFullscreenModal: {
      type: Boolean,
      default: false,
    },
    internalSearch: {
      type: Boolean,
      default: true,
    },

    maxListHeight: {
      type: Number,
      default: 300,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    dropdownPortal: {
      type: String,
      default: null,
    },

    listModalZIndex: {
      type: Number,
      default: 5000,
    },

    preventFocus: {
      type: Boolean,
      default: false,
    },

    arrow: {
      type: Boolean,
      default: true,
    },

    titleModal: {
      type: String,
      default: '',
    },
    swipeModalHeight: {
      type: [String, Number],
      default: '50vh',
    },

    testIdSelectBox: {
      type: String,
      default: '',
    },
    testIdSelectOption: {
      type: String,
      default: '',
    },
    testIdSelectOptions: {
      type: String,
      default: '',
    },
    closeDropdownOnScroll: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const rootRef = ref()
    const box = ref()
    const dropdown = ref()
    const dropdownOpened = ref()

    const dropdownOffsetX = computed(
      () => DROPDOWN_OFFSET_X[props.dropdownPlacement],
    )

    usePopper(box, dropdown, dropdownOpened, {
      offsetX: unref(dropdownOffsetX),
      placement: props.dropdownPlacement,
      strategy: SELECT_DROPDOWN_STRATEGY.STRATEGY,
    })

    return {
      box,
      dropdown,
      dropdownOpened,
      rootRef,
    }
  },

  data() {
    return {
      focused: false,

      selected: [],
      selecting: false,
      selectingWithKeyboard: false,

      clickedWithin: false,

      searchQuery: '',

      listWidth: 0,

      highlightConfig: {
        className: 'ga-select__snippet',
      },

      observer: null,
    }
  },

  computed: {
    mods() {
      return {
        view: this.view,

        focused: this.focused,
        disabled: this.disabled,
        selecting: this.selecting,

        uppercase: this.uppercase,
        'border-thick': this.borderThick,
        'border-pale': this.borderPale,
        'border-when-selecting': this.borderWhenSelecting,

        error: this.error,

        empty: this.empty,
      }
    },

    selectedValue() {
      return this.selected.map((option) => option.value).join(',')
    },

    selectedText() {
      return this.selected.map((option) => option.text).join(', ')
    },

    empty() {
      return this.selected.length === 0
    },

    optionsInternal() {
      return this.options.map((option) => {
        const selected = this.selected.some(
          ({ value }) => value === option.value,
        )

        return { ...option, selected }
      })
    },

    optionsInternalValuable() {
      return this.optionsInternal.filter((option) => !option.divider)
    },

    optionsInternalFiltered() {
      const query = this.searchQuery.trim()

      if (!query) {
        return this.optionsInternal
      }

      if (!this.internalSearch) {
        return this.optionsInternal.map((option) =>
          this.highlightOption(option, query),
        )
      }

      return this.optionsInternalValuable
        .filter((option) => this.filterOptionByQuery(option, query))
        .map((option) => this.highlightOption(option, query))
    },

    hasSearchQuery() {
      return Boolean(this.searchQuery)
    },

    isAutoWidth() {
      return this.dropdownWidth === DROPDOWN_WIDTH.AUTO
    },

    listStyle() {
      return {
        zIndex: this.listModalZIndex || 1,
        width: this.isAutoWidth ? 'auto' : `${this.listWidth}px`,
      }
    },

    list() {
      return this.withModal
        ? {
            show: true,
            class: null,
            style: null,
          }
        : {
            show: this.selecting,
            class: this.b('list'),
            style: this.listStyle,
          }
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.setSelectedValue()
      },
    },

    selecting(value) {
      if (process.client) {
        if (value) {
          this.bindMouseDownEvent()
        } else {
          this.unbindMouseDownEvent()
        }
      }
    },

    options() {
      this.setSelectedValue()
    },

    withModal() {
      this.finishSelection()
    },

    searchQuery(value) {
      this.$emit('search-query-input', value)
    },
  },

  destroyed() {
    this.unbindMouseDownEvent()
  },

  methods: {
    setSelectedValue() {
      const values = Array.isArray(this.value) ? this.value : [this.value]

      this.selected = this.optionsInternalValuable.filter((option) =>
        values.includes(option.value),
      )
    },

    onBoxClick(event) {
      const { target } = event
      const { search } = this.$refs

      if (target === search) {
        return
      }

      if (this.selecting) {
        this.finishSelection()
      } else {
        this.startSelection()
      }
    },

    onControlFocus() {
      this.focused = true
    },

    onControlKeyDown(event) {
      if (this.selecting) {
        return
      }

      const { up, down, space } = KEY_CODES
      const { keyCode } = event

      const isSelectionKey = [up, down, space].includes(keyCode)

      if (!isSelectionKey) {
        return
      }

      event.stopPropagation()
      event.preventDefault()

      this.startSelection({ withKeyboard: true })
    },

    onControlBlur() {
      this.focused = false

      this.$emit('blur')

      if (this.withModal) {
        return
      }

      if (this.clickedWithin) {
        this.clickedWithin = false

        this.focus()

        return
      }

      this.finishSelection()
    },

    onSearchControlInput(event) {
      // TODO: переписать на v-model, когда появится модификатор eager
      // https://github.com/vuejs/vue/issues/9777#issuecomment-478831263
      this.searchQuery = event.target.value
    },

    onListTransitionBeforeEnter() {
      if (this.isAutoWidth) return

      this.updateListWidth()
    },

    onListTransitionEnter() {
      this.dropdownOpened = true
    },

    onListTransitionAfterLeave() {
      this.searchQuery = ''
      this.dropdownOpened = false
    },

    onListSelect(option) {
      if (this.multiple) {
        this.updateMultipleValue(option)
      } else {
        this.updateSingleValue(option)
        this.finishSelection()
      }
    },

    onListCancel() {
      this.finishSelection()
    },

    bindMouseDownEvent() {
      window.addEventListener('mousedown', this.onWindowMouseDown)
    },

    unbindMouseDownEvent() {
      window.removeEventListener('mousedown', this.onWindowMouseDown)
    },

    onWindowMouseDown(event) {
      if (this.withModal) {
        return
      }

      const { target } = event

      this.clickedWithin = this.$el.contains(target)

      if (this.clickedWithin) {
        return
      }

      this.finishSelection()
    },

    onWindowResize() {
      this.finishSelection()
    },

    startSelection({ withKeyboard } = { withKeyboard: false }) {
      this.selecting = true
      this.selectingWithKeyboard = withKeyboard

      this.focus()
      this.$emit('selection-start')

      if (this.closeDropdownOnScroll) {
        this.initIntersectionObserver()
      }
    },

    finishSelection() {
      this.selecting = false
      if (this.observer) {
        this.clearIntersectionObserver()
      }
    },

    filterOptionByQuery(option, query) {
      try {
        const pattern = new RegExp(escapeRegExp(query), 'i')

        return pattern.test(option.text)
      } catch {
        return false
      }
    },

    highlightOption(option, query) {
      const { highlightConfig } = this

      const highlighted = queryHighlight(option.text, query, highlightConfig)

      return { ...option, highlighted }
    },

    focus() {
      if (this.preventFocus) return

      if (this.withInlineSearch) {
        this.$refs.search?.focus()
      } else {
        this.$refs.control?.focus()
      }
    },

    updateMultipleValue(option) {
      const valuesSelected = this.selected.map(({ value }) => value)
      const values = option.selected
        ? valuesSelected.filter((value) => value !== option.value)
        : valuesSelected.concat(option.value)

      this.updateValue(values)
    },

    updateSingleValue(option) {
      this.updateValue(option.value)
    },

    updateValue(value) {
      this.$emit('change', value)
    },

    updateListWidth() {
      const width = this.boxWidth || this.$refs.box.clientWidth

      this.listWidth = width + DROPDOWN_PADDING * 2
    },

    initIntersectionObserver() {
      this.observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(({ isIntersecting, target }) => {
          if (!isIntersecting) {
            this.finishSelection()
            observer.unobserve(target)
          }
        })
      })

      this.observer.observe(this.$refs.rootRef)
    },

    clearIntersectionObserver() {
      this.observer = null
    },

    // публичные методы
    close() {
      this.finishSelection()
    },
  },
}
