export const likesEndpoints = {
  setArticleLike: () => ({
    url: 'articles/like',
  }),
  removeArticleLike: () => ({
    url: 'articles/like',
  }),
  getArticleLikeInfo: () => ({
    url: 'articles/like/info',
  }),
}
