import {
  CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY,
  COUNTRY_GROUP,
  REDIRECT_STORE_URL,
} from '../constant/index'

export class LangSwitcherService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.setSubscriptions()
  }

  /**
   * Возвращает данные о текущем сторе
   *
   * @returns {object}
   */
  get currentStore() {
    return this.gaApp.services.app.main.currentStoreContent
  }

  /**
   * Возвращает массив возможных сторов
   *
   * @returns {array}
   */
  get allStores() {
    return this.gaApp.stores.app.common.convertedStores
  }

  /**
   * Возвращает массив возможных языков теущего стора, кроме текущего
   *
   * @returns {array}
   */
  get availableLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages.filter(
      (lang) => lang.code !== this.gaApp.i18n.locale.code,
    )
  }

  /**
   * Возвращает массив всех языков текущего стора
   *
   * @returns {array}
   */
  get allLanguages() {
    return this.gaApp.stores.app.common.convertedLanguages
  }

  /**
   * Возвращает данные текущего языка
   *
   * @returns {object}
   */
  get currentLanguage() {
    return this.gaApp.stores.app.common.convertedLanguages.find(
      (lang) => lang.code === this.gaApp.i18n.locale.code,
    )
  }

  /**
   * Проверяет, есть ли возможные языки, кроме текущего (у текущего стора)
   *
   * @returns {boolean}
   */
  get hasAvailableLanguages() {
    return !!this.availableLanguages.length
  }

  /**
   * Проверяет, является ли переданный язык текущим
   *
   * @param {string} languageCode - код языка
   * @returns {boolean}
   */
  isCurrentLanguage(languageCode) {
    return languageCode === this.currentLanguage.code
  }

  /**
   * Проверяет, является ли переданный язык дефолтным для переданного стора
   *
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   * @returns {boolean}
   */
  isStoreDefaultLanguage(languageCode, store) {
    return languageCode === store.languageDefaultCode
  }

  /**
   * Проверяет, равен ли код переданного стора коду текущего стора
   *
   * @param {string} storeCode - код стора (страны)
   * @returns {boolean}
   */
  isCurrentStore(storeCode) {
    return storeCode === this.currentStore.code
  }

  /**
   * Возвращает код языка в массиве languages из api, соответствующий переданному коду языка в i18n
   *
   * @param {string} code - код языка в i18n
   * @returns {string} - соответствующий код языка из api
   */
  getApiLanguageCode(code) {
    return this.gaApp.stores.app.common.initialLanguagesCodeMap[code] || code
  }

  /**
   * Записывает в куки переданную локаль, публикует событие о смене локали
   *
   * @param {string} code - код языка
   */
  switchLocale(code) {
    // установка локали в куку
    this.gaApp.i18n.setLocaleCookie(code)
    // Чистим весь кеш апи запросов
    this.gaApp.cache.clear()
    this.gaApp.eventBus.publish('module/app/lang-switcher/locale-changed', code)
  }

  /**
   * Открывает модалку смены языка
   */
  openModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = true
  }

  /**
   * Закрывает модалку смены языка
   */
  closeModal() {
    this.gaApp.stores.app.langSwitcher.modal.opened = false
  }

  /**
   * Открывает модалку смены страны
   *
   * @param {object} [props={}] - дополнительные пропсы для модалки, например { position: 'right' }
   */
  openCountryModal(props = {}) {
    this.gaApp.stores.app.langSwitcher.countryModal.props = props
    this.gaApp.stores.app.langSwitcher.countryModal.opened = true
  }

  /**
   * Закрывает модалку смены страны
   */
  closeCountryModal() {
    this.gaApp.stores.app.langSwitcher.countryModal.opened = false
    this.resetSelectedData()
  }

  /**
   * Открывает модалку подтверждения смены страны
   *
   * @param {object} [props={}] - дополнительные пропсы для модалки, например { position: 'right' }
   */
  openConfirmModal(props = {}) {
    this.gaApp.stores.app.langSwitcher.confirmModal.props = props
    this.gaApp.stores.app.langSwitcher.confirmModal.opened = true
  }

  /**
   * Закрывает модалку подтверждения смены страны
   */
  closeConfirmModal() {
    this.gaApp.stores.app.langSwitcher.confirmModal.opened = false
  }

  /**
   * Показывает дропдаун выбора страны и языка
   */
  showDropdown() {
    this.gaApp.stores.app.langSwitcher.dropdown.opened = true
  }

  /**
   * Скрывает дропдаун выбора страны и языка
   */
  hideDropdown() {
    this.gaApp.stores.app.langSwitcher.dropdown.opened = false
    this.resetSelectedData()
  }

  /**
   * Возвращает ссылку для редиректа.
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   * @returns {string} - ссылка для редиректа
   */
  getStoreDomain({ store, languageCode }) {
    let selectedStoreDomain

    const selectedStoreDomainParams = new URL(store.storeDomain).searchParams

    // Если в домене переданного в аргументах стора есть параметр store,
    // то записываем в selectedStoreDomain значение этого параметра
    if (selectedStoreDomainParams.get('store')) {
      selectedStoreDomain = selectedStoreDomainParams.get('store')
    } else {
      // иначе - записываем в selectedStoreDomain значение домена полностью
      selectedStoreDomain = store.storeDomain
    }

    return this.buildStoreUrl({ store, languageCode, selectedStoreDomain })
  }

  /**
   * Билдит и возвращает ссылку для редиректа.
   * Для авторизованного пользователя к текущему домену добавляет query параметры
   * - store - со значением, равным коду переданного в аргументах стора
   * - language - со значением, равным переданному аргументах languageCode либо текущему языку)
   * - path - со значением, равным текущему относительному пути (если он передан)
   * Для неавторизованного - к домену переданного в аргументах стора добавляет query параметр language,
   * а также строит урл на основе текущего относительного пути (если он передан)
   *
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   * @param {string} selectedStoreDomain - домен целевого стора
   * @returns {string} - ссылка для редиректа
   */
  buildStoreUrl({ store, languageCode, selectedStoreDomain }) {
    const currentStoreDomain = this.currentStore?.storeDomain
    let url

    const currentRelativePath =
      this.gaApp.stores.app.langSwitcher.currentRelativePath

    if (this.gaApp.stores.user.main.isAuthorized) {
      // Если пользователь авторизован, то строим ссылку вида "домен текущего стора + редирект урл ? store = код переданного стора
      // path = текущий относительный путь"
      url = new URL(REDIRECT_STORE_URL, currentStoreDomain)
      const selectedStoreCode = store.code
      selectedStoreCode && url.searchParams.set('store', selectedStoreCode)
      // добавляем параметр path с текущим относительным путем, чтобы редирект был на текущую страницу
      currentRelativePath && url.searchParams.set('path', currentRelativePath)
    } else {
      // Иначе - берем домен целевого стора (selectedStoreDomain) и
      // строим на его основе урлу с учетом относительного пути (если относительный путь передан)
      url = currentRelativePath
        ? new URL(currentRelativePath, selectedStoreDomain)
        : new URL(selectedStoreDomain)
    }

    // Добавляем в query параметры язык
    const selectedLanguage = languageCode ?? this.gaApp.i18n.locale.code
    selectedLanguage && url.searchParams.set('language', selectedLanguage)

    return decodeURIComponent(url.href)
  }

  /**
   * Записывает в стору обогащенный query параметром language домен переданного в аргументах стора
   *
   * @param {string} languageCode - код языка
   * @param {object} store - стор
   */
  setSelectedStoreDomain({ store, languageCode }) {
    this.gaApp.stores.app.langSwitcher.selectedStoreDomain =
      this.getStoreDomain({ store, languageCode })
  }

  /**
   * Очищает выбранный домен в сторе
   */
  resetSelectedStoreDomain() {
    this.gaApp.stores.app.langSwitcher.selectedStoreDomain = ''
  }

  /**
   * Записывает в стору в качестве выбранных:
   * - переданный в аргументах код стора (страны)
   * - соответствующий ему домен
   *
   * @param {string} code - код стора (страны)
   */
  setSelectedCountry(code) {
    this.gaApp.stores.app.langSwitcher.selectedCountry = code
    const selectedStore = this.gaApp.services.app.main.getStoreContent(code)
    this.setSelectedStoreDomain({ store: selectedStore })
  }

  /**
   * Очищает выбранную страну
   */
  resetSelectedCountry() {
    this.gaApp.stores.app.langSwitcher.selectedCountry = ''
  }

  /**
   * Редиректит пользователя на ранее выбранный им домен
   */
  redirectToSelectedStore() {
    window.location.href =
      this.gaApp.stores.app.langSwitcher.selectedStoreDomain
  }

  /**
   * Устанавливает куку подтверждения пользователем смены страны сроком на 1 год
   */
  setCountryConfirmCookie() {
    const expiresDate = new Date()
    expiresDate.setFullYear(expiresDate.getFullYear() + 1)

    this.gaApp.cookies.set(CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY, true, {
      expires: expiresDate,
      path: '/',
      sameSite: 'lax',
    })
  }

  /**
   * Убирает куку подтверждения пользователем смены страны
   */
  removeCountryConfirmCookie() {
    this.gaApp.cookies.remove(CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY)
  }

  /**
   * Устаналивает подписки:
   * - на событие логаута
   * - событие смены страницы
   */
  setSubscriptions() {
    this.gaApp.eventBus.subscribe('module/auth/logout', () => {
      this.removeCountryConfirmCookie()
    })

    // Обрабатывает кейс, когда пользак открыл модалку смены страны и
    // ушел на другую страницу
    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      this.closeCountryModal()
      this.closeConfirmModal()
      this.hideDropdown()
    })
  }

  /**
   * Проверяет необходимость показа модалки подтверждения смены страны.
   * Должна быть показана, если
   * - пользователь ранее НЕ подтверждал смену страны в текущем сторе
   * - текущая страна находится в группе СНГ
   *
   * @returns {boolean}
   */
  needConfirmation() {
    const countryConfirmCookie = this.gaApp.cookies.get(
      CHANGE_COUNTRY_CONFIRMED_COOKIES_KEY,
    )

    const { code } = this.currentStore

    return !countryConfirmCookie || COUNTRY_GROUP.CIS.includes(code)
  }

  /**
   * Проверяет необходимость показа модалки подтверждения смены страны
   * - если true, открывает модалку
   * - если false, редиректит пользователя на ранее выбранный им домен
   *
   * @param {object} [props={}] - дополнительные пропсы для модалки, например { position: 'right' }
   */
  confirmOrRedirectToSelected(modalProps = {}) {
    if (this.needConfirmation()) {
      this.openConfirmModal(modalProps)
    } else {
      this.redirectToSelectedStore()
    }
  }

  /**
   * Записывает в стор текущий относительный путь,
   * если открыта модалка смены стора ИЛИ дропдаун
   * @param {string} path относительный путь
   */
  setCurrentRelativePath(path) {
    const isCountryModalOpened =
      this.gaApp.stores.app.langSwitcher.countryModal.opened
    const isDropdownOpened = this.gaApp.stores.app.langSwitcher.dropdown.opened

    if (!isCountryModalOpened && !isDropdownOpened) return

    this.gaApp.stores.app.langSwitcher.currentRelativePath = path
  }

  /**
   * Ощищает текущий относительный путь в сторе
   */
  resetCurrentRelativePath() {
    this.gaApp.stores.app.langSwitcher.currentRelativePath = ''
  }

  /**
   * Очищает в сторе:
   * - выбранный код страны
   * - выбранный домен стора
   * - текущий относительный путь
   */
  resetSelectedData() {
    this.resetSelectedCountry()
    this.resetSelectedStoreDomain()
    this.resetCurrentRelativePath()
  }
}
