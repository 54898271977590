import { TYPE } from '../../constants'
import { GtmPickThematic } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class PickThematicBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmPickThematic]])
    super(models, data)
  }
}
