export class PublicService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Собирает строку региона с типом
   *
   * @param {string} region - регион
   * @param {string} regionType - страна/республика
   * @returns {string}
   */
  composeRegionWithType(region, regionType) {
    return this.gaApp.services.location.formatter.composeRegionWithType(
      region,
      regionType,
    )
  }

  /**
   * Запрос адреса по подсказке с городом
   * @param objectId - фиас адреса с подсказки
   * @param fullAddress - полный адрес с подсказки
   * @param {boolean} showNotification - флаг отображения нотификации при ошибке
   * @returns {Promise}
   */
  getGeocodeCities(objectId, fullAddress, showNotification) {
    return this.gaApp.services.location.api.geocodeCities(
      objectId,
      fullAddress,
      showNotification,
    )
  }

  /**
   * Получение локали от браузера
   *
   * @returns {Promise}
   */
  getCoordsByBrowser() {
    return this.gaApp.services.location.geolocation.getCoordsByBrowser()
  }

  /**
   * Получение геокода
   *
   * @param {object} coordinates - координаты
   * @param {object} requestParams - параметры запроса
   * @returns {Promise}
   */
  getReverseGeocode(coordinates, requestParams) {
    return this.gaApp.services.location.api.getReverseGeocode(
      coordinates,
      requestParams,
    )
  }
}
