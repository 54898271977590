<template>
  <svg fill="none" stroke-width="0" viewBox="0 0 62 25">
    <g clip-path="url(#bnpl-tabby-a)">
      <path
        fill="#5AFEAE"
        d="M53.484 25.194H7.878A7.913 7.913 0 0 1-.04 17.278V7.77A7.913 7.913 0 0 1 7.878-.146h45.606A7.913 7.913 0 0 1 61.4 7.77v9.508a7.913 7.913 0 0 1-7.916 7.916Z"
      />
      <path
        fill="#292929"
        d="m50.343 7.998-3.144 12.008v.038h2.462l3.144-12.008h-2.462v-.038Zm-38.864 6.818c-.379.19-.758.266-1.174.266-.872 0-1.364-.152-1.44-.872V8.074l-2.197.265c1.478-.303 2.31-1.44 2.31-2.613v-.72H6.518v3.371l-.152.038v6.212c.076 1.742 1.25 2.803 3.106 2.803.682 0 1.402-.151 1.97-.417l.038-2.197Z"
      />
      <path
        fill="#292929"
        d="m11.857 7.543-6.894 1.06v1.743l6.894-1.06V7.542Zm0 2.575L4.963 11.18v1.667l6.894-1.06v-1.668Zm7.727.796c-.113-1.932-1.326-3.106-3.295-3.106-1.137 0-2.084.454-2.728 1.288-.644.833-.985 2.045-.985 3.522 0 1.478.341 2.69.985 3.523.644.834 1.591 1.25 2.728 1.25 1.97 0 3.181-1.136 3.295-3.106v2.917h2.462V8.035l-2.462.379v2.5Zm.152 1.704c0 1.705-.91 2.841-2.311 2.841-1.44 0-2.31-1.06-2.31-2.84 0-1.78.87-2.841 2.31-2.841.682 0 1.288.265 1.705.795.378.492.606 1.212.606 2.045Zm9.47-4.81c-1.97 0-3.183 1.136-3.296 3.106V5.346l-2.462.378v11.478h2.462v-2.917c.113 1.97 1.326 3.106 3.295 3.106 2.31 0 3.712-1.78 3.712-4.773 0-2.992-1.401-4.81-3.712-4.81Zm-1.1 7.651c-1.4 0-2.31-1.098-2.31-2.84 0-.834.227-1.554.606-2.046.417-.53.985-.795 1.705-.795 1.44 0 2.31 1.06 2.31 2.84 0 1.78-.87 2.841-2.31 2.841Zm11.478-7.651c-1.97 0-3.182 1.136-3.295 3.106V5.346l-2.462.378v11.478h2.462v-2.917c.113 1.97 1.325 3.106 3.295 3.106 2.31 0 3.712-1.78 3.712-4.773 0-2.992-1.401-4.81-3.712-4.81Zm-1.098 7.651c-1.402 0-2.311-1.098-2.311-2.84 0-.834.227-1.554.606-2.046.417-.53.985-.795 1.705-.795 1.439 0 2.31 1.06 2.31 2.84 0 1.78-.871 2.841-2.31 2.841Zm4.81-7.462h2.614l2.121 9.205h-2.348l-2.387-9.205Zm11.515.947v-.72h-.303v-.151h.796v.151H55v.72h-.19Zm.53 0v-.909h.304l.151.417c.038.113.076.151.076.19 0-.039.038-.077.076-.19l.151-.417h.303v.91h-.19v-.72l-.264.72h-.19l-.227-.72v.72h-.19Z"
      />
    </g>
    <defs>
      <clipPath id="bnpl-tabby-a">
        <path fill="#fff" d="M0 0h61.364v25H0z" transform="translate(0 .006)" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-bnpl-tabby',
}
</script>
