export const endpoints = {
  redirect: () => ({
    url: 'v3/catalog/redirect',
  }),

  common: () => ({
    url: 'v1/settings',
  }),

  cookieAgreementText: () => ({
    url: 'cookie/agreement/text',
  }),

  appInfo: () => ({
    url: `api/app-info`,
  }),
}
