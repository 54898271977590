import { useContext } from '@nuxtjs/composition-api'

import { useUser } from '~/modules/user'

import { GaProductCardPriceColumn } from '../price-column'
import { GaProductCardPriceRow } from '../price-row'

import { COMPONENT_VIEW } from './scripts/const'

export default {
  name: 'ga-product-card-price',
  inheritAttrs: false,

  components: {
    GaProductCardPriceColumn,
    GaProductCardPriceRow,
  },

  setup() {
    const { $gaApp } = useContext()

    const isProductCardNewPrice = $gaApp.features.get('productCardNewPrice')
    const { userIsAuth } = useUser()

    const priceComponent =
      isProductCardNewPrice && userIsAuth.value
        ? COMPONENT_VIEW.COLUMN
        : COMPONENT_VIEW.ROW

    return {
      priceComponent,
    }
  },
}
