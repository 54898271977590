import { getNested, getVuelidateField } from '../utils'

export const useProxy = (v$) => {
  /**
   * Возвращает свойства валидации определенного поля.
   *
   * Если поле не найдено, то возвращает undefined.
   *
   * Если `filedPath === undefined`, то возвращает свойства всей валидации.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {object | undefined}
   */
  const getField = (fieldPath) => {
    return getNested(v$.value, fieldPath)
  }

  /**
   * Сообщает о том, была ли произведена валидация у поля или введены "сырые данные".
   *
   * Если поле не найдено, то возвращает undefined.
   *
   * Если `filedPath === undefined`, то возвращает свойства всей валидации.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {boolean | undefined}
   */
  const isDirty = (fieldPath) => {
    return getVuelidateField(v$, '$dirty', fieldPath)
  }

  /**
   * Сообщает о том, что введенные данные не соответствуют описанным правилам.
   *
   * Если поле не найдено, то возвращает undefined.
   *
   * Если `filedPath === undefined`, то возвращает свойства всей валидации.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {boolean | undefined}
   */
  const isInvalid = (fieldPath) => {
    return getVuelidateField(v$, '$invalid', fieldPath)
  }

  /**
   * Указывает, находится ли в данный момент на рассмотрении какой-либо дочерний асинхронный валидатор.
   *
   * Всегда `false`, если все валидаторы синхронны.
   *
   * Если поле не найдено, то возвращает undefined.
   *
   * Если `filedPath === undefined`, то возвращает свойства всей валидации.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {boolean | undefined}
   */
  const isPending = (fieldPath) => {
    return getVuelidateField(v$, '$pending', fieldPath)
  }

  /**
   * Устанавливает для своего свойства и всех вложенных свойств $dirty значение true.
   *
   * Если поле не найдено, то возвращает undefined.
   *
   * Если `filedPath === undefined`, то возвращает свойства всей валидации.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {boolean | undefined}
   */
  const touch = (fieldPath) => {
    return getVuelidateField(v$, '$touch', fieldPath)?.()
  }

  /**
   * Сбрасывает состояние валидации поля, устанавливая $dirty в false.
   *
   * Если поле не найдено, то возвращает undefined.
   *
   * Если `filedPath === undefined`, то сбрасывает состояние всей валидации.
   *
   * @param {string | undefined} fieldPath путь до поля в виде стандартного обращения к объекту. Например: `'path.to.object.field'`
   * @returns {boolean | undefined}
   */
  const reset = (fieldPath) => {
    return getVuelidateField(v$, '$reset', fieldPath)?.()
  }

  return {
    getField,
    isDirty,
    isInvalid,
    isPending,
    touch,
    reset,
  }
}
