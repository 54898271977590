import { KEY_CODES } from '@ga/constants/key-codes'
import { clamp } from '@ga/utils'

const HOVER_CHANGE_MIN_TIME = 100

// @vue/component
export default {
  name: 'ga-select-list-base',

  inheritAttrs: false,

  props: {
    options: {
      type: Array,
      required: true,
      validator: (value) =>
        value.every(
          (item) => item.divider || (String(item.value) && item.text),
        ),
    },
    active: {
      type: Boolean,
      default: false,
    },
    activatedWithKeyboard: {
      type: Boolean,
      default: false,
    },
    allowSpaceKey: {
      type: Boolean,
      default: false,
    },
    // Не обрабатывает клавишу enter, если не выбран ни один пункт
    allowEnterKey: {
      type: Boolean,
      default: false,
    },
    alwaysHoverFirst: {
      type: Boolean,
      default: false,
    },
    alwaysHoverVisible: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    // Выключить авто-фокус на первый элемент при изменении списка
    disabledAutoFocusFirstOption: {
      type: Boolean,
      default: false,
    },
    testIdOptions: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hoveredSelectableIndex: -1,
      hoveredGlobalIndex: -1,
      hoveredTimestamp: 0,

      interactWithMouse: false,
      interactWithKeyboard: false,

      withoutTransition: false,

      optionTagName: 'li',
      optionsNodes: [],
    }
  },

  computed: {
    mods() {
      return {
        keyboard: this.keyboard,
        'always-hover-visible': this.alwaysHoverVisible,
        'without-transition': this.withoutTransition,
      }
    },

    empty() {
      return this.options.length === 0
    },

    keyboard() {
      return this.interactWithKeyboard
    },

    optionsInternal() {
      let selectableIndex = -1
      const lastIndex = this.options.length - 1

      return this.options
        .map((option, globalIndex) => {
          const { disabled, divider, value } = option

          const key = value || `${name}-${globalIndex}`
          const selectable = !(disabled || divider)

          if (selectable) {
            selectableIndex += 1
          }

          return {
            ...option,
            meta: {
              key,
              selectable,
              globalIndex,
              selectableIndex,
            },
          }
        })
        .map((option, globalIndex) => {
          const { disabled, selected, divider } = option

          return {
            ...option,
            state: {
              selected,
              disabled,
              divider,

              hovered: globalIndex === this.hoveredGlobalIndex,

              first: globalIndex === 0,
              last: globalIndex === lastIndex,
            },
          }
        })
    },

    optionsSelectable() {
      return this.optionsInternal.filter((option) => option.meta.selectable)
    },

    hasOptionsSelectable() {
      return this.optionsSelectable.length > 0
    },
  },

  watch: {
    async active(value) {
      if (process.client) {
        if (value) {
          await this.updateOptionsNodes()

          this.onActivation()
        } else {
          this.onDeactivation()
        }
      }
    },

    async options() {
      if (!this.active) {
        return
      }

      await this.updateOptionsNodes()

      if (!this.disabledAutoFocusFirstOption) {
        const [option] = this.optionsSelectable

        this.setHoveredIndexesWithEvent(option, 'update')
      }
    },

    hoveredTimestamp(newValue, oldValue) {
      this.withoutTransition = newValue - oldValue < HOVER_CHANGE_MIN_TIME
    },
  },

  beforeDestroy() {
    this.onDeactivation()
  },

  methods: {
    onActivation() {
      this.interactWithKeyboard = this.activatedWithKeyboard

      if (!this.disabledAutoFocusFirstOption) {
        const optionSelected = this.optionsInternal.find(
          (option) => option.state.selected,
        )
        const [optionFirstSelectable] = this.optionsSelectable

        const optionToHover = this.alwaysHoverFirst
          ? optionFirstSelectable
          : optionSelected || optionFirstSelectable

        this.clearHoveredIndexes()
        this.setHoveredIndexesWithEvent(optionToHover, 'activation')
      }

      window.addEventListener('keydown', this.onKeyDown)
    },

    onDeactivation() {
      this.clearHoveredIndexes()

      window.removeEventListener('keydown', this.onKeyDown)
    },

    onOptionMouseEnter(option) {
      if (this.interactWithKeyboard) {
        return
      }

      if (option.meta.selectable) {
        this.setHoveredIndexes(option)
      }
    },

    onOptionMouseMove(option) {
      if (this.interactWithKeyboard) {
        this.interactWithKeyboard = false
      }

      if (option.meta.selectable) {
        this.setHoveredIndexes(option)
      }
    },

    onOptionMouseDown(option) {
      this.interactWithMouse = true

      if (option.meta.selectable) {
        this.selectionConfirm()
      }
    },

    onOptionMouseLeave(option) {
      if (this.interactWithKeyboard) {
        return
      }

      if (option.meta.selectable) {
        this.clearHoveredIndexes()
      }
    },

    onKeyDown(event) {
      if (this.hasOptionsSelectable) {
        this.onSelectionKeyDown(event)
      }
    },

    onSelectionKeyDown(event) {
      const { up, down, space, enter, esc } = KEY_CODES
      const { keyCode } = event

      if (this.allowSpaceKey && keyCode === space) {
        return
      }

      // Если ни один пункт не выбран, и нажата клавиша Enter
      if (this.allowEnter(event)) {
        return
      }

      const action = {
        [up]: () => this.selectionMove(-1),
        [down]: () => this.selectionMove(1),
        [space]: this.selectionConfirm,
        [enter]: this.selectionConfirm,
        [esc]: this.selectionCancel,
      }[keyCode]

      if (action) {
        this.interactWithKeyboard = true

        event.stopPropagation()
        event.preventDefault()

        action()
      }
    },

    selectionMove(direction) {
      return this.hasHoveredIndexes()
        ? this.selectionMoveRelatively(direction)
        : this.selectionMoveAnew(direction)
    },

    selectionMoveRelatively(direction) {
      const index = this.getMovedSelectableIndex(direction)
      const option = this.optionsSelectable[index]

      this.setHoveredIndexesWithKeyboard(option)
    },

    getMovedSelectableIndex(direction) {
      const { hoveredSelectableIndex, optionsSelectable, loop } = this

      const index = hoveredSelectableIndex + direction
      const min = 0
      const max = optionsSelectable.length - 1

      return clamp(index, { min, max, loop })
    },

    selectionMoveAnew(direction) {
      const lastIndex = this.optionsSelectable.length - 1
      const index = direction === 1 ? 0 : lastIndex

      const option = this.optionsSelectable[index]

      this.setHoveredIndexesWithKeyboard(option)
    },

    selectionConfirm() {
      const hasIndexes = this.hasHoveredIndexes()

      if (hasIndexes) {
        const index = this.hoveredGlobalIndex
        const option = this.options[index]

        this.$emit('select', option)
      }
    },

    selectionCancel() {
      this.$emit('cancel')
    },

    hasHoveredIndexes() {
      return this.hoveredGlobalIndex > -1 && this.hoveredSelectableIndex > -1
    },

    setHoveredIndexesWithKeyboard(option) {
      this.setHoveredIndexes(option)

      this.emitWithOption('keyboard-hover', option)
    },

    setHoveredIndexesWithEvent(option, event) {
      if (!option) {
        this.clearHoveredIndexes()

        return
      }

      if (this.interactWithKeyboard) {
        this.setHoveredIndexesWithKeyboard(option)
      } else {
        this.setHoveredIndexes(option)
      }

      this.emitWithOption(event, option)
    },

    setHoveredIndexes(option) {
      const { globalIndex, selectableIndex } = option.meta

      if (this.hoveredGlobalIndex === globalIndex) {
        return
      }

      this.hoveredGlobalIndex = globalIndex
      this.hoveredSelectableIndex = selectableIndex
      this.hoveredTimestamp = Date.now()

      this.emitWithOption('hover', option)
    },

    clearHoveredIndexes() {
      this.hoveredGlobalIndex = -1
      this.hoveredSelectableIndex = -1
    },

    async updateOptionsNodes() {
      await this.$nextTick()

      const { root } = this.$refs
      const optionsNodes = root.querySelectorAll(this.optionTagName)

      this.optionsNodes = [...optionsNodes]
    },

    emitWithOption(eventName, option) {
      const index = option.meta.globalIndex
      const el = this.optionsNodes[index]

      if (!el) {
        return
      }

      this.$emit(eventName, { option, el })
    },

    allowEnter(event) {
      return (
        this.allowEnterKey &&
        !this.hasHoveredIndexes() &&
        event.keyCode === KEY_CODES.enter
      )
    },
  },
}
