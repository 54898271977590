import debounce from 'lodash/debounce'

import { SEARCH_DEBOUNCE_DELAY } from '../constants/search'

export class CitiesService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.getSearchDataDebounced = debounce(
      this.getSearchDataDebounced,
      SEARCH_DEBOUNCE_DELAY,
    )
  }

  updateQuery(value) {
    this.gaApp.stores.location.search.cities.query = value

    if (!value?.trim()) {
      return this.resetState()
    }

    this.pendingStart()
    this.getSearchDataDebounced(value)
  }

  pendingStart() {
    this.gaApp.stores.location.search.cities.pending = true
  }

  pendingStop() {
    this.gaApp.stores.location.search.cities.pending = false
  }

  async getSearchDataDebounced(value) {
    try {
      const data = await this.gaApp.services.location.api.suggestCities(value)

      // если отменили запрос - в data будет undefined
      if (data) {
        this.gaApp.services.location.search.setItems(data)
      }
    } catch (error) {
      console.error(error)
    }

    this.pendingStop()
  }

  resetState() {
    this.gaApp.stores.location.search.cities.items = []
    this.gaApp.stores.location.search.cities.query = ''
    this.gaApp.stores.location.search.cities.pending = false
  }

  /**
   * Возвращает подсказку с полными данными, включая локацию
   * @param { object } option - подсказка
   * @param { string } option.value - фиас города
   * @param { string } option.fullAddress - адрес в виде строки
   * @returns {Promise<null|{[p: string]: *}>}
   */
  async getSuggestData(option) {
    this.pendingStart()

    try {
      const address = await this.gaApp.services.location.api.geocodeCities(
        option.value,
        option.fullAddress,
      )

      return {
        ...option,
        ...address,
      }
    } catch (error) {
      console.error(error)

      return null
    } finally {
      this.pendingStop()
    }
  }
}
