import isObject from 'lodash/isObject'
import isString from 'lodash/isString'

export class ErrorWrapper {
  constructor(error, dataRequest) {
    console.log(error)
    if (isObject(dataRequest)) {
      this.dataRequest = JSON.stringify(dataRequest)
    }
    if (isString(dataRequest)) {
      this.dataRequest = dataRequest
    }
    const errorResponse =
      error.response?.data?.data ?? error.response?.data ?? {}

    if (isObject(errorResponse)) {
      this.dataResponse = JSON.stringify(error.response?.data)
    }
    if (isString(errorResponse)) {
      this.dataResponse = error.response?.data
    }

    this.stack = error.stack

    this.type = this.name
    this.name = 'ApiRequest'
    this._data = errorResponse

    const {
      reason,
      statusCode,
      statusMessage,
      message,
      localizedMessage,
      id,
      invalidParameters,
      metadata,
    } = errorResponse

    this.reason = reason
    this.statusCode = statusCode || error.response?.status
    this.statusMessage =
      statusMessage || error.response?.statusText || error.message || ''

    // флаг того, что запрос был принудительно прерван
    this.aborted = error.config?.signal?.aborted ?? false

    // Иногда могут переписать по месту, иза этого есть дубль statusMessage
    this.message = message || error.response?.statusText || error.message || ''
    this.localizedMessage = localizedMessage
    this.id = id
    this.invalidParameters = invalidParameters
    this.metadata = metadata || {}
  }

  get data() {
    return new Proxy(this._data, {
      get(target, prop) {
        if (prop === 'data') {
          console.error(
            'No direct accessing to error.data.data: ',
            target[prop],
          )
        }
        return target[prop]
      },
    })
  }
}
