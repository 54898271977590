import { buildGetUrlForListing } from './builders'

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async fetchArticle(id) {
    const { url } = this.endpoints.fetchArticle(id)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchArticleByUrl(articleUrl) {
    const { url } = this.endpoints.fetchArticleByUrl(articleUrl)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // TODO - удалить после удаления ФТ flaconUseNewApiForListingTitles
  // TODO - удалить после удаления на фронте ФТ flaconUseNewFilters
  async fetchArticlesListing(data) {
    const { url } = this.endpoints.fetchArticlesListing()
    const params = buildGetUrlForListing(data)

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // Новые заголовки
  async fetchArticlesListingV2(data) {
    const { url } = this.endpoints.fetchArticlesListingV2()
    const params = buildGetUrlForListing(data)

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // Новые фильтры
  async fetchArticlesListingV3(data) {
    const { url } = this.endpoints.fetchArticlesListingV3()
    const params = buildGetUrlForListing(data)

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchListingFilters() {
    const { url } = this.gaApp.features.get('flaconUseNewFilters')
      ? this.endpoints.fetchFiltersV2()
      : this.endpoints.fetchFilters()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchProductsBySku(params) {
    const { url } = this.endpoints.fetchProductsBySku()

    try {
      const response = await this.gaApp.api.request().post(url, params)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchCategoryDataByUrl(categoryUrl) {
    const { url } = this.endpoints.fetchCategoryDataByUrl()
    const params = { url: categoryUrl }

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchProducts(data) {
    const { url } = this.endpoints.fetchProducts()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchRubricArticles(data) {
    const { url } = this.endpoints.fetchRubricArticles()

    const fullUrl = `${url}?page=${data.pagination.pageNumber}&limit=${data.pagination.pageSize}&rubric=${data.rubric}`

    try {
      const response = await this.gaApp.api.request().get(fullUrl)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  // TODO - удалить после переезда
  async fetchPreviewListing(id) {
    const { url } = this.endpoints.fetchPreviewListing(id)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchPreviewListingV2(id) {
    const { url } = this.endpoints.fetchPreviewListingV2(id)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchArticleViewed(data) {
    const { url } = this.gaApp.isWebview
      ? this.endpoints.fetchArticleViewedWebview()
      : this.endpoints.fetchArticleViewed()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
