// TODO подумать куда выносить и где хранить определение типов

/**
 * @typedef {object} ArticleLikeDto
 * @property {string} articleId - id статьи
 * @property {string} userId - уникальный идентификатор пользователя или устройства
 */

/**
 * @typedef {object} LikesInfo
 * @property {number} likesCount - кол-во лайков статьи
 */

/**
 * @typedef {object} UserLikesInfo
 * @property {boolean} liked - проставлен ли лайк текущим пользователем
 * @property {number} likesCount - кол-во лайков статьи
 */

export class LikesRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp
    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Получить данные по лайкам для заданной статьи и заданного пользователя/устройства
   *
   * @param {ArticleLikeDto} data
   * @returns {Promise<UserLikesInfo>} - кол-во лайков у статьи и проставлен ли лайк текущим
   * пользователем
   */
  async getArticleLikesInfo(data) {
    try {
      const { url } = this.endpoints.getArticleLikeInfo()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Установить лайк для заданной статьи
   * @param {ArticleLikeDto} data
   * @returns {Promise<LikesInfo>} - кол-во лайков у статьи после установки лайка текущим пользователем
   */
  async setArticleLike(data) {
    try {
      const { url } = this.endpoints.setArticleLike()
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Удалить лайк для заданной статьи
   * @param {ArticleLikeDto} data
   * @returns {Promise<LikesInfo>} - кол-во лайков у статьи после снятия лайка текущим пользователем
   */
  async removeArticleLike(data) {
    try {
      const { url } = this.endpoints.removeArticleLike()
      const response = await this.gaApp.api.request().delete(url, { data })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
