const SOURCES_TO_REMOVE_COOKIE = [
  'ask',
  'bing',
  'duckduckgo',
  'google',
  'rambler',
  'yandex',
  'baidu',
  'ecosia.org',
  'yahoo',
  'cpd_mny_master-strobing_sitemny',
  'sogou',
  'so.com',
  'go.mail.ru',
  'search.smt.docomo',
  'mom.life',
  'tut.by',
  'startsiden',
  'naver',
  'daum',
  'avg',
  'mail.ru',
  'mskmakeup.ru',
  'metro-spb',
  'metro',
  'babylon',
  'mini-flakony-duhov-krasivo-i-udobno',
  'moscow.cataloxy.ru',
  'ne-uznau-vas-v-grime',
  'n_expert',
  'googlemerchant',
  'ukr',
  'Google Ads Ibrand',
  'Google Ads Trade Ibrand',
  'Google Ads Retargeting Ibrand',
  'Google Ads GMC',
  'Google Ads',
  'Google Ads Retargeting',
  'SEO',
  '360.cn',
  'mts',
  'my_goods',
  'my_beauty_tales',
  'Rambler',
  'ecosia',
  'qwant',
  'rambler_18_24_PMP',
  'yandex_news',
  'google_organic_seo',
  'yandex_organic_seo',
  'duckduckgo_organic_seo',
  'bing_organic_seo',
  'yahoo_organic_seo',
  'ecosia_organic_seo',
  'search.goo.ne.jp',
  'naver.com',
]

//  трекер для отслеживания переходов, сохранение в куку
export default ({ $gaApp }) => {
  // Кол-во дней хранения cookie
  const daysToStore = 30
  const period = daysToStore * 60 * 60 * 24 // в секундах

  // Параметры создания cookie с UID
  const uidCookieName = 'columbus_ad_tracker' // Название cookie, в которой хранится uid
  const uidCookieOptions = {
    maxAge: period,
    path: '/',
    sameSite: 'lax',
  }

  const admitadParam = $gaApp.route.query.admitad_uid
  const utmSourceParam = $gaApp.route.query.utm_source
  const gsRefParam = $gaApp.route.query._gs_ref

  // Удаляем куки columbus_ad_tracker
  // если в utm_source пришел один из вышеперечисленных значений
  if (SOURCES_TO_REMOVE_COOKIE.includes(utmSourceParam)) {
    $gaApp.cookies.remove(uidCookieName)

    return
  }

  if (utmSourceParam === 'advcake') {
    $gaApp.cookies.set(
      uidCookieName,
      JSON.stringify({
        url: {
          utm_source: 'advcake',
        },
        id: '5',
      }),
      uidCookieOptions,
    )
  }

  if (utmSourceParam === 'admitad' && admitadParam) {
    $gaApp.cookies.set(
      uidCookieName,
      JSON.stringify({
        url: {
          utm_source: 'admitad',
          admitad_uid: admitadParam,
        },
        id: '1',
      }),
      uidCookieOptions,
    )
  }

  if (utmSourceParam === 'gdeslon' && gsRefParam) {
    $gaApp.cookies.set(
      uidCookieName,
      JSON.stringify({
        url: {
          utm_source: 'gdeslon',
          click_id: gsRefParam,
        },
        id: '9',
      }),
      uidCookieOptions,
    )
  }
}
