import { COUNTRY } from '~/modules/app/constant'

/**
 * Способ оплаты
 *
 * @readonly
 * @enum {string}
 */
export const PAY_METHOD = {
  CARD: 'card',
  SBP: 'sbp',
  MASTERCARD: 'mastercard',
  MAGNATI: 'magnati',
  APPLE_PAY: 'applePay',
}

/**
 * Ключи для поиска способа оплаты в локали для кнопки
 */
export const PAY_METHOD_BUTTON_KEYS = {
  [PAY_METHOD.CARD]: 'card',
  [PAY_METHOD.APPLE_PAY]: 'applePay',
}

/**
 * Шаг процесса оплаты заказа
 *
 * @readonly
 * @enum {string}
 */
export const STEP = {
  SELECT_PAY_METHOD: 'select-pay-method',
  SET_RECIPE_PHONE: 'set-recipe-phone',
  SELECT_CARD: 'select-card',
  SBP_SELECT_BANK: 'sbp-select-bank',
  SBP_QR_CODE: 'sbp-qr-code',
}

/**
 * Интервал проверки статуса оплаты заказа в миллисекундах
 */
export const CHECK_PAYMENT_STATUS_INTERVAL = 5000

/**
 * Статус оплаты заказа
 *
 * @readonly
 * @enum {string}
 */
export const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  PROCESS: 'process',
}

/**
 * Платформа пользователя
 *
 * @readonly
 * @enum {string}
 */
export const DEVICE_TYPE = {
  IOS: 'ios',
  ANDROID: 'android',
  UNKNOWN: 'unknown',
}

/**
 * Тип ссылки для оплаты СБП в зависимости от устройства
 *
 * @readonly
 * @enum {string}
 */
export const LINK_TYPE = {
  [DEVICE_TYPE.ANDROID]: 'androidLink',
  [DEVICE_TYPE.IOS]: 'iosLink',
}

/**
 * Значение опции "новая карта"
 */
export const NEW_CARD_NAME = 'NEW_CARD'

/**
 * Адреса страниц оплаты
 *
 * @readonly
 * @enum {string}
 */
export const PAYMENT_LINK = {
  SUCCESS: '/cards/payment/success',
  MASTERCARD: '/cards/payment/mastercard',
}

/**
 * Фолбэк способ оплаты, если дефолтный не вернулся с бэка
 */
export const FALLBACK_PAYMENT_METHOD = {
  [COUNTRY.QA]: PAY_METHOD.MASTERCARD,
  [COUNTRY.AE]: PAY_METHOD.MAGNATI,
  [COUNTRY.SA]: PAY_METHOD.CARD,
  DEFAULT: PAY_METHOD.SBP,
}
