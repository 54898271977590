import { PAGE_TYPE } from '../constants'

export const routes = [
  {
    moduleType: 'cart',
    pageType: PAGE_TYPE.CART,
    path: '/cart',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () => import('../components/pages/cart.vue'),
  },
  {
    moduleType: 'cart',
    pageType: PAGE_TYPE.SHARE,
    path: '/sharing/basket/:id',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () => import('../components/pages/share.vue'),
  },
]
