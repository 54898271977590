import get from 'lodash/get'

import { DESIGN_OPTIONS, DESIGN_TYPES, MAPS } from '../constants/digital'

export class DigitalDesignTypesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает значение выбранной опции дизайна.
   *
   * Если переданные ключи дизайна и опции не являются частью дизайна, то не изменяет значение.
   *
   * @param {DESIGN_TYPES} designType тип дизайна
   * @param {DESIGN_OPTIONS} designOption опция дизайна
   * @param {string | number} optionValue значение опции
   */
  setDesignSelectedOption(designType, designOption, optionValue) {
    if (
      !(designType in this.gaApp.stores.giftCards.digital.design) ||
      !(designOption in this.gaApp.stores.giftCards.digital.design[designType])
    ) {
      return undefined
    }

    this.gaApp.stores.giftCards.digital.design[designType][designOption] =
      optionValue
  }

  /**
   * Сохраняет выбранные параметры для текущего дизайна
   */
  saveCurrentDesign() {
    const currentDesignType =
      this.gaApp.stores.giftCards.digital.selectedDesignType

    this.setDesignSelectedOption(
      currentDesignType,
      DESIGN_OPTIONS.IMAGE,
      get(this.gaApp.stores.giftCards.digital, MAPS.IMG),
    )
    this.setDesignSelectedOption(
      currentDesignType,
      DESIGN_OPTIONS.BACKGROUND,
      get(this.gaApp.stores.giftCards.digital, MAPS.COLOR),
    )
  }

  /**
   * Восстанавливает параметры для текущего дизайна из ранее сохраненных
   */
  restoreDesign() {
    const currentDesignType =
      this.gaApp.stores.giftCards.digital.selectedDesignType
    const currentDesignOptions =
      this.gaApp.stores.giftCards.digital.design[currentDesignType]

    this.gaApp.services.giftCards.digital.changeImage(
      currentDesignOptions[DESIGN_OPTIONS.IMAGE],
    )
    this.gaApp.services.giftCards.digital.changeBackground(
      currentDesignOptions[DESIGN_OPTIONS.BACKGROUND],
    )
  }

  /**
   * Устанавливает выбранный тип дизайна.
   *
   * Если переданное значение не является типом дизайна, то не изменяет значение.
   *
   * @param {DESIGN_TYPES} designType тип дизайна
   */
  setDesignType(designType) {
    if (
      !Object.values(DESIGN_TYPES).includes(designType) ||
      this.gaApp.stores.giftCards.digital.selectedDesignType === designType ||
      !this.gaApp.features.get('giftCardsDesignTypes')
    ) {
      return undefined
    }

    this.gaApp.analytics.modules.giftCards.onSelectDesignType(designType)

    this.saveCurrentDesign()
    this.gaApp.stores.giftCards.digital.selectedDesignType = designType
    this.restoreDesign()
  }

  /**
   * Устанавливает по умолчанию дизайн от нейросети, если включены FT
   */
  setDefaultDesignType() {
    if (this.gaApp.features.get('giftCardsAiDesignDefault')) {
      this.setDesignType(DESIGN_TYPES.AI)
    }
  }
}
