import * as components from './components'
import { services } from './services'

export const editor = {
  meta: {
    name: 'editor',
  },
  components,
  layers: {
    services,
  },
}
