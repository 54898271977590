import {
  ALLOWED_EXTERNAL_AUTH_SERVICE,
  COUNTDOWN_TYPE,
  DEFAULT_STEPS,
  STEP,
  TOKEN_TYPE,
} from '../../constants'

export const getters = (gaApp) => ({
  /**
   * Формируем список серыисов для отображения
   * Фильтруем по допустимым сервисам, так как в БФФ и на бэке могут добавиться новые сервисы,
   * которые мы ещё не умеем корректно отображать на фронте
   */
  externalAuthServices() {
    return gaApp.stores.auth.main.externalAuthTypes
      .filter((service) =>
        ALLOWED_EXTERNAL_AUTH_SERVICE.includes(service.grant_type),
      )
      .map((authType) => authType.service)
  },

  hasExternalAuthServices() {
    return this.externalAuthServices.length
  },

  steps() {
    const steps = [...DEFAULT_STEPS]

    if (this.hasExternalAuthServices) {
      steps.unshift(STEP.AUTH_TYPES)
    }

    return steps
  },

  isCurrentStepInitial() {
    return this.steps[0] === this.step
  },

  isAuthTypesStep() {
    return this.step === STEP.AUTH_TYPES
  },

  isStartStep() {
    return this.step === STEP.START
  },

  isLocaleChangingStep() {
    return this.step === STEP.LOCALE_CHANGING
  },

  isConfirmStep() {
    return this.step === STEP.CONFIRM
  },

  isWelcomeStep() {
    return this.step === STEP.WELCOME
  },

  hasBackAction() {
    return !this.isCurrentStepInitial && !this.isWelcomeStep
  },

  isPhoneToken() {
    return gaApp.stores.auth.main.grantType === TOKEN_TYPE.PHONE_CALL
  },

  helpPhone() {
    return gaApp.stores.app.common.phones.authorization
  },

  codeMessage() {
    return this.isPhoneToken
      ? gaApp.i18n.t('auth.modalConfirmForm.phoneCode')
      : gaApp.i18n.t('auth.modalConfirmForm.code')
  },

  countdownType() {
    return this.isPhoneToken ? COUNTDOWN_TYPE.PHONE : COUNTDOWN_TYPE.SMS
  },

  actionMessage() {
    return this.isPhoneToken
      ? gaApp.i18n.t('auth.modalConfirmForm.actionPhone')
      : gaApp.i18n.t('auth.modalConfirmForm.action')
  },

  /**
   * отображаем спиннер загрузки, если шаг ещё неопределён, либо для шага требуется загрузка данных
   */
  showSpinner() {
    return !this.step || (this.isAuthTypesStep && this.pending)
  },
})
