export const state = () => {
  return {
    pageNumber: 1,
    mediaCount: 0,
    maxMediaCount: 0,
    media: {},
    pending: false,
    hasError: false,

    wasModalOpened: false,
    modalOpened: false,
    activeIndex: 0,
    activeReviewId: null,
  }
}
