import isEmpty from 'lodash/isEmpty'

import { CacheService } from './cache.service'

const STORAGE_ITEMS = {
  CART: 'cart',
  CART_ITEMS: 'cart_items_data_storage',
  LOCATION: 'geolocation',
  USER: 'customer',
}

const STORAGE_NAME = 'mage-cache-storage'
const CACHE_SESSID_KEY = 'mage-cache-sessid'

export class MainService extends CacheService {
  constructor(gaApp) {
    super(gaApp, STORAGE_NAME, CACHE_SESSID_KEY)
  }

  initCacheStorage() {
    try {
      this.init()

      // const cacheStorage = this.getCacheStorage()

      // if (!isEmpty(cacheStorage)) {
      //   const { cart, geolocation } = cacheStorage
      //   const data = {}

      //   if (!isEmpty(cart)) {
      //     data.cart = cart
      //   }

      //   if (!isEmpty(geolocation)) {
      //     data.geolocation = geolocation
      //   }

      //   await this.gaApp.services.cache.api.getUserData(data)
      // }
    } catch (error) {
      console.error(error)
    }
  }

  getLocation() {
    return this.getItem(STORAGE_ITEMS.LOCATION)
  }

  saveLocation(data) {
    this.setItem(STORAGE_ITEMS.LOCATION, data)
  }

  getCart() {
    return this.getItem(STORAGE_ITEMS.CART)
  }

  saveCart(data) {
    this.setItem(STORAGE_ITEMS.CART, data)
  }

  saveCartItems(items) {
    this.storage.set(STORAGE_ITEMS.CART_ITEMS, items)
  }

  getUser() {
    return this.getItem(STORAGE_ITEMS.USER)
  }

  saveUser(data) {
    this.setItem(STORAGE_ITEMS.USER, data)
  }

  getServerData(key) {
    if (typeof window === 'undefined') {
      return null
    }

    const storage = window.serverCache ?? {}
    let dataKey = null

    if (!isEmpty(storage[key])) {
      dataKey = storage[key]

      delete window.serverCache[key]

      return dataKey
    }

    return null
  }
}
