import { DEFAULT_ROUTE_OPTIONS } from '../../constants'

export const state = () => {
  return {
    parameters: {
      pageType: null,
      moduleType: null,
      entityId: null,
    },

    ticker: null,
    tickerGap: null,

    routeOptions: DEFAULT_ROUTE_OPTIONS,

    ribbon: {
      currentId: null,
      previousId: null,
    },
  }
}
