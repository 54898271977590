/** билдер для внешних сервисов авторизации */
export class BaseBuilder {
  constructor(gaApp, serviceName) {
    this.gaApp = gaApp
    this.serviceName = serviceName
  }

  /**
   * Получает конфиг внешнего сервиса авторизации
   *
   * @returns {{ url: string, params: Object }}
   */
  getConfig() {
    return this.gaApp.config.get('authorization')[this.serviceName]
  }

  /**
   * Получает фиксированные параметры внешнего сервиса авторизации из конфига
   *
   * @returns {{ response_type: string, client_id: string, scope: string, code_challenge_method: string, prompt: string }}
   */
  getParams() {
    return this.getConfig().params
  }

  /**
   * Получает URL внешнего сервиса авторизации из конфига
   *
   * @returns {string}
   */
  getUrl() {
    return this.getConfig().url
  }

  /**
   * Формирует redirect_uri для возврата с внешних сервисов авторизации
   *
   * @param {string} serviceName
   */
  buildRedirectUri() {
    return `${this.gaApp.services.app.main.baseUrl}/auth/${this.serviceName}`
  }

  /**
   * Формирует URL обогощённый параметрами
   *
   * @param {string} url
   * @param {Object} params
   */
  buildUrl(params) {
    const redirectUrl = new URL(this.getUrl())

    // добавляем параметры в формируемый redirectUrl
    Object.entries(params).forEach(([key, value]) => {
      redirectUrl.searchParams.append(key, value)
    })

    return redirectUrl
  }
}
