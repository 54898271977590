import {
  FALLBACK_PAYMENT_METHOD,
  PAY_METHOD,
  PAYMENT_LINK,
  STEP,
} from '../constants/payment'

export class PaymentService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Флаг указывающий, что пользователь авторизован
   */
  get isUserAuthorized() {
    return this.gaApp.stores.user.main.isAuthorized
  }

  /**
   * Флаг, указывающий, что пользователь выбрал подарочную карту для себя
   */
  get isForSelf() {
    return this.gaApp.stores.giftCards.digital.isUserRecipient
  }

  /**
   * Флаг, указывающий, что заказ был изменен
   */
  get orderWasModified() {
    return Boolean(this.gaApp.stores.giftCards.digital.lastModifyFormData)
  }

  /**
   * Содержимое заказа
   */
  get orderData() {
    if (
      !this.gaApp.stores.giftCards.digital.basketId ||
      this.orderWasModified
    ) {
      this.gaApp.services.giftCards.digital.setBasketId()
    }

    const digitalOrderData =
      this.gaApp.services.giftCards.dto.order.getOrderData()

    // Для каждого способа оплаты свой basketId
    digitalOrderData.basketId = `${this.gaApp.stores.giftCards.payment.payMethod.selected}-${digitalOrderData.basketId}`

    if (!digitalOrderData.senderPhone) {
      digitalOrderData.senderPhone =
        this.gaApp.stores.giftCards.payment.recipe.phone
    }

    return digitalOrderData
  }

  /**
   * Сервисы для каждого способа оплаты.
   *
   * Для каждого способа оплаты используйте новый сервис с добавленным в него методом startPayment.
   */
  get payMethodsServices() {
    return {
      [PAY_METHOD.CARD]: this.gaApp.services.giftCards.paymentCard,
      [PAY_METHOD.SBP]: this.gaApp.services.giftCards.paymentSbp,
      [PAY_METHOD.MASTERCARD]: this.gaApp.services.giftCards.paymentMasterCard,
      [PAY_METHOD.MAGNATI]: this.gaApp.services.giftCards.paymentMagnati,
      [PAY_METHOD.APPLE_PAY]: this.gaApp.services.giftCards.paymentApplePay,
    }
  }

  /**
   * Выбранный сервис для оплаты.
   */
  get selectedPayMethodService() {
    return this.payMethodsServices[
      this.gaApp.stores.giftCards.payment.payMethod.selected
    ]
  }

  /**
   * Получает конфигурацию каждого способа оплаты заказа
   */
  getPayMethodsServicesData() {
    const fetchers = Object.values(this.payMethodsServices).map(
      (payMethodService) => payMethodService.getData?.(),
    )

    return Promise.all(fetchers)
  }

  /** Получает конфигурацию для оплаты */
  async getPaymentConfig() {
    if (!this.gaApp.features.get('giftCardsNewPayment')) {
      return
    }

    try {
      const {
        saved,
        saveable,
        defaultPaymentMethod,
        availablePaymentMethods,
        merchantId,
        merchantCapabilities,
        supportedNetworks,
      } = await this.gaApp.services.giftCards.api.getPaymentConfig()

      this.setSavedCards(saved)
      this.setSaveable(saveable)
      this.setDefaultPayMethod(defaultPaymentMethod)
      this.setAvailablePayMethods(availablePaymentMethods)

      if (this.gaApp.features.get('giftCardsApplePay')) {
        this.gaApp.services.giftCards.paymentApplePay.setApplePayParams({
          merchantId,
          merchantCapabilities,
          supportedNetworks,
        })
      }
    } catch (error) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('giftCards.payment.errors.getPaymentConfig'),
      )
    }
  }

  /**
   * Устанавливает новый шаг.
   *
   * Устанавливает новый шаг и добавляет его в историю шагов. Текущим шагом является текущий из массива
   *
   * @param {STEP} newStep новый шаг, на который нужно перейти
   */
  nextStep(newStep) {
    if (!Object.values(STEP).includes(newStep)) {
      return undefined
    }

    this.gaApp.stores.giftCards.payment.stepHistory.push(newStep)
  }

  /**
   * Шаг назад по истории шагов.
   *
   * Удаляет последний шаг – с которого перешли из истории шагов.
   */
  stepBack() {
    if (this.gaApp.stores.giftCards.payment.stepHistory.length <= 1) {
      return undefined
    }

    this.gaApp.stores.giftCards.payment.stepHistory.pop()
  }

  setStepHistory(steps = []) {
    this.gaApp.stores.giftCards.payment.stepHistory = steps
  }

  /**
   * Сохраняет массив сохранённых карт в стор
   * @param {Array} cards
   */
  setSavedCards(cards) {
    this.gaApp.stores.giftCards.payment.card.saved = cards
  }

  /**
   * Устанавливает значение, управляющее возможностью сохранения карт
   * @param {Boolean} value
   */
  setSaveable(value) {
    this.gaApp.stores.giftCards.payment.card.saveable = value
  }

  /**
   * Устанавливает дефолтный способ оплаты из ответа бэкенда
   * @param {String} method
   */
  setDefaultPayMethod(method) {
    this.gaApp.stores.giftCards.payment.defaultPaymentMethod = method
  }

  /**
   * Устанавливает список доступных способов оплаты
   * @param {Array} methods
   */
  setAvailablePayMethods(methods) {
    this.gaApp.stores.giftCards.payment.availablePaymentMethods = methods
  }

  /**
   * Устанавливает id заказа
   * Служебное поле, используется для сбора аналитики
   * @param {String} id
   */
  setOrderId(id) {
    this.gaApp.stores.giftCards.payment.orderId = id
  }

  /**
   * Устанавливает id платежа
   * @param {String} id
   */
  setPaymentId(id) {
    this.gaApp.stores.giftCards.payment.paymentId = id
  }

  /**
   * Устанавливает paymentUrl
   * @param {String} url
   */
  setSbpPaymentUrl(url) {
    this.gaApp.stores.giftCards.payment.sbp.paymentUrl = url
  }

  /**
   * Устанавливает массив банков СБП
   * @param {Array} banks
   */
  setSbpBanks(banks) {
    this.gaApp.stores.giftCards.payment.sbp.banks = banks || []
  }

  /**
   * Устанавливает первый шаг.
   *
   * Если пользователь неавторизован, то первым шагом будет номер телефона для чека.
   * Если шаг уже установлен, то он не будет изменён.
   * В остальных случаях – способ выбора оплаты.
   */
  defineFirstStep() {
    if (
      !this.isForSelf &&
      !this.isUserAuthorized &&
      this.gaApp.stores.giftCards.payment.firstStep !== STEP.SET_RECIPE_PHONE
    ) {
      return this.setStepHistory([STEP.SET_RECIPE_PHONE])
    }

    if (
      (this.isForSelf || this.isUserAuthorized) &&
      this.gaApp.stores.giftCards.payment.firstStep === STEP.SET_RECIPE_PHONE
    ) {
      this.gaApp.stores.giftCards.payment.stepHistory.shift()
    }

    if (!this.gaApp.stores.giftCards.payment.firstStep) {
      this.setStepHistory([STEP.SELECT_PAY_METHOD])
    }
  }

  /**
   * Заменяет текущий шаг на указанный
   *
   * @param {STEP} step шаг, на который необходимо совершить замену
   */
  replaceCurrentStep(step) {
    this.stepBack()
    this.nextStep(step)
  }

  /**
   * Устанавливает предвыбранный способ оплаты у пользователя.
   *
   * Если способ оплаты уже установлен в модальном окне, то он не будет изменен. Когда предыдущая оплата ЭПК производилась картой, будет предвыбрана карта, во всех остальных случаях – СБП.
   */
  definePreselectedPayMethod() {
    if (this.gaApp.stores.giftCards.payment.payMethod.selected) {
      return undefined
    }
    const { defaultPaymentMethod } = this.gaApp.stores.giftCards.payment

    const store = this.gaApp.i18n.locale?.country
    const isMe = this.gaApp.isCountry.group.me

    const fallBackPaymentMethod = isMe
      ? FALLBACK_PAYMENT_METHOD[store]
      : FALLBACK_PAYMENT_METHOD.DEFAULT

    // если с бэкенда приходит дефолтный способ оплаты = apple pay, но фича отключена на фронте, используем фолбэк
    const isApplePayEnabled = this.gaApp.features.get('giftCardsApplePay')
    const isApplePayDefault = defaultPaymentMethod === PAY_METHOD.APPLE_PAY

    if (isApplePayEnabled && isApplePayDefault) {
      this.gaApp.stores.giftCards.payment.payMethod.selected =
        fallBackPaymentMethod
    } else {
      this.gaApp.stores.giftCards.payment.payMethod.selected =
        defaultPaymentMethod || fallBackPaymentMethod
    }
  }

  /**
   * Изменяет видимость модального окна
   */
  changeModalVisibility(visibility) {
    this.gaApp.stores.giftCards.payment.modal.isOpen = visibility
  }

  /**
   * Открывает модальное окно оплаты.
   *
   * Перед открытием:
   *
   * - получает конфигурацию по способам оплаты;
   * - определяет первый шаг;
   * - определяет предвыбранный способ оплаты;
   * - переопределяет данные оплаты, если ранее была начата оплата и заказ был изменен;
   * - выводит тост с ошибкой, если пользователь авторизован, но массив сохраненных карт пустой.
   */
  async openModal() {
    if (this.gaApp.features.get('giftCardsPayConfigFetch')) {
      await this.getPaymentConfig()
    } else {
      await this.getPayMethodsServicesData()
    }

    this.defineFirstStep()
    this.definePreselectedPayMethod()

    await this.updatePaymentData()

    this.changeModalVisibility(true)
  }

  /**
   * Закрывает модальное окно.
   */
  closeModal() {
    this.changeModalVisibility(false)
  }

  /**
   * Отображает ошибку на экране выбора способа оплаты.
   *
   * @param {string} text текст ошибки
   */
  setPayMethodError(text) {
    this.gaApp.stores.giftCards.payment.payMethod.error = text
  }

  /**
   * Очищает ошибку
   */
  clearPayMethodError() {
    this.gaApp.stores.giftCards.payment.payMethod.error = ''
  }

  /**
   * Перенаправляет пользователя на страницу пользователя
   */
  redirectToSuccessPage() {
    const query = {
      orderId: this.gaApp.stores.giftCards.payment.orderId,
      paymentType: this.gaApp.stores.giftCards.payment.payMethod.selected,
    }

    // TODO: Заменить на gaApp.services.app.router.pushToRoute после добавления в метод возможности прокидывать параметры для роутера
    this.gaApp.router.push({
      path: PAYMENT_LINK.SUCCESS,
      query,
    })
  }

  /**
   * Начинает оплату выбранным способом.
   *
   * Определяет какой метод должен быть вызван для выбранного способа оплаты и включает загрузку на странице способа оплаты.
   */
  async startPayment() {
    if (typeof this.selectedPayMethodService.startPayment !== 'function') {
      throw new TypeError('The "startPayment" method is not implemented')
    }
    try {
      this.clearPayMethodError()
      this.gaApp.services.giftCards.paymentStatus.stopChecker()
      this.gaApp.stores.giftCards.payment.payMethod.loading = true

      await this.selectedPayMethodService.startPayment()
    } catch (error) {
      const newError = new Error(
        `Ошибка оплаты методом ${this.gaApp.stores.giftCards.payment.payMethod.selected}`,
      )
      newError.errorData = JSON.stringify(error)
      this.gaApp.services.app.apm.captureError(newError)

      throw newError
    } finally {
      this.gaApp.stores.giftCards.payment.payMethod.loading = false
      this.gaApp.services.giftCards.digital.updateAtFormData(0)
    }
  }

  /**
   * Возвращает назад до указанного шага
   *
   * @param {STEP} step шаг, на который необходимо вернуться
   */
  returnToStep(step) {
    const stepHistory = this.gaApp.stores.giftCards.payment.stepHistory
    const selectPayMethodIndex = stepHistory.indexOf(step)

    if (selectPayMethodIndex === -1) {
      return undefined
    }

    this.setStepHistory(stepHistory.slice(0, selectPayMethodIndex + 1))
  }

  /**
   * Пересоздает заказ с новыми данными.
   *
   * Обновляет данные заказа только если:
   * - заказ был изменен после начала оплаты;
   * - пользователь не находится на шагах процесса оплаты;
   */
  async updatePaymentData() {
    const { currentStep } = this.gaApp.stores.giftCards.payment

    if (
      [STEP.SELECT_PAY_METHOD, STEP.SET_RECIPE_PHONE].includes(currentStep) ||
      !this.gaApp.stores.giftCards.payment.orderId ||
      !this.orderWasModified
    ) {
      return undefined
    }

    try {
      await this.startPayment()
    } catch (error) {
      this.returnToStep(STEP.SELECT_PAY_METHOD)
    }
  }
}
