export class EventBus {
  constructor() {
    if (typeof EventBus.instance === 'object') {
      return EventBus.instance
    }

    EventBus.instance = this

    this.subscriptions = []
    this.getNextUniqueId = this.getIdGenerator()
  }

  subscribe(eventType, listener) {
    if (!process.client) return

    const id = this.getNextUniqueId()

    if (!this.subscriptions[eventType]) {
      this.subscriptions[eventType] = {}
    }

    this.subscriptions[eventType][id] = listener

    return () => {
      delete this.subscriptions[eventType][id]

      if (Object.keys(this.subscriptions[eventType]).length === 0) {
        delete this.subscriptions[eventType]
      }
    }
  }

  publish(eventType, arg) {
    if (!process.client) return

    if (!this.subscriptions[eventType]) {
      return
    }

    const events = Object.keys(this.subscriptions[eventType])

    events.forEach((key) => {
      try {
        this.subscriptions?.[eventType]?.[key](arg)
      } catch (error) {
        console.error(error)
      }
    })
  }

  getIdGenerator() {
    if (!process.client) return

    let lastId = 0

    return function getNextUniqueId() {
      lastId += 1

      return lastId
    }
  }
}
