import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, toRefs, watch } from 'vue'

import { isIOS } from '@ga/shared-browser'

import { GaButton } from '@ga/ui-components/button'
import { GaSelectCountry } from '@ga/ui-components/select-country'

import { MODULE_TYPE as GAME_MODULE_TYPE } from '~/modules/game'
import { AUTH_MODAL_REASON_TO_OPEN } from '~/modules/modal'

import { GaInputPhone } from '~/components/input-phone'

import GaAuthModalHeader from '../modal-header'

import { useValidate, useValues } from './scripts/composables'
import {
  COUNTRY_MODAL,
  INPUT_PHONE_SIZE,
  MIN_COUNTRIES_LENGTH,
} from './scripts/constants'

// @vue/component
export default {
  name: 'ga-auth-modal-start-form',

  components: {
    GaButton,
    GaSelectCountry,
    GaInputPhone,

    GaAuthModalHeader,
  },

  props: {
    meta: {
      type: Object,
      default: () => {},
    },
    phone: {
      type: String,
      default: '',
    },
    phoneCode: {
      type: String,
      default: '',
    },
    phoneFormatted: {
      type: String,
      default: '',
    },
    error: {
      type: Object,
      default: null,
    },
    nextRequestDelay: {
      type: Number,
      default: 0,
    },
    modalData: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const { $gaApp } = useContext()

    const { phone, phoneFormatted, phoneCode, error } = toRefs(props)

    const {
      countryInternal,
      countries,
      phoneInternal,
      phoneCodeInternal,
      phoneFormattedInternal,
      phoneMetadata,
      phoneCodes,
    } = useValues($gaApp, phone, phoneFormatted, phoneCode, emit)

    const { hasErrors, getError, resetErrors, validate } = useValidate(
      $gaApp,
      phoneInternal,
      phoneMetadata,
      error,
    )

    const pending = computed(() => $gaApp.stores.auth.modal.pending)
    const isRtl = computed(() => $gaApp.isRtl)
    const isMobile = computed(() => $gaApp.mq('tablet-'))

    const phoneAutofocus = computed(() => !phoneInternal.value && !isIOS())
    const phoneSize = computed(() =>
      isMobile.value ? INPUT_PHONE_SIZE.S : INPUT_PHONE_SIZE.M,
    )
    const phoneWithModal = computed(() => isMobile.value)

    /** Определяем, что мы в разделе игр */
    const isGameRoute =
      $gaApp.services.app.router.getRouteByPath($gaApp.route.path)
        ?.moduleType === GAME_MODULE_TYPE

    /** Скрываем выбор страны если их мало или в разделе игр */
    const showCountrySelect = computed(
      () => countries.value.length > MIN_COUNTRIES_LENGTH && !isGameRoute,
    )
    const countryWithModal = computed(() => isMobile.value)
    const countryModalZIndex = COUNTRY_MODAL.Z_INDEX
    const countryModalHeight = COUNTRY_MODAL.HEIGHT

    const reasonToOpen = props.meta?.reason

    const isInitial = computed(
      () => $gaApp.stores.auth.modal.isCurrentStepInitial,
    )

    const title = computed(() =>
      isInitial.value
        ? $gaApp.i18n.t('auth.modalStartForm.title')
        : $gaApp.i18n.t('auth.modalStartForm.titleV2'),
    )

    const showProductIntro =
      reasonToOpen === AUTH_MODAL_REASON_TO_OPEN.ADD_PRODUCT_TO_FAVORITES &&
      isInitial.value
    const showBrandIntro =
      reasonToOpen === AUTH_MODAL_REASON_TO_OPEN.ADD_BRAND_TO_FAVORITES &&
      isInitial.value

    const onFormSubmit = async () => {
      const isValid = await validate()
      if (isValid) {
        const data = {
          phone: phoneInternal.value,
          phoneCode: phoneCodeInternal.value,
          phoneFormatted: phoneFormattedInternal.value,
        }

        const response = await $gaApp.services.auth.modalStart.sendCode(
          data.phone,
          data.phoneCode,
        )

        if (response) {
          emit('success', data)
        }
      }
    }

    watch([phoneInternal, countryInternal], () => {
      resetErrors()
      emit('error-clear')
    })

    // TODO: сделать нормальный вариант для расчета ширины дропдауна выбора кода страны
    const inputPhoneKey = ref(0)

    watch(
      () => props.modalData?.isFullyVisible,
      (val) => {
        if (val) {
          inputPhoneKey.value = Date.now()
        }
      },
    )

    // TODO: Удалить если не будет использоваться компонент ga-auth-countdown
    // watch([phoneInternal, countryInternal], () => {
    //   emitNextRequestDelay(0)
    //   onCountdownEnd()
    // })

    // const disabledNext = computed(() => $gaApp.stores.auth.modal.disabledNext)

    // function emitNextRequestDelay(ms = 0) {
    //   emit('set-next-request-delay', ms)
    // }

    // function onCountdownStart() {
    //   emit('set-disabled-next', true)
    // }

    // function onCountdownEnd() {
    //   emit('set-disabled-next', false)
    // }

    return {
      inputPhoneKey,
      countryInternal,
      countries,

      phoneInternal,
      phoneFormattedInternal,
      phoneCodeInternal,
      phoneMetadata,
      phoneCodes,

      hasErrors,
      getError,

      pending,

      onFormSubmit,

      isRtl,
      phoneSize,
      phoneWithModal,
      phoneAutofocus,

      showCountrySelect,
      countryWithModal,
      countryModalZIndex,
      countryModalHeight,

      reasonToOpen,
      title,
      showProductIntro,
      showBrandIntro,
    }
  },
}
