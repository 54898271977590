import { REACTIONS_TRIGGER_TYPE } from '~/modules/gift-cards/constants/reactions'

import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmSelectModalOpenModel extends GtmBaseModel {
  constructor({ triggerType, type }) {
    super({ event: 'show_receive_emotion' })

    this.show_type = this.buildShowType(triggerType)
    this.type = type
  }

  buildShowType(triggerType) {
    const showType = {
      [REACTIONS_TRIGGER_TYPE.BUTTON]: 'click_button',
      [REACTIONS_TRIGGER_TYPE.TIMER]: 'timer',
    }[triggerType]

    return showType
  }
}
