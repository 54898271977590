import isString from 'lodash/isString'

const gaUrlRegExp = /http(s)?:\/\/((stage|testecom)\.)?goldapple\.[a-zA-Z]+\//g

export const replaceAbsoluteHref = (url) => {
  if (!isString(url)) {
    return url
  }

  return url.replace(gaUrlRegExp, '/')
}
