import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmStartBuyModel extends GtmBaseModel {
  constructor({ cardType }) {
    super({ event: 'start_buy_card' })

    this.payload = {
      cardType,
    }
  }
}
