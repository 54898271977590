import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

import { ACTION_TYPE, EVENT, EVENT_CATEGORY, MODAL } from './../../../constants'

export class GtmGa4DownloadAppClickModel extends GtmBaseModel {
  constructor() {
    super(EVENT.USER_INTERACTION)

    this.event_category = EVENT_CATEGORY.MODALS
    this.action_type = ACTION_TYPE.CLICK
    this.modal_name = MODAL.DOWNLOAD_APP
  }
}
