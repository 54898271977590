export * from './modal'

export const ACCESS_TOKEN_KEY = 'access_token'
export const HAS_REFRESH_TOKEN_KEY = 'has_refresh_token'
export const HAS_ACCESS_TOKEN_KEY = 'has_access_token'

export const CUSTOMER_PREFIX = 'customer_'

export const CLIENT_ID = 'client_web'

export const REQUEST_FIELD_NAME = {
  CLIENT_ID: 'client_id',
  GRANT_TYPE: 'grant_type',
  CHALLENGE_CODE: 'challenge_code',
  VERIFICATION_TOKEN: 'verification_token',
  PHONE_NUMBER: 'phone_number',
  PHONE_COUNTRY_ISO: 'phone_country_iso',
  ACCESS_TOKEN: 'access_token',
  RESEND_TOKEN: 'resend_token',
  CAPTCHA_TOKEN: 'captcha_token',
  USE_MAGENTO_FOR_WEB: 'use_magento_for_web',
  REDIRECT_URI: 'redirect_uri',
  VK_DEVICE_ID: 'vk_device_id',
  VK_CLIENT_ID: 'vk_client_id',
  CODE_VERIFIER: 'code_verifier',
}

export const EXTERNAL_AUTH_SERVICE = {
  YANDEX: 'yandex',
  VK: 'vk',
}

export const GRANT_TYPE = {
  REFRESH: 'refresh_token',
  PHONE_NUMBER: 'phone_number_token',
  [EXTERNAL_AUTH_SERVICE.YANDEX]: 'yandex_code_challenge_token',
  [EXTERNAL_AUTH_SERVICE.VK]: 'vk_code_challenge_token',
}

export const ALLOWED_EXTERNAL_AUTH_SERVICE = [
  GRANT_TYPE[EXTERNAL_AUTH_SERVICE.YANDEX],
  GRANT_TYPE[EXTERNAL_AUTH_SERVICE.VK],
]

export const TOKEN_TYPE = {
  PHONE_CALL: 'phone_call_token',
  PHONE_SMS: 'phone_sms_token',
}

export const COUNTDOWN_TYPE = {
  PHONE: 'phone',
  SMS: 'sms',
}

export const ERROR = {
  PAGE_NOT_FOUND: 'Page Not Found',
  SERVICE_NOT_FOUND: 'Service Not Found',
}

export const PAGE_TYPE = {
  CONFIRM_CODE: 'confirm-code',
}

export const LOCAL_STORAGE_KEY = {
  AUTH_REDIRECT_URL: 'ga-auth-redirect-url',
  AUTH_VERIFIER: 'ga-auth-verifier',
  AUTH_MODAL_OPEN_REASON: 'ga-auth-modal-open-reason',
}
