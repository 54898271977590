import { AUTOMATED_TESTING_ADDRESS_CONFIRMED } from '@ga/constants/automated-testing'

import {
  COOKIE_ADDRESS_CONFIRMED,
  COOKIE_LOCATION_ID,
} from '../constants/confirm'

export class ConfirmService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setOpened(value) {
    this.gaApp.stores.location.confirm.opened = value
  }

  setDisabled(value) {
    this.gaApp.stores.location.confirm.disabled = value
  }

  open() {
    this.gaApp.services.location.confirm.setOpened(true)
    this.gaApp.analytics.modules.location.onModalConfirmLocationShow()
  }

  close() {
    this.gaApp.services.location.confirm.setOpened(false)
    this.gaApp.analytics.modules.location.onModalConfirmLocationClose()
  }

  requestOpening() {
    // Не показываем конфирм если открыта любая модалка
    if (this.gaApp.stores.modal.register.hasModals) {
      return
    }

    this.gaApp.services.location.confirm.open()
  }

  accept({ ignoreEditModal } = {}) {
    const {
      id: cityId,
      isStreetOrHouse,
      isManyStores,
    } = this.gaApp.services.location.main.getDeliveryAddress()

    // адресный блок выключен
    if (!this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      this.gaApp.services.location.confirm.saveClientLocation(cityId)
      this.gaApp.services.location.main.setSelectedAddress()
      return
    }

    // адресный блок включен
    if (isManyStores && !isStreetOrHouse && !ignoreEditModal) {
      this.gaApp.services.location.specify.openEditModal({
        openingReason: 'confirm',
      })
    } else {
      const address =
        this.gaApp.services.location.specify.getAddressById(cityId)
      this.gaApp.services.location.main.setSelectedAddress(address)
    }
    this.gaApp.services.location.confirm.setAddressConfirmed()
  }

  cancel() {
    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      if (this.gaApp.stores.location.addresses.mixedAddresses?.length) {
        this.gaApp.services.location.specify.openSelectModal()
      } else {
        this.gaApp.services.location.specify.openEditModal()
      }
    } else {
      this.gaApp.services.location.modal.open()
    }
  }

  closeConfirmModal() {
    this.accept({ ignoreEditModal: true })
    this.close()
  }

  acceptConfirmModal() {
    this.gaApp.analytics.modules.location.onModalConfirmLocationConfirm()

    this.accept()
    this.close()
  }

  cancelConfirmModal() {
    this.gaApp.analytics.modules.location.onModalConfirmLocationChangeAddress()

    this.close()
    this.cancel()
  }

  /**
   * Флаг, по которому мы определяем можно ли переходить к схеме уточнения адреса
   *
   * схема уточнения - это когда мы сравниваем [дефолтный сохраненный/гео/адрес в шапке]
   * может привести к смене адреса, отображению плашки подтверждения
   * в идеале должна приводить к тому что адрес станет подтвержденным
   *
   * @returns {boolean}
   */
  get isClarificationDisabled() {
    return (
      this.gaApp.stores.location.confirm.disabled ||
      this.gaApp.services.location.confirm.isAddressConfirmed()
    )
  }

  /**
   * Проверяет подтвержден ли адрес
   * @returns {boolean}
   */
  isAddressConfirmed() {
    if (
      this.gaApp.automatedTestingConfig?.[AUTOMATED_TESTING_ADDRESS_CONFIRMED]
    ) {
      return true
    }

    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      return Boolean(this.gaApp.cookies.get(COOKIE_ADDRESS_CONFIRMED))
    }

    const location = this.gaApp.stores.location.main.location
    const clientLocationId = this.gaApp.cookies.get(COOKIE_LOCATION_ID)

    return clientLocationId === location.id
  }

  /**
   * Установка флага что адрес подтвержден (для включенного адресного блока)
   * @param {boolean} isConfirmed - флаг что адрес подствержден
   */
  setAddressConfirmed(isConfirmed = true) {
    this.gaApp.cookies.set(COOKIE_ADDRESS_CONFIRMED, isConfirmed, {
      path: '/',
      sameSite: 'lax',
    })
  }

  saveClientLocation(locationId) {
    this.gaApp.cookies.set(COOKIE_LOCATION_ID, locationId, { sameSite: 'lax' })
  }
}
