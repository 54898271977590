export const ENTITIES = {
  NON_BREAKING_SPACE: '&nbsp;',
  WORD_JOINER: '&#8288;',
  SPACE: '&#32;',
}

export const REGEXPS = {
  PLUS_SIGN: /^\+$/,
  SLASH_SIGN: /^\/$/,
  HAS_SLASH_SIGN: /\//,
}

export const SPECIAL_CHARS_REGEX = /[-/]/g
