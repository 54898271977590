import { useContext } from '@nuxtjs/composition-api'
import { getPageScrollBarWidth } from 'scroll-lock'

import { computed, ref, toRefs, unref } from 'vue'

import { scrollLockScrollable } from '@ga/shared-directives'

import { useItems } from '~/modules/header/composable/use'

import { GaHeaderContacts } from '../../atoms/contacts'
import GaNavLocation from '../../atoms/nav-location'

import { GaHeaderNavMinimizedAdditional } from './children/nav-minimized-additional'
import { GaHeaderNavMinimizedAuth } from './children/nav-minimized-auth'
import { GaHeaderNavMinimizedItem } from './children/nav-minimized-item'
import { GaHeaderNavMinimizedLangSwitcher } from './children/nav-minimized-lang-switcher'
import GaHeaderNavMinimizedScreens from './children/nav-minimized-screens'
import { useAnimating, useEvents, useRender } from './scripts/use'

// @vue/component
export default {
  name: 'ga-header-nav-minimized',

  components: {
    GaHeaderNavMinimizedItem,
    GaHeaderNavMinimizedAdditional,
    GaHeaderNavMinimizedScreens,
    GaNavLocation,
    GaHeaderContacts,
    GaHeaderNavMinimizedAuth,
    GaHeaderNavMinimizedLangSwitcher,
  },

  directives: {
    scrollLockScrollable,
  },

  props: {
    active: {
      type: Boolean,
      defaut: false,
    },
    path: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { active, path } = toRefs(props)
    const { $gaApp } = useContext()

    const canvasRef = ref()
    const innerRef = ref()

    const { render } = useRender(active)
    const navMobile = computed(() => $gaApp.stores.header.nav.navMobile)
    const { items, hasItems, itemsMain, itemsAdditional } = useItems(
      path,
      navMobile,
      true,
    )

    const { animating } = useAnimating(active, canvasRef, innerRef)

    const mods = computed(() => ({
      active: unref(active),
      extended: $gaApp.stores.header.nav.navExtended,
    }))

    const { onItemClick } = useEvents()

    const innerStyles = computed(() => ({
      paddingRight: unref(animating) ? `${getPageScrollBarWidth()}px` : 0,
      overflowY: unref(animating) ? 'hidden' : 'scroll',
    }))

    const langSwitcherFeatureOn = computed(() =>
      $gaApp.features.get('langSwitcher'),
    )
    const storeSwitcherFeatureOn = computed(() =>
      $gaApp.features.get('storeSwitcher'),
    )

    const showLangSwitcher = computed(
      () =>
        $gaApp.services.app.langSwitcher.hasAvailableLanguages &&
        (unref(langSwitcherFeatureOn) || unref(storeSwitcherFeatureOn)),
    )

    return {
      canvasRef,
      innerRef,
      innerStyles,

      render,

      items,
      hasItems,
      itemsMain,
      itemsAdditional,

      showLangSwitcher,

      mods,
      onItemClick,
    }
  },
}
