import { services } from './services'

export const payment = {
  meta: {
    name: 'payment',
  },
  layers: {
    services,
  },
}
