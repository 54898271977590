import { ApiService } from './api/api.service'
import { ApiCitiesService } from './api/api-cities.service'
import { CitiesService } from './cities.service'
import { MainService } from './main.service'
import { QueryParseService } from './query-parse.service'
import { SelectedFiltersService } from './selected-filters.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  cities: new CitiesService(gaApp),
  queryParse: new QueryParseService(gaApp),
  selectedFilters: new SelectedFiltersService(gaApp),
  api: new ApiService(gaApp),
  apiCities: new ApiCitiesService(gaApp),
})
