export const SEARCH_DEBOUNCE_DELAY = 250

export const SUGGEST_ABORT_KEY = 'suggest-abort-key'

/** Типы адресных подсказок */
export const SUGGEST_TYPE = {
  CITY: 'city', // города и поселения
  STREET: 'street', // улица, дом
  DISTRICT: 'district', // районы города

  // "без типа" означает поиск любых мест и ориентиров: парки, торговы центры и т.д.
}
