export const BADGE_SIZE = {
  XS: 'xs',
  S: 's',
  SM: 'sm',
  M: 'm',
  L: 'l',
}

export const BADGE_HIGHLIGHT_THEME = {
  DEFAULT: 'default',
  PALE: 'pale',
  GRAY: 'gray',
}

export const BNPL_PROVIDER = {
  DEFAULT: 'default',
  INSTALLMENT: 'installment',
  TABBY: 'tabby',
}
