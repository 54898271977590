import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { useAnalytics } from '../analytics'

const COOKIE_SIMPLE_LAYOUT_NAME = 'ga-plp-simple-layout'

export const useLayoutToggle = () => {
  const { $gaApp } = useContext()

  const { onChangeListingView } = useAnalytics()

  const isSimple = computed(() => {
    return (
      $gaApp.stores.user.main.isBot || $gaApp.stores.plp.main.getIsSimpleLayout
    )
  })

  const setIsSimpleLayout = (value) =>
    $gaApp.stores.plp.main.setIsSimpleLayout(value)

  // Устанавливаем значение режима отображения листинга, полученное из куки %COOKIE_SIMPLE_LAYOUT_NAME%
  const setSimpleFromCookieValue = () => {
    const isSimpleLayoutCookie = $gaApp.cookies.get(COOKIE_SIMPLE_LAYOUT_NAME)

    setIsSimpleLayout(Boolean(isSimpleLayoutCookie))
  }

  // Устанавливаем значение режима отображения листинга в куку %COOKIE_SIMPLE_LAYOUT_NAME%
  const onChange = (value) => {
    $gaApp.cookies.set(COOKIE_SIMPLE_LAYOUT_NAME, value, { sameSite: 'lax' })

    setIsSimpleLayout(value)

    onChangeListingView(value)
  }

  return {
    isSimple,

    onChange,
    setSimpleFromCookieValue,
  }
}
