export const endpoints = {
  fetchProductsBySku: () => ({
    url: 'catalog/products-by-sku',
  }),

  fetchCategoryDataByUrl: () => ({
    url: 'catalog/redirect',
  }),

  fetchProducts: () => ({
    url: 'catalog/products',
  }),

  fetchArticle: (id) => ({
    url: `articles/article/preview/${id}`,
  }),

  fetchArticleByUrl: (url) => ({
    url: `articles/article/${url}`,
  }),

  // TODO удалить после удаления ФТ flaconUseNewFilters
  fetchFilters: () => ({
    url: 'articles/filters/main',
  }),

  fetchFiltersV2: () => ({
    url: 'articles/filters/main/v2',
  }),

  // TODO - удалить после удаления ФТ flaconUseNewApiForListingTitles
  fetchArticlesListing: () => ({
    url: 'articles/listing/main',
  }),

  fetchArticlesListingV2: () => ({
    url: 'articles/listing/main/v2',
  }),

  fetchArticlesListingV3: () => ({
    url: 'articles/listing/main/v3',
  }),

  fetchRubricArticles: () => ({
    url: 'articles/listing/rubrics',
  }),

  // TODO - удалить после удаления ФТ flaconUseNewApiForListingTitles
  fetchPreviewListing: (id) => ({
    url: `articles/listing/main/preview/${id}`,
  }),

  fetchPreviewListingV2: (id) => ({
    url: `articles/listing/main/v2/preview/${id}`,
  }),

  fetchArticleViewed: () => ({
    url: 'articles/viewed',
  }),

  fetchArticleViewedWebview: () => ({
    url: 'articles/webview/viewed',
  }),
}
