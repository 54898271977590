import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, watch } from 'vue'

import { FILTER_KEY } from '../../../constant'

export const useFitlers = () => {
  const { $gaApp } = useContext()

  const cityId = computed(
    () => $gaApp.services.location.main.getDeliveryAddress()?.id,
  )
  const categoryId = computed(() => $gaApp.stores.plp.main.getCategoryData?.id)

  const filtersMainMobile = computed(
    () => $gaApp.stores.filters.main.getFiltersMainMobile,
  )
  const filtersMain = computed(() => $gaApp.stores.filters.main.getFiltersMain)
  const filtersQuick = computed(() => $gaApp.stores.filters.main.filtersQuick)
  const hasFilters = computed(() => $gaApp.stores.filters.main.hasFilters)
  const hasFiltersQuick = computed(
    () => $gaApp.stores.filters.main.hasFiltersQuick,
  )

  const isPending = computed(() => $gaApp.stores.filters.main.getFiltersPending)
  const isError = computed(() => $gaApp.stores.filters.main.getFiltersError)

  const hasSelectedCount = () => {
    const filters = $gaApp.mq('desktop-small+')
      ? $gaApp.stores.filters.main.getFiltersMainSelected
      : $gaApp.stores.filters.main.getFiltersMainMobileSelected

    return Boolean(filters.length)
  }

  const productsCount = computed(
    () => $gaApp.stores.filters.main.countProductsFiltered,
  )

  const countSelectedFilters = computed(
    () => $gaApp.stores.filters.main.countSelectedFilters,
  )

  const showReset = ref(hasSelectedCount())

  /**
   * Нужно для того, что бы показывать или скрывать кнопку "Сбросить"
   * только после ответа от сервера, а не после того, как фильтры были изменены
   */
  watch(isPending, (value) => {
    if (value) {
      return false
    }

    showReset.value = hasSelectedCount()
  })

  const onResetFilters = () => {
    if (isPending.value) {
      return false
    }

    return $gaApp.services.filters.main.resetFilters({
      categoryId: categoryId.value,
      cityId: cityId.value,
    })
  }

  const onFilterChange = async (value, filter) => {
    const { id, key, type } = filter

    const updatedFilterValue =
      $gaApp.services.filters.main.getUpdatedFilterValue(filter, value)

    await $gaApp.services.filters.main.updateFilter({
      categoryId: categoryId.value,
      cityId: cityId.value,
      filter: {
        id,
        key,
        type,
        ...updatedFilterValue,
      },
    })
  }

  const onFilterInput = (value, filter) => {
    if (filter.key === FILTER_KEY.CITY_ID)
      $gaApp.services.filters.cities.updateCitiesFilter(value)
  }

  return {
    filtersMainMobile,
    filtersMain,
    filtersQuick,
    hasFilters,
    hasFiltersQuick,

    showReset,

    productsCount,
    countSelectedFilters,

    isPending,
    isError,

    onResetFilters,
    onFilterChange,
    onFilterInput,
  }
}
