<template>
  <svg fill="none" stroke="none" viewBox="0 0 40 40">
    <path fill="#EDEDED" d="M0 0h28l12 12v28H0V0Z" />
    <path fill="#D9D9D9" d="m28 0 12 12H28V0Z" />
    <path
      fill="#000"
      stroke="none"
      d="M13.402 25.105h-1.32v6.18h-2.26v-14.88h3.58c1.6 0 2.32.68 2.32 2.46v3.52c0 1.76-.78 2.72-2.32 2.72Zm-1.32-6.68v4.54h.58c.68 0 .78-.48.78-1.08v-2.44c0-.58-.12-1.02-.78-1.02h-.58Zm4.822-2.02h2.04l1.94 7.98v-7.98h2.06v14.88h-1.84l-2.16-8.56v8.56h-2.04v-14.88Zm9.616 15.08h-.14c-1.32 0-2.16-1.18-2.16-2.72v-9.3c0-1.66.64-3.2 2.78-3.2h.44c2.42 0 2.7 1.58 2.7 3.34v1.52h-2.2v-1.68c0-.66-.16-1.02-.72-1.02-.48 0-.7.36-.7 1.02v8.7c0 .7.28 1.06.74 1.06.58 0 .84-.4.84-.96v-3.72h-1.02v-2.28h3.16v9.04h-1.28c-.02-.08-.2-.82-.38-1.46-.16.5-.92 1.66-2.06 1.66Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-file-png',
}
</script>
