export class PaymentRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getSavedCards() {
    const { url } = this.endpoints.getPaymentSavedCards()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async validateMerchant(data = {}) {
    const { url } = this.endpoints.validateMerchant()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getPaymentConfig() {
    const { url } = this.endpoints.getPaymentConfig()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async removeSavedCard(cardId) {
    const { url } = this.endpoints.removePaymentSavedCard(cardId)

    try {
      const response = await this.gaApp.api.request().delete(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async orderCard(data = {}) {
    const { url } = this.endpoints.paymentOrderCard()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async orderApplePay(data = {}) {
    const { url } = this.endpoints.paymentOrderApplePay()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async orderMasterCardInfo(sessionId) {
    const config = {
      params: { sessionId },
    }

    const { url } = this.endpoints.paymentOrderMasterCardInfo()

    try {
      const response = await this.gaApp.api.request().get(url, config)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async orderSbp(data = {}) {
    const { url } = this.endpoints.paymentSbpCard()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async checkOrderStatus(paymentId) {
    const { url } = this.endpoints.checkOrderStatus(paymentId)

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
