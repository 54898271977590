import { COUNTRY } from '~/modules/app/constant'

/**
 * Ключи полей формы
 * @enum {number}
 * @readonly
 */
export const KEYS = {
  DATE: 'deliveryDatetime',
  IMG: 'foregroundId',
  COLOR: 'backgroundId',
  YANDEX_IMAGE_ID: 'yandexImageId',
  AMOUNT: 'denomination',
  PHONE: 'recipientPhone',
  EMAIL: 'recipientEmail',
  TEXT: 'felicitationText',
  SENDER: 'senderName',
  RECIPIENT: 'recipientName',
  PHONE_SENDER: 'senderPhone',
  PHONE_RECEIPT: 'receiptPhone',
  EMAIL_SENDER: 'senderEmail',
  SELF: 'isForSelf',
  BASKET: 'basketId',
  TIMEZONE: 'timezone',
  CAPTCHA: 'captchaToken',
}

/**
 * Шаг оформления заказа ЭПК
 * @enum {number}
 * @readonly
 */
export const STEPS = {
  DESIGN: 0,
  PRICE: 1,
  RECIPIENT: 2,
  DELIVERY: 3,
  PAYMENT: 4,
}

/**
 * Способы оплаты заказа ЭПК
 * @enum {string}
 * @readonly
 */
export const PAYMENT_METHODS = {
  CARD: 'card',
  SBER: 'sber_pay',
  YANDEX: 'yandex_pay',
}

/**
 * Типы доставки заказа ЭПК
 * @enum {string}
 * @readonly
 */
export const TABS_VALUE_DELIVERY = {
  NOW: 'now',
  DEFERRED: 'deferred',
}

/**
 * Типы получателя заказа ЭПК
 * @enum {string}
 * @readonly
 */
export const TABS_VALUE_RECIPIENT = {
  FRIEND: 'friend',
  SELF: 'self',
}

/**
 * Время устаревания данных в ms (2 часа)
 */
export const EXPIRED = 2 * 60 * 60 * 1000

/**
 * Ограничения длины текста
 */
export const MAX_TEXT_LIMIT = 400

/**
 * Форматы времени
 * @enum {string}
 * @readonly
 */
export const DATE_FORMAT = {
  DATE: 'D MMMM YYYY',
  WITH_INDENT: 'D MMMM YYYY[, </br>] HH:mm',
  UTC: 'YYYY-MM-DDTHH:mm:ss.sss[Z]',
}

/**
 * Пути до полей стора
 * @enum {string}
 * @readonly
 */
export const MAPS = {
  IMG: `formData.${STEPS.DESIGN}.${KEYS.IMG}`,
  COLOR: `formData.${STEPS.DESIGN}.${KEYS.COLOR}`,
  AMOUNT: `formData.${STEPS.PRICE}.${KEYS.AMOUNT}`,
  RECIPIENT: `formData.${STEPS.RECIPIENT}.${KEYS.RECIPIENT}`,
  SENDER: `formData.${STEPS.RECIPIENT}.${KEYS.SENDER}`,
  PHONE_SENDER: `formData.${STEPS.RECIPIENT}.${KEYS.PHONE_SENDER}`,
  PHONE_RECEIPT: `formData.${STEPS.PAYMENT}.${KEYS.PHONE_RECEIPT}`,
  TEXT: `formData.${STEPS.RECIPIENT}.${KEYS.TEXT}`,
  DATE: `formData.${STEPS.DELIVERY}.${KEYS.DATE}`,
  TIMEZONE: `formData.${STEPS.DELIVERY}.${KEYS.TIMEZONE}`,
}

/**
 * Адреса страниц результатов оплаты
 * @enum {string}
 * @readonly
 */
export const PATH = {
  ERROR: '/cards/payment/error',
  SUCCESS: '/cards/payment/success',
}

/**
 * Адреса перехода при нажатии на кнопку Закрыть
 */
export const CLOSE_PAGE_PATHS = {
  ROOT: '/',
  CARDS: '/cards',
}

/**
 * Лоудер оформления ЭПК
 * @readonly
 * @enum {string}
 */
export const LOADER = {
  /**
   * Лоудер кнопки "далее" и "оплатить" оформления ЭПК
   */
  SUBMIT: 'submit',

  /**
   * Лоудер всего модального окна оформления ЭПК
   */
  CONFIG: 'config',
}

/**
 * Поля выбора времени/даты
 * @enum {number}
 * @readonly
 */
export const FIELD_DATEPICKER = {
  TIME: 'time',
  DATE: 'date',
  DAY: 'day',
  YEAR: 'year',
  MONTH: 'month',
  TIMEZONE: 'timezone',
}

/**
 * Тип подарочной карты
 * @enum {number}
 * @readonly
 */
export const ORDER_TYPE = {
  /** КЭПК */
  CORPORATE: 'corporate',

  /** ЭПК */
  INDIVIDUAL: 'individual',
}

/**
 * Тип дизайна
 * @enum {string}
 * @readonly
 */
export const DESIGN_TYPES = {
  GA: 'ga',
  AI: 'ai',
}

/**
 * Тип подарочной карты
 * @enum {string}
 * @readonly
 */
export const CARD_TYPE = {
  ELECTRONIC: 'electronic',
  PLASTIC: 'plastic',
}

/**
 * Опции дизайна
 * @enum {string}
 * @readonly
 */
export const DESIGN_OPTIONS = {
  IMAGE: 'selectedImageId',
  BACKGROUND: 'selectedBackgroundId',
}

/**
 * Длительность анимации смены фона
 */
export const CHANGE_BG_DURATION_SEC = 0.2

/**
 * Страны, для которых актуален флоу покупки карты «Себе»
 */
export const IS_FOR_SELF_COUNTRIES = [COUNTRY.RU, COUNTRY.KZ, COUNTRY.BY]
