import { useContext } from '@nuxtjs/composition-api'

import { computed, unref } from 'vue'

import { BNPL_PROVIDER } from '../../../../../constants'

/**
 * Функция-компаньон для выбора контента беджа.
 * В зависимости от провайдера, возвращает нужный компонент и набор пропсов.
 * Приоритет отдается провайдеру, явно указанному через FT.
 *
 * @param { Payload } param Набор параметров для все компонентов и имя провайдера
 * @returns {{ content: BadgeContent }}
 *
 * @typedef Payload
 * @property { Ref<PriceData> } data
 * @property { Ref<string> } size
 * @property { Ref<string> } provider
 *
 * @typedef PriceData
 * @property { number } amount
 * @property { string } currency
 * @property { number } denominator
 *
 * @typedef BadgeContent
 * @property { string } component
 * @property { { data?: Ref<PriceData>, size: Ref<string> } } props
 */
export const useBadgeContent = ({ data, size, provider }) => {
  const { $gaApp } = useContext()

  const contentDict = computed(() => ({
    [BNPL_PROVIDER.DEFAULT]: {
      component: 'ga-bnpl-badge-content-default',
      props: { data: unref(data), size: unref(size) },
    },
    [BNPL_PROVIDER.INSTALLMENT]: {
      component: 'ga-bnpl-badge-content-installment',
      props: { size: unref(size) },
    },
    [BNPL_PROVIDER.TABBY]: {
      component: 'ga-bnpl-tabby-badge',
      props: { data: unref(data), size: unref(size) },
    },
  }))

  const content = computed(() => {
    const contentKey = unref(provider)
    const dict = unref(contentDict)

    if ($gaApp.features.get('plpDolyameInstallment')) {
      return dict.installment
    }

    if ($gaApp.features.get('bnplTabbyAePlpPdp')) {
      return dict.tabby
    }

    return dict[contentKey]
  })

  return { content }
}
