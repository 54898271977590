export const MAX_PAGE_COUNT = 500

// количество страниц, которые нужно выводить вокруг текущей,
// если страница не первая / последняя
export const AROUND_PAGES_COUNT = 1

// количество страниц, которые нужно выводить вокруг текущей,
// если страница первая / последняя
export const AROUND_PAGES_EXTENDED_COUNT = 2

// шаг для ссылок-многоточий от крайних страниц, которые выводятся вокруг текущей
export const JUMP_STEP_FROM_AROUND_PAGES = 2

// Количество страниц, меньше которых не вывести при текущей настройке.
// Высчитывается по следующей логике:
// + выводимые вокруг текущей страницы с двух сторон
// + 2 крайних страницы (первая и последняя)
// + 1 текущая страница
export const PAGES_VISIBLE_MIN_COUNT = AROUND_PAGES_COUNT * 2 + 2 + 1
