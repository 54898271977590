import { DownloadAppClickBuilder, DownloadAppViewBuilder } from '../../builders'
import { BaseController } from '../base.controller/base.controller'

export class ModalAnalytics extends BaseController {
  /**
   * Просмотр модалки "скачать приложение"
   */
  onDownloadAppView() {
    this.send(DownloadAppViewBuilder)
  }

  /**
   * Клик по кнопке "скачать приложение"
   */
  onDownloadAppClick() {
    this.send(DownloadAppClickBuilder)
  }
}
