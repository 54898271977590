export class PaymentApplePayService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает параметры для оплаты Apple Pay
   * @param {string} merchantId
   * @param {Array} merchantCapabilities
   * @param {Array} supportedNetworks
   */
  setApplePayParams({ merchantId, merchantCapabilities, supportedNetworks }) {
    this.gaApp.stores.giftCards.payment.applePayParams = {
      merchantId,
      merchantCapabilities,
      supportedNetworks,
    }
  }

  /**
   * Очистка параметров Apple Pay
   */
  clearApplePayParams() {
    this.gaApp.stores.giftCards.payment.applePayParams = {}
  }

  /**
   * Конфигурирует сессию Apple Pay
   */
  getSessionConfig() {
    const { merchantId, merchantCapabilities, supportedNetworks } =
      this.gaApp.stores.giftCards.payment.applePayParams

    const config = {
      countryCode: this.gaApp.i18n.locale.country.toUpperCase(),
      currencyCode: this.gaApp.stores.giftCards.digital.priceInfo.currency,
      merchantId,
      supportedNetworks,
      merchantCapabilities,
      total: {
        label: this.gaApp.i18n.t(
          'giftCards.payment.selectPayMethod.applePay.sessionLabel',
        ),
        amount: this.gaApp.stores.giftCards.digital.totalSum,
      },
    }

    return config
  }

  /**
   * Редирект на страницу успеха оплаты
   */
  redirectToSuccessPage() {
    this.clearApplePayParams()

    this.gaApp.services.app.router.pushToRoute({
      moduleType: 'giftCards',
      pageType: 'payment/success',
    })
  }

  /**
   * Редирект на страницу ошибки оплаты
   */
  redirectToErrorPage() {
    this.clearApplePayParams()

    this.gaApp.services.app.router.pushToRoute({
      moduleType: 'giftCards',
      pageType: 'payment/error',
    })
  }

  /**
   * Запускает размещение заказа
   * @param {Object} orderData данные для размещения заказа
   */
  async handlePayment(orderData) {
    const { data } =
      await this.gaApp.services.giftCards.api.paymentOrderApplePay(orderData)

    const { isPaid, salesId } = data

    this.gaApp.services.giftCards.payment.setOrderId(salesId)

    if (!isPaid) {
      throw new Error('Payment failed')
    }
  }

  /**
   * Начало оплаты Apple Pay
   */
  startPayment() {
    this.gaApp.services.payment.applePay.startPayment({
      sessionConfig: this.getSessionConfig(),
      orderData: this.gaApp.services.giftCards.payment.orderData,
      onValidateMerchant:
        this.gaApp.services.giftCards.api.validateMerchant.bind(this),
      onPayment: this.handlePayment.bind(this),
      onSuccessPayment: () => this.redirectToSuccessPage(),
      onPaymentFailed: () => this.redirectToErrorPage(),
    })
  }
}
