import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

import { PROMOTION_TYPE } from './../../../../constants/keys'
import { CARD_ACTION_TYPES, EVENT_CATEGORIES } from './../../../constants'

export class GtmGa4FullCardViewModel extends GtmBaseModel {
  constructor({ title, type, newOffers }) {
    super('user_interaction')

    // условие уберется как только дропнутся старые акции
    if (newOffers) {
      this.event_category = EVENT_CATEGORIES.PROMO
      this.action_type = CARD_ACTION_TYPES.PROMO
    } else {
      this.event_category =
        type === PROMOTION_TYPE.PROMO
          ? EVENT_CATEGORIES.PROMO
          : EVENT_CATEGORIES.CLIENT_DAYS
      this.action_type =
        type === PROMOTION_TYPE.PROMO
          ? CARD_ACTION_TYPES.PROMO
          : CARD_ACTION_TYPES.CLIENT_DAYS
    }

    this.title = title
  }
}
