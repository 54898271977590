export class RequestAbort {
  constructor() {
    this.requests = new Map()
  }

  addRequest(key) {
    if (this.requests.get(key)) {
      this.abortRequest(key)
    }

    const controller = new AbortController()

    this.requests.set(key, controller)

    return controller.signal
  }

  abortRequest(key) {
    const controller = this.requests.get(key)

    if (controller) {
      this.requests.delete(key)
      controller.abort()
    }
  }
}
