import { FORM_STEP, FORM_STEPS } from '../constants'

export class PlasticFormStepsService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Очищает значение шагов.
   */
  clearSteps() {
    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.openSteps =
      Object.values(FORM_STEP)
  }

  /**
   * Очищает все ошибки формы
   */
  clearErrors() {
    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.errors = []
  }

  /**
   * Очищает активный шаг формы, устанавливая его как пустую строку
   */
  clearActiveStep() {
    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.activeStep = ''
  }

  /** Очищает все значения */
  clearStepsForm() {
    this.clearSteps()
    this.clearErrors()
    this.clearActiveStep()
  }

  /**
   * Устанавливает значение шага
   * @param {string} stepName - имя шага
   * @param {boolean} value - значение шага
   */
  setStep(stepName, value) {
    const stepsSet = new Set(
      this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.openSteps,
    )

    const method = value ? 'add' : 'delete'
    stepsSet[method](stepName)

    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.openSteps =
      Array.from(stepsSet)
  }

  /**
   * Проверяет наличие переданного шага
   *
   * @param {string} stepName - значение флагов
   * @return {boolean} true, если флаг имеет значение, иначе false
   */
  hasStep(stepName) {
    return (
      this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.openSteps?.includes(
        stepName,
      ) ?? false
    )
  }

  /**
   * Устанавливает значение открытия переданного шага.
   * @param {STEP} stepName - название шага
   * @param {boolean} isOpen - флаг открытости шага
   */
  setStepOpen(stepName, isOpen) {
    this.setStep(stepName, isOpen)
  }

  /**
   * Открывает переданный шаг
   * @param {STEP} stepName - название шага
   */
  openStep(stepName) {
    this.setStepOpen(stepName, true)
  }

  /**
   * Устанавливает шаг, который является активным.
   *
   * @param {STEP} stepName - название шага
   */
  setActiveStep(stepName) {
    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.activeStep = stepName
  }

  /**
   * Закрывает все шаги
   */
  closeAllSteps() {
    this.clearSteps()
  }

  /**
   * Закрывает шаги, которые не имеют ошибок
   * Метод проходится по ошибкам в форме и закрывает уже заполненные шаги
   */
  closeValidSteps() {
    const { errors } = this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm

    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.openSteps = errors.map(
      (error) => error.name,
    )
  }

  /**
   * Закрывает все шаги, кроме переданного
   * @param {STEP} stepName - название текущего шага
   */
  closeOtherSteps(stepName) {
    this.closeAllSteps()
    this.setStepOpen(stepName, true)
  }

  /**
   * Сбрасывает ошибку формы по имени
   * Если ошибки нет, то ничего не делает
   *
   * @param {string} name - имя ошибки
   */
  resetError(name) {
    const { errors } = this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm

    const index = errors?.findIndex((error) => error.name === name)

    if (index === -1) return

    this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.errors.splice(index, 1)
  }

  /**
   * Устанавливает ошибку формы по имени
   * Если передана пустая ошибка, то сбрасывает ошибку
   * Если ошибка уже установлена, то ничего не делает
   *
   * @param {string} name - тип ошибок
   * @param { boolean} error - значение ошибки
   */
  setError(name, error) {
    if (!error) {
      return this.resetError(name)
    }

    const errorsList =
      this.gaApp.stores.giftCardsB2b.plasticNew.stepsForm.errors
    const hasErrorInStore = errorsList?.some((err) => err.name === name)

    if (hasErrorInStore) return

    errorsList.push({
      name,
      error,
    })
  }

  /**
   * Устанавливает ошибки для всех шагов формы.
   */
  setErrors() {
    FORM_STEPS.forEach((step) => this.setError(step, true))
  }
}
