import isEmpty from 'lodash/isEmpty'

import {
  RECEIVE_SOURCE_LINK_OPTIONS,
  RECEIVE_STEPS,
  RECEIVE_STEPS_LOCAL_STORAGE_KEY,
} from '../constants/receive'

export class DigitalReceiveCardService {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.unsubscribeLangSwitcher = null
  }

  /**
   * Устанавливает данные для страницы получения ЭПК
   * @param {object} receivedCard данные получаемой ЭПК
   */
  setReceivedCard(receivedCard = {}) {
    if (isEmpty(receivedCard)) {
      return undefined
    }

    Object.assign(this.gaApp.stores.giftCards.digitalReceive, receivedCard)
  }

  /**
   * Вычисляет источник перехода по ссылке получения ЭПК
   */
  getSourceLink() {
    const source = this.gaApp.route.query?.from
    const hasSourceLink = Object.values(RECEIVE_SOURCE_LINK_OPTIONS).includes(
      source,
    )
    const sourceLink = hasSourceLink ? source : RECEIVE_SOURCE_LINK_OPTIONS.SMS

    return sourceLink
  }

  /**
   * Локально сохраняет шаг пользователя
   * @param {RECEIVE_STEPS} step Текущий шаг пользователя
   */
  setStep(step) {
    this.gaApp.stores.giftCards.digitalReceive.currentStep = step
  }

  /**
   * Сохраняет в localStorage шаг пользователя
   */
  saveStepInStorage() {
    this.gaApp.services.app.browserStorage.local.set(
      RECEIVE_STEPS_LOCAL_STORAGE_KEY,
      this.gaApp.stores.giftCards.digitalReceive.currentStep,
    )
  }

  /**
   * Возвращает сохраненный шаг пользователя из localStorage
   */
  getStepFromStorage() {
    return this.gaApp.services.app.browserStorage.local.get(
      RECEIVE_STEPS_LOCAL_STORAGE_KEY,
    )
  }

  /**
   * Удаляет шаг пользователя из localStorage
   */
  removeStepFromStorage() {
    this.gaApp.services.app.browserStorage.local.remove(
      RECEIVE_STEPS_LOCAL_STORAGE_KEY,
    )
  }

  resetStep() {
    this.gaApp.stores.giftCards.digitalReceive.currentStep =
      RECEIVE_STEPS.CARD_PACKED
  }

  /**
   * Проверяет шаг из localStorage
   * @returns {boolean} - открывал ли уже пользователь ЭПК
   */
  initAndCheckOpenedCardStep() {
    const step = this.getStepFromStorage()

    // Удаляем, чтобы в процессах без проверки шага карта шла по обычному флоу
    this.removeStepFromStorage()

    if (step) {
      this.setStep(step)
    }

    return step ? step === RECEIVE_STEPS.CARD_OPENED : false
  }

  /**
   * Подписывается на событие смены языка
   */
  subscribeToLangSwitch() {
    this.unsubscribeLangSwitcher = this.gaApp.eventBus.subscribe(
      'module/app/lang-switcher/locale-changed',
      () => {
        this.saveStepInStorage()
      },
    )
  }

  unsubscribeFromLangSwitch() {
    if (this.unsubscribeLangSwitcher) {
      this.unsubscribeLangSwitcher()
      this.unsubscribeLangSwitcher = null
    }
  }
}
