import throttle from 'lodash/throttle'

import {
  EDITOR_BLOCK_TYPES,
  REQUEST_SOURCES,
  THROTTLE_DELAY,
} from '../constants'
import { sectionChunks } from '../utils'
import { buildBannerApiType } from '../utils/banner'

export class OneService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Маппер данных для сохранения в хранилище и последующего использования
   * Необходимо разбить секции на чанки, так как некоторые виджеты разрывают
   * секцию в десктоп версии
   * @param {object} data - данные статьи
   */
  formatArticleData(data) {
    return {
      ...data,
      rawDate: data.date,
      date: this.formatDateForView(data.date, 'D MMMM YYYY'),
      sections: data.sections?.map((section) => ({
        ...section,
        chunks: sectionChunks(section),
      })),
    }
  }

  /**
   * Привести ISO дату к человекочитаемому формату
   * @param {string} date - ISO дата (пример - 2011-08-12T20:17:46.384Z)
   * @param {string} format - формат даты в которой необходимо преобразовать ISO дату (пример DD-MM-YYYY)
   *
   * @returns {string} - человекочитаемая дата
   */
  formatDateForView(date, format) {
    return this.gaApp.libs.dateWrapper(date).format(format)
  }

  /**
   * Преобразовать формат данных о рекомендациях, чтобы передать корректно в компонент
   * @param {object[]} articles - массив рекомендованных статей
   */
  mapRecommendationsArticles(articles) {
    return articles.map((item) => ({
      image: item.imageUrls,
      date: item.publishedDate
        ? this.formatDateForView(item.publishedDate, 'D MMMM YYYY')
        : '',
      subtitle: item.subTitle,
      ...item,
    }))
  }

  /**
   * Обновить данные в хранилище статьи и подняться наверх
   * @param {object} data - данные статьи
   */
  async patchArticlePage(data) {
    // Сначала обновляем данные в хранилище без рекомендаций
    // так как если обновить рекомендации возникает ситуация когда контент старницы уже подменился
    // и пользователь видит новые рекоендации, но еще не проскроллен наверх.
    // Если сначала делать скрол - то пользователь будет видеть старые данные, что тоже не хорошо
    const recommendations = data.recommendations
    delete data.recommendations
    await this.gaApp.stores.articles.one.$patch(data)

    this.scrollToTop()
    // После скрола обновляем рекомендации в хранилище
    this.gaApp.stores.articles.one.recommendations =
      this.mapRecommendationsArticles(recommendations)

    this.gaApp.stores.articles.one.isLoadedArticle = true
  }

  /**
   *  Получить данные о статье по ее id и заполнить хранилище полученными данными
   *  Необходимо для показа превью статьи для редакции
   *  Такая статья может быть заполнена не полностью
   *  @param {string} id - url статьи
   */
  async fetchPreviewArticle(id) {
    if (this.gaApp.isServer) {
      this.gaApp.redirectError({ statusCode: 500 })
    }

    this.gaApp.stores.articles.one.isPreview = true

    const { data } = await this.gaApp.services.articles.api.fetchArticle(id)
    const likesData = await this.getLikesInfo(data.id)

    const preparedData = this.formatArticleData({
      ...data,
      ...likesData,
    })

    await this.patchArticlePage(preparedData)
  }

  /**
   *  Получить данные о статье по ее url и заполнить хранилище полученными данными
   *  @param {string} url - url статьи
   */
  async fetchArticleByUrl(url) {
    this.gaApp.stores.articles.one.isPreview = false

    // Ожадаем получения основной информации по статье
    const { data } =
      await this.gaApp.services.articles.api.fetchArticleByUrl(url)

    const likesData = await this.getLikesInfo(data.id)

    // подготавливаем данные для патчинга в хранилище
    const preparedData = this.formatArticleData({
      ...data,
      ...likesData,
    })

    // если необходимо подтягиваем баннера и добавляем данные в нужные блоки
    if (this.gaApp.features.get('flaconPromo')) {
      await this.loadBanners(preparedData)
    }

    // патчим данным в хранилище - все за раз
    await this.patchArticlePage(preparedData)
  }

  /**
   *  Получить данные о лайках для заданной статьи
   *  @param {string} articleId - id статьи
   *
   *  @returns {UserLikesInfo} - возвращает информацию о лайках
   */
  getLikesInfo(articleId) {
    return this.gaApp.features.get('flaconUseLikes')
      ? this.gaApp.services.articles.likes.getArticleLikesInfo(articleId)
      : {}
  }

  /**
   *  Получить список продуктов
   *  Применяется в продуктовых виджетах - слайтер или листинг
   *  @param {string[]} skuArray - массив sku товаров
   *
   *  @returns {object} - возвращает полученные продукты и продукты промапленные для применения в продуктовых карточках
   */
  async fetchProductsBySku(skuArray = []) {
    // В случае ошибки в АПИ вернется пустой массив
    const productsRaw =
      await this.gaApp.services.articles.api.fetchProductsBySku(skuArray)

    return {
      productsRaw,
    }
  }

  /**
   *  Получить список продуктов по url категории
   *  Применяется в продуктовых виджетах - слайтер или листинг
   *
   *  @param {string} url - url категории
   *  @param {number} pageNumber - номер страницы
   *
   *  @returns {object} - возвращает полученные продукты и их общее кол-во
   */
  async fetchProductsByUrl(url, pageNumber = 0) {
    const { products, count } =
      await this.gaApp.services.articles.api.fetchProductsByUrl(url, pageNumber)

    return {
      productsRaw: products,
      count,
    }
  }

  /**
   *  Отправить запрос о том что статья просмотрена
   */
  async fetchArticleViewed() {
    if (
      this.gaApp.stores.articles.one.isPreview ||
      !this.gaApp.stores.articles.one.id
    ) {
      return
    }

    const captchaToken = !this.gaApp.isWebview
      ? await this.gaApp.services.captcha.main.generateToken()
      : null

    const id = this.gaApp.stores.articles.one.id

    await this.gaApp.services.articles.api.fetchArticleViewed({
      captchaToken,
      id,
    })
  }

  /**
   *  Перед загрузкой новых данных - поднимаем страницу наверх чтобы не было скачка контента
   */
  scrollToTop() {
    // Должен срабатывать только при переходах между статьями
    // Так как очистка хранилища происходит после ухода со страницы статьи
    // и только в случае если мы перейдем на другой тип страницы - то тут
    // будет храниться информация о том что страница статьи была загружена ранее
    if (!this.gaApp.stores.articles.one.isLoadedArticle) {
      return
    }

    window?.scrollTo?.({ top: 0 })
  }

  /**
   *  Получить список рекламных баннеров, размеченных в редакторе
   *  Из редактора нам призодят пустые баннерные слоты, зная их кол-во и место
   *  далее запросим конкретные рекламные банера и подменим полученные слоты
   *
   *   @param {object[]} sections - список секций статьи
   *
   * @returns {object[]} - возвращает массив пустых баннеров
   */
  getBanners(sections) {
    const banners = []

    sections.forEach((section) => {
      section.chunks.forEach((chunk) => {
        chunk.blocks.forEach((block) => {
          if (block.type === EDITOR_BLOCK_TYPES.BANNER) {
            banners.push(block)
          }
        })
      })
    })

    return banners
  }

  /**
   * Загрузить рекламные баннера и проставновить их в секции
   *
   *   @param {object} articleData - данные статьи
   */
  async loadBanners(articleData) {
    // Получить все баннерные блоки пустышки
    const banners = this.getBanners(articleData.sections)

    if (!banners.length) {
      return
    }

    const userId = this.gaApp.services.user.main.data.id
    const deviceId = this.gaApp.services.app.main.deviceId

    // Запросить рекламные баннера для текщего пользователя
    const apiBanners =
      await this.gaApp.services.articles.advBanners.loadBanners({
        userId: userId || deviceId,
        articleId: articleData.id,
        banners: banners.map((banner) => ({
          bannerType: buildBannerApiType(banner.data.type),
          blockId: banner.id,
        })),
      })

    if (!apiBanners || !apiBanners.length) {
      return
    }

    // Заменяем баннерные-пустышки на реальные данные от АПИ
    banners.forEach((banner) => {
      // находим баннер из запроса и расширяем текущий баннер
      const apiBanner = apiBanners.find((item) => item.blockId === banner.id)

      if (!apiBanner) {
        return
      }

      banner.data = {
        ...banner.data,
        ...apiBanner,
      }
    })
  }

  /**
   * Запросить список рекоменованных товаров
   * @param {object} data
   * @param {string[]} data.itemIds - массив айдишников для которых нужен список рекомендаций
   * @returns {object} - возвращает список рекомендаций и названии блока
   */
  async fetchProductRecommendations({ itemIds }) {
    const placements = await this.gaApp.services.placements.api.getProducts({
      type: REQUEST_SOURCES.FLACON,
      productsIds: itemIds,
    })

    // Api placements в случае ошибки может вернуть null,
    // тогда обратимся к пустому объекту и получим занчения по умолчанию
    const { products = [], name = '' } = (placements && placements[0]) || {}

    return {
      products,
      name,
    }
  }

  /**
   * Клик по иконке лайка внутри статьи (поставить лайк/снять лайк)
   * Добавили тротлинг чтобы не слать запросы на лайк слишком часто
   */
  onClickLike = throttle(() => {
    if (this.gaApp.stores.articles.one.liked) {
      return this.gaApp.services.articles.likes.removeArticleLike(
        this.gaApp.stores.articles.one.id,
      )
    }

    // Отправляем аналитику только при проставлении лайка
    this.gaApp.analytics.modules.articles.onLikeClick({
      articleId: this.gaApp.stores.articles.one.id,
    })

    return this.gaApp.services.articles.likes.setArticleLike(
      this.gaApp.stores.articles.one.id,
    )
  }, THROTTLE_DELAY)

  /**
   * Сбрасывае хранилище с данными о статье до дефолтного
   */
  reset() {
    this.gaApp.stores.articles.one.$reset()
  }
}
