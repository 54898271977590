import { scriptInjecting } from '@ga/shared-browser'

import { PAYMENT_LINK } from '../constants/payment'

export class PaymentMasterCardService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Завершает оплату мастеркард
   */
  endMasterCardPayment() {
    this.gaApp.services.app.browserStorage.session.clear()

    // TODO: использовать метод удаления скрипта (обратный inject) со страницы, когда он появится вместо этого delete
    delete window.Checkout
  }

  /**
   * Генерирует форму оплаты при помощи библиотеки из scriptUrl
   * @param {string} nodeId id элемента в DOM
   */

  async embedPayment(nodeId) {
    const { sessionId } = this.gaApp?.route?.query
    let scriptUrl

    try {
      const { data } =
        await this.gaApp.services.giftCards.api.orderMasterCardInfo(sessionId)
      scriptUrl = data.scriptUrl
    } catch (error) {
      throw new Error('scriptUrl missing')
    }

    try {
      if (!window.Checkout) {
        await scriptInjecting.inject(scriptUrl, { async: false })
      }
      window.Checkout.configure({
        session: {
          id: sessionId,
        },
      })
      window.Checkout.showEmbeddedPage(`#${nodeId}`)
    } catch (error) {
      this.gaApp.services.app.router.pushToRoute({
        moduleType: 'giftCards',
        pageType: 'payment/error',
      })
    }
  }

  /**
   * Начало оплаты картой мастеркард
   */
  async startPayment() {
    const { orderData } = this.gaApp.services.giftCards.payment

    const { data } =
      await this.gaApp.services.giftCards.api.paymentOrderCard(orderData)

    this.gaApp.services.giftCards.payment.setOrderId(data.salesId)

    const { sessionId } = data

    if (!sessionId) {
      throw new Error('sessionId missing')
    }
    // sessionId передается в query для возможности начала оплаты в мобильных приложениях с редиректом на эту же страницу
    this.gaApp.router.push({
      path: PAYMENT_LINK.MASTERCARD,
      query: { sessionId },
    })
  }
}
