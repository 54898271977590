export class SearchProductsEmptyBuilder {
  constructor() {
    this.data = {
      marketingProducts: [],
      filters: [],
      url: '',
      count: 0,
      countSelectedFilters: 0,

      products: [],
      placements: [],
    }
  }
}
