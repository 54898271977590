export const SIZE = {
  L: 'l',
  M: 'm',
  S: 's',
}

export const HEIGHT = {
  MAX: 460,
}

export const DROPDOWN_MAX_HEIGHT = 200

export const COUNTRY_FLAG_ICON_SIZE = 18

export const VISIBILITY_OPTIONS_LENGTH = {
  MAX: 9,
}
