export const mainEndpoints = {
  address: () => ({
    url: 'location/address',
  }),
  setAddressDefault: () => ({
    url: 'location/address/setDefault',
  }),
  locationByIp: () => ({
    url: 'location/location-by-ip',
  }),
  geoSuggest: () => ({
    url: 'location/suggest',
  }),
  geoGeocode: () => ({
    url: 'location/geocode',
  }),
  geoReverseGeocode: () => ({
    url: 'location/reverse-geocode',
  }),
}
