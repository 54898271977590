import { DATE_FORMAT } from './../constants/card'

export class DateService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getDate(date) {
    return this.gaApp.libs.dateWrapper(date) ?? null
  }

  getFullDate(data, format) {
    if (!data.startDate) {
      return null
    }

    const startDate = this.getDate(data.startDate).format(format)

    const endDate = data.endDate
      ? this.getDate(data.endDate).format(format)
      : null

    if (!endDate) {
      return startDate
    }

    return `${startDate} — ${endDate}`
  }

  getShortDate(
    data,
    baseFormat = 'DD MMMM',
    shortFormat = 'DD',
    isNewOffers = false,
  ) {
    if (!data.startDate) {
      return null
    }

    const startMonth = this.getDate(data.startDate).month()
    const endMonth = data.endDate ? this.getDate(data.endDate).month() : null

    if (endMonth === null) {
      return this.getDate(data.startDate).format(baseFormat)
    }

    const startDate =
      startMonth === endMonth
        ? this.getDate(data.startDate).format(shortFormat)
        : this.getDate(data.startDate).format(baseFormat)

    const endDate = this.getDate(data.endDate).format(baseFormat)

    // TODO: isNewOffers убрать после выпила старых акций
    if (isNewOffers) {
      // не переносить даты формата 1 - 15 января
      if (startMonth === endMonth) {
        return `${startDate} — ${endDate}`
      }

      return `<span>${startDate}</span> — <span>${endDate}</span>`
    }

    return `${startDate} — ${endDate}`
  }

  // TODO: переименовать в getOfferDate после выпила старых акций
  getNewOfferDate(data, inListing = false) {
    if (!data.startDate) {
      return null
    }

    const startDate = this.getDate(data.startDate)
    const endDate = data.endDate ? this.getDate(data.endDate) : null

    const formattedEndDate = endDate ? endDate.format(DATE_FORMAT.BASE) : null

    const isEqualDate = startDate.isSame(endDate, 'date')
    const isEqualMonth = startDate.isSame(endDate, 'month')

    // если дата конца и начала совпадают / нет даты конца - возвращаем только одну дату
    if (isEqualDate || !formattedEndDate) {
      return startDate.format(DATE_FORMAT.BASE)
    }

    // если месяц конца и начала совпадают - возвращаем только день даты начала
    const formattedStartDate = isEqualMonth
      ? startDate.date()
      : startDate.format(DATE_FORMAT.BASE)

    // если дата в листинге и  формата 1 мая - 15 января
    if (inListing && !isEqualMonth) {
      return `<span>${formattedStartDate}</span> — <span>${formattedEndDate}</span>`
    }

    return `${formattedStartDate} — ${formattedEndDate}`
  }
}
