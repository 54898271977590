import { useContext } from '@nuxtjs/composition-api'

import { computed, ref, unref, watch } from 'vue'

import { iosKeyboardOpen } from '@ga/shared-browser'

import {
  MODULE_TYPE as MODULE_TYPE_CUSTOMER,
  PAGE_TYPE as PAGE_TYPE_CUSTOMER,
} from '~/modules/customer'

import { config } from '../../../../../../../config'
import { FROM } from '../../../../../../customer/analytics/constants'
import { useAnalytics as useCustomerAnalytics } from '../../../../../../customer/composable/use/analytics'
import { useAnalytics } from '../../../../../composable/use/analytics'
import { TABS } from '../../../../../constants'

/**
 * Возвращает список табов

 */
export const useTabs = (
  setModalsByTab,
  openCartModal,
  searchModalOpened,
  langSwitcherModalOpened,
  updateHeaderExtendedState,
  updateSearchModalTransitionDelay,
  full,
  mounted,
  minimized,
  authorized,
  svg,
) => {
  const { $gaApp } = useContext()

  const analytic = useAnalytics()
  const { onOpenWishlist } = useCustomerAnalytics()

  /** логика переключения табов */
  const selectedTab = ref()

  const currentRouteTab = ref()

  const setCurrentRouteTab = (pageKey) => {
    currentRouteTab.value = pageKey
  }

  const setSelectedTab = (tab) => {
    selectedTab.value = tab
  }

  const clearSelectedTab = () => {
    selectedTab.value = null
  }

  $gaApp.eventBus.subscribe('module/app/router/page-created', ({ pageKey }) => {
    setCurrentRouteTab(pageKey)
  })

  watch(
    searchModalOpened,
    (value) => {
      updateSearchModalTransitionDelay()

      if (value) {
        updateHeaderExtendedState()

        setSelectedTab(TABS.SEARCH)

        return
      }

      if (unref(selectedTab) === TABS.SEARCH) {
        clearSelectedTab()
      }
    },
    { immediate: true },
  )

  watch(langSwitcherModalOpened, (value) => {
    if (value) {
      clearSelectedTab()
    } else {
      setSelectedTab(TABS.NAV)
    }
  })

  watch(selectedTab, (newValue, oldValue) => {
    setModalsByTab(oldValue, false)
    setModalsByTab(newValue, true)
  })

  const onTabClick = (tab) => {
    const { href, type, openExternalModal } = tab

    const isLink = Boolean(href)
    const isSelected = unref(selectedTab) === type

    if (type === TABS.AUTH) {
      analytic.onTabClick(tab)
    } else if (type === TABS.FAVORITES) {
      onOpenWishlist(FROM.TABBAR)
    } else if (type === TABS.SEARCH && !isSelected) {
      // ios хак для установки фокуса при открытии модалки поиска
      iosKeyboardOpen({ enterkeyhint: 'search' })
    }

    if (!href && openExternalModal) {
      openExternalModal()
      return
    }

    if (isLink || isSelected) {
      clearSelectedTab()
    } else {
      setSelectedTab(type)
    }
  }

  /** логика переключения табов */

  /** генерация списка табов */
  const getFavoritesTabHref = () => {
    const href = $gaApp.services.app.router.getPathByRoute({
      moduleType: 'customer',
      pageType: 'favorites',
    })

    if (unref(mounted)) {
      return unref(authorized) && href
    }

    return href
  }

  const getAuthTabText = () => {
    const profile = $gaApp.i18n.t('header.main.authTab.profile')
    const auth = $gaApp.i18n.t('header.main.authTab.auth')

    if (unref(mounted)) {
      return unref(authorized) ? profile : auth
    }

    return profile
  }

  const getAuthTabHref = () => {
    const href = $gaApp.services.app.router.getPathByRoute({
      moduleType: 'customer',
      pageType: 'orders',
    })

    if (unref(mounted)) {
      return unref(authorized) && href
    }

    return null
  }

  const getCartTabHref = () => {
    // Если параметр enabledCart в конфиге = false
    // То, нужно установить внешнюю ссылку на корзину
    if (!config.get('enabledCart')) {
      const content = $gaApp.services.app.main.currentStoreContent
      const storeDomain = content ? content.storeDomain : '/'

      return `${storeDomain}#cart`
    }

    return null
  }

  const tabs = computed(() => {
    const items = () => [
      {
        component: 'ga-icon',

        type: TABS.NAV,
        icon: svg.nav,
        text: $gaApp.i18n.t('header.main.tabs.nav'),
      },
      {
        component: 'ga-icon',

        type: TABS.SEARCH,
        // TODO: поменять для закрытия модалки поиска
        icon: unref(full) && unref(searchModalOpened) ? svg.cross : svg.search,
        text: $gaApp.i18n.t('header.main.tabs.search'),
      },
      {
        component: 'ga-icon',

        type: TABS.MAIN,
        icon: svg.logo,
        text: $gaApp.i18n.t('header.main.tabs.main'),
        href: '/',
      },
      {
        component: 'ga-icon',

        type: TABS.FAVORITES,
        icon: svg.heart,
        text: $gaApp.i18n.t('header.main.tabs.favorites'),
        href: getFavoritesTabHref(),
        openExternalModal: () => {
          const moduleType = MODULE_TYPE_CUSTOMER
          const pageType = PAGE_TYPE_CUSTOMER.FAVORITES

          $gaApp.services.app.router.pushToRoute({
            moduleType,
            pageType,
          })
        },
      },
      {
        component: 'ga-icon',

        type: TABS.AUTH,
        icon: svg.user,
        text: getAuthTabText(),
        href: getAuthTabHref(),
        openExternalModal: () => {
          const moduleType = MODULE_TYPE_CUSTOMER
          const pageType = PAGE_TYPE_CUSTOMER.ORDERS

          $gaApp.services.app.router.pushToRoute({
            moduleType,
            pageType,
          })
        },
      },
      {
        component: 'ga-cart-icon',

        type: TABS.CART,
        text: $gaApp.i18n.t('header.main.tabs.cart'),
        href: getCartTabHref(),
        openExternalModal: openCartModal,

        externalHref: true,
      },
    ]

    // На SSR будут рендриться табы по-умолчанию
    if (!unref(mounted)) {
      return items()
    }

    // На клиенте табы будут пересчитываться
    return items().map((tab) => {
      const { href, type, externalHref } = tab

      const nuxt = Boolean(href) && !externalHref
      let checked

      if (unref(selectedTab)) {
        checked = type === unref(selectedTab) && unref(minimized)
      } else {
        checked = type === unref(currentRouteTab)
      }

      return { ...tab, nuxt, checked }
    })
  })
  /** генерация списка табов */

  const onAuthClick = () => {
    const tabAuth = unref(tabs).find((item) => item.type === TABS.AUTH)
    return onTabClick(tabAuth)
  }

  return {
    svg,
    selectedTab,
    setSelectedTab,
    clearSelectedTab,
    tabs,
    onTabClick,
    onAuthClick,
  }
}
