export class ModalStartService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async sendCode(phone, phoneCode) {
    this.gaApp.analytics.modules.auth.onSubmitSms()

    this.gaApp.stores.auth.modal.pending = true

    try {
      const token = await this.gaApp.services.captcha.main.generateToken()
      const response = await this.gaApp.services.auth.main.sendAuthCode({
        phone,
        phoneCode,
        captchaToken: token,
      })

      this._onSendCodeSuccess(response)

      return response
    } catch (error) {
      this._onSendCodeError(error)
    } finally {
      this.gaApp.stores.auth.modal.pending = false
    }
  }

  _onSendCodeSuccess(response) {
    this.gaApp.services.auth.modal.updateVerificationCode(
      response.data?.verify_token,
    )
    this.gaApp.services.auth.modal.updateNextRequestDelay(
      response.data?.next_request_delay_ms,
    )
  }

  _onSendCodeError(error) {
    const requestDelayMs = error.metadata?.next_request_delay_ms

    if (error.statusCode === 429) {
      this.gaApp.services.auth.modal.updateNextRequestDelay(requestDelayMs)
    }

    this.gaApp.services.auth.modal.handleError(error)
  }
}
