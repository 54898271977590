<template>
  <svg fill="none" viewBox="0 0 18 18">
    <path
      stroke="currentColor"
      stroke-width="1.5"
      d="M13.533 6.452 9.001 1.954 4.468 6.452"
    />
    <path stroke="currentColor" stroke-width="1.5" d="M9 13.378V2.854" />
    <path
      stroke="currentColor"
      stroke-width="1.5"
      d="M1.748 12.388v4.772H16.25v-4.772"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-articles-share-semi-bold',
}
</script>
