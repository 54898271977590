import { LAST_ORDER_ID_LOCAL_STORAGE_KEY } from '../constants/main'

export class CleanupService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.subscribeOnUserLogout()
  }

  /**
   * Очистка данных пользователя после его выхода
   */
  subscribeOnUserLogout() {
    this.gaApp.eventBus.subscribe('module/auth/logout', () => {
      this.removeCheckoutData()
    })
  }

  /**
   * Удаляет данные чекаута
   */
  removeCheckoutData() {
    this.gaApp.services.checkout.recipient.removeDataFromLocalStore()
  }

  /**
   * Удаляет данные последнего оформленного заказа из local storage, если id заказа ему соответствует
   * @param {string} maskedId - id заказа
   */
  removeLastOrderId(maskedId) {
    const lastOrderId = this.gaApp.services.app.browserStorage.local.get(
      LAST_ORDER_ID_LOCAL_STORAGE_KEY,
    )

    // Удаляем сохраненный id последнего оформленного заказа только в случае, если TYP был открыт для него
    if (maskedId === lastOrderId) {
      this.gaApp.services.app.browserStorage.local.remove(
        LAST_ORDER_ID_LOCAL_STORAGE_KEY,
      )
    }
  }
}
