import { DELIVERY_ABORT_KEY, STATE, TARGET } from '../../constants/common'
import { DELAY_LOADING, DELAY_LOADING_OFF } from '../../constants/common/delay'

/**
 * ApiService предназначен для получения данных о продукте,
 * доставке и описании продукта из репозиториев и кэша.
 */
export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/pdp/prefetch', (item) => {
      this.getProduct({ itemId: item.id })
    })
  }

  // ручка запроса карточки продукта
  async fetchProduct({ itemId = null, view = TARGET.MAIN } = {}) {
    this.gaApp.stores.pdp.main[STATE.PRODUCT].pending = true

    const productId = itemId || this.gaApp.stores.pdp[view].productSelectedId
    try {
      const response = await this.getProduct({ itemId: productId })

      this.gaApp.stores.pdp[view][STATE.PRODUCT].data = response?.data
      this.gaApp.stores.pdp[view].attributesSelected =
        response?.data?.attributesSelectedDefault

      return response
    } catch (error) {
      const apmError = new Error(
        `Ошибка получения данных продукта пдп(${view}) ${productId}`,
      )
      apmError.errorData = JSON.stringify(error)
      this.gaApp.services.app.apm.captureError(apmError)
    } finally {
      this.gaApp.stores.pdp[view][STATE.PRODUCT].pending = false
    }
  }

  /**
   * Асинхронно получает данные о продукте.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @param {string} param.view - Название представления, по умолчанию TARGET.MAIN.
   * @return {Promise<Object>} - Возвращает Promise с данными о продукте.
   */
  async getProduct({ itemId, view }) {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()
    const { customerGroupId, groupId } = this.gaApp.services.user.main.data

    const requestParams = {
      itemId: itemId || this.gaApp.stores.pdp[view].productSelectedId,
      customerGroupId: customerGroupId || groupId || '0',
      ...addressParams,
    }

    const cacheData =
      this.gaApp.services.cache.main.getServerData('productCard')

    const response =
      cacheData ||
      (await this.gaApp.repositories.pdp.main.getProduct(requestParams))

    return response
  }

  /**
   * Асинхронно получает данные о доставке продукта.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string|null} param.itemId - Идентификатор продукта, по умолчанию null.
   * @param {string} param.view - Название представления, по умолчанию TARGET.MAIN.
   * @return {Promise<void>} - Возвращает Promise после обработки данных о доставке.
   */
  async getDelivery({
    itemId = null,
    view = TARGET.MAIN,
    ...otherParams
  } = {}) {
    const timer = setTimeout(() => {
      this.gaApp.stores.pdp[view][STATE.DELIVERY].pending = true
    }, DELAY_LOADING)

    try {
      const cacheData = this.gaApp.services.cache.main.getServerData('delivery')
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.pdp.main.getDelivery(
          {
            itemId: itemId || this.gaApp.stores.pdp[view].productSelectedId,
            ...addressParams,
            ...otherParams,
          },
          { abortKey: DELIVERY_ABORT_KEY },
        ))
      this.gaApp.stores.pdp[view][STATE.DELIVERY].data = data
    } catch (error) {
      if (error?.aborted) {
        return
      }

      this.gaApp.stores.pdp[view][STATE.DELIVERY].error = error?.data
    } finally {
      clearTimeout(timer)
      this.gaApp.stores.pdp[view][STATE.DELIVERY].pending = false
    }
  }

  /**
   * Асинхронно получает описание продукта.
   *
   * @param {Object} param - Объект с параметрами.
   * @param {string} param.itemId - Идентификатор продукта.
   * @param {string} param.view - Название представления, по умолчанию TARGET.MAIN.
   * @return {Promise<void>} - Возвращает Promise после обработки описания продукта.
   */
  async getProductDescription({ itemId, view = TARGET.MAIN }) {
    const timer = setTimeout(() => {
      this.gaApp.stores.pdp[view].hasLoadingDescription = true
    }, DELAY_LOADING)

    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const user = this.gaApp.services.user.main.data

      const { data } =
        await this.gaApp.repositories.pdp.main.getProductDescription({
          itemId,
          customerGroupId: user.customerGroupId || user.groupId || '0',
          ...addressParams,
        })

      this.gaApp.stores.pdp[view][STATE.PRODUCT].data.productDescription =
        data.productDescription
    } catch (error) {
      this.gaApp.services.app.apm.captureError(error)
    } finally {
      clearTimeout(timer)
      const timerOff = setTimeout(() => {
        this.gaApp.stores.pdp[view].hasLoadingDescription = false
        clearTimeout(timerOff)
      }, DELAY_LOADING_OFF)
    }
  }

  /**
   * Получает слоты для продукта на основе информации о бренде.
   *
   * @param {Object} product - Объект продукта, содержащий информацию о бренде.
   * @param {Object} product.brandZoneInfo - Информация о бренд-зоне продукта.
   * @param {string} product.brandZoneInfo.brandMainCategoryId - ID основной категории бренда.
   * @return {Object} - Объект с данными о слотах или пустой объект, если информация о категории отсутствует.
   */
  getProductSlots(product) {
    const categoryId = product.brandZoneInfo?.brandMainCategoryId

    /**
     * Возвращаем пустые данные если у продукта нет информации о категории
     */
    if (!categoryId) {
      return {
        data: null,
      }
    }

    const params = { categoryId }

    if (this.gaApp.features.get('bzCategorySlots')) {
      return this.gaApp.repositories.pdp.main.getCategorySlots(params)
    }

    return this.gaApp.features.get('mergingJSONandWidgetsForBZ')
      ? this.gaApp.repositories.pdp.main.getSlotsV3(params)
      : this.gaApp.repositories.pdp.main.getSlotsV2(params)
  }

  async getBenefits({ itemId = null }) {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const requestParams = {
      itemId: itemId || this.gaApp.stores.pdp.main.productSelectedId,
      ...addressParams,
    }

    const { data } =
      await this.gaApp.repositories.pdp.main.getBenefits(requestParams)

    this.gaApp.stores.pdp.main[STATE.PRODUCT].data.benefits = data.benefits

    return data
  }
}
