import { useContext } from '@nuxtjs/composition-api'

import { useUser } from '~/modules/user'

export const getMappedPrice = (price) => {
  const { $gaApp } = useContext()
  const { userIsAuth } = useUser()

  const isGuestPrices = $gaApp.features.get('guestPrices')

  let prefix = false

  if (!userIsAuth.value) {
    prefix = isGuestPrices ? price.viewOptions.priceFrom : price.hasLoyalty
  }

  return {
    old: price.old || undefined,
    actual: price.actual,
    oldStrikethrough: price.viewOptions.crossPrice,
    prefix,
  }
}
