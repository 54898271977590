/**
 * Утилита чтобы правильно получать id из адреса
 * @param { object } address - адрес
 * @param { string | null } address.saveId - id сохраненного адреса
 * @param { string } address.exactId - наиболее точный фиас
 * @returns {{ saved: string, mixed: string }}
 */
export const getAddressId = (address) => {
  // у временного адреса нет saveId, а exactId может быть не уникальным, поэтому
  // для сохраненных + временного используем exactId + saveId
  // для сохраненных достаточно saveId

  // на данный момент:
  // уточнение адреса (если кликнуть на адрес в хедере) - mixed
  // ЛК - saved
  // Чекаут - saved, но планируется перейти на mixed
  return {
    mixed: address.exactId + (address.saveId || ''),
    saved: address.saveId || '',
  }
}
