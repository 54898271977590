export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getProducts({ productsIds, categoryId, type }) {
    if (!type) {
      return
    }

    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const cacheData = this.gaApp.services.cache.main.getServerData(
        `placements-${type}`,
      )

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.placements.main.getProducts({
          requestSource: type,
          customerGroupId: this.gaApp.stores.user.main.data.groupId || '0',
          categoryId: categoryId || null,
          itemIds: productsIds || [],
          ...addressParams,
        }))

      return data.placements
    } catch (error) {
      console.error(error)

      return null
    }
  }
}
