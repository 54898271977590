export const DEFAULT_LISTING_PAGE = 0

export const BP_MOBILE_SMALL = 340

// Размер страницы в листинге статей
export const ARTICLES_PAGE_CHUNK_SIZE = 18

// Размер страницы в листинге стримов
export const STREAMS_PAGE_CHUNK_SIZE = 16

// Кол-во отображаемых продуктов в листинге продуктов на странице стрима
export const PRODUCT_LISTING_PAGE_SIZE = 24

export const CHUNK_SIZE_ANALYTICS = 1

// Количество изображений в карточках листинга, которые
// должны быть загружены перед показом листинга
export const COUNT_OF_LOAD_IMAGES = 4

// Количество изображений в карточках листинга, которые
// должны быть загружены перед показом листинга для режима вебвью
export const COUNT_OF_LOAD_IMAGES_WEBVIEW = 2

// Как отображаем филтры в url (во множественном числе)
// например ?rubrics=manikyur,vneshnyaya
export const URL_FILTER_TYPE = {
  rubric: 'rubrics',
  tag: 'tags',
  author: 'authors',
}

// В каком виде принимает фильтры АПИ (в единственном числе)
// при запросе листингов
export const BACK_FILTER_TYPE = {
  rubric: 'rubric',
  tag: 'tag',
  author: 'author',
}

// В каком виде принимает фильтры АПИ (в единственном числе)
// при запросе фильтров
export const FILTER_TYPE = {
  rubric: 'rubric',
  tag: 'tag',
}

// Префикс для роутов флакона
const FLACON_PREFIX_URL = 'flacon'

// Роуты статей
export const ARTICLES_BASE_URL = `/${FLACON_PREFIX_URL}/articles`
export const ARTICLE_BASE_URL = `/${FLACON_PREFIX_URL}/article/`
export const ARTICLE_PREVIEW_BASE_URL = `/${FLACON_PREFIX_URL}/preview/`

// Роуты стримов
export const STREAMS_BASE_URL = `/${FLACON_PREFIX_URL}/live`
export const STREAM_BASE_URL = `/${FLACON_PREFIX_URL}/live/:url`
export const STREAM_PREVIEW_BASE_URL = `/${FLACON_PREFIX_URL}/preview/live/`

// Роут рубрики для SSR
export const STREAMS_RUBRIC_URL = `/${STREAMS_BASE_URL}/rubric`

// Ключ используемый в url для номера страницы
export const URL_PAGE_KEY = 'p'

// Параметры необходимы для корректной работы вебвью в МП
// отображать ли иконку шеринга и цвет иконок в навбаре
export const WEB_VIEW_QUERY_PARAM = {
  shareable: 'shareable',
  iconColorNav: 'icon-color-nav',
}

// Допустисы цвета иконок в навбаре в режиме вебвью
export const WEB_VIEW_ICON_COLOR = {
  white: '000000',
  black: 'FFFFFF',
}

// Ключи seo  атрибутов - нужны при парсинге общего массива seo атрибутов для выделения отдельно заголовка и описания
export const SEO_ATTRS_KEY = {
  TITLE: 'title',
  DESCRIPTION: 'description',
}

// Направления загрузки листингов - вперед или назад
export const LOAD_DIR = {
  NEXT: 'next',
  PREV: 'prev',
}

// Сущнсоти Флакона - статьи и стримы
// применяются в листинге и аналитике, чтобы в редких кейса применять немного отличные методы или данные
export const ENTITY_TYPE = {
  ARTICLE: 'article',
  STREAM: 'stream',
}

// Тип баннера - может быть светлый или темный
// от цвета зависит цвет текста на баннере
export const BANNER_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
}

// Цвет тултипа доп информации на изображениях - светлый или темный
// для того чтобы иконка была более контрастной
export const COVER_CAPTION_COLOR = {
  WHITE: 'white',
  BLACK: 'black',
}

// REQUEST_SOURCES при запросе placements для флакона
export const REQUEST_SOURCES = {
  FLACON: 'flacon',
}
