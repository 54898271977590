/**
 * Сервис предоставляет методы для отправки уведомлений и обработки ошибок в процессе отправки отзыва:

`invalidForm()` - отправляет уведомление об ошибке валидации формы. Использует `gaApp.i18n.t()` для получения текста уведомления и `gaApp.services.notification.main.open()` для открытия уведомления.

`errorSubmit()` - отправляет уведомление об ошибке отправки отзыва. Использует `gaApp.i18n.t()` для получения текста уведомления и `gaApp.services.notification.main.open()` для открытия уведомления.

`errorGetProducts(errorText)` - отправляет уведомление об ошибке получения списка продуктов. Принимает опциональный параметр `errorText` для указания пользовательского текста ошибки. Если `errorText` не указан, используется текст по умолчанию, полученный через `gaApp.i18n.t()`. Использует `gaApp.services.notification.main.open()` для открытия уведомления.

`successSubmit()` - асинхронный метод, который выполняет переход на страницу успешной отправки отзыва.

`errorRepeatReview(redirect)` - метод, который выполняет переход на страницу ошибки повторной отправки отзыва.
 */
export class NotifyService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  invalidForm() {
    const text = this.gaApp.i18n.t('review.form.submit.errorValidation')

    this.gaApp.services.notification.main.open({
      data: {
        props: {
          alert: true,
          text,
        },
      },
    })
  }

  errorSubmit() {
    const text = this.gaApp.i18n.t('review.form.submit.error')

    this.gaApp.services.notification.main.open({
      data: {
        props: {
          alert: true,
          text,
        },
      },
    })
  }

  errorGetProducts(errorText) {
    const text = errorText || this.gaApp.i18n.t('review.products.notify.error')
    this.gaApp.services.notification.main.open({
      data: {
        props: {
          alert: true,
          text,
        },
      },
    })
  }

  successSubmit() {
    return this.gaApp.services.app.router.pushToRoute({
      moduleType: 'review',
      pageType: 'submitSuccess',
    })
  }

  errorRepeatReview() {
    return this.gaApp.services.app.router.redirectToRoute({
      moduleType: 'review',
      pageType: 'submitFailure',
    })
  }
}
