import { useContext } from '@nuxtjs/composition-api'
import debounce from 'lodash/debounce'

import { unref } from 'vue'

import { useApp } from '~/modules/app'

/**
 * Это пользовательский хук, используемый для предварительной загрузки URL-адресов и улучшения производительности.
 *
 * @return {Object} Объект с функциями для предварительной загрузки URL-адресов.
 */
export const usePrefetch = () => {
  const { $gaApp } = useContext()
  const { appWithHover } = useApp()

  // Массив для хранения идентификаторов предварительно загруженных элементов.
  const itemsPrefetch = []

  /**
   * Функция для предварительной загрузки URL-адресов при входе курсора в карточку продукта.
   *
   * @param {Object} product - Объект продукта.
   */
  const onProductCardMouseEnter = unref(appWithHover)
    ? debounce(({ product }) => {
        const { itemId: id } = product
        if (itemsPrefetch.includes(id)) return
        itemsPrefetch.push(id)

        if (id) {
          $gaApp.eventBus.publish('module/pdp/prefetch', {
            id,
          })
        }
      }, 200)
    : () => {}

  /**
   * Функция для обработки клика по карточке продукта.
   *
   * @param {Object} product - Объект продукта.
   */
  const onProductCardClick = ({ product }) => {
    const { itemId: id, brand, name } = product

    if (!id) {
      return
    }

    // Публикуем событие в шину событий.
    $gaApp.eventBus.publish('module/app/link/redirect', {
      type: 'redirect',
      data: {
        moduleType: 'pdp',
        pageType: 'product',
        entityId: id,
        seo: {
          title: brand + ' ' + name,
        },
      },
    })
  }

  return { onProductCardMouseEnter, onProductCardClick }
}
