export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData() {
    try {
      this.pendingStart()

      const data = await this.gaApp.services.location.api.getCitiesModalData()

      if (data) {
        this.gaApp.stores.location.modal.citiesData = data
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.pendingStop()
    }
  }

  resetState() {
    this.gaApp.stores.location.modal.$reset()
  }

  /**
   * Открывает модалку с выбором города
   * @param { boolean } obj
   * @param { boolean } obj.countrySelectDisabled // флаг, который делает селект выбора страны активным/неактивным
   * @param { boolean } obj.updateDataOnSelect // флаг, чтобы дернуть ручку api.updateData даже если включен адресный блок (нужен для чекаута)
   */
  open({ countrySelectDisabled = false, updateDataOnSelect = false } = {}) {
    this.gaApp.stores.location.modal.isOpened = true
    this.gaApp.stores.location.modal.updateDataOnSelect = updateDataOnSelect
    this.gaApp.stores.location.modal.countrySelectDisabled =
      countrySelectDisabled
  }

  close() {
    this.gaApp.stores.location.modal.isOpened = false
    this.gaApp.stores.location.modal.updateDataOnSelect = false
    this.gaApp.stores.location.modal.countrySelectDisabled = false
  }

  pendingStart() {
    this.gaApp.stores.location.modal.isPending = true
  }

  pendingStop() {
    this.gaApp.stores.location.modal.isPending = false
  }

  setGeolocationPending(value) {
    this.gaApp.stores.location.modal.geolocationPending = value
  }

  resetGeolocation() {
    this.gaApp.stores.location.modal.resetProp('geolocation')
  }

  resetConfirmCountry() {
    this.gaApp.stores.location.modal.resetProp('confirmCountry')
  }

  redirectToCountry() {
    const code = this.gaApp.stores.location.modal.confirmCountry.newCode
    const storeContent = this.gaApp.services.app.main.getStoreContent(code)
    const storeDomain = storeContent?.storeDomain || '/'

    window.location.href = storeDomain
  }

  openConfirmCountry(countryCode) {
    this.gaApp.stores.location.modal.confirmCountry.newCode = countryCode
    this.gaApp.stores.location.modal.confirmCountry.isOpen = true
  }

  /**
   * Устанавливает локацию: город или геолокацию
   * @param location
   */
  async setLocation(location) {
    // смена города на чекауте
    if (this.gaApp.stores.location.modal.updateDataOnSelect) {
      this.gaApp.eventBus.publish('module/location/city-modal/city-change', {
        location,
      })
      return
    }

    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      this.gaApp.services.location.main.setLocationTemp(location)
    } else {
      await this.changeCity(location)
    }

    this.close()
  }

  /**
   * Смена города на страницах, кроме чекаута
   */
  async changeCity(location) {
    this.pendingStart()

    await this.gaApp.services.location.main.setSelectedAddress(location)

    this.pendingStop()
  }

  async browserDetectionGeolocation() {
    try {
      const { latitude, longitude } =
        await this.gaApp.services.location.geolocation.browserDetection()

      if (latitude && longitude) {
        const geolocation =
          await this.gaApp.services.location.api.getGeolocation({
            latitude,
            longitude,
          })

        if (geolocation) {
          return geolocation
        }
      }
    } catch (error) {
      console.log(error)
    }

    return null
  }
}
