import * as components from './components'
import { locales } from './locales'
// import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
// import { stores } from './store'

export { productSchema as productCardVerticalProductSchema } from './components/organisms/product-card-vertical'
export { GaProductCardActions } from './components/organisms/product-card-actions'

export const productCard = {
  meta: {
    name: 'productCard',
    locales,
    routes,
  },
  components,
  layers: {
    // stores,
    services,
    // repositories,
  },
}

export * from './constants'
export {
  mapProduct,
  useProductCardCart,
  useProductData,
  useCurrencyVisible,
  getImageAttributes,
} from './composables'
