export const RECOMMENDATION_CARD_SIZE = {
  M: 'm',
  S: 's',
  XS: 'xs',
}

export const RECOMMENDATION_PRODUCTS_SIZE = {
  SMALL: 3,
  LARGE: 4,
}
