import { AUTH_MODAL_REASON_TO_OPEN } from '~/modules/modal'

export class BrandsService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.setSubscriptions()
  }

  setItem(id) {
    if (!this.gaApp.stores.favorites.main.brands.includes(id)) {
      this.gaApp.stores.favorites.main.brands.push(id)
    }
  }

  removeItem(id) {
    const index = this.gaApp.stores.favorites.main.brands.indexOf(id)

    if (index !== -1) {
      this.gaApp.stores.favorites.main.brands.splice(index, 1)
    }
  }

  setItems(ids) {
    this.gaApp.stores.favorites.main.brands = Array.from(
      new Set([...this.gaApp.stores.favorites.main.brands, ...ids]),
    )
  }

  /**
   * Создание объекта для получения favorite по ключу
   */
  setBrandsMap(brands) {
    this.gaApp.stores.favorites.main.brandsMap = brands.reduce((acc, item) => {
      acc[item.id] = item.favourite
      return acc
    }, {})
  }

  setItemEx(id) {
    if (!this.gaApp.stores.favorites.main.brandsEx.includes(id)) {
      this.gaApp.stores.favorites.main.brandsEx.push(id)
    }
  }

  setItemsEx(ids) {
    this.gaApp.stores.favorites.main.brandsEx = Array.from(
      new Set([...this.gaApp.stores.favorites.main.brandsEx, ...ids]),
    )
  }

  async executeWithBlocking(id, callback) {
    if (this.gaApp.stores.favorites.brands.busy.has(id)) {
      return undefined
    }

    this.gaApp.stores.favorites.brands.busy.add(id)

    await callback()

    this.gaApp.stores.favorites.brands.busy.delete(id)
  }

  addItem(item, hasNotification) {
    return this.executeWithBlocking(item.id, () =>
      this.gaApp.services.favorites.api.addBrands(item, hasNotification),
    )
  }

  deleteItem(item, hasNotification) {
    return this.executeWithBlocking(item.id, () =>
      this.gaApp.services.favorites.api.deleteBrands(item, hasNotification),
    )
  }

  hasItem(id) {
    return this.items.includes(id)
  }

  /**
   * Добавляем бренд, как "отложенный"
   */
  setItemDeferred(item) {
    if (this.gaApp.features.get('authorizationTypesEnabled')) {
      this.gaApp.services.favorites.localStorage.setLocalStorageAuthBrandDeferredKey(
        item,
      )
    } else {
      this.gaApp.stores.favorites.main.brandDeferred = item
    }
  }

  /**
   * Удаляем "отложенный" бренд
   */
  clearItemDeferred() {
    if (this.gaApp.features.get('authorizationTypesEnabled')) {
      this.gaApp.services.favorites.localStorage.removeLocalStorageAuthBrandDeferredKey()
    } else {
      this.gaApp.stores.favorites.main.brandDeferred = null
    }
  }

  /**
   * Подписываемся на ивенты
   */
  setSubscriptions() {
    // на событие login
    // добавляем "отложенный" бренд в избранное
    const loginUnsubscriber = this.gaApp.eventBus.subscribe(
      'module/auth/login',
      ({ meta } = {}) => {
        const item = this.gaApp.features.get('authorizationTypesEnabled')
          ? this.gaApp.services.favorites.localStorage.getLocalStorageAuthBrandDeferredKey()
          : this.gaApp.stores.favorites.main.brandDeferred
        const isReasonAddToFavorite =
          meta?.reason === AUTH_MODAL_REASON_TO_OPEN.ADD_BRAND_TO_FAVORITES

        if (item && isReasonAddToFavorite) {
          this.addItem(item, true)
          this.gaApp.analytics.modules.brands.onAddBrandToFavorite({
            labelOriginal: item.name,
          })
          // удаляем id из списка добавленных брендов,
          // чтобы на мобильном разрешении бренд отображался в списке "избранные"
          this.gaApp.stores.favorites.brands.addedItems.delete(item.id)
        }

        this.clearItemDeferred()
        loginUnsubscriber()
      },
    )
  }

  /**
   * Перенаправляем на страницу брендов
   */
  redirectToFavorites() {
    this.gaApp.services.app.router.pushToRoute({
      moduleType: 'brands',
      pageType: 'main',
    })
  }
}
