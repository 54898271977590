import isEmpty from 'lodash/isEmpty'

import { SearchProductsEmptyBuilder } from './builders'

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  /**
   * Запрос за категорией
   */
  async getCategory(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.category()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за фильтрами
   */
  async fetchFilters(data = {}, pageType) {
    const { url } = this.endpoints[`${pageType}Filters`]()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за продуктами
   */
  async fetchProducts(data = {}, pageType) {
    const { url } = this.endpoints[`${pageType}Products`]()

    try {
      let response

      if (pageType === 'listing' && isEmpty(data.filters)) {
        response = await this.gaApp.api.request().get(url, { params: data })
      } else {
        response = await this.gaApp.api.request().post(url, data)
      }

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async fetchProductsActual(data = {}) {
    const { url } = this.endpoints.actual()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за продуктами категории
   */
  async getProductsByCategory(data = {}) {
    const { url } = this.endpoints.listingProducts()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за продуктами по массиву sku
   */
  async getProductsBySku(data = {}) {
    const { url } = this.endpoints.productsBySku()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Возвращает пустой объект для поиска продуктов
   */
  getSearchProductsEmpty() {
    return new SearchProductsEmptyBuilder()
  }

  /**
   * Запрос за блоками брендзоны для plp v2
   */
  async getSlotsV2(params = {}) {
    const { url } = this.endpoints.slotsV2()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за блоками брендзоны для plp v3
   */
  async getSlotsV3(params = {}) {
    const { url } = this.endpoints.slotsV3()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Запрос за блоками категории брендзоны для plp
   */
  async getCategorySlots(params = {}) {
    const { url } = this.endpoints.categorySlots()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async checkBrandzone(params = {}) {
    const { url } = this.endpoints.checkBrandzone()

    try {
      const response = await this.gaApp.api.request().get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
