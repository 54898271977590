import { computed, toRefs } from 'vue'

import { BADGE_SIZE } from '../../../constants'

// @vue/component
export default {
  name: 'ga-bnpl-badge-content-installment',

  props: {
    /** Размер беджа */
    size: {
      type: String,
      default: BADGE_SIZE.M,
      validator: (value) => Object.values(BADGE_SIZE).includes(value),
    },
  },

  setup(props) {
    const { size } = toRefs(props)

    const mods = computed(() => ({ size: size.value }))

    return { mods }
  },
}
