import gtm from './gtm'
import mindbox from './mindbox'

class AuthAnalytics {
  constructor(gaApp) {
    this.gaApp = gaApp
    this.core = gaApp.analytics.libs

    this.gtm = gtm(this.core.all.gtm)
    this.mindbox = mindbox(this.core.all.mindbox, this.gaApp)
  }

  // Открытие модального окна
  onOpenAuthModal() {
    this.gtm.onOpenAuthModal()
  }

  // Выбор способа авторизации
  onClickLoginMethod(authorizationType) {
    this.gtm.onClickLoginMethod(authorizationType)
  }

  // Клик по кнопке "Получить код"
  onSubmitSms() {
    this.gtm.onSubmitSms()
  }

  // Клик по кнопке "Подтверждение номера"
  onSubmitTel() {
    this.gtm.onSubmitTel()
  }

  // Клик по ссылке "Отправить код повторно"
  onResubmitSms() {
    this.gtm.onResubmitSms()
  }

  // Пользователь успешно залогинился
  onSuccessLogin(serviceName) {
    this.gtm.onSuccessLogin(serviceName)
    this.mindbox.onSuccessLogin()
  }

  // Пользователь успешно зарегистрировался
  onSuccessSignUp(authorizationType) {
    this.gtm.onSuccessSignUp(authorizationType)
  }

  // Ошибка авторизации
  onError(authorizationType) {
    this.gtm.onError(authorizationType)
  }
}

export const analytics = (gaApp) => {
  return new AuthAnalytics(gaApp)
}
