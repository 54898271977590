import { watch } from 'vue'

import { required, useValidation } from '@ga/validation'

/**
 * Формирует валидацию формы
 *
 * @param {Object} $gaApp - контекст приложения
 * @param {Ref<string>} code - значение codeInternal из формы
 * @param {Ref<Object>} error - объект ошибки сервера
 */
export function useValidate($gaApp, code, error) {
  const rules = {
    code: {
      required: required($gaApp.i18n.t('auth.validations.required')),
    },
  }

  watch(error, (value) => {
    const message = value?.invalidParameters?.verificationToken.localizedMessage
    message && setError('code', message)
  })

  const { validate, getError, hasErrors, resetErrors, setError } =
    useValidation(rules, {
      code,
    })

  return { validate, getError, hasErrors, resetErrors }
}
