<template>
  <svg fill="none" viewBox="0 0 22 22">
    <path
      fill="#fff"
      stroke="#fff"
      stroke-width="1.5"
      d="m10.466 5.276.533.538.533-.538c.454-.458 1.5-1.274 2.746-1.618 1.194-.328 2.569-.231 3.889 1.1 1.32 1.333 1.42 2.726 1.092 3.938-.341 1.262-1.152 2.318-1.606 2.775l-3.978 4.014-2.23 2.25-.446.45-.445-.45-2.23-2.25-3.977-4.014c-.454-.457-1.265-1.514-1.606-2.775-.328-1.212-.23-2.606 1.091-3.938 1.32-1.331 2.694-1.428 3.888-1.1 1.247.344 2.293 1.16 2.747 1.618Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-articles-like-small-filled',
}
</script>
