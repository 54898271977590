import { computed, toRefs, unref } from 'vue'

import { useBem } from '@ga/bem'
import { transitionPropsBuilder } from '@ga/utils'

import { GaIconPlpMinus, GaIconPlpPlus } from '@ga/icons'

import { GaButtonBase } from '@ga/ui-components/button-base'
import { GaIcon } from '@ga/ui-components/icon'

import { useApp } from '~/modules/app'

import { useShakeAnimation } from './scripts/composables'
import { MAX_COUNT, SIZE } from './scripts/const'

// @vue/component
export default {
  name: 'ga-product-card-counter',

  components: {
    GaButtonBase,
    GaIcon,
    GaIconPlpMinus,
    GaIconPlpPlus,
  },

  props: {
    // Количество добавленного продукта
    count: {
      type: Number,
      default: 0,
    },

    // Размер компонента
    size: {
      type: String,
      default: SIZE.S,
      validator: (value) => Object.values(SIZE).includes(value),
    },

    // В состоянии загрузки ли
    loading: {
      type: Boolean,
      default: false,
    },

    // Заблокировано ли добавление продуктов
    disabled: {
      type: Boolean,
      default: false,
    },

    // Максимально возможное количество каунтера
    maxCount: {
      type: Number,
      default: MAX_COUNT,
    },
  },

  emits: ['decrease', 'increase'],

  setup(props, { emit }) {
    const { appWithHover } = useApp()

    const b = useBem()

    const { count, size, loading, disabled, maxCount } = toRefs(props)

    // Нужно для того, что бы запустить анимацию тряски кнопки добавления
    const { isAnimating, runAnimation, onAnimationEnd } = useShakeAnimation()

    // Нужно для того, что бы запретить добавлять продукты в корзину
    // Удалять продукты не запрещается
    const disabledInternal = computed(() => {
      return (
        unref(disabled) || unref(loading) || unref(count) >= unref(maxCount)
      )
    })

    const mods = computed(() => ({
      size: unref(size),
      touch: unref(appWithHover) ? 'off' : 'on',
      loading: unref(loading),
      animating: unref(isAnimating),
      disabled: unref(disabledInternal),
    }))

    // Стили перехода между скрытым и раскрытым состоянием
    const transitionProps = computed(() => transitionPropsBuilder(b)('in-out'))

    const onDecreaseClick = () => {
      emit('decrease')
    }

    const onIncreaseClick = () => {
      // Если кнопка добавления недоступна, то запускаем анимацию тряски
      if (unref(disabled) || unref(count) >= unref(maxCount)) {
        return runAnimation()
      }

      emit('increase')
    }

    return {
      mods,

      onAnimationEnd,

      transitionProps,

      onDecreaseClick,
      onIncreaseClick,
    }
  },
}
