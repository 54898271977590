import '../static/_static/329fcfb0-563e-11ef-b57a-fda7bc000000.js'

export default (context) => {
  // Блокируем дальнейшую загрузку пока не инициализируем GUN
  return new Promise((resolve) => {
    // проверяем домены
    const isMainDomain = window.location.host.split('.')[0] === 'goldapple'
    const isStageDomain = window.location.host.split('.')[0] === 'stage'
    const allowedDomains = isMainDomain || isStageDomain

    if (context.$gaApp.config.get('dev') || !allowedDomains) {
      return resolve()
    }

    const gunApiPath = '/front/api/event'
    const originalOpen = XMLHttpRequest.prototype.open
    let timerId = null

    const customResolve = () => {
      // Восстанавливаем оригинальный метод
      if (XMLHttpRequest.prototype.open !== originalOpen) {
        XMLHttpRequest.prototype.open = originalOpen
      }
      clearTimeout(timerId)
      resolve()
    }

    try {
      // В зависимости от домена, строим строку cid
      const cid = (() => {
        // ru, kz, by, qa
        const tld = window.location.host.split('.').pop()

        /**
         * w-goldapple-ru-t
         * w-goldapple-by-t
         * w-goldapple-kz-t
         * w-goldapple-qa-t
         */
        if (isStageDomain) return `w-goldapple-${tld}-t`

        /**
         * w-goldapple-ru
         * w-goldapple-by
         * w-goldapple-kz
         * w-goldapple-qa
         */
        return `w-goldapple-${tld}`
      })()

      timerId = setTimeout(() => {
        const errorApm = new Error(`Ошибка инициализации GUN за 15 секунды`)
        errorApm.name = 'GUN_INIT_TIMEOUT_ERROR'

        context.app.$apm.captureError(errorApm)
        customResolve()
      }, 15000)

      // Перехватываем ошибки api GUN
      XMLHttpRequest.prototype.open = function (method, url, ...args) {
        if (typeof url === 'string' && url.includes(gunApiPath)) {
          this.addEventListener('load', function () {
            if (this.status >= 400) {
              const error = {
                status: this.status,
                responseText: this.responseText,
              }

              const errorApm = new Error(`Ошибка инициализации GUN api`)
              errorApm.name = `GUN_INIT_API_ERROR`
              errorApm.errorData = JSON.stringify(error)
              context.app.$apm.captureError(errorApm)

              customResolve()
            }
          })
        }

        return originalOpen.apply(this, [method, url, ...args])
      }

      // Метод успеха выполнения апи запроса gun
      window.gibOnFlowResponse = () => {
        window.gibOnFlowResponse = () => {}
        customResolve()
      }

      // Инит скрипта gun
      window.gib.init({
        cid,
        backUrl: gunApiPath,
        gafUrl: '//ru.id.facct.ru/id.html',
      })

      // Устанавливаем данные пользователя в GUN
      const setUserIdentity = () => {
        if (!window.gib) return

        const { data, isAuthorized } = context.$gaApp.stores.user.main

        const userId = data?.id ?? null
        const userSessionId = context.$gaApp.cookies.get('ga-session-id')
        const userAuthStatus = isAuthorized
          ? window.gib.IS_AUTHORIZED
          : window.gib.IS_GUEST

        window.gib.setIdentity(userId)
        window.gib.setSessionID(userSessionId)
        window.gib.setAuthStatus(userAuthStatus)
      }

      // Сбрасываем данные пользователя в GUN
      const resetUserIdentity = () => {
        if (!window.gib) return

        const userId = null
        const userSessionId = context.$gaApp.cookies.get('ga-session-id')
        const userAuthStatus = window.gib.IS_GUEST

        window.gib.setIdentity(userId)
        window.gib.setSessionID(userSessionId)
        window.gib.setAuthStatus(userAuthStatus)
      }

      // Устанавливаем данные пользователя на этапе инита
      const unsubscribeInitial = context.$gaApp.eventBus.subscribe(
        'module/app/router/page-created',
        () => {
          setUserIdentity()
          unsubscribeInitial()
        },
      )

      // Подписываемся на события login/logout
      context.$gaApp.eventBus.subscribe('module/auth/login', setUserIdentity)
      context.$gaApp.eventBus.subscribe('module/auth/logout', resetUserIdentity)
    } catch (error) {
      console.log('Ошибка инициализации GUN', error)

      const errorApm = new Error(`Ошибка инициализации GUN`)
      errorApm.name = 'GUN_INIT_ERROR'
      errorApm.errorData = JSON.stringify(error)

      context.app.$apm.captureError(errorApm)

      customResolve()
    }
  })
}
