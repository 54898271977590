import { computed } from 'vue'

import { scrollLockScrollable } from '@ga/shared-directives'

import {
  GaIconAdditionalBackArrow,
  GaIconAdditionalCrossSmall,
  GaIconPrimarySearch,
} from '@ga/icons'

import { GaAction } from '../../../action'
import { GaIcon } from '../../../icon'
import { GaModal } from '../../../modal/modal'
import { GaModalSwipe } from '../../../modal-swipe'
import { GaScroll } from '../../../scroll'
import { GaSelectListBase } from '../../../select-list-base'

export const VIEW = {
  SWIPE: 'swipe',
  MODAL: 'modal',
  DROPDOWN: 'dropdown',
}

// @vue/component
export default {
  name: 'ga-select-list',

  components: {
    GaAction,
    GaIcon,
    GaModal,
    GaScroll,
    GaSelectListBase,
    GaIconAdditionalBackArrow,
    GaIconAdditionalCrossSmall,
    GaIconPrimarySearch,
  },

  directives: {
    scrollLockScrollable,
  },

  inheritAttrs: false,

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },

    searchQuery: {
      type: String,
      default: '',
    },

    withModal: {
      type: Boolean,
      default: false,
    },

    withFullscreenModal: {
      type: Boolean,
      default: false,
    },

    maxListHeight: {
      type: Number,
      required: true,
    },

    maxSwipeModalHeight: {
      type: [String, Number],
      default: 'auto',
    },

    modalZIndex: {
      type: Number,
      default: 5000,
    },

    label: {
      type: String,
      default: '',
    },

    testIdSelectOption: {
      type: String,
      default: '',
    },

    testIdSelectOptions: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const withSwipeModal = computed(
      () => props.withModal && !props.withFullscreenModal,
    )

    return {
      withSwipeModal,
    }
  },

  computed: {
    root() {
      return this.withModal
        ? {
            component: this.withFullscreenModal ? GaModal : GaModalSwipe,
            class: null,
            attrs: {
              'class-name': this.b({
                modal: this.withFullscreenModal,
                swipe: this.withSwipeModal,
              }),

              height: this.maxSwipeModalHeight,
              opened: this.active,

              'no-close-action': true,
              'no-scroll': this.withFullscreenModal,
              fullwidth: true,
              'z-index': this.modalZIndex,
              initialFocus: () => this.$refs.modalSearchControl,
            },
            listeners: {
              toggle: this.onModalToggle,

              'start-open': this.onModalStartOpen,
              'finish-close': this.onModalFinishClose,
              'start-close': this.onModalStartClose,
              'finish-open': this.onModalFinishOpen,
            },
          }
        : {
            component: GaScroll,
            class: this.b({ scroll: true }),
            attrs: {
              faded: true,
              'max-height': this.maxListHeight,
            },
          }
    },

    inner() {
      return this.withFullscreenModal
        ? {
            class: this.b('inner'),
            listeners: {
              touchstart: this.onModalInnerTouchStart,
            },
          }
        : {
            class: null,
            listeners: {},
          }
    },
  },

  watch: {
    searchQuery() {
      this.scrollToTop()
    },
  },

  methods: {
    onListActivation({ el }) {
      if (this.withModal) {
        return
      }

      const options = { instant: true }

      this.scrollToElement(el, options)
    },

    onListKeyboardHover({ el }) {
      this.scrollToElement(el)
    },

    onModalToggle(value) {
      if (value) {
        return
      }

      this.emitCancel()
    },

    onModalStartOpen() {
      this.$emit('modal-start-open')
      this.scrollToTop()
    },

    onModalFinishClose() {
      this.$emit('modal-finish-close')
      this.emitSearchQueryUpdate('')
    },

    onModalStartClose() {
      this.$emit('modal-start-close')
    },

    onModalFinishOpen() {
      this.$emit('modal-finish-open', {
        isSwipeModal: this.withSwipeModal,
      })
    },

    onModalInnerTouchStart(event) {
      if (!this.withModal) {
        return
      }

      const { target } = event
      const { modalSearch, modalSearchControl } = this.$refs

      if (modalSearch.contains(target)) {
        return
      }

      modalSearchControl.blur()
    },

    onModalHeaderBackClick() {
      this.emitCancel()
    },

    onModalSearchInput(event) {
      this.emitSearchQueryUpdate(event.target.value)
    },

    onModalSearchClearClick() {
      this.emitSearchQueryUpdate('')

      this.$refs.modalSearchControl.focus()
    },

    emitCancel() {
      this.$emit('cancel')
    },

    emitSearchQueryUpdate(value) {
      this.$emit('update:search-query', value)
    },

    scrollToElement(el, options) {
      const position = this.getScrollPosition(el)

      this.updateScroll(position, options)
    },

    scrollToTop() {
      this.updateScroll(0, { instant: true })
    },

    getScrollPosition(el) {
      const { root } = this.$refs

      const optionOffset = el.offsetTop
      const optionHalfHeight = el.offsetHeight / 2

      const rootHalfHeight = root.$el.offsetHeight / 2

      return optionOffset + optionHalfHeight - rootHalfHeight
    },

    updateScroll(position, options) {
      if (this.withModal) {
        const { inner } = this.$refs

        if (inner) {
          inner.scrollTop = position
        }
      } else {
        const { root } = this.$refs

        root.updateScroll(position, options)
      }
    },
  },
}
