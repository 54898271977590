import { MENU_ITEM_NAMES, MENU_ITEM_STATES } from '../../constants'

export class MenuService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getState(id) {
    const isActive = id === this.gaApp.stores.customer.main.menu.activeItem
    const isSelected = id === this.gaApp.stores.customer.main.menu.selectedItem

    if (isSelected) {
      return MENU_ITEM_STATES.SELECTED
    } else if (isActive) {
      return MENU_ITEM_STATES.ACTIVE
    }
    return MENU_ITEM_STATES.DEFAULT
  }

  prepareMenu() {
    const menu = {
      main: [
        {
          id: MENU_ITEM_NAMES.ORDERS,
          name: this.gaApp.i18n.t('customer.menu.orders.name'),
          link: this.gaApp.i18n.t('customer.menu.orders.link'),
          state: this.getState(MENU_ITEM_NAMES.ORDERS),
          isNuxt: true,
        },
        {
          id: MENU_ITEM_NAMES.FAVORITES,
          name: this.gaApp.i18n.t('customer.menu.favorites.name'),
          link: this.gaApp.i18n.t('customer.menu.favorites.link'),
          state: this.getState(MENU_ITEM_NAMES.FAVORITES),
          isNuxt: true,
        },
        {
          id: MENU_ITEM_NAMES.GIFTCARDS,
          name: this.gaApp.i18n.t('customer.menu.giftcards.name'),
          link: this.gaApp.i18n.t('customer.menu.giftcards.link'),
          state: this.getState(MENU_ITEM_NAMES.GIFTCARDS),
          isNuxt: true,
        },
      ],
      additional: [
        {
          id: MENU_ITEM_NAMES.SETTINGS,
          name: this.gaApp.i18n.t('customer.menu.settings.name'),
          link: this.gaApp.i18n.t('customer.menu.settings.link'),
          state: this.getState(MENU_ITEM_NAMES.SETTINGS),
          isNuxt: true,
        },
      ],
      activeItem: this.gaApp.stores.customer.main.menu.activeItem,
    }

    if (this.gaApp.features.get('profileMenuFaqLink')) {
      menu.additional.push({
        id: MENU_ITEM_NAMES.FAQ,
        name: this.gaApp.i18n.t('customer.menu.faq.name'),
        link: this.gaApp.i18n.t('customer.menu.faq.link'),
        state: this.getState(MENU_ITEM_NAMES.FAQ),
        isNuxt: true,
      })
    }

    if (this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      menu.additional.unshift({
        id: MENU_ITEM_NAMES.ADDRESSES,
        name: this.gaApp.i18n.t('customer.menu.addresses.name'),
        link: this.gaApp.i18n.t('customer.menu.addresses.link'),
        state: this.getState(MENU_ITEM_NAMES.ADDRESSES),
        isNuxt: true,
      })
    }

    return menu
  }

  getMenu() {
    this.gaApp.stores.customer.main.menu = this.prepareMenu()
  }

  setActiveMenuItem(pageType) {
    switch (pageType) {
      case 'orders':
        this.gaApp.stores.customer.main.menu.activeItem = MENU_ITEM_NAMES.ORDERS
        break
      case 'favorites':
        this.gaApp.stores.customer.main.menu.activeItem =
          MENU_ITEM_NAMES.FAVORITES
        break
      case 'giftcards':
        this.gaApp.stores.customer.main.menu.activeItem =
          MENU_ITEM_NAMES.GIFTCARDS
        break
      case 'settings':
        this.gaApp.stores.customer.main.menu.activeItem =
          MENU_ITEM_NAMES.SETTINGS
        break
      case 'addresses':
        this.gaApp.stores.customer.main.menu.activeItem =
          MENU_ITEM_NAMES.ADDRESSES
        break
      default:
        this.gaApp.stores.customer.main.menu.activeItem = null
    }
  }

  setSelectedMenuItem(pageType) {
    switch (pageType) {
      case 'order':
      case 'products':
        this.gaApp.stores.customer.main.menu.selectedItem =
          MENU_ITEM_NAMES.ORDERS
        break
      default:
        this.gaApp.stores.customer.main.menu.selectedItem = null
    }
  }
}
