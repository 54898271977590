<template>
  <svg
    fill="none"
    stroke="none"
    transform="scale(.537)"
    transform-origin="center"
    viewBox="0 0 30 30"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      d="M2.609 2.609C.5 4.718.5 8.112.5 14.9v1.2c0 6.788 0 10.182 2.109 12.291S8.112 30.5 14.9 30.5h1.2c6.788 0 10.182 0 12.291-2.109S30.5 22.888 30.5 16.1v-1.2c0-6.788 0-10.182-2.109-12.291S22.888.5 16.1.5h-1.2C8.112.5 4.718.5 2.609 2.609Zm2.954 7.016c.162 7.8 4.062 12.488 10.9 12.488h.387V17.65c2.513.25 4.412 2.088 5.175 4.463h3.55c-.975-3.55-3.538-5.513-5.138-6.263 1.6-.925 3.85-3.175 4.388-6.225H21.6c-.7 2.475-2.775 4.725-4.75 4.938V9.625h-3.225v8.65c-2-.5-4.525-2.925-4.637-8.65H5.563Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-services-vk',
}
</script>
