import { RECEIVE_STEPS } from '../../constants/receive'

export const state = () => ({
  foreground: {},
  background: {},
  designColor: '',
  denomination: {},
  felicitation: {},
  cardNumber: '',
  qrCode: '',
  cvc: '',
  validUntil: '',
  video: {},
  orderType: '',
  isForSelf: false,

  // для v2
  // переводы для страницы
  content: {
    title: '',
    tab1: '',
    tab2: '',
    cardTitle: '',
    cvcTitle: '',
    qrTitle: '',
    qrText: '',
    qrButton: '',
    qrCvcModalText: '',
    ctaButton: '',
    aiText: '',
    qrModalCloseButton: '',
  },
  // блок FAQ
  faq: {},
  // данные для показа ссылок на сторы с приложением ЗЯ
  additionalSettings: {
    iosSettings: {
      buttonIconUrl: {},
    },
    androidSettings: {
      buttonIconUrl: {},
    },
    storeSection: {
      mobile: {
        text: '',
        imageUrls: [],
      },
      desktop: {
        text: '',
        imageUrls: [],
      },
    },
  },
  // тип карты по дизайну
  cardType: null,

  // для переключения языков
  currentStep: RECEIVE_STEPS.CARD_PACKED,
})
