import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

/**
 * Composable для определения типа ориентации устройства.
 * @returns {Object} Объект, содержащий информацию о типе ориентации устройства.
 * @property {Ref<Boolean>} isPortrait - Признак вертикального ориентирования.
 * @property {Ref<Boolean>} isLandscape - Признак горизонтального ориентирования.
 * @property {Ref<Boolean>} isMobile - Признак мобильного устройства.
 * @property {Ref<Boolean>} isMobilePortrait - Признак вертикального ориентирования на мобильном устройстве.
 * @property {Ref<Boolean>} isMobileLandscape - Признак горизонтального ориентирования на мобильном устройстве.
 * @property {Ref<Boolean>} isTablet - Признак планшета.
 * @property {Ref<Boolean>} isTabletPortrait - Признак вертикального ориентирования на планшете.
 * @property {Ref<Boolean>} isTabletLandscape - Признак горизонтального ориентирования на планшете.
 * @property {Ref<Boolean>} isDesktopPortrait - Признак вертикального ориентирования на десктопе.
 * @property {Ref<Boolean>} isDesktopLandscape - Признак горизонтального ориентирования на десктопе.
 * @property {Ref<Boolean>} isDesktop - Признак десктопа.
 */
export const useDeviceOrientationType = () => {
  const { $gaApp } = useContext()
  const deviceOrientationType = computed(() => {
    return $gaApp.stores.app.main.deviceOrientationType
  })

  const isPortrait = computed(() => deviceOrientationType.value.isPortrait)
  const isLandscape = computed(() => deviceOrientationType.value.isLandscape)

  const isMobile = computed(() => deviceOrientationType.value.isMobile)
  const isMobilePortrait = computed(
    () => deviceOrientationType.value.isMobilePortrait,
  )
  const isMobileLandscape = computed(
    () => deviceOrientationType.value.isMobileLandscape,
  )

  const isTablet = computed(() => deviceOrientationType.value.isTablet)
  const isTabletPortrait = computed(
    () => deviceOrientationType.value.isTabletPortrait,
  )
  const isTabletLandscape = computed(
    () => deviceOrientationType.value.isTabletLandscape,
  )

  const isDesktop = computed(() => deviceOrientationType.value.isDesktop)
  const isDesktopPortrait = computed(
    () => deviceOrientationType.value.isDesktopPortrait,
  )
  const isDesktopLandscape = computed(
    () => deviceOrientationType.value.isDesktopLandscape,
  )

  return {
    isPortrait,
    isLandscape,

    isMobile,
    isMobilePortrait,
    isMobileLandscape,

    isTablet,
    isTabletPortrait,
    isTabletLandscape,

    isDesktopPortrait,
    isDesktopLandscape,
    isDesktop,

    deviceOrientationType,
  }
}
