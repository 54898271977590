export const ORIENTATION = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
}

/**
 * утилита для определения текущей ориентации экрана
 *
 * @return {Object} Объект, содержащий две функции: `isPortrait` и `isLandscape`.
 */
export const deviceOrientation = () => {
  const getOrientation = () => {
    if (!window) return

    if (window.matchMedia('(orientation: portrait)').matches) {
      return ORIENTATION.PORTRAIT
    }

    if (window.matchMedia('(orientation: landscape)').matches) {
      return ORIENTATION.LANDSCAPE
    }
  }

  const isPortrait = () => {
    return getOrientation() === ORIENTATION.PORTRAIT
  }

  const isLandscape = () => {
    return getOrientation() === ORIENTATION.LANDSCAPE
  }

  return {
    isPortrait,
    isLandscape,
  }
}
