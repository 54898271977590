export class ApiLikesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получить данные по лайкам для заданной статьи и заданного пользователя/устройства
   *
   * @param {ArticleLikeDto} data
   * @returns {Promise<UserLikesInfo>} - кол-во лайков у статьи и проставлен ли лайк текущим
   * пользователем
   */
  async getArticleLikesInfo({ articleId, userId }) {
    try {
      const { data } =
        await this.gaApp.repositories.articles.likes.getArticleLikesInfo({
          articleId,
          userId,
        })

      return data
    } catch (error) {
      return {
        liked: false,
        likesCount: 0,
      }
    }
  }

  /**
   * Установить лайк для заданной статьи
   * @param {ArticleLikeDto} data
   * @returns {Promise<LikesInfo>} - кол-во лайков у статьи после установки лайка текущим пользователем
   */
  setArticleLike({ articleId, userId }) {
    try {
      return this.gaApp.repositories.articles.likes.setArticleLike({
        articleId,
        userId,
      })
    } catch (error) {
      console.error(error.message)
    }
  }

  /**
   * Установить лайк для заданной статьи
   * @param {ArticleLikeDto} data
   * @returns {Promise<LikesInfo>} - кол-во лайков у статьи после установки лайка текущим пользователем
   */
  removeArticleLike({ articleId, userId }) {
    try {
      return this.gaApp.repositories.articles.likes.removeArticleLike({
        articleId,
        userId,
      })
    } catch (error) {
      console.error(error.message)
    }
  }
}
