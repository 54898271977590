import isEmpty from 'lodash/isEmpty'

import { NOTIFICATION_MODAL_LOCAL_STORAGE_KEY } from '../../constants'

export class NotificationModalService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Устанавливает данные для модального окна уведомлений
   * @param {Object} data - Объект с данными для модального окна уведомлений
   */
  setData(data) {
    this.gaApp.stores.customer.notificationModal.data = data
  }

  /**
   * Очищает данные для модального окна уведомлений
   */
  deleteData() {
    this.setData({})
  }

  /**
   * Сохраняет полученные данные для модального окна уведомлений в localStorage
   */
  saveDataToLocalStorage() {
    this.gaApp.services.app.browserStorage.local.set(
      NOTIFICATION_MODAL_LOCAL_STORAGE_KEY,
      this.gaApp.stores.customer.notificationModal.data,
    )
  }

  /**
   * Инициализирует данные для модального окна уведомлений из localStorage
   */
  initDataFromLocalStorage() {
    // Проверяем данные для модалки в localStorage
    const data =
      this.gaApp.services.app.browserStorage.local.get(
        NOTIFICATION_MODAL_LOCAL_STORAGE_KEY,
      ) || {}

    if (isEmpty(data)) {
      return
    }

    // Сохраняем данные из localStorage в стор
    this.gaApp.services.customer.notificationModal.setData(data)

    // Удаляем данные из localStorage
    this.gaApp.services.app.browserStorage.local.remove(
      NOTIFICATION_MODAL_LOCAL_STORAGE_KEY,
    )
  }

  /**
   * Открывает модальное окно уведомлений
   */
  openModal() {
    this.gaApp.stores.customer.notificationModal.opened = true
  }

  /**
   * Закрывает модальное окно уведомлений
   */
  closeModal() {
    this.gaApp.stores.customer.notificationModal.opened = false
  }

  /**
   * Метод открытия модального окна после авторизации
   */
  onAuth() {
    // Проверяем, разрешено ли показывать модалку
    if (!this.gaApp.stores.customer.notificationModal.isAllowedToShow) {
      return
    }

    // Проверяем если ожидается форс апдейт приложения
    if (this.gaApp.stores.app.main.hasForceUpdate) {
      // Сохраняем данные для модалки в localStorage
      this.saveDataToLocalStorage()
      return
    }

    this.gaApp.ctx.$doubleRAF(() => {
      /** Определяем событие для подписки, в зависимости от изменения маршрута
       * Если маршрут изменился, используем событие 'page-mounted', если нет - 'finish-close/auth'
       */
      const eventName = this.gaApp.stores.app.main.routeChange
        ? 'module/app/router/page-mounted'
        : 'module/modal/event/finish-close/auth'

      // Подписываемся на событие
      const unsubscribe = this.gaApp.eventBus.subscribe(eventName, () => {
        // Открываем модалку при событии
        this.openModal()

        unsubscribe()
      })
    })
  }

  /**
   * Метод открытия модального окна после загрузки приложения
   */
  onAppInit() {
    // Проверяем ФТ или наличие другой открытой модалки
    if (
      this.gaApp.features.get('showLoyaltyCardScreens') &&
      this.gaApp.stores.user.main.isAuthorized &&
      !this.gaApp.stores.modal.main.hasModals
    ) {
      // Проверяем данные в localStorage
      this.initDataFromLocalStorage()
    }

    // Проверяем, разрешено ли показывать модалку
    if (this.gaApp.stores.customer.notificationModal.isAllowedToShow) {
      // Открываем модалку
      this.gaApp.ctx.$doubleRAF(() => {
        this.openModal()
      })
    }
  }
}
