export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getDataMainPage(params) {
    const { url } = this.endpoints.getDataMainPage()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getDataMainConfig(params) {
    const { url } = this.endpoints.getDataMainConfig()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getDataMenu(params) {
    const { url } = this.endpoints.getDataMenu()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getDataAboutPage(params) {
    const { url } = this.endpoints.getDataAboutPage()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getConfig() {
    const { url } = this.endpoints.getConfig()

    try {
      // POST на bff решает проблему кэширования получения конфига ЭПК через GET /electronic/configuration
      const response = await this.gaApp.api.request().post(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  /**
   * Получает данные ЭПК для лендинга
   * @param {String} hashCardNumber - хэш карты
   * @param {String} source - показатель откуда перешел пользователь (sms, whatsapp и т.д.)
   */
  async getReceiveCard(hashCardNumber, source) {
    const { url } = this.gaApp.features.get('giftCardsReceiveCardV2')
      ? this.endpoints.receiveCardV2()
      : this.endpoints.receiveCard() // TODO - удалить после удаления ФТ giftCardsReceiveCardV2

    const config = {
      params: { hashCardNumber, source },
    }

    try {
      const response = await this.gaApp.api.request().get(url, config)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getReceiveReturnCard(hashCardNumber) {
    const { url } = this.endpoints.receiveReturnCard()
    const config = {
      params: { hashCardNumber },
    }

    try {
      const response = await this.gaApp.api.request().get(url, config)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getDenomination(hashCardNumber) {
    const { url } = this.endpoints.getDenomination()
    const config = {
      params: { hashCardNumber },
    }

    try {
      const response = await this.gaApp.api.request().get(url, config)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
