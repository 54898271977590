export const TYPE = {
  GAA: 'ga_analytics',
  GTM_4: 'gtm_4',
}
export const MODULE_TYPES = {
  // TODO нужен рефактор чтобы не было хардкода
  PDP: 'pdp',
  PLP: 'plp',
  BRANDS: 'brands',
  PROMO: 'promo',
}

export const PAGE_TYPES = {
  // TODO нужен рефактор чтобы не было хардкода
  [MODULE_TYPES.PDP]: ['product'],
  [MODULE_TYPES.PLP]: ['listing'],
  [MODULE_TYPES.BRANDS]: ['main'],
  [MODULE_TYPES.PROMO]: ['main'],
  [MODULE_TYPES.PROMO]: ['offer'],
}

export const CARD_ACTION_TYPES = {
  CLIENT_DAYS: 'view_client_days_card',
  PROMO: 'view_action_card',
}

export const ACTIONS = {
  USER_INTERACTION: {
    GA4: 'user_interaction',
  },
}

export const ACTION_TYPES = {
  CLICK: 'click_action',
}

export const EVENT_CATEGORIES = {
  PROMO: 'actions',
  CLIENT_DAYS: 'client_days',
}
