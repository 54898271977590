// import merge from 'lodash/merge'

import customer from '~/modules/customer/locales/kk'
import footer from '~/modules/footer/locales/kk'
import header from '~/modules/header/locales/kk'
import stockists from '~/modules/stockists/locales/kk'
// import stockistsOptions from '~/modules/stockists/locales/en-QA.options'
// import streamerce from '~/modules/streamerce/locales/en'
import user from '~/modules/user/locales/kk'

import layouts from '~/locales/languages/layouts/kk'
import pages from '~/locales/languages/pages/kk'

export default () => {
  return {
    ...customer,
    ...footer,
    ...header,
    // ...merge(stockists, stockistsOptions),
    ...stockists,
    // ...streamerce,
    ...user,

    ...layouts,
    ...pages,
  }
}
