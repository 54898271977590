import { encode } from 'js-base64'

import { APP_EVENT } from '~/modules/app'
import {
  AUTH_MODAL_REASON_TO_OPEN,
  AUTH_MODAL_TYPE_NAMES,
  AUTH_MODAL_TYPES,
} from '~/modules/modal'

import { GRANT_TYPE } from '../constants'
import { buildContentTypeHeader, buildRequestPayload } from '../helpers'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Перезагружает страницу и добавляет в hash страницы #auth
   */
  reloadToAuthUrl() {
    window.location.href += '#auth'
    window.location.reload()
  }

  /**
   * Возвращает признак авторизации
   *
   * true - если авторизован
   * false - если не авторизован
   *
   * Если признак авторизации не совпадает с признаком авторизации в сторе, то выбрасывается ошибка в Кибану
   */
  get isAuthorized() {
    const isAuthorizedByStore = this.gaApp.stores.user.main.isAuthorized

    const { hasAccessToken, hasRefreshToken } =
      this.gaApp.services.auth.core.checkTokens()

    if (isAuthorizedByStore !== hasAccessToken) {
      const error = new Error(
        'Значение куки авторизации не совпадает со значением стора',
      )
      error.errorData = JSON.stringify({
        isAuthorizedByStore,
        hasAccessToken,
        hasRefreshToken,
      })
      error.name = 'auth-is-authorized'
      this.gaApp.services.app.apm.captureError(error)
    }

    return isAuthorizedByStore
  }

  /**
   * очищаем данные авторизации в local storage
   */
  removeAuthLocalStorageData() {
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthModalOpenReason()
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthRedirectUrl()
  }

  /**
   * Метод запроса СМС-кода по номеру телефона
   *
   * @param {object} obj
   * @param {string} obj.phone - Номер телефона, на который отправить СМС
   * @param {string} obj.phoneCode - Код страны, например 'ru'
   * @param {string} obj.captchaToken - каптча-токен
   *
   * @returns {object} response | error
   */
  async sendAuthCode({ phone, phoneCode, captchaToken } = {}) {
    try {
      const reqType = 'json'
      const data = buildRequestPayload('sendCode', reqType, {
        phone,
        phoneCode,
        captchaToken,
        magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
      })
      const config = buildContentTypeHeader(reqType)

      const response = await this.gaApp.services.auth.api.sendCodeV2(
        data,
        config,
      )

      if (response.data) {
        this.gaApp.stores.auth.main.setResendCodeToken(
          response.data.resend_token,
        )

        this.gaApp.stores.auth.main.setTokenOptions({
          grantType: response.data.grant_type,
        })
      }

      return response
    } catch (error) {
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      throw error
    }
  }

  /**
   * Метод повторного запроса СМС-кода
   *
   * @param {object} obj
   * @param {string} obj.phone - Номер телефона, на который отправить СМС
   * @param {string} obj.phoneCode - Код страны, например 'ru'
   * @param {string} obj.captchaToken - каптча-токен
   *
   * @returns {object} response | error
   */
  async resendAuthCode({ phone, phoneCode, captchaToken } = {}) {
    try {
      const resendToken = this.gaApp.stores.auth.main.resendCodeToken

      const reqType = 'json'
      const data = buildRequestPayload('resendCode', reqType, {
        phone,
        phoneCode,
        resendToken,
        captchaToken,
        magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
      })
      const config = buildContentTypeHeader(reqType)

      const response = await this.gaApp.services.auth.api.resendCodeV2(
        data,
        config,
      )

      if (response.data) {
        this.gaApp.stores.auth.main.setResendCodeToken(
          response.data.resend_token,
        )

        this.gaApp.stores.auth.main.setTokenOptions({
          grantType: response.data.grant_type,
        })
      }

      return response
    } catch (error) {
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      throw error
    }
  }

  /**
   * Метод подтверждения СМС-кода
   *
   * @param {object} obj
   * @param {string} obj.code - код из СМС-сообщения
   * @param {string} obj.phone - Номер телефона, куда была отправлен СМС-код
   *
   * @returns {object} response | error
   */
  async login({ code, phone, meta }) {
    const reqType = 'form'

    const grantType = this.gaApp.stores.auth.main.grantType
    const data = buildRequestPayload('loginGrandType', reqType, {
      code,
      phone,
      grantType,
      magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
    })

    const config = buildContentTypeHeader(reqType)

    const response = await this.gaApp.services.auth.api.login(data, config)

    if (response.data) {
      // eslint-disable-next-line camelcase
      const { magento_data = {} } = response.data

      // Запрашиваем данные юзера и устанавливаем
      await this.gaApp.services.user.api.fetchUserInfoFull()

      // Получаем фича тогглы для авторизованного пользователя
      await this.gaApp.services.featuresToggle.main.refresh({ force: true })

      // Устанавливаем для magento данные в localStorage
      // eslint-disable-next-line camelcase
      this.gaApp.services.cache.main.saveUser(magento_data.customer_section)

      // Сбрасываем токен для перезапроса смс-кода
      this.gaApp.stores.auth.main.setResendCodeToken(null)

      // Мержим корзину гостя с корзиной пользователя
      await this.gaApp.services.cart.api.getData()
    }

    this.gaApp.eventBus.publish(APP_EVENT.LOGIN, {
      meta,
    })

    // отправка аналитического события успешной авторизации/регистрации
    this.onLoginAnalytics(response)

    return response
  }

  /**
   * готовит метаданные для обработки события логина
   */
  prepareModalMeta() {
    const reason =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthModalOpenReason()

    return {
      meta: {
        reason,
      },
    }
  }

  /**
   * формирует и публикует событие логина
   */
  publishLoginEvent(modalMeta) {
    this.gaApp.eventBus.publish(APP_EVENT.LOGIN, modalMeta)
  }

  /**
   * публикуем событие логина, очищаем данные local storage
   */
  publishLoginEventWithoutRedirect(modalMeta) {
    this.publishLoginEvent(modalMeta)
    this.gaApp.services.auth.main.removeAuthLocalStorageData()
  }

  /**
   * редиректим на страницу, с которой уходили на внешний сервис авторизации,
   * после этого публикуем событие логина, очищаем данные local storage
   */
  publishLoginEventWithRedirect(modalMeta) {
    const path =
      this.gaApp.services.auth.localStorage.getLocalStorageAuthRedirectUrl() ??
      '/'

    this.gaApp.services.app.router.push(path, () => {
      this.publishLoginEvent(modalMeta)
    })
  }

  /**
   * в зависимости от причины открытия модалки авторизации выполняем дальнейшие действия
   */
  handleModalOpenReason(modalMeta) {
    const reason = modalMeta.meta.reason

    switch (reason) {
      case AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.CART]:
        // пушим на шину событие логина, запуская таким образом дальнейшую логику перехода на чекаут
        this.publishLoginEventWithoutRedirect(modalMeta)
        break
      case AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.PRODUCTS]:
      case AUTH_MODAL_TYPES[AUTH_MODAL_TYPE_NAMES.BRANDS]:
      default:
        // редиректим на страницу, с которой уходили на внешний сервис авторизации
        this.publishLoginEventWithRedirect(modalMeta)
    }
  }

  /**
   * Отправка аналитического события успешного логина или регистрации
   *
   * @param {Object} response
   * @param {string} service
   */
  onLoginAnalytics(response, service) {
    // eslint-disable-next-line camelcase
    const isRegistered = response.data.customer_is_registered
    if (isRegistered) {
      this.gaApp.analytics.modules.auth.onSuccessLogin(service)
    } else {
      this.gaApp.analytics.modules.auth.onSuccessSignUp(service)
    }
  }

  /**
   * Метод подтверждения кода внешнего сервиса авторизации
   *
   * @param {object} obj
   * @param {string} obj.code - код подтверждения внешнего сервиса авторизации
   * @param {string} obj.service - имя сервиса авторизации
   *
   * @returns {object} response | error
   */
  async loginViaExternalService(service) {
    const reqType = 'form'

    const grantType = GRANT_TYPE[service] ?? undefined

    const modalMeta = this.prepareModalMeta()

    if (!grantType) {
      throw new Error('There is no such authorization service among possible')
    }

    const { query } = this.gaApp.route

    const data =
      this.gaApp.services.auth.externalAuthBuilder.buildRequestPayloadByService(
        service,
        query,
      )

    const config = buildContentTypeHeader(reqType)
    const response = await this.gaApp.services.auth.api.login(data, config)

    if (response.data) {
      // eslint-disable-next-line camelcase
      const { magento_data = {} } = response.data

      // Запрашиваем данные юзера и устанавливаем
      await this.gaApp.services.user.api.fetchUserInfoFull()

      // Устанавливаем для magento данные в localStorage
      // eslint-disable-next-line camelcase
      this.gaApp.services.cache.main.saveUser(magento_data.customer_section)

      // Запрашиваем корзину пользователя после авторизации
      await this.gaApp.services.cart.api.getData()
    }

    this.handleModalOpenReason(modalMeta)

    // отправка аналитического события успешной авторизации/регистрации
    this.onLoginAnalytics(response, service)

    // очищаем local storage
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthRedirectUrl()
    this.gaApp.services.auth.localStorage.removeLocalStorageAuthModalOpenReason()

    return response
  }

  /**
   * Метод отправляет запрос на выход из системы
   *
   * @returns {object} response | error
   */
  async logout() {
    try {
      const reqType = 'json'
      const data = buildRequestPayload('logout', reqType, {
        magento: !this.gaApp.features.get('cartPlaidApi'), // при включенной плед корзине, отправляем флаг для отключения мага авторизации
      })

      this.gaApp.services.auth.core.resetTokens()

      const response = await this.gaApp.services.auth.api.logout(data)

      this.gaApp.eventBus.publish('module/auth/logout')

      return response
    } catch (error) {
      this.gaApp.services.auth.core.resetTokens()

      this.gaApp.eventBus.publish('module/auth/logout')

      await new Promise((resolve) => setTimeout(resolve, 1000))

      this.reloadToAuthUrl()

      throw error
    }
  }

  async logoutWithClearData() {
    await this.logout()
    this.gaApp.services.user.main.resetUser()
    this.gaApp.services.auth.modal.reset()
  }

  onAuthFailed(redirect, to) {
    // сбрасываем стор модалки авторизации
    this.gaApp.services.auth.modal.reset()

    // сохраняем redirect URL в local storage
    if (this.gaApp.features.get('authorizationTypesEnabled')) {
      this.gaApp.services.auth.localStorage.setLocalStorageAuthRedirectUrl(
        to.path,
      )
    }

    window.requestAnimationFrame(() => {
      const payload = redirect
        ? {
            redirect: 'page',
            callbacks: {
              redirect: () => {
                setTimeout(redirect, 1000) // добавляем задержку на отработку подписчиков события логина
              },
            },
          }
        : {}

      // Открываем модалку
      window.requestAnimationFrame(() => {
        this.gaApp.services.modal.main.openSuperModalAuth({
          reason: AUTH_MODAL_REASON_TO_OPEN.RELOGIN,
          ...payload,
        })

        // Если модалка не открылась, то очищаем юзера
        this.gaApp.ctx.$doubleRAF(() => {
          if (!this.gaApp.stores.modal.main.hasOpenedAuthModal) {
            this.gaApp.services.user.main.resetUserAndRefresh()
          }
        })
      })
    })
  }

  openModal(settings) {
    const payload = { push: true, ...settings }

    this.gaApp.services.auth.modal.reset()

    this.gaApp.services.modal.main.openSuperModalAuth(payload)
  }

  async checkRouteAuth(to, _, next) {
    const onAccessDenied = () => {
      next(false)

      this.gaApp.services.auth.core.resetTokens()
      this.gaApp.services.auth.main.onAuthFailed(next, to)
    }

    // Если это роут не требующий авторизации,
    // то просто пропускаем юзера
    if (!this.gaApp.services.app.router.isAuthRequired(to.path)) {
      return next()
    }

    if (!this.isAuthorized) {
      return onAccessDenied()
    }

    // Если юзер авторизован, то проверяем его токены
    const { hasAccessToken, hasRefreshToken } =
      this.gaApp.services.auth.core.checkTokens()

    try {
      // Если нет access'a, но есть рефреш, то пробуем рефрешнуть
      if (!hasAccessToken && hasRefreshToken) {
        await this.gaApp.services.auth.core.doRefreshTokens()

        await this.gaApp.services.user.api.fetchUserInfoFull()

        return next()
      }

      // Если нет ни access'a, ни refresh'a, то не пропускаем юзера
      if (!hasAccessToken && !hasRefreshToken) {
        return onAccessDenied()
      }

      // Запросили полное инфо юзера
      await this.gaApp.services.user.api.fetchUserInfoFull()

      return next()
    } catch (error) {
      return onAccessDenied()
    }
  }

  /**
   * При авторизаци передаем данные для мержка сессии гостя и пользователя
   */
  getUserData() {
    const data = {}
    const cartId = this.gaApp.services.cart.guest.getId()

    // Если есть айди гостевой корзины и это не Plaid корзина, то добавляем айди корзины
    if (cartId && !this.gaApp.features.get('cartPlaidApi')) {
      data.quoteId = cartId
    }

    // Если выключен адресный блок, то добавляем айди адреса
    if (!this.gaApp.stores.app.common.toggle.addressBlockEnabled) {
      data.geolocationId =
        this.gaApp.services.location.main.getDeliveryAddress().id
    }

    const encodedAuthData = encode(JSON.stringify(data))

    return {
      'X-Magento-Auth-Data': encodedAuthData,
      'X-Magento-Debug-Data': encodedAuthData,
    }
  }
}
