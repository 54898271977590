import { getId, trimObjectStrings } from '@ga/utils'

import { MOSCOW_PICKUP_DATA } from '../constants'

export class PlasticService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Формирование данных заказа для КППК
   */
  get order() {
    const formData = new FormData()
    const { details, counterparty, contacts } =
      this.gaApp.stores.giftCardsB2b.plastic

    const dto = {
      ...trimObjectStrings(details),
      ...trimObjectStrings(counterparty),
      ...trimObjectStrings(contacts),
      deliveryDate: details.deliveryDate.toISOString(),
      qty: parseInt(details.qty),
      cardType: 'real',
    }

    Object.entries(dto).forEach(([name, value]) => {
      if (name === 'requisites') {
        formData.append(name, value, value.name)

        return undefined
      }

      formData.append(name, value)
    })

    return formData
  }

  /** Ниже всё для КППК (новый флоу) */

  /** Текущий идентификатор заказа */
  get requestId() {
    return (
      this.gaApp.stores.giftCardsB2b.plasticNew.requestIdCache[
        this.gaApp.stores.giftCardsB2b.plasticNew.stringifiedForm
      ] || this.generateRequestId()
    )
  }

  /** Данные заказа */
  get orderV2() {
    return {
      ...this.gaApp.stores.giftCardsB2b.plasticNew.form,
      requestId: this.requestId,
    }
  }

  /**
   * Генерация идентификатора заказа
   * В requestIdCache хранится связка requestId:состояние формы.
   * При создании заказа (получаем requestId) идет поиск (requestIdCache) по состоянию формы.
   * Если уже такое состояние заказа было, то возвращается ранее сгенерированный requestId.
   * Если при создании заказа такого состояния еще не было, то создается новый requestId и связка.
   *
   * Таким образом, если пользователь уже отправлял заказ с одинаковым содержимым, мы это узнаем по requestId.
   * @returns {string}
   */
  generateRequestId() {
    const newId = getId()

    this.gaApp.stores.giftCardsB2b.plasticNew.requestIdCache = {
      ...this.gaApp.stores.giftCardsB2b.plasticNew.requestIdCache,
      [this.gaApp.stores.giftCardsB2b.plasticNew.stringifiedForm]: newId,
    }

    return newId
  }

  /** Установка статуса загрузки */
  setPlasticV2ConfigLoading(value) {
    this.gaApp.stores.giftCardsB2b.plasticNew.config.isLoading = value
  }

  /**
   * Получение конфигурации КППК v2
   * @param {string} cityId идентификатор города
   */
  /**
   * Получение конфигурации КППК v2
   * @param {string} cityId идентификатор города
   */
  async getPlasticV2Config(cityId) {
    try {
      this.setPlasticV2ConfigLoading(true)

      /**
       *  Если указан идентификатор города (смена города в модалке),
       *  то получаем конфигурацию по выбранному городу
       */
      if (cityId) {
        return await this.fetchPlasticV2Config(cityId)
      }

      /** Получение дефолтной конфигурации */
      const baseConfigData = await this.fetchPlasticV2Config()
      if (!baseConfigData) return

      /** Определение координат пользователя
       *  - null, если не удалось определить
       */
      const addressByCoordinates =
        await this.gaApp.services.giftCardsB2b.location.getUserGeoAddress()
      const cityIdByCoordinates = addressByCoordinates?.address?.cityId

      /** Установка базовых данных конфига - городов */
      this.setBaseConfigData(baseConfigData?.cities, cityIdByCoordinates)

      /** Определение наличия города в базовом конфиге */
      const cityIdInConfig =
        this.gaApp.services.giftCardsB2b.location.getCityIdInConfig(
          cityIdByCoordinates,
        )

      const isDefaultCityId =
        this.gaApp.stores.giftCardsB2b.plasticNew.defaultCityId ===
        cityIdInConfig

      /** Если
       * - город не определен
       * - города нет в конфиге
       * - город по гео равен дефолтному,
       * то отображаем данные дефолтному городу
       */
      if (!cityIdByCoordinates || !cityIdInConfig || isDefaultCityId) {
        this.setPlasticV2Data(baseConfigData)
        return baseConfigData
      }

      /** Получение конфигурации по определенному городу пользователя */
      return await this.fetchPlasticV2Config(cityIdInConfig)
    } catch (error) {
      const getConfigError = new Error(error?.message)

      getConfigError.name = 'gift-b2b-plastic-v2-config-error'
      getConfigError.errorData = JSON.stringify(error)

      this.gaApp.services.app.apm.captureError(getConfigError)

      /** Переход на страницу с ошибкой ввиду отсутствия данных для создания заявки */
      this.gaApp.redirectError({ statusCode: 500, message: error })
    } finally {
      /**
       * Для избежания анимации единственного открытого набора
       * (await nextTick не решает проблему)
       */
      setTimeout(() => {
        this.setPlasticV2ConfigLoading(false)
      }, 0)
    }
  }

  /**
   * Запрос на получение конфигурации КППК v2 и обработка ошибки
   * @param {string} cityId идентификатор города
   */
  async fetchPlasticV2Config(cityId) {
    const data =
      await this.gaApp.services.giftCardsB2b.api.getPlasticV2Config(cityId)

    if (!data?.cards?.length) {
      throw new Error('Отсутствуют данные конфигурации пластиковых карт')
    }

    /** Установка данных в стор только для выбранного пользователем города */
    if (cityId) this.setPlasticV2Data(data)

    return data
  }

  /**
   * Установка базовых данных конфига - городов (v2)
   * @param {Array} cities массив городов
   * @param {string} cityId идентификатор города
   */
  setBaseConfigData(cities, cityId) {
    /** Установка городов, доступных для заказа КППК */
    this.gaApp.services.giftCardsB2b.location.setCities(cities)
    /** Инициализация города */
    this.gaApp.services.giftCardsB2b.location.initializeCity(cityId)
  }

  /** Установка данных */
  setPlasticV2Data(data) {
    const { cards, sets, counterparty, contacts, document, stores } = data

    /** Установка возможных дизайнов КППК */
    this.gaApp.stores.giftCardsB2b.plasticNew.config.cards = cards
    /** Установка правил валидации полей заявки */
    this.gaApp.stores.giftCardsB2b.plasticNew.sets.rules = sets
    /** Установка правил валидации полей контрагента */
    this.gaApp.stores.giftCardsB2b.plasticNew.counterparty.rules = counterparty
    /** Установка правил валидации полей контактов */
    this.gaApp.stores.giftCardsB2b.plasticNew.contacts.rules = contacts
    /** Установка ссылки на документ */
    this.gaApp.stores.giftCardsB2b.plasticNew.contacts.document = document

    /** При отсутствии наборов, добавляем новый с нулевыми значениями */
    if (!this.gaApp.stores.giftCardsB2b.plasticNew.sets.items.length) {
      this.gaApp.services.giftCardsB2b.plasticSets.addSet()
    }

    /** Установка опций доставки */
    this.gaApp.services.giftCardsB2b.plasticDelivery.setDeliveryOptions()

    /** Установка данных ПВЗ города */
    /** Хардкод данных для Москвы - https://jira01.goldapple.ru/browse/B2BGC-82 */
    if (this.gaApp.stores.giftCardsB2b.plasticNew.isMoscowCity) {
      this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.store =
        MOSCOW_PICKUP_DATA
    } else {
      const storeData = stores?.[0]
      this.gaApp.stores.giftCardsB2b.plasticNew.deliveryInfo.store = storeData

      if (!storeData?.id) {
        const storeIdError = new Error('Отсутствуют данные storeId')

        storeIdError.name = 'gift-b2b-error'
        storeIdError.errorData = JSON.stringify(data)

        this.gaApp.services.app.apm.captureError(storeIdError)
      }
    }

    /** Установка первоначального шага */
    this.gaApp.services.giftCardsB2b.steps.setCurrent(0)

    /** Установка региона */
    this.gaApp.services.giftCardsB2b.location.setCityRegion()
  }

  /** Отправка данных формы */
  async submitPlasticNewOrder() {
    this.gaApp.stores.giftCardsB2b.main.isLoading = true

    await this.gaApp.services.giftCardsB2b.api.submitPlasticNewOrder()

    this.gaApp.stores.giftCardsB2b.main.isLoading = false
  }

  async uploadFile(orderNumber) {
    const file =
      this.gaApp.stores.giftCardsB2b.plasticNew.contacts.form.document

    if (!orderNumber || !file) return

    const form = new FormData()
    form.append('file', file, file.name)

    await this.gaApp.repositories.giftCardsB2b.plastic.uploadFile(
      form,
      orderNumber,
    )
  }

  resetPlasticNewStore() {
    /** Сброс стора кппк v2 */
    this.gaApp.stores.giftCardsB2b.plasticNew.$reset()
    /** Сброс стора шагов */
    this.gaApp.stores.giftCardsB2b.steps.$reset()
  }
}
