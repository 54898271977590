import { ORDER_TYPE } from '../../constants/digital'

export const getters = (gaApp) => ({
  hasDuration() {
    return Boolean(this.timer.duration)
  },
  isTimerRunning() {
    return Boolean(
      this.timer.timeoutId && (this.timer.resumeTime || this.timer.startTime),
    )
  },
  isTimerPaused() {
    return Boolean(!this.timer.timeoutId && this.timer.durationLeft)
  },
  isReactionsEnabled() {
    const { orderType } = gaApp.stores.giftCards.digitalReceive
    const isB2cReactionsEnabled =
      orderType === ORDER_TYPE.INDIVIDUAL &&
      gaApp.features.get('giftCardsReactions')
    const isB2bReactionsEnabled =
      orderType === ORDER_TYPE.CORPORATE &&
      gaApp.features.get('giftCardsCorpReactions')

    return isB2cReactionsEnabled || isB2bReactionsEnabled
  },
})
