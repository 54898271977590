import { RECIPIENT_STORAGE_KEY } from '../constants/recipient'

export class RecipientService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /** Удаляет данные получателя из localstorage */
  removeDataFromLocalStore() {
    this.gaApp.services.app.browserStorage.local.remove(RECIPIENT_STORAGE_KEY)
  }
}
