export class ProductListingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   *  Получить список продуктов по sku
   *
   *  @param {string[]} sku - массив sku товаров
   *
   *  @returns {array} - возвращает массив продуктов
   */
  getProductsBySku(sku = []) {
    return this.gaApp.services.editor.api.getProductsBySku(sku)
  }

  /**
   *  Получить список продуктов по id категории
   *
   *  @param {object} redirectData - данные редиректа
   *  @param {number} pageNumber - номер страницы
   *
   *  @returns {array} - возвращает массив продуктов
   */
  getProductsByCategory(redirectData, pageNumber = 0) {
    return this.gaApp.services.editor.api.getProductsByCategory(
      redirectData,
      pageNumber,
    )
  }

  /**
   *  Получить id категории из ручки редиректа
   *
   *  @param {string} url - url категории
   *
   *  @returns {object} - возвращает id категории
   */
  getRedirectData(url) {
    return this.gaApp.services.editor.api.getRedirectData(url)
  }
}
