/**
 * Проверка на видимость элемента, к примеру если нужны css анимации
 *
 * Метод getClientRects() возвращает коллекцию прямоугольников, описывающих область отображения элемента.
 * Если элемент или его родитель скрыт через display: none, этот метод вернёт пустой массив.
 * Однако этот способ не всегда однозначно говорит именно о display: none (например, если элемент имеет нулевые размеры по другим причинам).
 *
 * @param {HTMLElement} element – элемент который нужно проверить
 * @returns {boolean} истинно ли значение.
 */
export const isElementRendered = (element) => {
  return Boolean(element?.getClientRects().length)
}
