import isPlainObject from 'lodash/isPlainObject'

import { set as vueSet } from 'vue'

import { FILTER_PAGE_NUMBER, initFiltersData } from '../constants/filters'

export class ListingService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getPageData({ redirect }) {
    this.preparePageData({ redirect })

    return await this.gaApp.services.review.api.fetchListingAllData()
  }

  async initMount() {
    this.gaApp.services.review.main.closePageKeydownEsc()

    const shouldCheckAvailableProduct = this.gaApp.stores.user.main.isAuthorized

    if (shouldCheckAvailableProduct) {
      await this.gaApp.services.review.api.checkAvailableProductForReview()
    }

    this.gaApp.services.review.main.scrollToAnchor()
  }

  setInitialData({ productId, pageNumber = 1, filters = initFiltersData() }) {
    this.gaApp.stores.review.listing.productId = productId
    this.gaApp.stores.review.listing.pageNumber = pageNumber
    this.gaApp.stores.review.filters.value = filters

    // если в сторе есть данные от предыдущей пагинации, то сбрасываем их
    if (isPlainObject(this.gaApp.stores.review.listing.reviews)) {
      this.gaApp.stores.review.listing.reviews = {}
    }
  }

  preparePageData({ redirect }) {
    const pageNumberFromRoute =
      +this.gaApp.route?.query?.[FILTER_PAGE_NUMBER.key] || 1

    this.setInitialData({
      productId: redirect.entityId,
      pageNumber: this.gaApp.services.review.main.isBot
        ? pageNumberFromRoute
        : 1,
    })

    this.gaApp.services.review.main.saveFromRoutePath()
  }

  forwardPagination() {
    this.incrementPageNumber()
    this.gaApp.services.review.api.fetchListingReviews()
  }

  applyFilters() {
    this.scrollToTop()

    this.gaApp.stores.review.listing.pageNumber = 1
    this.gaApp.stores.review.listing.reviews = {}

    this.gaApp.services.review.api.fetchListingReviews()
  }

  setChunkReview({ pageNumber, reviews }) {
    // необходимо из-за потери реактивности во вновь создаваемом объекте (vue2)
    vueSet(this.gaApp.stores.review.listing.reviews, pageNumber, reviews)
  }

  incrementPageNumber() {
    this.gaApp.stores.review.listing.pageNumber += 1
  }

  scrollToTop() {
    const top = this.gaApp.mq('desktop-small+')
      ? 0
      : this.gaApp.stores.app.window.windowSize.innerHeight / 3

    window.scrollTo({ top })
  }

  resetStores() {
    this.gaApp.stores.review.listing.$reset()
    this.gaApp.stores.review.filters.$reset()
    this.gaApp.stores.review.gallery.$reset()
    this.gaApp.stores.review.card.$reset()
  }

  getProductsForRating() {
    const productId = this.gaApp.stores.review.listing.productId

    this.gaApp.services.review.products.getProductsVariants({ productId })
  }

  filledStore(fetchedData = {}) {
    const {
      reviews,
      matchedReviewsCount = 0,
      maxReviewsCount = 0,
    } = fetchedData
    this.gaApp.services.review.listing.setChunkReview({
      pageNumber: this.gaApp.stores.review.listing.pageNumber,
      reviews,
    })
    this.gaApp.stores.review.listing.matchedReviewsCount = matchedReviewsCount

    this.gaApp.stores.review.listing.maxReviewsCount = Math.min(
      maxReviewsCount,
      matchedReviewsCount,
    )
  }
}
