import { analytics } from './analytics'
import * as components from './components'
import { repositories } from './repositories'
import { routes } from './routes'
import { services } from './services'
import { stores } from './stores'

export { MODULE_TYPE, PAGE_TYPE } from './constants'

export const customer = {
  meta: {
    name: 'customer',
    routes,
  },
  components,
  layers: {
    stores,
    services,
    repositories,
    analytics,
  },
}
