export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * запрос данных для слотов
   */

  fetchSlots() {
    if (this.gaApp.features.get('bzSlotsV2')) {
      return this.fetchSlotsV2()
    } else {
      return this.fetchSlotsV3()
    }
  }

  /**
   * запрос данных для слотов v2
   */
  fetchSlotsV2() {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const name = this.gaApp.stores.brandzone.page.meta.brandName
    const categoryId = this.gaApp.stores.brandzone.page.data.params.categoryId
    const customerGroupId = this.gaApp.stores.user.main.data.groupId

    return this.gaApp.repositories.brandzone.main.slotsV2({
      name,
      path: this.gaApp.route.path,

      categoryId,

      ...addressParams,
      ...(customerGroupId ? { customerGroupId } : {}),
    })
  }

  /**
   * запрос данных для слотов v3
   */
  fetchSlotsV3() {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const name = this.gaApp.stores.brandzone.page.meta.brandName
    const categoryId = this.gaApp.stores.brandzone.page.data.params.categoryId
    const customerGroupId = this.gaApp.stores.user.main.data.groupId

    return this.gaApp.repositories.brandzone.main.slotsV3({
      name,
      path: this.gaApp.route.path,
      categoryId,

      ...addressParams,
      ...(customerGroupId ? { customerGroupId } : {}),
    })
  }

  /**
   * запрос данных лендинга
   */
  fetchLanding() {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const name = this.gaApp.stores.brandzone.page.meta.brandName
    const categoryId = this.gaApp.stores.brandzone.page.data.params.categoryId
    const customerGroupId = this.gaApp.stores.user.main.data.groupId

    return this.gaApp.repositories.brandzone.main.landing({
      name,
      path: this.gaApp.route.path,
      categoryId,

      ...addressParams,
      ...(customerGroupId ? { customerGroupId } : {}),
    })
  }

  /**
   * Запрос листинга
   */
  fetchListing({ slotData, page = 1 }) {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const categoryId = this.gaApp.stores.brandzone.page.category.id
    const customerGroupId = this.gaApp.stores.user.main.data.groupId

    if (this.gaApp.features.get('mergingJSONandWidgetsForBZ')) {
      const { id, type, version, url } = slotData

      return this.fetchBlock({
        id,
        blockId: id,
        type,
        version,
        url,
        pageNumber: page,
      })
    }

    return this.gaApp.repositories.brandzone.main.fetchProducts(
      {
        categoryId,

        filters: [],

        pageNumber: page,

        ...addressParams,
        ...(customerGroupId ? { customerGroupId } : {}),
      },
      'listing',
    )
  }

  /**
   * Запрос категории
   */
  async fetchCategory() {
    const { params } = this.gaApp.stores.brandzone.page.data

    const response =
      await this.gaApp.repositories.brandzone.main.fetchCategory(params)

    this.gaApp.stores.brandzone.page.category = response.data
  }

  /**
   * Запрос данных редиректа по категории
   */
  async fetchRedirect(params) {
    const { data } =
      await this.gaApp.repositories.brandzone.main.fetchRedirect(params)

    return data
  }

  /**
   * запрос данных для блока страницы брендзоны
   */
  async fetchBlock(slotData) {
    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()
      const user = this.gaApp.services.user.main.data

      const requestParams = {
        ...slotData,
        customerGroupId: user.customerGroupId || user.groupId || '0',
        ...addressParams,
      }

      return await this.gaApp.repositories.brandzone.main.block(requestParams)
    } catch (error) {
      // при ошибке запроса данных одного блока возвращаем null
      return { data: null }
    }
  }
}
