import keyBy from 'lodash/keyBy'

import { ref } from 'vue'

export class MainService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getLabelsMap() {
    const { labelsMap, labelsMapRequest } = this.gaApp.stores.labels.main

    if (Boolean(labelsMap) || labelsMapRequest.pending) {
      return false
    }

    this.gaApp.stores.labels.main.setRequestPending(true)
    this.gaApp.stores.labels.main.setRequestRejected(false)

    try {
      const { labelsInfo } =
        await this.gaApp.services.labels.api.fetchLabelsMap()

      this.gaApp.stores.labels.main.setLabelsMap(
        keyBy(labelsInfo, (value) => value.id),
      )

      this.gaApp.stores.labels.main.setRequestRejected(false)
    } catch (error) {
      this.gaApp.stores.labels.main.setRequestRejected(true)
    } finally {
      this.gaApp.stores.labels.main.setRequestPending(false)
    }
  }

  getLabelByMap(label = {}) {
    return this.gaApp.stores.labels.main.labelsMap?.[label.id]
  }

  getFormattedText(text) {
    const percent = '%'
    const value = Number.parseInt(text, 10)

    if (!text.includes(percent) || isNaN(value)) {
      return text
    }

    return this.gaApp.isRtl ? `${percent}${value}` : `${value}${percent}`
  }

  mapLabels(labels) {
    return labels.map((label) => {
      const labelByMap = this.getLabelByMap(label)

      if (!labelByMap) {
        return { id: label.id, exists: false }
      }

      const labelText = labelByMap.text || label.text

      return {
        ...labelByMap,
        exists: true,
        text: labelText && this.getFormattedText(labelText),
        image: labelByMap.resource?.resourceUrl,
        imageType: labelByMap.resource?.resourceType,
        imageStatus: {
          error: ref(false),
          onError: function () {
            this.error.value = true
          },
        },
      }
    })
  }

  getFormattedLabel(label) {
    return { ...label, text: label.text && this.getFormattedText(label.text) }
  }

  hasImageErrorLabel(label) {
    return label.imageStatus?.error.value
  }

  onImageErrorLabel(label) {
    return label.imageStatus?.onError()
  }
}
