import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4QuickFilterClickModel extends GtmBaseModel {
  constructor({ filter }) {
    super('user_interaction')

    this.event_category = 'actions'
    this.action_type = 'click_apply_filter'
    this.filter = filter
  }
}
