import { COURIER_COMMENT_LOCAL_STORAGE_KEY } from '../constants/main'

export class ShippingCourierComment {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  removeCommentFromLocalStorage() {
    this.gaApp.services.app.browserStorage.local.remove(
      COURIER_COMMENT_LOCAL_STORAGE_KEY,
    )
  }
}
