export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getData() {
    const { url } = this.endpoints.default()

    try {
      const response = await this.gaApp.api
        .request({ apiType: 'webBff' })
        .get(url, {
          id: this.gaApp.cache.genId('features'),
          cache: {
            ttl: 1000 * 60 * 4, // 4 минут
          },
        })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
