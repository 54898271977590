import {
  CategoryBlockClickBuilder,
  ContentCategoryClickBuilder,
  ContentProductClickBuilder,
  ContentSlideClickBuilder,
  ContentSlideViewBuilder,
  ContentTitleClickBuilder,
  GetListingAnalyticsBuilder,
  InfoBlockBaseBuilder,
  StoriesClickBuilder,
  StoriesViewBuilder,
  StoriesViewedBuilder,
  TitleClickBuilder,
  ViewBuilder,
} from '../../builders'
import { BaseController } from '../base.controller/base.controller'

import { MODULE_TYPES, PAGE_TYPES } from './../../../constants'
import {
  ACTION_CATEGORIES,
  ACTION_TYPES,
  STORIES_VIEWED_STATE,
} from './../../constants'

export class HomeAnalytics extends BaseController {
  onMounted() {
    this.send(ViewBuilder, {})
  }

  /**
   * Показ слайда.
   * акции
   */
  onContentTitleClick() {
    this.send(ContentTitleClickBuilder)
  }

  /**
   * Клик по тайтлу слайдера.
   * Главный баннер, акции, клиентские дни, мы на youtube, блоггеры советуют.
   */
  onContentSlideView({ blockName, blockPosition, slideName, slidePosition }) {
    this.send(ContentSlideViewBuilder, {
      blockName,
      blockPosition,
      slideName,
      slidePosition,
    })
  }

  /**
   * Клик по слайду.
   * Главный баннер, акции, клиентские дни, мы на youtube, блоггеры советуют.
   */
  onContentSlideClick({ blockName, blockPosition, slideName, slidePosition }) {
    this.send(ContentSlideClickBuilder, {
      blockName,
      blockPosition,
      slideName,
      slidePosition,
    })
  }

  /**
   * Клик по слайду с переходжом на plp или pdp.
   * Главный баннер, акции, клиентские дни, мы на youtube, блоггеры советуют.
   */
  onContentLinkClick({
    blockName,
    blockPosition,
    slideName,
    slidePosition,
    itemListName,
    link,
    itemId,
    type,
    pageType,
  }) {
    if (!PAGE_TYPES[type]) {
      return
    }

    if (!PAGE_TYPES[type].includes(pageType)) {
      return
    }

    switch (type) {
      case MODULE_TYPES.PDP:
        this.send(ContentProductClickBuilder, {
          blockName,
          blockPosition,
          slideName,
          slidePosition,
          itemListName,
          itemId,
        })
        break
      case MODULE_TYPES.PLP:
      case MODULE_TYPES.BRANDS:
      case MODULE_TYPES.PROMO:
        this.send(ContentCategoryClickBuilder, {
          blockName,
          blockPosition,
          slideName,
          slidePosition,
          itemListName,
          link,
        })
        break
      default:
        return null
    }
  }

  /**
   * Клик по пункту.
   * Блок категорий.
   */
  onCategoryBlockClick({ path, link, blockPosition, actionLabel, blockName }) {
    this.send(CategoryBlockClickBuilder, {
      eventCategory: ACTION_CATEGORIES.MAIN,
      path,
      link,
      blockName,
      blockPosition,
      actionLabel,
      actionType: ACTION_TYPES.CATEGORIES_BLOCK_CLICK,
    })
  }

  /**
   * Показ инфо-блока
   */
  onInfoBlockView({ blockPosition, actionLabel }) {
    this.send(InfoBlockBaseBuilder, {
      eventCategory: ACTION_CATEGORIES.MAIN,
      actionType: ACTION_TYPES.INFO_BLOCK_VIEW,
      actionLabel,
      blockPosition,
    })
  }

  /**
   *  Клик по кнопке инфо-блока
   */
  onInfoBlockClick({ blockPosition, actionLabel }) {
    this.send(InfoBlockBaseBuilder, {
      eventCategory: ACTION_CATEGORIES.MAIN,
      actionType: ACTION_TYPES.INFO_BLOCK_CLICK_BUTTON,
      actionLabel,
      blockPosition,
    })
  }

  /**
   *  Клик по тайтлу слайдера плейсмента
   */
  onTitleClick({ itemListName, itemListId, url }) {
    this.send(TitleClickBuilder, { itemListName, itemListId, url })
  }

  /**
   *   Клик по сторис
   */
  onStoriesClick({ storyPosition, name }) {
    this.send(StoriesClickBuilder, {
      eventCategory: ACTION_CATEGORIES.STORIES,
      actionType: ACTION_TYPES.STORIES_OPEN,
      storyPosition,
      name,
    })
  }

  /**
   *   Старт просмотра сторис
   */
  onStoriesView() {
    const story = this.gaApp.services.home.stories.getActiveStory
    this.send(StoriesViewBuilder, {
      eventCategory: ACTION_CATEGORIES.STORIES,
      actionType: ACTION_TYPES.STORIES_VIEW,
      name: story.name,
    })
  }

  /**
   *   Конец просмотра сторис
   */
  onStoriesViewed() {
    const { state, pagesViewed, skipPages, firstStories } =
      this.gaApp.stores.home.stories.viewSkipInfo

    const nextState = state || STORIES_VIEWED_STATE.SWIPE

    const story = this.gaApp.services.home.stories.getActiveStory
    const activeStoryItem = this.gaApp.stores.home.stories.activeStoryItem

    this.send(StoriesViewedBuilder, {
      eventCategory: ACTION_CATEGORIES.STORIES,
      actionType: ACTION_TYPES.STORIES_VIEWED,
      name: story.name,
      state: nextState,
      pages: story.items.length,
      pagesViewed,
      skipPages,
      lastPagesId: activeStoryItem.id,
      lastPagesPosition: story.activeIndex + 1,
      sound: !this.gaApp.stores.home.stories.muted,
      firstStories,
      pagesView: skipPages + pagesViewed,
    })
  }

  /**
   * запрос за данными аналитики
   */
  async getAnalyticByUrl(data) {
    const results = await this.get(GetListingAnalyticsBuilder, data)

    return results.reduce((accumulator, current) => {
      const { key, data } = current.value

      accumulator[key] = data
      return accumulator
    }, {})
  }
}
