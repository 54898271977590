export const PAGE_TYPE = {
  DOCUMENTS: 'info/documents',
  CONTACTS: 'info/contacts',
  LOYALTY: 'info/loyalty',
  FAQ: 'info/faq',
  APP: 'info/app',
}

export const PAGE_URL_KEY = {
  DOCUMENTS: 'documents',
  FAQ: 'faq',
}
