import { cardsStore } from './cards'
import { editStore } from './edit'
import { loyaltyQrModalStore } from './loyalty-qr-modal'
import { mainStore } from './main'
import { modalStore as modal } from './modal'
import { notificationModalStore } from './notification-modal'
import { orderStore } from './order'
import { ordersStore } from './orders'
import { settingsStore } from './settings'

export const stores = (gaApp) => {
  return {
    main: mainStore(gaApp),
    modal: modal(gaApp),
    orders: ordersStore(gaApp),
    order: orderStore(gaApp),
    edit: editStore(gaApp),
    settings: settingsStore(gaApp),
    cards: cardsStore(gaApp),
    loyaltyQrModal: loyaltyQrModalStore(gaApp),
    notificationModal: notificationModalStore(gaApp),
  }
}
