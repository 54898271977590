import { PAGE_NOT_FOUND } from '../constants/listing'
import { TYPE_PRODUCTS } from '../constants/products'

/**
 * Предоставляет методы для взаимодействия с API для работы с продуктами, отзывами, загрузкой медиафайлов и другими связанными операциями в приложении.
 */

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/review/prefetch', ({ productId }) => {
      this.getListingAllData({ productId })
    })
  }

  async getProducts({ orderId, customerId, itemId } = {}, context) {
    try {
      if (!orderId || !itemId) {
        throw new Error('orderId и itemId must be specified')
      }
      this.gaApp.stores.review.products.updateAt = Date.now()
      const userId = customerId || this.gaApp.stores.user.main?.data?.id

      const { data } = await this.gaApp.repositories.review.main.getProducts({
        orderId,
        customerId: userId,
      })
      // проверяем с какой страницы пришел пользак, чтоб вернуть его обратно при нажатии на кнопку
      if (context?.route?.fullPath !== context?.from?.fullPath) {
        this.gaApp.stores.review.products.fromRoutePath =
          context?.from?.fullPath
      }

      // если товар не найден в заказе то считаем, что отзыв уже был оставлен
      if (!data?.products?.some((product) => product.itemId === itemId)) {
        return this.gaApp.services.review.notify.errorRepeatReview(
          this.gaApp.redirect,
        )
      }

      this.gaApp.services.review.products.setData({
        data,
        orderId,
        userId,
        itemId,
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getProductsVariants({ productId }) {
    this.gaApp.stores.review.products.$reset()

    this.gaApp.stores.review.products.updateAt = Date.now()
    const type = TYPE_PRODUCTS.VARIANTS
    try {
      this.gaApp.stores.review.products.pending = true
      const { data } = await this.gaApp.repositories.review.main.getProducts({
        itemId: productId,
        type,
      })

      if (data?.products?.length === 0) {
        throw new Error('products is empty')
      }

      this.gaApp.services.review.products.setData({
        data,
        type,
      })
    } catch (error) {
      console.error(error)
      this.gaApp.services.review.notify.errorGetProducts(
        error?.data?.localizedMessage,
      )
    } finally {
      this.gaApp.stores.review.products.pending = false
    }
  }

  async getProductsAvailable() {
    this.gaApp.stores.review.products.$reset()

    this.gaApp.stores.review.products.updateAt = Date.now()
    const type = TYPE_PRODUCTS.AVAILABLE
    try {
      this.gaApp.stores.review.products.pending = true
      const { data } = await this.gaApp.repositories.review.main.getProducts({
        type,
      })

      if (this.gaApp.services.review.modal.shouldShowModal(data)) {
        this.gaApp.services.review.products.setData({
          data,
          type,
        })
        this.gaApp.services.review.modal.open()
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.gaApp.stores.review.products.pending = false
    }
  }

  async uploadMedia(id) {
    const formData = this.gaApp.services.review.media.prepareUploadFile(id)
    if (!formData) {
      return
    }
    try {
      this.gaApp.services.review.media.setFileData(id, 'isUploading', true)

      // фичтогл предварительной загрузки превью изрбражений
      if (!this.gaApp.features.get('reviewFormPreloadImage')) {
        const src = await this.gaApp.services.review.media.toBase64(id)

        this.gaApp.services.review.media.setFileData(id, 'src', src)
      }

      const { data } = await this.gaApp.repositories.review.main.uploadMedia(
        formData,
        id,
      )

      if (!data?.id) {
        // eslint-disable-next-line unicorn/error-message
        throw new Error('id медиа отзыва не получен')
      }

      this.gaApp.services.review.media.setFileData(id, 'mediaId', data?.id)
      this.gaApp.services.review.media.setFileData(id, 'isAdded', true)
    } catch (error) {
      this.gaApp.services.review.media.delete(id)
      this.gaApp.services.review.media.setError(
        this.gaApp.i18n.t('review.form.media.error.upload'),
      )
    } finally {
      this.gaApp.services.review.media.setFileData(id, 'isUploading', false)
    }
  }

  async submit({ isInvalidForm = false } = {}) {
    if (isInvalidForm) {
      return this.gaApp.services.review.notify.invalidForm()
    }

    try {
      this.gaApp.stores.review.form.pending = true
      const sendData = this.gaApp.services.review.form.preparedFormData()

      // в дев режиме не отправляем отзыв
      if (!this.gaApp.config.get('devConfig')) {
        await this.gaApp.repositories.review.main.submit(sendData)
      }

      this.gaApp.stores.review.products.submittedItemIds.push(sendData.itemId)

      // редирект на страницу успеха
      await this.gaApp.services.review.notify.successSubmit()
    } catch (error) {
      console.error(error)

      this.gaApp.services.review.notify.errorSubmit()
    } finally {
      this.gaApp.stores.review.form.pending = false
    }
  }

  async fetchGallery({ pageNumber, productId } = {}) {
    try {
      this.gaApp.stores.review.gallery.pending = true
      this.gaApp.stores.review.gallery.hasError = false

      const paginationNumber =
        pageNumber ?? this.gaApp.stores.review.gallery.pageNumber
      const itemId = productId ?? this.gaApp.stores.review.listing.productId

      if (!itemId) {
        throw new Error('itemId must be specified')
      }

      const body = {
        pageNumber: paginationNumber,
        itemId,
      }

      const { data } = await this.gaApp.repositories.review.main.fetchGallery({
        data: body,
      })

      this.gaApp.services.review.gallery.filledStore(data)
    } catch (error) {
      this.gaApp.stores.review.gallery.hasError = true
      console.error(error)
    } finally {
      this.gaApp.stores.review.gallery.pending = false
    }
  }

  async getFilters({ productId } = {}) {
    try {
      const itemId = productId ?? this.gaApp.stores.review.listing.productId

      if (!itemId) {
        throw new Error('itemId must be specified')
      }

      this.gaApp.stores.review.filters.pending = true
      const { data } = await this.gaApp.repositories.review.main.getFilters({
        itemId,
      })

      this.gaApp.services.review.filters.filledStore(data)
    } catch (error) {
      console.error(error)
    } finally {
      this.gaApp.stores.review.filters.pending = false
    }
  }

  async getListingAllData({ productId } = {}) {
    const { pageNumber, productId: itemId } = this.gaApp.stores.review.listing
    const filters = this.gaApp.stores.review.filters.value
    const cityId = this.gaApp.services.app.main.getMainCity()?.value

    const body = {
      pageNumber,
      itemId: productId || itemId,
      cityId,
      ...filters,
    }

    return await this.gaApp.repositories.review.main.getListingBlocks(body)
  }

  async fetchListingAllData() {
    try {
      const { data } = await this.getListingAllData()
      const itemId = this.gaApp.stores.review.listing.productId

      this.gaApp.services.review.main.filledAllStores(data, itemId)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async fetchListingReviews() {
    try {
      this.gaApp.stores.review.listing.pending = true
      this.gaApp.stores.review.listing.hasError = false

      const { pageNumber, productId: itemId } = this.gaApp.stores.review.listing
      const { value: filtersValue } = this.gaApp.stores.review.filters

      if (!itemId) {
        throw new Error('itemId must be specified for review')
      }

      const body = {
        pageNumber,
        itemId,
        ...filtersValue,
      }

      const { data } =
        await this.gaApp.repositories.review.main.fetchMainReviews({
          data: body,
        })

      if (!data?.reviews?.length && !this.gaApp.services.review.main.isBot) {
        throw new Error(PAGE_NOT_FOUND)
      }

      this.gaApp.services.review.listing.filledStore(data)
    } catch (error) {
      this.gaApp.stores.review.listing.hasError = true
      throw error
    } finally {
      this.gaApp.stores.review.listing.pending = false
    }
  }

  async getPreviewReviews({ productId } = {}) {
    try {
      const itemId = productId ?? this.gaApp.stores.review.preview.productId

      if (!itemId) {
        throw new Error('itemId must be specified')
      }

      this.gaApp.stores.review.preview.pending = true
      this.gaApp.stores.review.preview.hasError = false

      const { data } =
        await this.gaApp.repositories.review.main.getPreviewReviews({
          itemId,
        })

      this.gaApp.services.review.preview.filledStore(data, itemId)
    } catch (error) {
      console.error(error)
      this.gaApp.stores.review.preview.hasError = true
    } finally {
      this.gaApp.stores.review.preview.pending = false
    }
  }

  async getReview({ reviewId } = {}) {
    try {
      const paramReviewId = reviewId ?? this.gaApp.stores.review.detail.reviewId

      if (!paramReviewId) {
        throw new Error('reviewId must be specified')
      }

      this.gaApp.stores.review.detail.pending = true
      this.gaApp.stores.review.detail.hasError = false

      const { data } =
        await this.gaApp.repositories.review.main.getReview(paramReviewId)

      this.gaApp.stores.review.detail.data = data
    } catch (error) {
      console.error(error)
      this.gaApp.stores.review.detail.hasError = true
    } finally {
      this.gaApp.stores.review.detail.pending = false
    }
  }

  async getStatistic({ productId } = {}) {
    try {
      const itemId = productId ?? this.gaApp.stores.review.listing.productId

      if (!itemId) {
        throw new Error('itemId must be specified')
      }

      this.gaApp.stores.review.statistic.pending = true
      const { data } = await this.gaApp.repositories.review.main.getStatistic({
        itemId,
      })

      this.gaApp.services.review.statistic.filledStore(data, itemId)
    } catch (error) {
      console.error(error)
    } finally {
      this.gaApp.stores.review.statistic.pending = false
    }
  }

  async checkAvailableProductForReview({ productId } = {}) {
    try {
      const itemId = productId ?? this.gaApp.stores.review.listing.productId

      const { data } =
        await this.gaApp.repositories.review.main.checkAvailableProductForReview(
          {
            itemId,
          },
        )

      this.gaApp.services.review.statistic.filledAvailableForReviewStatus(data)
    } catch (error) {
      console.error(error)
    }
  }
}
