export const modulePdpSsrRoutes = [
  {
    moduleType: 'pdp',
    pageType: 'product',
    path: '/product/:entityId',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/pdp/page/product' */
        '../components/main/pages/main.vue'
      ),
  },
]

export const routes = [...modulePdpSsrRoutes]
