import { ACTION_CATEGORIES, ACTION_TYPES, ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4ContentTitleClickModel extends GtmBaseModel {
  constructor() {
    super(ACTIONS.USER_INTERACTION.GA4)

    this.event_category = ACTION_CATEGORIES.MAIN
    this.action_type = ACTION_TYPES.CONTENT_TITLE_CLICK
    this.widget = 'actions'
  }
}
