import { SCREEN_TYPES } from '../analytics/constants'

export class AddressesApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async setDefaultAddress(addressId) {
    try {
      const response =
        await this.gaApp.repositories.location.main.setAddressDefault({
          id: addressId,
        })

      this.updateAddresses(response)

      this.gaApp.stores.location.addresses.activeId =
        this.gaApp.stores.location.addresses.defaultId

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('location.addresses.addressChanged', {
          value:
            this.gaApp.services.location.addresses.formatAddressNotification(
              this.gaApp.stores.location.addresses.activeId,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.addresses.error.setDefaultId'),
      )
    }
  }

  async setAddress(addressId) {
    this.gaApp.analytics.modules.location.onClickChangeAddress(
      SCREEN_TYPES.ACCOUNT_MY_ADDRESSES,
    )

    await this.setDefaultAddress(addressId)
  }

  async deleteAddress(addressId) {
    try {
      const response =
        await this.gaApp.repositories.location.main.deleteAddress({
          id: addressId,
        })

      // создаем текст нотификации до того как удалим адрес из стора в updateAddresses
      const notification =
        this.gaApp.services.location.addresses.formatAddressNotification(
          addressId,
        )

      this.updateAddresses(response)

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('location.addresses.delete.success', {
          value: notification,
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.addresses.error.delete'),
      )
    }
  }

  async updateAddress(addressData) {
    if (this.gaApp.features.get('locationAddressFormEditUnification')) {
      this.gaApp.stores.location.addresses.isLoading = true
    }

    try {
      const response =
        await this.gaApp.repositories.location.main.updateAddress(addressData)

      this.updateAddresses(response)

      this.gaApp.services.notification.main.open({
        slotText: this.gaApp.i18n.t('location.addresses.edit.success', {
          value:
            this.gaApp.services.location.addresses.formatAddressNotification(
              addressData.id,
            ),
        }),
      })
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('location.addresses.error.update'),
      )
    } finally {
      this.gaApp.stores.location.addresses.isLoading = false
    }
  }

  updateAddresses(response) {
    const { selectedAddress, addresses } = response?.data || {}

    if (addresses) {
      this.gaApp.services.location.main.setAddresses(addresses)
    }

    if (selectedAddress) {
      this.gaApp.services.location.main.setLocation(selectedAddress)
    }
  }
}
