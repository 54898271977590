// Кол-во лайков по умолчанию, в случае если не получим ответ от АПИ
export const DEFAULT_LIKES_COUNT = 0

// Используется для того чтобы привести число к языкозависимому представлению (в методе Number.prototype.toLocaleString())
// т.е. разбиет число по разрядно - 10000 -> 10 000 и т.п.
// Используется как значение по умолчанию, если не удасться получить локаль пользователя
export const DEFAULT_LIKES_COUNT_LOCALE = 'ru'

// Задержка для отправки запросов на установку/снятие лайка (не более 1го запроса в N мс)
export const THROTTLE_DELAY = 500
