import { LOCAL_STORAGE_KEY } from '../constants'

/**
 * Медоды для работы с ключами в local storage:
 * get - забирает значение по ключу,
 * set - устанавливает значение по ключу
 * remove - удаляет запись по ключу
 */
export class LocalStorageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * @returns {string}
   */
  getLocalStorageAuthRedirectUrl() {
    return this.gaApp.services.app.browserStorage.local.get(
      LOCAL_STORAGE_KEY.AUTH_REDIRECT_URL,
    )
  }

  /**
   * @param {string} redirectUrl
   */
  setLocalStorageAuthRedirectUrl(redirectUrl) {
    this.gaApp.services.app.browserStorage.local.set(
      LOCAL_STORAGE_KEY.AUTH_REDIRECT_URL,
      redirectUrl,
    )
  }

  removeLocalStorageAuthRedirectUrl() {
    this.gaApp.services.app.browserStorage.local.remove(
      LOCAL_STORAGE_KEY.AUTH_REDIRECT_URL,
    )
  }

  /**
   * @returns {string}
   */
  getLocalStorageAuthModalOpenReason() {
    return this.gaApp.services.app.browserStorage.local.get(
      LOCAL_STORAGE_KEY.AUTH_MODAL_OPEN_REASON,
    )
  }

  /**
   * @param {string} openReason
   */
  setLocalStorageAuthModalOpenReason(openReason) {
    this.gaApp.services.app.browserStorage.local.set(
      LOCAL_STORAGE_KEY.AUTH_MODAL_OPEN_REASON,
      openReason,
    )
  }

  removeLocalStorageAuthModalOpenReason() {
    this.gaApp.services.app.browserStorage.local.remove(
      LOCAL_STORAGE_KEY.AUTH_MODAL_OPEN_REASON,
    )
  }

  /**
   * @returns {string}
   */
  getLocalStorageAuthVerifier() {
    return this.gaApp.services.app.browserStorage.local.get(
      LOCAL_STORAGE_KEY.AUTH_VERIFIER,
    )
  }

  /**
   * @param {string} codeVerifier
   */
  setLocalStorageAuthVerifier(codeVerifier) {
    this.gaApp.services.app.browserStorage.local.set(
      LOCAL_STORAGE_KEY.AUTH_VERIFIER,
      codeVerifier,
    )
  }

  removeLocalStorageAuthVerifier() {
    this.gaApp.services.app.browserStorage.local.remove(
      LOCAL_STORAGE_KEY.AUTH_VERIFIER,
    )
  }
}
