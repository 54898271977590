import { deviceOrientation } from '@ga/shared-browser'

export const getters = (gaApp) => ({
  getZIndex(state) {
    return ({ id }) => {
      return state.zIndexes[id] || null
    }
  },

  /**
   * Возвращает объект, содержащий информацию о типе ориентации устройства.
   * @returns {Reactive<Object>} Объект, содержащий информацию о типе ориентации устройства.
   * @property {Boolean} isPortraitOrientation - Признак вертикального ориентирования.
   * @property {Boolean} isLandscapeOrientation - Признак горизонтального ориентирования.
   * @property {Boolean} isMobilePortrait - Признак вертикального ориентирования на мобильном устройстве.
   * @property {Boolean} isMobileLandscape - Признак горизонтального ориентирования на мобильном устройстве.
   * @property {Boolean} isMobile - Признак мобильного устройства.
   * @property {Boolean} isTabletPortrait - Признак вертикального ориентирования на планшете.
   * @property {Boolean} isTabletLandscape - Признак горизонтального ориентирования на планшете.
   * @property {Boolean} isTablet - Признак планшета.
   * @property {Boolean} isDesktopPortrait - Признак вертикального ориентирования на десктопе.
   * @property {Boolean} isDesktopLandscape - Признак горизонтального ориентирования на десктопе.
   * @property {Boolean} isDesktop - Признак десктопа.
   */
  deviceOrientationType() {
    const { isPortrait, isLandscape } = deviceOrientation()

    const isPortraitOrientation = isPortrait()
    const isLandscapeOrientation = isLandscape()

    const isMobilePortrait = isPortraitOrientation && gaApp.mq('tablet-small-')
    const isMobileLandscape =
      isLandscapeOrientation && gaApp.mq('tablet-large-')

    const isMobile = isMobilePortrait || isMobileLandscape

    const isTabletPortrait =
      isPortraitOrientation &&
      gaApp.mq('tablet-small+') &&
      gaApp.mq('desktop-small-')

    const isTabletLandscape =
      isLandscapeOrientation &&
      gaApp.mq('tablet-large+') &&
      gaApp.mq('desktop-small-')

    const isTablet = isTabletPortrait || isTabletLandscape

    const isDesktop = !isTablet && !isMobile
    const isDesktopPortrait = isDesktop && isPortraitOrientation
    const isDesktopLandscape = isDesktop && isLandscapeOrientation

    return {
      isPortrait: isPortraitOrientation,
      isLandscape: isLandscapeOrientation,

      isMobilePortrait,
      isMobileLandscape,
      isMobile,

      isTabletPortrait,
      isTabletLandscape,
      isTablet,

      isDesktopPortrait,
      isDesktopLandscape,
      isDesktop,
    }
  },
})
