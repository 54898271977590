import { ELEMENT_TEST_IDS } from '../../constants'

/**
 * Возвращает объект со значениями data-test-id для элементов карточки продукта.
 *
 * @param {String} rootTestId - значение рутового testId карточки продукта
 * @param {Object=} product - Объект продукта из входящих параметров карточки продукта
 * @return {Object} объект со значениями data-test-id для элементов карточки продукта
 */
export const useTestIds = (rootTestId, product) => {
  /**
   * Добавляет параметры к тестовому идентификатору
   * @param {String} testId - Тестовый идентификатор
   * @param {Object=} params - Параметры идентификатора
   * @return {String} Параметризированный тестовый идентификатор
   */
  const addParamsToTestId = (testId, params) => {
    if (!params) return testId

    return Object.keys(params).reduce(
      (result, key) => `${result}_${key}`,
      testId,
    )
  }

  /**
   * Фильтрует объект по заданному условию
   * @param {Object} obj - Объект для фильтрации
   * @param {Function} predicate - Функция-предикат для фильтрации
   * @returns {Object} Отфильтрованный объект
   */
  const filterObject = (obj, predicate) => {
    return Object.fromEntries(Object.entries(obj).filter(predicate))
  }

  /**
   * Модифицирует поле тестового идентификатора с учетом дополнительных параметров
   * @param {String} testId - Базовый тестовый идентификатор
   * @param {Object=} params - Параметры идентификатора
   * @param {Function=} predicate - Предикат для фильтрации
   * @returns {string} Модифицированный тестовый идентификатор
   */
  const modifyTestId = (testId, params, predicate) => {
    if (!params) {
      return testId
    }

    const filteredParams = predicate ? filterObject(params, predicate) : params
    return addParamsToTestId(testId, filteredParams)
  }

  /**
   * Рекурсивно модифицирует тестовые идентификаторы, добавляя префикс
   * @param {Object} testIds - Объект с идентификаторами
   * @param {String} prefix - Префикс добавляемый идентификаторам
   * @returns {Object} Модифицированный объект с идентификаторами
   */
  const getModifiedTestIds = (testIds, prefix) => {
    const modifiedTestIds = {}

    Object.entries(testIds).forEach(([key, value]) => {
      if (typeof value === 'object') {
        modifiedTestIds[key] = getModifiedTestIds(value, prefix)
      } else {
        modifiedTestIds[key] = `${prefix}__${value}`
      }
    })

    return modifiedTestIds
  }

  /**
   *  Возвращает модифицированный рутовый идентификатор
   */
  const getModifiedIdRoot = () => {
    if (!product) {
      return rootTestId
    }

    const isComplex = Object.values(product.attributes).some(
      (value) => value.count > 1,
    )

    const params = {
      simple: !isComplex,
      complex: isComplex,
    }

    return modifyTestId(rootTestId, params, ([_, value]) => value)
  }

  /**
   *  Возвращает модифицированный идентификатор атрибутов
   */
  const getModifiedIdAttributes = () =>
    modifyTestId(
      modifiedTestIds.attributes,
      product?.attributes,
      ([_, value]) => value.count > 1,
    )

  const modifiedTestIds = getModifiedTestIds(ELEMENT_TEST_IDS, rootTestId)

  // Модифицируем различные поля с их специфичными предикатами
  const testIds = {
    root: getModifiedIdRoot(),
    ...modifiedTestIds,
    attributes: getModifiedIdAttributes(),
    // Здесь можно добавить модификацию других полей по аналогии:
    // someField: modifyTestIdField(
    //   baseTestIds.someField,
    //   product?.someData,
    //   ([_, value]) => value.someCondition
    // ),
  }

  return testIds
}
