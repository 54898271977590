import { computed, toRefs } from 'vue'

import { GaPrice } from '~/components/price'

import {
  BADGE_HIGHLIGHT_THEME,
  BADGE_SIZE,
  BNPL_PROVIDER,
} from '../../../constants'
import { propValidator } from '../../../utils'
import { price as priceSchema } from '../../../utils/schemas'
import { GaBnplBadgeContentDefault } from '../../atoms/badge-content-default'
import { GaBnplBadgeContentInstallment } from '../../atoms/badge-content-installment'
import { GaBnplBadgeContentTabby } from '../../atoms/badge-content-tabby'
import { GaBnplTabbyBadge } from '../tabby-badge'

import { useBadgeContent } from './scripts/composables'

// @vue/component
export default {
  name: 'ga-bnpl-badge',

  components: {
    GaPrice,
    GaBnplBadgeContentDefault,
    GaBnplBadgeContentInstallment,
    GaBnplBadgeContentTabby,
    GaBnplTabbyBadge,
  },

  props: {
    /** Провайдер, определяющий контент беджа */
    provider: {
      type: String,
      default: BNPL_PROVIDER.DEFAULT,
      required: false,
      validator: (value) => Object.values(BNPL_PROVIDER).includes(value),
    },

    /** Объект с ценой (за разовый платеж) */
    data: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (value) => propValidator(value, priceSchema),
    },

    /** Размер беджа */
    size: {
      type: String,
      default: BADGE_SIZE.M,
      validator: (value) => Object.values(BADGE_SIZE).includes(value),
    },

    /** Подсветка беджа */
    highlight: {
      type: Boolean,
      default: false,
    },

    /** Тема подсветки беджа */
    highlightTheme: {
      type: String,
      default: BADGE_HIGHLIGHT_THEME.DEFAULT,
      validator: (value) =>
        Object.values(BADGE_HIGHLIGHT_THEME).includes(value),
    },

    /** Количество платежей */
    paymentCount: {
      type: Number,
      default: 4,
    },
  },

  setup(props) {
    const { size, data, provider, highlight, highlightTheme } = toRefs(props)

    const mods = computed(() => {
      if (provider.value === BNPL_PROVIDER.TABBY) {
        return {
          custom: true,
        }
      }

      return {
        size: size.value,
        highlight: highlight.value,
        highlight_theme: highlightTheme.value,
      }
    })

    const { content } = useBadgeContent({ data, size, provider })

    return {
      mods,
      content,
    }
  },
}
