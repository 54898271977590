// TODO: apm.captureError
export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   *  Получить список продуктов по sku
   *
   *  @param {string[]} sku - массив sku товаров
   *
   *  @returns {array} - возвращает массив продуктов
   */
  async getProductsBySku(sku = []) {
    try {
      const { data } = await this.gaApp.services.plp.public.getProductsBySku({
        itemIds: sku,
      })
      return data?.products || []
    } catch (error) {
      console.error('ошибка получения продуктов по sku: ' + error.message)

      return []
    }
  }

  /**
   *  Получить данные редиректа
   *
   *  @param {string} url - url категории
   *
   *  @returns {object} - возвращает данные редиректа
   */
  async getRedirectData(url) {
    try {
      const { data } = await this.gaApp.services.app.public.getRedirectData({
        url,
      })

      if (!data.entityId) {
        throw new Error('некорректный url категории ' + url)
      }

      return data
    } catch (error) {
      console.error('ошибка получения данных редиректа: ' + error.message)

      return null
    }
  }

  /**
   *  Получить список продуктов категории
   *
   *  @param {object} redirectData - данные редиректа
   *  @param {number} pageNumber - номер страницы
   *
   *  @returns {array} - возвращает массив продуктов
   */
  async getProductsByCategory(redirectData, pageNumber = 1) {
    try {
      const { data } =
        await this.gaApp.services.plp.public.getProductsByCategory({
          categoryId: redirectData.entityId,
          filters: redirectData.filters,
          pageNumber,
        })

      return data?.products || []
    } catch (error) {
      console.error('ошибка получения продуктов категории: ' + error.message)

      return []
    }
  }
}
