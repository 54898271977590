/**
 * Проверка на наличие скролла
 *
 * @param {HTMLElement} element – элемент который нужно проверить
 * @returns {boolean} истинно ли значение
 */
export const hasScroll = (element) => {
  if (!(element instanceof HTMLElement)) {
    return false
  }

  return element.scrollWidth > element.clientWidth
}
