<template>
  <svg fill="none" stroke="none" viewBox="0 0 40 40">
    <path fill="#EDEDED" d="M0 0h28l12 12v28H0V0Z" />
    <path fill="#D9D9D9" d="m28 0 12 12H28V0Z" />
    <path
      fill="#000"
      stroke="none"
      d="M9.754 31.285v-14.88h3.46c1.94 0 2.54 1.1 2.54 2.86v9.06c0 1.82-.68 2.96-2.54 2.96h-3.46Zm2.26-12.86v10.7h.54c.68 0 .88-.36.88-1.16v-8.38c0-.86-.22-1.16-.78-1.16h-.64Zm11.157 1.24v8.3c0 2.08-.7 3.5-2.88 3.5h-.38c-2.16 0-2.92-1.42-2.92-3.5v-8.28c0-2.06.78-3.42 2.9-3.42h.38c2.2 0 2.9 1.36 2.9 3.4Zm-2.3 8.46v-8.56c0-.78-.26-1.14-.78-1.14-.58 0-.8.38-.8 1.14v8.56c0 .64.18 1.14.82 1.14.6 0 .76-.5.76-1.14Zm6.535 1.14c.54 0 .74-.4.74-1v-3.46h2.22v3.44c0 1.6-.52 3.22-2.7 3.22h-.5c-2.28 0-2.78-1.64-2.78-3.14v-9.08c0-1.52.66-2.98 2.7-2.98h.52c2.48 0 2.76 1.64 2.76 3.1v2.66h-2.22v-2.62c0-.64-.2-.98-.76-.98s-.7.32-.7.98v8.84c0 .66.22 1.02.72 1.02Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-gift-cards-b2b-file-doc',
}
</script>
