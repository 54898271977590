export class PublicService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение данных редиректа
   *
   * @param {object} params - объект параметров
   * @param {string} params.url - запрашиваемый url
   *
   * @returns {Promise}
   */
  getRedirectData(params) {
    return this.gaApp.services.app.api.getRedirectData(params)
  }
}
