import { ERROR, PAGE_TYPE } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect) {
    try {
      switch (redirect.pageType) {
        case PAGE_TYPE.CONFIRM_CODE:
          if (!this.gaApp.features.get('authorizationTypesEnabled')) {
            throw new Error(ERROR.PAGE_NOT_FOUND)
          }

          await this.gaApp.services.auth.main.loginViaExternalService(
            redirect.id,
          )
      }
    } catch (error) {
      const isErrorPageNotFound =
        error?.statusCode === 404 || error?.message === ERROR.PAGE_NOT_FOUND

      if (isErrorPageNotFound) {
        this.toErrorPage()
      } else {
        // отправка аналитического события ошибки авторизации
        this.gaApp.analytics.modules.auth.onError(redirect.id)

        this.toMainPageWithErrorMessage(error)
      }
    }
  }

  toErrorPage({ statusCode = 404 } = {}) {
    this.gaApp.redirectError({
      statusCode,
    })
  }

  /**
   * Редиректит на главную, открывает модалку авторизации и отображает сообщение об ошибке
   * @param {Object} error
   */
  toMainPageWithErrorMessage(error) {
    this.gaApp.services.app.router.push(`/#auth`, () => {
      this.gaApp.services.auth.modal.handleError(error)
    })
  }
}
