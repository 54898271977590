export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Запрос списка продуктов
   */
  async fetchProductsByPage({ categoryId, page = 1 }) {
    const addressParams =
      this.gaApp.services.location.main.getDeliveryAddressParams()

    const user = this.gaApp.services.user.main.data

    const { data } = await this.gaApp.repositories.dolyame.main.fetchProducts({
      pageNumber: page,
      categoryId,

      filters: [],
      ...addressParams,

      customerGroupId: user.customerGroupId || user.groupId || '0',
    })

    const { products } = data

    this.gaApp.stores.dolyame.main.products = products
  }
}
