import { ENTITIES, REGEXPS, SPECIAL_CHARS_REGEX } from '@ga/constants/text'

// Проверяет, содержит ли слово специальные символы
const hasSpecialChars = (word) => SPECIAL_CHARS_REGEX.test(word)

// Проверяет, является ли слово одиночным символом
const isPlusSign = (word) => REGEXPS.PLUS_SIGN.test(word)
const isSlashSign = (word) => REGEXPS.SLASH_SIGN.test(word)

// Проверяет длину слова
const hasProperLength = (word, maxWordLength) => word.length < maxWordLength

// Добавляет неразрывные соединители вокруг специальных символов
const addWordJoinersAroundSymbols = (word) => {
  return word.replace(
    SPECIAL_CHARS_REGEX,
    `${ENTITIES.WORD_JOINER}$&${ENTITIES.WORD_JOINER}`,
  )
}

// Добавляет неразрывный пробел к слову
const addNonBreakingSpace = (word) => word + ENTITIES.NON_BREAKING_SPACE

// Добавляет обычный пробел к слову
const addRegularSpace = (word) => word + ENTITIES.SPACE

// Определяет, какой пробел нужно добавить в зависимости от следующего слова
const determineSpaceToAdd = (word, nextWord, maxWordLength) => {
  if (!word) return ''

  const processedWord = hasSpecialChars(word)
    ? addWordJoinersAroundSymbols(word)
    : word

  if (!nextWord) {
    return processedWord
  }

  if (
    hasProperLength(word, maxWordLength) ||
    isPlusSign(nextWord) ||
    isSlashSign(nextWord)
  ) {
    return addNonBreakingSpace(processedWord)
  }

  return addRegularSpace(processedWord)
}

// Преобразует текст, добавляя соответствующие неразрывные пробелы
const addNonBreakingSpaces = (text, maxWordLength) => {
  if (!text) return ''

  const words = text.split(' ')

  return words
    .map((word, index) =>
      determineSpaceToAdd(word, words[index + 1], maxWordLength),
    )
    .join('')
}

export { addNonBreakingSpaces }
