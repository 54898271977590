import { ARTICLES_BASE_URL, STREAM_BASE_URL, STREAMS_BASE_URL } from './common'

export const MODULE_TYPE = 'articles'

export const PAGE_TYPE = {
  // статьи
  ARTICLE_ONE: 'article-one',
  ARTICLE_LISTING: 'article-listing',
  ARTICLE_LISTING_FILTER: 'article-listing-filter',

  // стримы / лайв
  STREAM_ONE: 'stream-one',
  STREAM_LIVE: 'stream-live',
  STREAM_LISTING: 'stream-listing',
  STREAM_LISTING_FILTER: 'stream-listing-filter',

  // превью страницы
  PREVIEW_ARTICLE: 'preview-article',
  PREVIEW_ARTICLE_LISTING: 'preview-article-listing',

  PREVIEW_STREAM: 'preview-stream',
  PREVIEW_STREAM_LISTING: 'preview-stream-listing',

  PREVIEW_BANNER: 'preview-banner',
}

export const PAGE_PATH = {
  // статьи
  ARTICLE_ONE: '/flacon/article/:url',
  ARTICLE_LISTING: ARTICLES_BASE_URL,
  ARTICLE_LISTING_FILTER: '/flacon/:filterType/:filterKey',

  // стримы / лайв
  STREAM_ONE: STREAM_BASE_URL,
  STREAM_LIVE: '/flacon/live/:url/stream/:externalId',
  STREAM_LISTING: STREAMS_BASE_URL,
  STREAM_LISTING_FILTER: '/flacon/live/:filterType/:filterKey',

  // превью страницы
  PREVIEW_ARTICLE: '/flacon/preview/:id',
  PREVIEW_ARTICLE_LISTING: '/flacon/preview-listing/:id',

  PREVIEW_STREAM: '/flacon/preview/live/:id',
  PREVIEW_STREAM_LISTING: '/flacon/preview-listing/live/:id',

  // campaignId и bannerId - необходимы для запроса информации по баннеру от АПИ
  PREVIEW_BANNER: '/flacon/preview/:campaignId/banner/:bannerId',
}
