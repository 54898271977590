/**
 * Сервис для управления подписками на события для модального окна
 * Отвечает за открытие и закрытие модалок
 */
export class SubscribesService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  init() {
    this.subscribeToRouterAfterEach()
    this.subscribeToPageCreated()
  }

  /**
   * Подписывается на событие "переход на страницу" и проверяет наличие хэша в url
   * Если есть хэш, открывает модальное окно (например #auth)
   */
  subscribeToRouterAfterEach() {
    this.gaApp.router.afterEach((to) => {
      this.gaApp.services.modal.hash.check(to.hash)
    })
  }

  /**
   * Подписывается на событие "создание страницы"
   * Если страница смонтировалась и есть открытые модалки, то закрывает их
   * Закрытие модалок осуществляем после монтирования страницы
   * для того чтобы модалка открытая через хэш не закрывалась при первом открытии страницы
   */
  subscribeToPageCreated() {
    this.gaApp.eventBus.subscribe('module/app/router/page-created', () => {
      const { mounted } = this.gaApp.stores.app.main
      const { hasOpenedModals } = this.gaApp.stores.modal.main

      if (mounted && hasOpenedModals) {
        this.gaApp.services.modal.main.hardCloseAll()
      }
    })
  }
}
