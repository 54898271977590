export const moduleBrandzoneSsrRoutes = [
  {
    moduleType: 'brandzone',
    pageType: 'main',
    meta: {
      authRequired: false,
      analyticDisabled: false,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/brandzone/page/brandzone' */
        '../components/pages/main.vue'
      ),
  },
  {
    moduleType: 'brandzone',
    pageType: 'landing',
    path: '/brands/dior/ekspertiza-doma-dior',
    meta: {
      authRequired: false,
      analyticDisabled: true,
      addressDisabled: true,
      webview: true,
    },
    component: () =>
      import(
        /* webpackChunkName: 'modules/brandzone/page/landing' */
        '../components/pages/landing/landing.vue'
      ),
  },
]

export const routes = [...moduleBrandzoneSsrRoutes]
