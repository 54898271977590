import merge from 'lodash/merge'

import customer from '~/modules/customer/locales/ar'
import footer from '~/modules/footer/locales/ar'
import header from '~/modules/header/locales/ar'
import stockists from '~/modules/stockists/locales/ar'
import stockistsOptions from '~/modules/stockists/locales/ar-QA.options'
import streamerce from '~/modules/streamerce/locales/ar'
import user from '~/modules/user/locales/ar'

import layouts from '~/locales/languages/layouts/ar'
import pages from '~/locales/languages/pages/ar'

export default () => {
  return {
    ...customer,
    ...footer,
    ...header,
    ...merge(stockists, stockistsOptions),
    ...streamerce,
    ...user,

    ...layouts,
    ...pages,
  }
}
