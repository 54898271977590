export class BrowserStorageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  get local() {
    return {
      get(key) {
        if (typeof window === 'undefined') return undefined

        const item = window.localStorage.getItem(key)

        if (item === 'undefined') return undefined

        return JSON.parse(item) || null
      },

      set(key, value) {
        if (typeof window === 'undefined') return undefined

        if (!key) {
          throw new Error('Необходимо указать key')
        }

        window.localStorage.setItem(key, JSON.stringify(value))
      },

      has(key) {
        if (typeof window === 'undefined') return undefined
        return Boolean(window.localStorage.getItem(key))
      },

      remove(key) {
        if (typeof window === 'undefined') return undefined
        window.localStorage.removeItem(key)
      },

      clear() {
        if (typeof window === 'undefined') return undefined
        window.localStorage.clear()
      },
    }
  }

  get session() {
    return {
      get(key) {
        if (typeof window === 'undefined') return undefined

        const item = window.sessionStorage.getItem(key)

        return JSON.parse(item) || null
      },

      set(key, value) {
        if (typeof window === 'undefined') return undefined

        if (!key) {
          throw new Error('Необходимо указать key')
        }

        window.sessionStorage.setItem(key, JSON.stringify(value))
      },

      has(key) {
        if (typeof window === 'undefined') return undefined

        return Boolean(window.sessionStorage.getItem(key))
      },

      remove(key) {
        if (typeof window === 'undefined') return undefined

        window.sessionStorage.removeItem(key)
      },

      clear() {
        if (typeof window === 'undefined') return undefined
        window.sessionStorage.clear()
      },
    }
  }
}
