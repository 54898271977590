import { SETTINGS_LANG_NAMES_MAP } from '../../constant'

export const getters = (gaApp) => ({
  contactsModalData(state) {
    const phones = state.phones
    const emails = state.emails
    const messengers = state.messengers

    return {
      phones,
      messengers,
      emails,
    }
  },

  getTickerGap() {
    const hasTicker = this.ticker.has
    const tickerGap = this.ticker.gap

    return (hasTicker && tickerGap) || 0
  },

  getTickerTopGap() {
    return this.ticker.position === 'top' && this.getTickerGap
  },

  getTickerBottomGap() {
    return this.ticker.position === 'bottom' && this.getTickerGap
  },

  // Приводим коды языков к понятным для i18n
  convertedLanguages() {
    return this.languages.map((lang) => {
      const nextLang = { ...lang, initialCode: lang.code }

      if (SETTINGS_LANG_NAMES_MAP[nextLang.code]) {
        nextLang.code = SETTINGS_LANG_NAMES_MAP[nextLang.code]
      }

      return nextLang
    })
  },

  availablePhoneCodes() {
    return this.phoneCodes.filter((phoneCode) =>
      gaApp.features.get('showLoyaltyCardScreens') ||
      gaApp.features.get('newWelcomeScreen') ||
      !gaApp.isMiddleEast
        ? true
        : !gaApp.services.app.phone.isCISPhone(phoneCode.value),
    )
  },

  availableStores() {
    return this.stores.filter((store) =>
      gaApp.features.get('storeSwitcher') || !gaApp.isMiddleEast
        ? true
        : store.code === gaApp.i18n.locale.country,
    )
  },

  // Приводим коды языков в сторах к понятным для i18n
  convertedStores() {
    const getConvertedLangCode = (code) => SETTINGS_LANG_NAMES_MAP[code] ?? code

    return this.stores.map((store) => {
      const languages =
        store.languages &&
        store.languages.map((lang) => {
          const nextLang = { ...lang, initialCode: lang.code }

          nextLang.code = getConvertedLangCode(nextLang.code)

          return nextLang
        })

      const languageDefaultCode =
        store.languageDefaultCode &&
        getConvertedLangCode(store.languageDefaultCode)

      return {
        ...store,
        ...(languages ? { languages } : {}),
        ...(languageDefaultCode ? { languageDefaultCode } : {}),
      }
    })
  },

  // Мапа языков i18n и тех, что пришли из api
  initialLanguagesCodeMap() {
    return this.convertedLanguages.reduce((acc, lang) => {
      // i18n код = коду из массива languages
      acc[lang.code] = lang.initialCode
      return acc
    }, {})
  },

  marketAppUrls() {
    return this.marketApps.reduce((acc, app) => {
      const key = app.text.replace(/\s/g, '')

      acc[key] = app.href
      return acc
    }, {})
  },
})
