import { formatResponse } from '../helpers/nav'

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async fetchNavigation(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.navigation()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
        id: this.gaApp.cache.genId('navigation'),
        cache: {
          ttl: 1000 * 60 * 15, // 15 минут
        },
      })

      return this.gaApp.api.response(formatResponse(response))
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
