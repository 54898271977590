/**
 * Дефолтный цвет текста таба, не равный цвету фона главной страницы
 */
export const DEFAULT_TAB_COLOR = '#000'

/**
 * Фон главной страницы для Middle East
 */
export const MIDDLE_EAST_BACKGROUND_IMAGE = {
  // eslint-disable-next-line no-template-curly-in-string
  url: '/_static/img/gift-cards/main-arab/banner/background.${format}',
  format: ['jpg'],
  screen: [],
}

/**
 * Главной изображение для Middle East
 */
export const MIDDLE_EAST_FOREGROUND_IMAGE = {
  LTR: {
    // eslint-disable-next-line no-template-curly-in-string
    url: '/_static/img/gift-cards/main-arab/banner/ltr/main-image-${screen}.${format}',
    format: ['png', 'webp'],
    screen: ['fullhd', 'tablet', 'mobile'],
  },
  RTL: {
    // eslint-disable-next-line no-template-curly-in-string
    url: '/_static/img/gift-cards/main-arab/banner/rtl/main-image-${screen}.${format}',
    format: ['png', 'webp'],
    screen: ['fullhd', 'tablet', 'mobile'],
  },
}

/**
 * Модификаторы кнопки Купить
 */
export const BUY_BUTTON_MOD = {
  CLASSIC: 'classic',
  CURSOR: 'cursor',
}

/**
 * Количество элементов в блоке бенефитов главной страницы ПК
 */
export const BENEFITS_ITEMS_COUNT = 3
