import { useContext } from '@nuxtjs/composition-api'

import { CHUNK_SIZE_ANALYTICS } from '../../../analytics/constants'

import { STRATEGY_NAME, WIDGET_TYPE } from './../../../constant'

export const useAnalytics = () => {
  const { $gaApp } = useContext()

  // Получение itemListName
  const getItemListName = (data) => {
    return $gaApp.analytics.modules.plp.getItemListName(data)
  }

  const getCategoryId = () => {
    return $gaApp.analytics.modules.plp.getCategoryId()
  }

  // Получение AnalyticData
  const getAnalyticData = () => {
    return $gaApp.analytics.modules.plp.getAnalyticData()
  }

  // Получение поисковой фразы
  const getSearchTerm = () => {
    return $gaApp.analytics.modules.plp.getSearchTerm()
  }

  // При входе на страницу
  const onMounted = () => {
    const { products, number } = $gaApp.stores.plp.main.getPages?.[0] || {}

    if ($gaApp.services.plp.main.isPageTypeListing()) {
      $gaApp.analytics.modules.plp.onMounted()
    }

    if ($gaApp.services.plp.main.isPageTypeSearch()) {
      $gaApp.analytics.modules.plp.onSearchResult()

      $gaApp.analytics.modules.plp.onSearchProducts(products, number)
    }
  }

  // При уходе со страницы
  const onPageLeave = () => {
    $gaApp.analytics.modules.plp.onPageLeaveProductView()
  }

  // При просмотре товара
  const onProductView = (product) => {
    const analyticData = $gaApp.analytics.modules.plp.getAnalyticData()
    $gaApp.analytics.modules.plp.onProductView(product, analyticData)
  }

  // При клике на карточку
  const onClickCard = (product) => {
    const analyticData = $gaApp.analytics.modules.plp.getAnalyticData()
    $gaApp.analytics.modules.plp.onProductClick(product, analyticData)
  }

  // При смене вида листинга
  const onChangeListingView = (view) => {
    const analyticData = $gaApp.analytics.modules.plp.getAnalyticData()
    $gaApp.analytics.modules.plp.onChangeListingView(view, analyticData)
  }

  // При добавлении в избранное
  const onAddToFavorite = (product) => {
    const analyticData = $gaApp.analytics.modules.plp.getAnalyticData()
    $gaApp.analytics.modules.plp.onAddToFavorite(product, analyticData)
  }

  // При добавлении в корзину
  const onAddToCart = (product, isAdded) => {
    const analyticData = $gaApp.analytics.modules.plp.getAnalyticData()
    $gaApp.analytics.modules.plp.onAddToCart(product, isAdded, analyticData)
  }

  // При клике на баббл-категории
  const onClickBubble = (bubble) => {
    $gaApp.analytics.modules.filters.onClickBubble(bubble)
  }

  // При смене чек-бокса экспресс доставки в панели быстрых фильтров
  const onChangeFastFilterExpressDelivery = (value) => {
    // Если чекбокс выключен, то событие не отправляем
    if (value) {
      $gaApp.analytics.modules.filters.onChangeFastFilterExpress(value)
    }
  }

  // При смене сортировки экспресс доставки в панели быстрых фильтров
  const onChangeFastFilterSorting = (value) => {
    $gaApp.analytics.modules.filters.onChangeFastFilterSorting(value)
  }

  // При применении фильтров в модалке
  const onApplyFilters = () => {
    const isFiltersChanged = !$gaApp.services.plp.listing.areHashesEqual()
    const hasSelected = $gaApp.stores.filters.main.hasFiltersSelected

    // Если есть выбранные фильтры и они были изменены, то отправляем событие
    if (hasSelected && isFiltersChanged) {
      return $gaApp.analytics.modules.filters.onAppliedFilters()
    }
  }

  // При открытии модалки фильтров
  const onModalOpen = () => {
    $gaApp.analytics.modules.filters.onOpenedFiltersModal()
  }

  // При клике на хлебную крошку
  const onBreadcrumbClick = (breadcrumbs) => {
    $gaApp.analytics.modules.plp.onBreadcrumbClick(breadcrumbs)
  }

  /**
   * Может использоваться только для виджета листинга продуктов при нулевой поисковой выдаче,
   * т.к. digi аналитика в plp не включена
   *
   * @param {string} type — тип листинга
   * @return {Object} Объект с функциями
   * - getAnalyticData: возвращает analyticData для глобальной аналитики продуктов / виджетов
   * - getCartAnalyticDetails: возвращает analyticsDetails передаваемых в payload запроса добавления продукта в корзину
   */
  const searchNoResultProductsAnalytic = ({ type }) => {
    const getAnalyticData = ({ name = '', products }) => {
      return {
        path: `${type}/block: ${name}`,
        chunkSize: CHUNK_SIZE_ANALYTICS,
        digiAnalytics: true,
        placement: {
          strategyName: STRATEGY_NAME,
          products,
          widgetType: WIDGET_TYPE,
        },
        plpType: 's',
        searchTerm: getSearchTerm(),
      }
    }

    const getCartAnalyticDetails = ({ itemIndex, itemListName }) => {
      return {
        analyticsDetails: {
          itemListName,
          indexTotal: itemIndex + 1,
        },
      }
    }

    return { getAnalyticData, getCartAnalyticDetails }
  }

  return {
    getCategoryId,
    getItemListName,
    getAnalyticData,
    getSearchTerm,
    onMounted,
    onPageLeave,
    onProductView,
    onClickCard,
    onChangeListingView,
    onAddToFavorite,
    onAddToCart,
    onClickBubble,
    onChangeFastFilterExpressDelivery,
    onChangeFastFilterSorting,
    onApplyFilters,
    onModalOpen,
    onBreadcrumbClick,
    searchNoResultProductsAnalytic,
  }
}
