export class ThankYouService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получает данные заказа для отображения страницы успешного оформления заказа - thank you page (TYP).
   * @param {object} redirect - данные редиректа от роута
   * @param {string} redirect.maskedId - id заказа
   */
  async getData({ maskedId }) {
    try {
      const data =
        await this.gaApp.services.checkout.api.getThankYouData(maskedId)

      this.gaApp.stores.checkout.thankYou.$patch(data)

      this.gaApp.services.checkout.cleanup.removeLastOrderId(maskedId)
      this.gaApp.services.checkout.cleanup.removeCheckoutData()

      this.gaApp.eventBus.publish('module/checkout/success')
      this.gaApp.services.checkout.shippingCourierComment.removeCommentFromLocalStorage()
    } catch (error) {
      console.log(error)
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }
}
