import { CommonDataBuilder } from './builders'

export class MainRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getAppInfo() {
    const params = {}

    const { url } = this.endpoints.appInfo()

    try {
      const response = await this.gaApp.api
        .request({ apiType: 'frontStatic' })
        .get(url, {
          params,
        })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getRedirectData(parameters = {}) {
    const params = { ...parameters }

    const { url } = this.endpoints.redirect()

    try {
      const response = await this.gaApp.api
        .request({ apiType: 'webBff' })
        .post(url, params, {
          cache: {
            ttl: 1000 * 60 * 15, // 15 минут
          },
        })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getCommonData(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.common()

    try {
      const response = await this.gaApp.api
        .request({ apiType: 'webBff' })
        .get(url, {
          params,
          id: this.gaApp.cache.genId('settings'),
          cache: {
            ttl: 1000 * 60 * 15, // 15 минут
          },
        })

      response.data = new CommonDataBuilder(response.data)
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getCookieAgreementText() {
    const { url } = this.endpoints.cookieAgreementText()

    try {
      const response = await this.gaApp.api.request().get(url)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
