import {
  DEFAULT_LISTING_PAGE,
  FILTER_TYPE,
  LISTING_PAGE_TYPE,
  LOAD_DIR,
  STREAM_UPDATE_STATUS_INTERVAL,
  STREAMS_BASE_URL,
  STREAMS_PAGE_CHUNK_SIZE,
} from '../constants'

import { ListService } from './list.service'

export class StreamsService extends ListService {
  constructor(gaApp) {
    super(gaApp, LISTING_PAGE_TYPE.STREAMS)
    this.updateStatusesIntervalId = null
  }

  // Загрузка начальных данных
  // сброс хранилища, загрузка фильтров, загрузка списка стримов, обновление статусов
  async loadData() {
    this.gaApp.stores.articles.listStreams.isPreview = false

    await this.gaApp.services.articles.filters.loadFilters(
      this.gaApp.route.query,
      LISTING_PAGE_TYPE.STREAMS,
    )

    // загрузить данные по стримам в храниоище
    await this.fetchInitialData()

    // обновить статусы стримов
    await this.updateStatuses()

    // обновить номер страницы - для случае если перезапрашивали 0 страницу
    this.updatePageInCurrentPath()
  }

  async loadPreviewListingData(id) {
    await this.loadPreviewListing(id)
    await this.updateStatuses()
  }

  // Загружаем дынные для превью карточки стрима в листинге.
  async loadPreviewListing(id) {
    this.gaApp.stores.articles.listStreams.isLoading = true
    this.gaApp.stores.articles.listStreams.isPreview = true

    try {
      const response =
        await this.gaApp.services.articles.apiStreams.fetchStreamsListingPreview(
          id,
        )

      const data = {
        ...response,
        items: response.streams,
      }

      this.clearPages()
      this.setInitialListingDataToStore(data)

      this.gaApp.stores.articles.listStreams.totalArticlesAmount =
        response.streams.length
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.listStreams.isLoading = false
    }
  }

  // Загрузка начальной страницы стримов
  // фильтры передаем при загрузке страницы стримов с выделенной рубрикой для SSR
  async fetchInitialData(filters) {
    this.resetFirstLoadedImagesCount()
    this.gaApp.stores.articles.listStreams.isLoading = true

    try {
      const params = this.prepareParamsForInitialLoad(filters)

      let response =
        await this.gaApp.services.articles.apiStreams.fetchStreamsListing(
          params,
        )

      // Если для текущей (не первой) страницы нет данных - грузим первую страницу
      const shouldRetryResponse =
        params.pagination.pageNumber !== DEFAULT_LISTING_PAGE &&
        !response.items.length

      if (shouldRetryResponse) {
        params.pagination.pageNumber = DEFAULT_LISTING_PAGE
        response =
          await this.gaApp.services.articles.apiStreams.fetchStreamsListing(
            params,
          )
      }

      if (!response.items.length) {
        this.showEmptyError()
      }

      this.setCurrentPageNumber(params.pagination.pageNumber)
      this.clearPages()
      this.setInitialListingDataToStore(response)
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.listStreams.isLoading = false
    }
  }

  // Подготовить параметры для запроса листинга
  prepareParamsForInitialLoad(filters) {
    return this.prepareParams({
      pageSize: STREAMS_PAGE_CHUNK_SIZE,
      pageNumber: this.getPageNumberFromRouteQuery(),
      filters,
    })
  }

  // Загружаем страницу со стримами если это возможно
  // страница не загружается в текущий момент
  // странице не была загружена ранее
  // страница не была отрисована ранее
  async triggerFetchPage(pageNumber, dir) {
    const cannotFetch =
      this.isPageFetching(pageNumber) ||
      !this.isPageExists(pageNumber) ||
      this.isPageRendered(pageNumber)

    if (cannotFetch) {
      return
    }

    await this.fetchPage(pageNumber, dir)
  }

  // Загружаем следующую страницу с материалами
  async loadNextPage() {
    await this.triggerFetchPage(
      this.gaApp.stores.articles.listStreams.lastLoadedPageNumber + 1,
      LOAD_DIR.NEXT,
    )
  }

  // Загружаем предыдущую страницу с материалами
  async loadPrevPage() {
    if (this.gaApp.stores.articles.listStreams.firstLoadedPageNumber === 0) {
      return
    }

    await this.triggerFetchPage(
      this.gaApp.stores.articles.listStreams.firstLoadedPageNumber - 1,
      LOAD_DIR.PREV,
    )
  }

  // Загружаем следующую страницу со стримами
  onPageIntersect(pageNumber) {
    this.triggerFetchPage(pageNumber, LOAD_DIR.NEXT)
  }

  // Загрузка страницы со списком стримов
  async fetchPage(pageNumber, dir) {
    this.setLoadingDirection(dir)
    this.gaApp.stores.articles.listStreams.fetchingPageNumber = pageNumber

    try {
      const lastTranslationDate =
        dir === LOAD_DIR.NEXT
          ? this.gaApp.stores.articles.listStreams.lastActualPublicationDate
          : undefined

      // Подготовим параметры аналогично статьям,
      // за исключением параметра даты последнего элемента
      const params = this.prepareParams({
        pageNumber,
        lastTranslationDate,
      })

      const { streams } =
        await this.gaApp.services.articles.apiStreams.fetchStreamsListing(
          params,
        )

      const page = {
        number: pageNumber,
        items: streams,
      }

      this.addPage(page, dir)
    } catch (error) {
      console.error('error')
    } finally {
      this.setLoadingDirection(null)
      this.gaApp.stores.articles.listStreams.fetchingPageNumber = null
    }
  }

  // заносим значение статуса стрима в таблицу - ключ (id стрима) - значение (статус)
  async updateStatuses() {
    try {
      const { statuses } =
        await this.gaApp.services.articles.apiStreams.fetchStreamsStatuses()

      if (!statuses || !statuses.length) {
        return
      }

      // Переводим массив статусов в объект
      const mappedStatuses = statuses.reduce((result, status) => {
        return Object.assign(result, { [status.externalId]: status.statusType })
      }, {})

      this.gaApp.stores.articles.streams.statuses = Object.assign(
        {},
        this.gaApp.stores.articles.streams.statuses,
        mappedStatuses,
      )
    } catch (error) {
      console.error(error)
    }
  }

  // Обновление статуса стримов по интервалу
  fetchStatusesByInterval() {
    if (this.updateStatusesIntervalId) {
      return
    }

    // обновляем статусы стримов раз в 30 секунд
    this.updateStatusesIntervalId = setInterval(
      () => this.updateStatuses(),
      STREAM_UPDATE_STATUS_INTERVAL,
    )
  }

  // Удаление интервала обновления стримов
  clearUpdateStatusesInterval() {
    clearInterval(this.updateStatusesIntervalId)
    this.updateStatusesIntervalId = null
  }

  // Обновление статуса одиночно стрима на заданный
  updateStatus(status) {
    this.gaApp.stores.articles.streams.statuses[status.externalId] =
      status.statusType
  }

  // Загружаем материалы конкретной рубрики
  async fetchStreamsRubric(rubric) {
    this.gaApp.stores.articles.listStreams.isLoading = true

    try {
      const pageNumber = this.getPageNumberFromRouteQuery()

      this.gaApp.stores.articles.listStreams.currentPageNumber = pageNumber

      const params = {
        pagination: {
          pageSize: STREAMS_PAGE_CHUNK_SIZE,
          pageNumber,
        },
        rubric,
      }

      const response =
        await this.gaApp.services.articles.apiStreams.fetchStreamsFilterListing(
          params,
        )

      const data = {
        ...response,
        items: response.streams,
      }

      this.setInitialListingDataToStore(data)
    } catch {
      this.clearPages()
    } finally {
      this.gaApp.stores.articles.listStreams.isLoading = false
    }
  }

  async fetchStreamsFilterListing({ filterType, filterKey }) {
    // Если это клиент - перенаправляем на листинг и проставляем фильтры в url
    if (!this.gaApp.isServer) {
      return this.redirectToListingPage(filterType, filterKey, STREAMS_BASE_URL)
    }

    // Потенциально помимо рубрик далее появятся теги
    switch (filterType) {
      case FILTER_TYPE.rubric:
        await this.fetchStreamsRubric(filterKey)
        break
      default: {
        const filters = [{ key: filterKey, type: filterType }]
        await this.fetchInitialData(filters)
      }
    }
  }

  showEmptyError() {
    this.gaApp.services.notification.main.open({
      data: {
        props: {
          text: this.gaApp.i18n.t('articles.streamsNotFound'),
          alert: true,
        },
      },
    })
  }

  reset() {
    this.gaApp.stores.articles.listStreams.$reset()
  }
}
