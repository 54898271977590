export const endpoints = {
  getCart: () => ({
    url: 'cart',
  }),
  items: () => ({
    url: 'cart/items',
  }),
  item: () => ({
    url: 'cart/item',
  }),
  itemByBarcode: () => ({
    url: 'cart/item/barcode',
  }),
  updateItemQty: () => ({
    url: 'cart/item/update-qty',
  }),
  coupon: () => ({
    url: 'cart/coupon',
  }),
  recollectAvailableCoupons: () => ({
    url: 'cart/recollect-available-coupons',
  }),
  validateStock: () => ({
    url: 'cart/validate-stock',
  }),
  reorder: () => ({
    url: 'cart/reorder',
  }),
  sharingHash: () => ({
    url: 'cart/sharing/share',
  }),
  sharingList: () => ({
    url: 'cart/sharing/list',
  }),
}
