import { ACTIONS } from '../../../constants'
import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmGa4LikeClickModel extends GtmBaseModel {
  constructor(data) {
    super(ACTIONS.USER_INTERACTION.GA4)

    const { articleId, eventCategory, actionType } = data

    this.event_category = eventCategory
    this.action_type = actionType
    this.article_id = articleId
  }
}
