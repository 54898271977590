import { TYPE } from '../../constant'

export class SelectedFiltersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Генерирует новый объект фильтра с полями выбранного фильтра в зависимости от типа фильтра
   *
   * @param {Object} filter - Объект формата с бэка
   * @return {Object} - Объект с форматом выбранных фильтров в зависимости от типа фильтра
   */
  filterSelectedValue(filter) {
    const filterInternal = {
      id: filter.id,
      key: filter.key,
      type: filter.type,
    }

    switch (filter.type) {
      case TYPE.CHECKBOX: {
        filterInternal.value = Boolean(filter.value)
        break
      }

      case TYPE.CHECKLIST_SINGLE: {
        filterInternal.value = filter.value
        break
      }

      case TYPE.CHECKLIST_ONLY: {
        filterInternal.value = filter.values
          .filter((el) => el.isSelected)
          .find((el) => el.id).id
        break
      }

      case TYPE.CHECKLIST_TREE:
      case TYPE.CHECKLIST_MULTIPLE:
      case TYPE.QUICK: {
        filterInternal.values = filter.values
          .filter((el) => el.isSelected)
          .map((el) => el.id)
        break
      }
    }

    return filterInternal
  }

  /**
   * Применяет преобразование к каждому фильтру,
   * и отфильтровывает записи без пустых значений.
   *
   * @param {Array} filters - массив объектов с фильтрами с бэка
   * @return {Array} Массив объектов с типом выбранных фильтров и с
   *                 непустыми значениями.
   */
  getSelected(filters = []) {
    return filters
      .map((filter) => this.filterSelectedValue(filter))
      .filter((el) => el.values?.length || el.value)
  }
}
