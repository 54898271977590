import merge from 'lodash/merge'

import ar from '~/locales/languages/ar'
import arAE from '~/locales/languages/ar-AE'
import arQA from '~/locales/languages/ar-QA'
import arSA from '~/locales/languages/ar-SA'
import en from '~/locales/languages/en'
import enAE from '~/locales/languages/en-AE'
import enQA from '~/locales/languages/en-QA'
import enSA from '~/locales/languages/en-SA'
import kk from '~/locales/languages/kk'
import ru from '~/locales/languages/ru'
import ruBY from '~/locales/languages/ru-BY'
import ruKZ from '~/locales/languages/ru-KZ'

const localsMap = {
  'ru-RU': {},
  'ru-BY': ruBY(),

  'ru-KZ': ruKZ(),
  'kk-KZ': merge({}, ru(), kk()),

  'ar-QA': merge({}, ru(), ar(), arQA()),
  'en-QA': merge({}, ru(), en(), enQA()),

  'ar-AE': merge({}, ru(), ar(), arAE()),
  'en-AE': merge({}, ru(), en(), enAE()),

  'ar-SA': merge({}, ru(), ar(), arSA()),
  'en-SA': merge({}, ru(), en(), enSA()),

  // для тестирования
  'ar-RU': merge({}, ru(), ar()),
  'en-RU': merge({}, ru(), en()),
}

export default ({ app, i18n, $dayjs, $gaApp }, inject) => {
  const {
    locale,
    localeProperties,
    // getBrowserLocale,
    getLocaleCookie,
    setLocaleCookie,
  } = i18n

  // const currentEnvLocale = $config.NODE_LOCALE_ENV

  /**
   * getBrowserLocale определяет приоритетную локаль в настройках браузера из имеющихся в codesByCountry
   * если не находит совпадений, то отдает undefined
   * например, в настройках браузера указаны ru и en, а codesByCountry === ['ar'], то getBrowserLocale() === undefined, поскольку нет совпадений
   */
  // const browserLocale = getBrowserLocale()

  const cookieLocale = getLocaleCookie()

  const { iso: localeAndCountry } = localeProperties

  const proposedLocale = locale // browserLocale || locale

  // если предлагаемая локаль есть в локалях страны
  if (!cookieLocale) {
    setLocaleCookie(proposedLocale)
  }

  /**
   * слияние значений из файла текущей страны с локалью
   */
  const { locales } = $gaApp.modules

  i18n.mergeLocaleMessage(locale, {
    ...locales[localeAndCountry],
    ...localsMap[localeAndCountry],
  })

  /**
   * установка значений для dayjs
   */
  const dayjsUpdateLocale = (locale) => {
    $dayjs.updateLocale(locale, {
      monthsShort: i18n.t('app.date.monthsShort').split('_'),
      months: i18n.t('app.date.monthsGenitive').split('_'),
      meridiem: (hour) => {
        return hour >= 12
          ? i18n.t('app.date.ampm').split('_')[1]
          : i18n.t('app.date.ampm').split('_')[0]
      },
    })

    $dayjs.locale(locale)
  }

  // обновляем текущую локаль
  dayjsUpdateLocale(i18n.locale)

  i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
    const { dayjsLocale } = i18n.locales.find(({ code }) => code === newLocale)

    dayjsUpdateLocale(dayjsLocale)
  }

  inject('i18n', {
    locale: i18n.localeProperties,
    locales: i18n.locales,
    t: i18n._vm.$t.bind({ $i18n: i18n }),
    tc: i18n._vm.$tc.bind({ $i18n: i18n }),
    d: i18n._vm.$d.bind({ $i18n: i18n }),
    te: i18n._vm.$te.bind({ $i18n: i18n }),
    n: i18n._vm.$n.bind({ $i18n: i18n }),
    setLocaleCookie: i18n.setLocaleCookie.bind({ $i18n: i18n }),
    switchLocalePath: app.switchLocalePath,
  })
}
