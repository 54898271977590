import { ref, toRefs } from 'vue'

import { GaAction } from '@ga/ui-components/action'

import {
  useApplyContext,
  useCartCounter,
  useHoverFocus,
  useSlot,
  useStockStatus,
  useTestIds,
} from '../../../composables'
import { DISCLAIMER_TOOLTIP_PLACEMENT, ROOT_TEST_ID } from '../../../constants'
import { propValidator } from '../../../utils'
import { GaProductCardCartAdapter } from '../../adapters/cart-adapter'
import { GaProductCardFavoriteAdapter } from '../../adapters/favorite-adapter'
import { GaProductCardLabelsAdapter } from '../../adapters/labels-adapter'
import { GaProductCardAttributes } from '../../molecules/attributes'
import { GaProductCardDisclaimer } from '../../molecules/disclaimer'
import { GaProductCardMedia, VIDEO_STRATEGY } from '../../molecules/media'
import { GaProductCardName } from '../../molecules/name'
import { GaProductCardPrice } from '../../molecules/price'
import { GaProductCardStatus } from '../../molecules/status'

import { useMods, useSizes } from './scripts/composables'
import { RATIO, SIZE } from './scripts/consts'
import { product as schemaProduct } from './scripts/schemas'

// @vue/component
export default {
  name: 'ga-product-card-large',

  components: {
    GaAction,

    GaProductCardPrice,
    GaProductCardStatus,
    GaProductCardFavoriteAdapter,
    GaProductCardCartAdapter,
    GaProductCardLabelsAdapter,
    GaProductCardMedia,
    GaProductCardAttributes,
    GaProductCardDisclaimer,
    GaProductCardName,
  },

  props: {
    product: {
      type: Object,
      default: null,
      validator: (value) => propValidator(value, schemaProduct),
    },

    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },

    reversed: {
      type: Boolean,
      default: false,
    },

    forceShowAttrs: {
      type: Boolean,
      default: false,
    },

    cancelDefaultAddToCart: {
      type: Boolean,
      default: false,
    },

    cancelDefaultUpdateQty: {
      type: Boolean,
      default: false,
    },

    cartLoading: {
      type: Boolean,
      default: false,
    },

    showAddToCart: {
      type: Boolean,
      default: true,
    },

    showAddToFavorites: {
      type: Boolean,
      default: true,
    },

    showPriceCurrency: {
      type: Boolean,
      default: true,
    },

    skeletonEnable: {
      type: Boolean,
      default: true,
    },

    tooltipPlacement: {
      type: String,
      default: DISCLAIMER_TOOLTIP_PLACEMENT.AUTO,
      validator: (value) =>
        Object.values(DISCLAIMER_TOOLTIP_PLACEMENT).includes(value),
    },

    maxCountMedia: {
      type: Number,
      default: 10,
    },

    rootTestId: {
      type: String,
      default: ROOT_TEST_ID,
    },

    videoStrategy: {
      type: String,
      default: VIDEO_STRATEGY.NONE,
    },

    // Позволяет включать css-свойство "content-visibility" c значением 'auto', для некоторых элементов
    contentVisibilityAuto: {
      type: Boolean,
      default: true,
    },

    // Соотношение сторон для изображения в карточке товара
    ratio: {
      type: String,
      default: RATIO.RECT,
      validator: (value) => Object.values(RATIO).includes(value),
    },
  },

  emits: [
    'click-card',
    'mouseenter-card',
    'click-add-to-favorite',
    'add-to-favorite',
    'click-add-to-cart',
    'add-to-cart',
    'open-preview',
  ],

  setup(props, { emit }) {
    // refs
    const linkRef = ref()
    const rootRef = ref()
    const cartRef = ref()

    // props
    const {
      product,
      size,
      reversed,
      showAddToCart,
      showAddToFavorites,
      contentVisibilityAuto,
      ratio,
    } = toRefs(props)

    // use
    const { isNotInStock, isWholeStock, isOutOfStock, productStatus } =
      useStockStatus(product)

    const { cartWithCounter } = useCartCounter(product)

    const {
      contextShowAddToCart,
      contextShowAddToFavorites,
      contextNuxtProductLink,
    } = useApplyContext(product, showAddToCart, showAddToFavorites)

    // view
    const { withHover, isHovering, highlight } = useHoverFocus(
      linkRef,
      rootRef,
      cartRef,
    )

    const { sizes } = useSizes(size)

    const slot = useSlot({
      product,
      sizes,
      props: { reversed },
      mods: { highlight },
    })

    const { mods } = useMods(
      size,
      reversed,
      highlight,
      isOutOfStock,
      isWholeStock,
      isNotInStock,
      withHover,
      cartWithCounter,
      contentVisibilityAuto,
      ratio,
    )

    const testIds = useTestIds(props.rootTestId, props.product)

    const mediaListeners = {
      'video-end': () => emit('media-video-end'),
      'video-error': () => emit('media-video-error'),
      'video-start': () => emit('media-video-start'),
      'video-hover': ($event) => emit('media-video-hover', $event),
      'video-swipe': ($event) => emit('media-video-swipe', $event),
      'video-intersect': ($event) => emit('media-video-intersect', $event),
      'video-wrapper-hover': ($event) =>
        emit('media-video-wrapper-hover', $event),
    }

    return {
      linkRef,
      rootRef,
      cartRef,

      testIds,

      highlight,
      isHovering,
      mods,

      isNotInStock,
      productStatus,

      contextShowAddToCart,
      contextShowAddToFavorites,
      contextNuxtProductLink,

      slot,
      sizes,
      mediaListeners,
    }
  },
}
