import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { getAddressId } from '../utils'

/**
 * Предоставляет все необходимое для работы с сохраненными курьерскими адресами
 */
export const useLocationSavedAddresses = () => {
  const { $gaApp } = useContext()

  const savedAddresses = computed(
    () => $gaApp.stores.location.addresses.savedAddresses,
  )

  const hasSavedAddresses = computed(
    () => $gaApp.stores.location.addresses.hasSavedAddresses,
  )

  const getSavedAddressById = (savedAddressId) => {
    return $gaApp.stores.location.addresses.getSavedAddressById(savedAddressId)
  }

  return {
    savedAddresses,
    hasSavedAddresses,
    getSavedAddressById,
    getAddressId,
  }
}
