import { nextTick } from 'vue'
/**
 * Кастомное решение сохранения позиции курсора в поле ввода национальной части номера телефона (без префикса +7).
 * Стало актуально после перехода на программную маску.
 * https://git.goldapple.ru/web/frontend/projects/shop/frontend/-/merge_requests/2463
 *
 * @param {Boolean} disablePositionCursorSave - Отключает сохранение позиции курсора
 */
export function useCursorPosition(disablePositionCursorSave = false) {
  /**
   * @param {Event} event - Объект события.
   * @param {HTMLInputElement} event.target - Элемент input, на котором произошло событие
   * @param {string | undefined} event.inputType - Тип действия, вызвавшего изменение значения (например, 'insertText' или 'deleteContentBackward')
   */
  function saveCursorPosition({ target, inputType }) {
    // Стартовая позиция курсора
    const cursorPosition = target.selectionStart
    // Условия для автоматического перемещения курсора:
    // 1. Пользователь вводит символ. Все inputType связанные с удалением, содержат 'delete'
    const isInsertionOperation = !inputType?.includes('delete')
    // 2. Предыдущий символ является разделителем. Если не число, считаем, что символ - разделитель.
    const isPrevSymbolSeparator = isNaN(
      Number(target.value[cursorPosition - 1]),
    )
    const shouldJumpOverSeparator =
      isInsertionOperation && isPrevSymbolSeparator

    // Сдвигаем курсор после разделителя при выполнении условий
    // Пример: ввод "12|" -> ввод "3" -> "12-3|" (курсор перепрыгивает '-')
    const newCursorPosition = shouldJumpOverSeparator
      ? cursorPosition + 1
      : cursorPosition

    // Устанавливаем позицию курсора
    target.setSelectionRange(newCursorPosition, newCursorPosition)
  }

  /**
   * Обработчик события изменения позиции курсора
   *
   * @param {Event} event - Объект события.
   */
  function onCursorPositionChange(event) {
    if (disablePositionCursorSave) return

    // Сохраняем позицию курсора перед ререндером нового значения
    const cursorPosition = event.target.selectionStart

    // Дожидаемся ререндера нового значения
    nextTick(() => {
      // Возвращаем позицию курсора
      event.target.setSelectionRange(cursorPosition, cursorPosition)
      saveCursorPosition(event)
    })
  }

  return {
    onCursorPositionChange,
  }
}
