import { ApiService } from './api.service'
import { CoreService } from './core.service'
import { ExternalAuthBuilder } from './external-auth-builder.service'
import { InterceptorsService } from './interceptors.service'
import { LocalStorageService } from './local-storage.service'
import { MainService } from './main.service'
import { ModalService } from './modal.service'
import { ModalAuthTypesService } from './modal-auth-types.service'
import { ModalConfirmService } from './modal-confirm.service'
import { ModalStartService } from './modal-start.service'
import { PageService } from './page.service'
import { VkBuilder } from './vk-builder.service'
import { YandexBuilder } from './yandex-builder.service'

export const services = (gaApp) => ({
  main: new MainService(gaApp),
  core: new CoreService(gaApp),
  api: new ApiService(gaApp),
  interceptors: new InterceptorsService(gaApp),
  modal: new ModalService(gaApp),
  modalAuthTypes: new ModalAuthTypesService(gaApp),
  modalStart: new ModalStartService(gaApp),
  modalConfirm: new ModalConfirmService(gaApp),
  page: new PageService(gaApp),
  localStorage: new LocalStorageService(gaApp),
  externalAuthBuilder: new ExternalAuthBuilder(gaApp),
  yandexBuilder: new YandexBuilder(gaApp),
  vkBuilder: new VkBuilder(gaApp),
})
