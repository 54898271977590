import { TYPE } from '../../constants'
import { GtmGa4QuickFilterClickModel } from '../../models'
import { BaseBuilder } from '../base.builder/base.builder'

export class QuickFilterClickBuilder extends BaseBuilder {
  constructor(data) {
    const models = new Map([[TYPE.GTM_4, GtmGa4QuickFilterClickModel]])
    super(models, data)
  }
}
