import ar from './ar.json'
import en from './en.json'
import kk from './kk.json'
import ru from './ru.json'
import ruRUOptions from './ru-RU.options.json'

export const locales = {
  'ru-RU': [ru, ruRUOptions],
  'kk-KZ': kk,
  'ar-AE': ar,
  'ar-QA': ar,
  'ar-SA': ar,
  'en-AE': en,
  'en-QA': en,
  'en-SA': en,
  'ar-RU': ar,
  'en-RU': en,
}
