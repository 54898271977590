// урлы нового фронта для перехода с перезагрузкой
const URLS_TO_REDIRECT = [
  '/stockists',
  '/documents',
  '/loyalty',
  '/contacts',
  '/faq',
]

export default async function ({ from, route, $gaApp }) {
  // очищаем данные в браузере о режиме webview, если разрешение экрана больше мобильного
  if ($gaApp.isWebview && $gaApp.mq('tablet-small+') && process.client) {
    $gaApp.services.app.main.clearWebview()
  }

  if (process.server || from.path === route.path) {
    return null
  }

  $gaApp.services.app.main.updateAppVersion()

  // рефреш для обновления на новую версию прилы
  if ($gaApp.stores.app.main.hasForceUpdate) {
    window.location.assign($gaApp.route.fullPath)
    await new Promise(() => {})
  }

  const isToGifts = route.path.startsWith('/cards')
  const isFromGifts = from.path.startsWith('/cards')
  const isBetweenGifts = isToGifts && isFromGifts
  const shouldReloadGifts = (isToGifts || isFromGifts) && !isBetweenGifts

  const isToB2BGifts = route.path.startsWith('/b2b/cards')
  const isFromB2BGifts = from.path.startsWith('/b2b/cards')
  const isBetweenB2BGifts = isToB2BGifts && isFromB2BGifts
  const shouldReloadB2BGifts =
    (isToB2BGifts || isFromB2BGifts) && !isBetweenB2BGifts

  const isToGame =
    route.path.startsWith('/game/') || route.path.startsWith('/games/')
  const isFromGame =
    from.path.startsWith('/game/') || from.path.startsWith('/games/')
  const shouldReloadGame = isToGame || isFromGame

  const isNewCheckout =
    route.path.startsWith('/new-checkout') ||
    route.path.startsWith('/gocheckout')
  const shouldReloadCheckout = isNewCheckout

  const shouldReloadToNewFrontend = URLS_TO_REDIRECT.includes(route.path)

  // вход и выход из раздела подарочных карт с перезагрузкой страницы (сделано для работы yandex metrika с webvisor)
  // вход в раздел игр с перезагрузкой страницы
  // вход в раздел нового чекаута
  // вход в на страницы нового фронта
  if (
    shouldReloadGifts ||
    shouldReloadB2BGifts ||
    shouldReloadGame ||
    shouldReloadCheckout ||
    shouldReloadToNewFrontend
  ) {
    window.location.assign($gaApp.route.fullPath)
    // пустым промисом, останавливаем работу js
    await new Promise(() => {})
  }

  $gaApp.services.user.main.setUserViewGUID()

  $gaApp.stores.app.main.setRouter({
    from: {
      fullPath: from.fullPath,
      path: from.path,
      params: from.params,
    },
    to: {
      fullPath: route.fullPath,
      path: route.path,
      params: route.params,
    },
  })
}
