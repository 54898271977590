export class NumberService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Функция для определения кода локали,
   * которая будет использована для форматирования чисел
   *
   * @param {string} locale - Код локали
   *
   * @returns {string} Код локали
   */
  getLocaleCode(locale) {
    switch (locale) {
      // Intl не поддерживает код kk-KZ. Преобразуем в ru-KZ
      case 'kk-KZ':
        return 'ru-KZ'

      default:
        return locale
    }
  }

  /**
   * Функция для форматирования чисел с учетом локали.
   *
   * @param {number} value   - Число, которое нужно отформатировать.
   * @param {Object} options - Опции форматирования, которые будут переданы в Intl.NumberFormat.
   * @param {string} locale  - Код локали, которая будет использоваться для форматирования. Если не указан, то
   *                           будет использоваться locale, установленная в приложении.
   *
   * @returns {string} Строковое представление числа value.
   */
  format(value, options = {}, locale = this.gaApp.i18n.locale.iso) {
    const localeCode = this.getLocaleCode(locale)

    const mergedOptions = {
      numberingSystem: 'latn',
      maximumFractionDigits: 2,
      ...options,
    }

    return (
      new Intl.NumberFormat(localeCode, mergedOptions)
        .format(value)
        /**
         * NumberFormat возвращает строку с неразрывными пробелами
         * неразрывный пробел и обычный пробел различются по ширине
         * поэтому неразрывные пробелы заменяем на обычные пробелы
         */
        .replace(/\s/g, ' ')
    )
  }
}
