import { BLOCK_TYPES } from '../constants'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * запрос данных для слотов главной страницы
   */
  async fetchSlots() {
    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const user = this.gaApp.services.user.main.data
      const segments = this.gaApp.services.featuresToggle.main.getZSegments()
      const cacheData = this.gaApp.services.cache.main.getServerData('home')

      const requestParams = {
        customerGroupId: user.customerGroupId || user.groupId || '0',
        z: segments || null,
        ...addressParams,
      }

      const { data } =
        cacheData ||
        (await this.gaApp.repositories.home.main.slots(requestParams))

      // заполняем в сторе items и statuses
      data.blocks.forEach((block) => {
        this.gaApp.services.home.slots.updateStatus(block.id, block.data)
      })
      this.gaApp.stores.home.slots.items = data.blocks
      this.gaApp.services.home.slots.fillStores()
    } catch (error) {
      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  /**
   * запрос данных для блока главной страницы
   */
  async fetchBlock(slotData) {
    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const user = this.gaApp.services.user.main.data
      const segments = this.gaApp.services.featuresToggle.main.getZSegments()

      const requestParams = {
        ...slotData,
        customerGroupId: user.customerGroupId || user.groupId || '0',
        z: segments || null,
        ...addressParams,
      }

      return await this.gaApp.repositories.home.main.block(requestParams)
    } catch (error) {
      // при ошибке запроса данных одного блока возвращаем null
      return { data: null }
    }
  }

  async updateBlockData(slotId) {
    const { id, type, url, version, index, blockId } =
      this.gaApp.stores.home.slots.asObject[slotId]
    // проверяем тип на допустимость
    if (Object.values(BLOCK_TYPES).includes(type)) {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      // готовим данные для запроса, запрашиваем данные блока
      const blockRequestData = {
        id,
        type,
        url,
        version,
        ...addressParams,
      }

      if (blockId) {
        blockRequestData.blockId = blockId
      }

      const response = await this.fetchBlock(blockRequestData)
      const responseData = response.data

      // обновляем статус слота, заполняем его полученными данными
      this.gaApp.services.home.slots.updateStatus(id, responseData)
      if (responseData) {
        const block = this.gaApp.stores.home.slots.items[index]
        block.data = responseData
        this.gaApp.services.home.slots.fillStoresBlockData(block)
      }
    }
  }

  async fetchStoryItem(storyId) {
    try {
      const addressParams =
        this.gaApp.services.location.main.getDeliveryAddressParams()

      const user = this.gaApp.services.user.main.data

      const { data } = await this.gaApp.repositories.home.main.storyItem(
        storyId,
        {
          customerGroupId: user.customerGroupId || user.groupId || '0',
          ...addressParams,
        },
      )

      this.gaApp.stores.home.stories.products.items = data.products
    } catch {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('home.stories.getProductsError'),
      )
    }
  }
}
