export class CitiesRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async geoSuggest(params = {}, requestParams = {}) {
    const { url } = this.endpoints.geoSuggest()

    try {
      const response = await this.gaApp.api
        .request(requestParams)
        .get(url, { params })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async geoGeocode(params = {}) {
    const { url } = this.endpoints.geoGeocode()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
