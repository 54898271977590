import { computed, toRefs } from 'vue'

import { GaImageResponsive } from '@ga/ui-components/image-responsive'

import { DEFAULT_WIDTH } from './scripts/const'

// @vue/component
export default {
  name: 'ga-country-flag',

  components: {
    GaImageResponsive,
  },

  inheritAttrs: false,

  props: {
    className: {
      type: String,
      default: null,
    },
    image: {
      type: [String, Object],
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: DEFAULT_WIDTH,
    },
  },

  setup(props) {
    const { rounded, bordered, width } = toRefs(props)

    // Объект для ga-image-responsive
    const internalImage = computed(() =>
      typeof props.image === 'string'
        ? {
            url: props.image,
            format: [],
            screen: [],
          }
        : props.image,
    )

    const mods = computed(() => ({
      rounded: rounded.value,
      bordered: bordered.value,
    }))

    const styles = computed(() => ({
      width: `${width.value}px`,
    }))

    return {
      mods,
      styles,
      internalImage,
    }
  },
}
