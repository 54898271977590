import merge from 'lodash/merge'

import customer from '~/modules/customer/locales/en'
import footer from '~/modules/footer/locales/en'
import header from '~/modules/header/locales/en'
import stockists from '~/modules/stockists/locales/en'
import stockistsOptions from '~/modules/stockists/locales/en-QA.options'
import streamerce from '~/modules/streamerce/locales/en'
import user from '~/modules/user/locales/en'

import layouts from '~/locales/languages/layouts/en'
import pages from '~/locales/languages/pages/en'

export default () => {
  return {
    ...customer,
    ...footer,
    ...header,
    ...merge(stockists, stockistsOptions),
    ...streamerce,
    ...user,

    ...layouts,
    ...pages,
  }
}
