// import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
// import { buildStorage, setupCache } from 'axios-cache-interceptor/dev'
import { buildStorage, setupCache } from 'axios-cache-interceptor'
import { del, get, set } from 'idb-keyval'

import { Api } from '../api'

import { config as appConfig } from '~/config'

export default (context, inject) => {
  const { i18n } = context

  const urlPrefixes = {
    frontStatic: '_static',
    frontBff: 'front/api',
    webBff: 'web/api',
  }

  const locale = i18n.localeProperties
  const deviceId = context.app.$cookies.get('ga-device-id')
  const config = {
    progress: false,
    timeout: 10000,
    baseURL: '/',
    headers: {
      'plaid-platform': 'web',
      'plaid-version': '1.0.0',
      'plaid-store-id': locale.country,
      'plaid-language-id': locale.languageCode[locale.code],
      'plaid-device-id': deviceId,
    },
    params: {
      locale: locale.code,
    },
  }

  const indexedDbStorage = buildStorage({
    async find(key) {
      const value = await get(key)

      if (!value) {
        return
      }

      return value
    },

    async set(key, value) {
      await set(key, value)
    },

    async remove(key) {
      await del(key)
    },
  })

  const clientWrapper = !appConfig.get('devConfig')
    ? (client) => {
        return setupCache(client, {
          methods: ['get', 'post'],
          ttl: 0,
          // debug: console.log,
          headerInterpreter: () => {
            return 'not enough headers'
          },

          storage: indexedDbStorage,
        })
      }
    : null

  const api = new Api({
    client: axios,
    urlPrefix: urlPrefixes,
    config,
    clientWrapper,
  })

  api.subscribe('error', ({ error }) => {
    const errorJson = error?.toJSON() || {}
    const config = errorJson.config || {}

    const apiType = config.apiType || 'frontBff'
    const urlPrefix = urlPrefixes[apiType]
    const url = config.url.replace(urlPrefix, '')

    const code = error.response?.status

    console.log(`Ошибка api ${url}`, error?.toJSON())

    errorJson.name = url + '-' + (code || '000') + '-' + error.toJSON().message
    errorJson.message = 'Ошибка api ' + (code || '') + url
    errorJson.url = config.url
    errorJson.method = config.method
    errorJson.data = JSON.stringify(config.data)
    errorJson.params = JSON.stringify(config.params)
    errorJson.headers = JSON.stringify(config.headers)
    errorJson.response = JSON.stringify(error.response?.data)

    /**
     * Если заголовок X-Auth-Marker = false, значит его заблокирол WAF
     * необходимо залогировать ошибку в кибану
     */
    if (error.response?.headers['x-auth-marker'] === 'false') {
      errorJson.name = 'WAF-' + errorJson.name
    }

    context.app.$apm.captureError(errorJson)
  })

  inject('api', api)
}
