import { DESIGN_TYPES, ORDER_TYPE } from '../../constants/digital'

export const TYPE = {
  GTM_4: 'gtm_4',
}

export const EVENT = {
  USER_INTERACTION: 'user_interaction',
}

export const OPTIONS = {
  ACTION: {
    DEFAULT: 'default',
    SLIDER: 'slider',
    WRITE: 'write',
  },
}

/**
 * Тип подарочной карты для аналитики
 * @enum {string}
 * @readonly
 */

export const ANALYTICS_ORDER_TYPE = {
  [ORDER_TYPE.CORPORATE]: 'kepk',
  [ORDER_TYPE.INDIVIDUAL]: 'epk',
}

export const EVENT_CATEGORY = 'gift_card'

/**
 * Тип выбранного дизайна для gtm модели
 * @readonly
 * @enum {string}
 */
export const GTM_SELECT_DESIGN_TYPES = {
  [DESIGN_TYPES.AI]: 'neural_network',
  [DESIGN_TYPES.GA]: 'design_ga',
}

/** Возможные источники перехода к покупке ЭПК */
export const CLICK_BUY_ORIGIN = {
  MAIN: 'giftcard_main',
  ABOUT: 'about',
}

/** Возможные типы карт */
export const CARD_TYPES = {
  DIGITAL: 'digital',
  PLASTIC: 'plastic',
}
