import { computed, toRefs, unref } from 'vue'

import { useTransitionProps } from '@ga/use/transition-props'

import { GaAction } from '@ga/ui-components/action'

import { GaImageAdult } from '~/components/image-adult'
import { GaImageResponsive } from '~/components/image-responsive'

import { DURATION } from './scripts/consts'

// @vue/component
export default {
  name: 'ga-nav-banners-tablet',

  components: {
    GaAction,
    GaImageAdult,
    GaImageResponsive,
  },

  props: {
    /** Показывать ли баннера */
    active: {
      type: Boolean,
      default: false,
    },
    /**
     * Массив объектов с баннерами
     */
    items: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['click'],

  setup(props, { emit }) {
    const { items } = toRefs(props)

    const key = computed(() =>
      unref(items)
        .map((item) => item.id)
        .join('-'),
    )

    const isSmallCard = computed(() => unref(items).length > 1)

    const onBannerWrapperClick = () => {
      emit('click')
    }

    return {
      DURATION,
      transitionProps: useTransitionProps(),

      key,
      isSmallCard,

      onBannerWrapperClick,
    }
  },
}
