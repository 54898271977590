import { useContext } from '@nuxtjs/composition-api'

import { computed } from 'vue'

import { useAdult } from '~/modules/app'
import { useUser } from '~/modules/user'

export default {
  name: 'ga-image-adult',

  props: {
    isForAdults: {
      type: Boolean,
      default: false,
    },
    imageClass: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { $gaApp } = useContext()

    const { adultAge } = useAdult()
    const { userIsAdult } = useUser()

    const showIcon = computed(
      () => !props.hideIcon && props.isForAdults && !userIsAdult.value,
    )
    const iconLabel = computed(() => `${adultAge.value}+`)
    const shouldShowModal = computed(
      () => props.isForAdults && !userIsAdult.value,
    )

    const onImageClick = (event) => {
      if (shouldShowModal.value) {
        event.stopPropagation()
        event.preventDefault()

        $gaApp.services.modal.main.openSuperModalAdult()
      }
    }

    return {
      showIcon,
      iconLabel,
      onImageClick,
    }
  },
}
