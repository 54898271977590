export const endpoints = {
  updateUserLanguage: () => ({
    url: 'user/language',
  }),
  updateUserLanguageV2: () => ({
    url: 'user/language-v2',
  }),
  getInfoFull: () => ({
    url: 'user/info/full',
  }),
  getMobileInfoFull: () => ({
    url: 'user/mob/info/full',
  }),
  checkPhoneNumber: () => ({
    url: 'user/checknumber',
  }),
}
