export const streamsEndpoints = {
  // TODO удалить после удаления ФТ flaconUseNewFilters
  fetchStreamsListing: () => ({
    url: 'articles/streams/listing/main',
  }),

  fetchStreamsListingV2: () => ({
    url: 'articles/streams/listing/main/v2',
  }),

  fetchStreamsListingPreview: (id) => ({
    url: `articles/streams/listing/main/preview/${id}`,
  }),

  fetchStreamsFilterListing: () => ({
    url: 'articles/streams/listing/rubrics',
  }),

  fetchStreamsStatuses: () => ({
    url: 'articles/streams/statuses',
  }),

  fetchStreamInfoByUrl: (url) => ({
    url: `articles/streams/stream/${url}`,
  }),

  fetchStreamInfoById: (id) => ({
    url: `articles/streams/stream/preview/${id}`,
  }),

  fetchStreamsFilters: () => ({
    url: 'articles/streams/filters/main',
  }),

  // TODO удалить после удаления ФТ flaconUseNewFilters
  fetchStreamsFiltersV2: () => ({
    url: 'articles/streams/filters/main/v2',
  }),

  fetchStreamViewed: () => ({
    url: 'articles/streams/viewed',
  }),

  fetchStreamViewedWebview: () => ({
    url: 'articles/streams/webview/viewed',
  }),
}
