export * from './edit'
export * from './settings'
export * from './cards'

export const MODULE_TYPE = 'customer'

export const PAGE_TYPE = {
  ORDERS: 'orders',
  FAVORITES: 'favorites',
}

export const MENU_ITEM_STATES = {
  DEFAULT: 'default',
  ACTIVE: 'active',
  SELECTED: 'selected',
}

export const MENU_ITEM_NAMES = {
  ORDERS: 'orders',
  FAVORITES: 'favorites',
  GIFTCARDS: 'giftcards',
  EDIT: 'edit',
  ADDRESSES: 'addresses',
  SETTINGS: 'settings',
  FAQ: 'faq',
}

export const PAGINATION_BUTTON = {
  NEXT: 'next',
  PREV: 'prev',
}

export const DIALOG = {
  CONFIRMATION: 'confirmation',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
  DELIVERING: 'delivering',
  DEFAULT: 'default',
}

export const CATEGORY = 'favorites'

export const CHANGE_DELIVERY_MODAL_TYPES = {
  INFO: 'info',
  DELIVERY_DATES: 'deliveryDates',
  STORAGE_DATES: 'storageDates',
}

export const CHANGE_DELIVERY_TYPES = {
  DELIVERY: 'delivery',
  STORAGE: 'storage',
}

export const PRODUCT_LINK_QUERY = {
  CROSS_PDP: 'crosspdp',
}

export const ORDER_ID_FONT_MULTIPLIER = {
  S: 0.8,
  M: 0.9,
}
export const ORDER_ID_LENGTH_RANGE = {
  MIN: 12,
  MAX: 15,
}

export const NOTIFICATION_MODAL_TYPE = {
  MODAL: 'modal',
  BOTTOMSHEET: 'bottomsheet',
  MODALOLD: 'modalOld',
  NONE: 'none',
}

export const NOTIFICATION_MODAL_LOCAL_STORAGE_KEY = 'ga-notification-modal-data'
