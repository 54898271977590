export const BLOCK_TYPES = {
  PRODUCTS: 'products',
  BANNERS: 'banners',
  STORIES: 'stories',
  CATEGORY: 'category',
  INFO: 'info',
  PROMO: 'promo',
  CLIENT_DAYS: 'clientDays',
  LISTING: 'listing',
  CONTENT: 'content',
  CATEGORIES: 'categoryList',
}

// типы блоков, которые следует перерендрить при смене данных
export const BLOCK_TYPES_TO_UPDATE = [
  BLOCK_TYPES.CLIENT_DAYS,
  BLOCK_TYPES.PROMO,
  BLOCK_TYPES.CONTENT,
  BLOCK_TYPES.PRODUCTS,
  BLOCK_TYPES.CATEGORY,
  BLOCK_TYPES.LISTING,
]

export const SLOT_COMPONENTS = {
  [BLOCK_TYPES.BANNERS]: 'ga-home-slot-banner',
  [BLOCK_TYPES.STORIES]: 'ga-home-slot-stories',
  [BLOCK_TYPES.CATEGORY]: 'ga-home-slot-category',
  [BLOCK_TYPES.PRODUCTS]: 'ga-home-slot-products',
  [BLOCK_TYPES.INFO]: 'ga-home-slot-info',
  [BLOCK_TYPES.CLIENT_DAYS]: 'ga-home-slot-client-days',
  [BLOCK_TYPES.LISTING]: 'ga-home-slot-listing',
  [BLOCK_TYPES.CONTENT]: 'ga-home-slot-content',
  [BLOCK_TYPES.PROMO]: 'ga-home-slot-promo',
  [BLOCK_TYPES.CATEGORIES]: 'ga-home-slot-categories',
}

export const SLOT_STATUSES = {
  PENDING: 'pending',
  SUCCEED: 'succeed',
  FAILED: 'failed',
}

export const STORY_TYPES = {
  STREAM: 'stream',
  STORY: 'story',
}

export const DISCLAIMER_MODS = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
}

export const DISCLAIMER_SIZES = {
  XXS: 'xxs',
  XS: 'xs',
  S: 's',
}

export const COUNT_RIBBON = {
  DESKTOP: 5,
  MOBILE: 1,
}

export const VIEWED_STORIES_LOCAL_STORE_KEY = 'module-home-viewed-stories'
export const STORY_ITEM_DEFAULT_DURATION = 5000
export const STORY_ITEM_TYPE = {
  VIDEO: 'video',
  IMAGE: 'image',
}

export const STORY_PRODUCTS_MODAL_ID = 'home-story-product-modal'
export const LISTING_MAX_PAGE_INFINITE_LOADER = 1
export const CHUNK_SIZE_ANALYTICS = 1
export const MODULE_TYPES = {
  // TODO нужен рефактор чтобы не было хардкода
  PDP: 'pdp',
  PLP: 'plp',
  BRANDS: 'brands',
  PROMO: 'promo',
}

export const PAGE_TYPES = {
  // TODO нужен рефактор чтобы не было хардкода
  [MODULE_TYPES.PDP]: ['product'],
  [MODULE_TYPES.PLP]: ['listing'],
  [MODULE_TYPES.BRANDS]: ['main'],
  [MODULE_TYPES.PROMO]: ['main'],
  [MODULE_TYPES.PROMO]: ['offer'],
}
