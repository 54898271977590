<template>
  <svg fill="none" viewBox="0 0 18 18">
    <path
      stroke="currentColor"
      stroke-width="1.5"
      d="M8.455 3.453 9 4.028l.544-.575c.419-.441 1.38-1.225 2.519-1.553.558-.16 1.139-.206 1.717-.069.573.136 1.191.462 1.815 1.12 1.218 1.285 1.314 2.636 1.008 3.817-.316 1.223-1.067 2.245-1.484 2.685l-3.689 3.892-2.067 2.182L9 15.91l-.363-.383-2.067-2.182L2.88 9.453c-.417-.44-1.168-1.462-1.484-2.686-.306-1.18-.21-2.531 1.007-3.816.624-.658 1.242-.984 1.815-1.12.579-.137 1.16-.091 1.717.07 1.14.327 2.1 1.11 2.52 1.552Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-articles-like-semi-bold',
}
</script>
