import { replaceAbsoluteHref } from '../../helpers/replaceAbsoluteHref.js'

class StoreBuilder {
  constructor(data) {
    this.code = data.code
    this.flag = data.imageUrl?.url
    this.flagSquare = data.imageUrl?.square
    this.flagRectangle = data.imageUrl?.rectangle
    this.text = data.title
    this.storeDomain = data.storeDomain
    this.languageDefaultCode = data.languageDefaultCode
    this.languages = data.languages
  }
}

class MessengerBuilder {
  constructor(data) {
    this.text = data.title
    this.href = data.link
    this.isDefault = data.isDefault
  }
}

export class CommonDataBuilder {
  constructor(data) {
    this.emailRegexp = data.emailRegexp
    this.mainCity = this.buildCityDefault(data.cityDefault)

    this.minimumAge = data.minimumAge
    this.adultAge = data.adultAge

    this.languageDefaultCode = data.languageDefaultCode
    this.baseUrl = data.baseUrl
    this.languages = data.languages

    this.stores = this.buildStores(data.stores)

    this.phoneCodes = this.buildPhoneCodes(data.phoneCode)

    this.emails = this.buildEmails(data.emails)
    this.phones = this.buildPhones(data.phones)
    this.messengers = this.buildMessengers(data.messengers)
    this.messengersArr = this.buildMessengersArr(data.messengers)
    this.socials = this.buildSocials(data.socialNetwork)
    this.marketApps = this.buildMarketApps(data.marketApp)
    this.payments = this.buildPayments(data.iconPayment)

    this.footerText = data.description?.title
    this.footerGroups = this.buildFooterGroups(data.footerNavigation)

    this.toggle = {
      addressBlockEnabled: !!data.extendedAddressBlockEnabled,
    }
  }

  buildCityDefault(data) {
    return { value: data.fiasId, text: data.title }
  }

  buildStores(data) {
    return data.map((store) => new StoreBuilder(store))
  }

  buildPhoneCodes(data) {
    return data.map(
      ({
        code,
        title,
        value,
        iconUrl,
        maskPhone,
        nationalPrefix,
        isDefault,
      }) => {
        // 7;
        const countryCode = value.replace('+', '')

        // '+7'
        const countryCallingCode = value

        // Замена _ на 0, получаем из '(_ _ _)-_ _ _-_ _-_ _' маску '(0 0 0)-0 0 0-0 0-0 0';
        const placeholderChar = '_'
        const mask = maskPhone
          .split('')
          .map((char) =>
            char.includes(placeholderChar)
              ? char.replace(placeholderChar, '0')
              : char,
          )
          .join('')

        // Удаляем скобки для национальной маски
        const nationalMask = mask.replace(/[()]/g, '')

        // '+{7} (0) 000-00-00';
        const internationalMask = `+{${countryCode}} ${mask}`

        // '+7 ('
        const prefix = `${countryCode} (`

        const prefixAliases = [countryCode, countryCallingCode, nationalPrefix]
        const numberLength = internationalMask.replace(/\D/g, '').length
        const lengths = [numberLength]

        return {
          value: code,
          text: title,
          countryCode,
          countryCallingCode,
          lengths,
          masks: {
            national: nationalMask,
            international: internationalMask,
          },
          nationalPrefix,
          prefix,
          prefixAliases,
          flag: iconUrl.url,
          isDefault,
        }
      },
    )
  }

  buildPhones(data) {
    return Object.entries(data).reduce(
      (acc, [item, phone]) => {
        if (!phone) {
          return acc
        }

        return {
          ...acc,
          [item]: {
            value: phone.value,
            text: phone.title,
            href: phone.link,
          },
        }
      },
      {
        support: null,
        authorization: null,
      },
    )
  }

  buildEmails(data) {
    return Object.entries(data).reduce((acc, [item, email]) => {
      if (!email) {
        return acc
      }

      return {
        ...acc,
        [item]: {
          text: email.title,
          href: email.link,
        },
      }
    }, {})
  }

  buildMessengers(data) {
    return data.reduce((acc, messenger) => {
      const result = acc

      if (messenger.type && typeof messenger.type === 'string') {
        result[messenger.type.toLowerCase()] = new MessengerBuilder(messenger)
      } else if (messenger.title && typeof messenger.title === 'string') {
        result[messenger.title.toLowerCase()] = new MessengerBuilder(messenger)
      }

      return result
    }, {})
  }

  buildMessengersArr(data) {
    return data.map((messenger) => ({
      text: messenger.title,
      href: messenger.link,
      isDefault: messenger.isDefault,
    }))
  }

  buildSocials(data) {
    return data.map((social) => ({
      text: social.title,
      href: social.link,
      icon: social.imageUrl.url,
    }))
  }

  buildMarketApps(data) {
    return data.map((app) => ({
      text: app.title,
      href: app.link,
      icon: app.imageUrl.url,
    }))
  }

  buildPayments(data) {
    return data.map((payment) => ({
      text: payment.title,
      icon: payment.imageUrl.url,
    }))
  }

  buildFooterGroupListItem(data) {
    const href = replaceAbsoluteHref(data.link)
    const isNuxt = !href?.includes('http')

    return {
      text: data.title,
      href,
      nuxt: isNuxt,
    }
  }

  buildFooterGroups(data) {
    return data.map((group) => {
      return {
        title: group.title,
        list: group.items.map(this.buildFooterGroupListItem),
      }
    })
  }
}
