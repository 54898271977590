import { deparam } from '@ga/utils'

import { PAGE_TYPE } from '../constants'

export class PageService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async getData(redirect, context) {
    try {
      switch (redirect.pageType) {
        case PAGE_TYPE.CART:
          await this.openCart(context)
          return

        case PAGE_TYPE.SHARE:
          await this.openShare(redirect, context)
          return

        default:
          return this.gaApp.redirectError({ statusCode: 400 })
      }
    } catch (error) {
      console.error(error)

      this.gaApp.redirectError({ statusCode: 500, message: error })
    }
  }

  getParams() {
    return deparam(window.location.search.slice(1))
  }

  async openCart() {
    this.goToHomePage()

    if (this.gaApp.isServer) {
      return
    }

    const params = this.getParams()

    if (!params.products) {
      return
    }

    await this.gaApp.services.cart.product.addItemsFromUrlParams(params)
    this.gaApp.services.modal.main.openSuperModalCart()
  }

  /**
   * TODO: Вынести в sharing service
   *
   * Открывает отправленную корзину на странице первой категории.
   *
   * @param {Object} redirect - Объект с данными о перенаправлении.
   */
  async openShare(redirect) {
    this.goToFirstCategory()

    if (this.gaApp.isServer) {
      return
    }

    await this.gaApp.services.cart.share.open(redirect.id)
  }

  goToHomePage() {
    this.gaApp.redirect('/')
  }

  /**
   * TODO: Вынести в sharing service
   *
   * Перенаправляет на первую категорию.
   *
   * Если ссылка не успела прогрузится, то отправляем на главную
   */
  goToFirstCategory() {
    const url = this.gaApp.services.header.nav.getUrlFirstCategory() || '/'

    this.gaApp.redirect(url)
  }
}
