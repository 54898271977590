import { GtmBaseModel } from '../gtm-base.model/gtm-base.model'

export class GtmBurgerMenuClick extends GtmBaseModel {
  constructor({ path }) {
    super({ event: 'click_menu_item' })

    this.payload = {
      path,
    }
  }
}
