import * as deviceDetector from '@ga/shared-browser'

import { DEVICE_TYPE, LINK_TYPE, STEP } from '../constants/payment'

export class PaymentSbpService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Платформа пользователя.
   *
   * Используется для определения следующих шагов оплаты.
   */
  get deviceType() {
    if (deviceDetector.isIOS()) {
      return DEVICE_TYPE.IOS
    }

    if (deviceDetector.isAndroid()) {
      return DEVICE_TYPE.ANDROID
    }

    return DEVICE_TYPE.UNKNOWN
  }

  /**
   * Очищает данные оплаты
   */
  clearPaymentData() {
    this.gaApp.services.giftCards.payment.setSbpPaymentUrl(null)
    this.gaApp.services.giftCards.payment.setPaymentId(null)
  }

  /**
   * Вызывается при ошибке получения статуса или если статус оплаты PAYMENT_STATUS.FAILED
   */
  onCheckStatusError(error) {
    if (error.statusCode) {
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('giftCards.payment.errors.checkStatus'),
      )
    }

    const { currentStep } = this.gaApp.stores.giftCards.payment
    if (currentStep === STEP.SBP_SELECT_BANK) {
      this.gaApp.services.giftCards.payment.returnToStep(STEP.SELECT_PAY_METHOD)
    }
    this.clearPaymentData()
  }

  /**
   * Вызывается если заказ был успешно оплачен
   */
  onCheckStatusSuccess() {
    this.clearPaymentData()
    this.gaApp.services.giftCards.payment.redirectToSuccessPage()
  }

  /**
   * Начало оплаты по СБП
   *
   * Первичное получение данных для оплаты. Используется как зависимость для сервиса оплаты – `gaApp.services.giftCards.payment.startPayment()`.
   */
  async startPayment() {
    const { paymentId, orderId, sbpMembers, paymentUrl } =
      await this.gaApp.services.giftCards.api.paymentOrderSbp(
        this.gaApp.services.giftCards.payment.orderData,
      )

    this.gaApp.services.giftCards.payment.setOrderId(orderId)
    this.gaApp.services.giftCards.payment.setPaymentId(paymentId)
    this.gaApp.services.giftCards.payment.setSbpBanks(sbpMembers)
    this.gaApp.services.giftCards.payment.setSbpPaymentUrl(paymentUrl)

    this.gaApp.services.giftCards.paymentStatus.startChecker(
      this.gaApp.stores.giftCards.payment.paymentId,
      {
        onFailed: this.onCheckStatusError.bind(this),
        onSuccess: this.onCheckStatusSuccess.bind(this),
      },
    )
  }

  /**
   * Обновление QR-кода.
   *
   * При обновлении отображает спиннер и в случае ошибки получения QR-кода будет отображено соответствующее уведомление.
   */
  async refreshQrCode() {
    try {
      this.gaApp.stores.giftCards.payment.sbp.loading = true
      this.gaApp.services.giftCards.digital.setBasketId()

      await this.startPayment()
    } catch (error) {
      console.error(error)
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('giftCards.payment.errors.refreshQrCode'),
      )
    } finally {
      this.gaApp.stores.giftCards.payment.sbp.loading = false
    }
  }

  /**
   * Перенаправляет пользователя по ссылке оплаты СПБ в выбранный банк в зависимости от платформы
   */
  redirectToPayment() {
    const linkType = LINK_TYPE[this.deviceType]

    if (!linkType) {
      return window.open(this.gaApp.stores.giftCards.payment.sbp.paymentUrl)
    }

    const link =
      this.gaApp.stores.giftCards.payment.sbpSelectedBankOption[linkType]
    window.open(link)
  }
}
