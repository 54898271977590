export const endpoints = {
  getProduct: () => ({
    url: 'catalog/product-card/base/v2',
  }),
  getDelivery: () => ({
    url: 'delivery/calculate/item',
  }),
  subscribe: () => ({
    url: 'catalog/subscribe',
  }),
  getProductDescription: () => ({
    url: 'catalog/product/description',
  }),
  subscribeOnProductInStock: () => ({
    url: 'customer/stock/subscribe',
  }),
}
