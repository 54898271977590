export const getters = (gaApp) => ({
  hasTicker() {
    return Boolean(this.ticker)
  },

  /**
   * Возвращает текущие параметры маршрута для тикера для конкретного брейкпоинта.
   * Если параметры маршрута не установлены, возвращает null.
   *
   * @returns {object|null}
   */
  currentRouteOptions() {
    const isCurrentBreakpoint = (breakpoint) => gaApp.mq(breakpoint.key)

    return this.routeOptions?.breakpoints?.find(isCurrentBreakpoint) ?? null
  },

  tickerPosition() {
    return this.currentRouteOptions?.position
  },

  /**
   * Возвращает заданный размер тикера.
   *
   * @returns {string|null}
   */
  tickerSize() {
    return this.currentRouteOptions?.size || null
  },
})
