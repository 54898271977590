import { NuxtBase } from './base'

export class NuxtClient extends NuxtBase {
  initAppInterceptors() {
    this.gaApp.api.addRequestCallback({
      success: (config) => {
        if (
          ['get', 'post'].includes(config.method) &&
          this.gaApp.i18n.locales.length > 1
        ) {
          config.params = {
            ...config.params,
          }
        }

        return config
      },
    })
  }

  /**
   * Метод инициализации запроса на использование кук
   *
   */
  initCookiePermissions() {
    this.gaApp.services.app.cookiePermission.init()
  }

  /**
   * Метод проверки поддержки media (hover)
   */
  detectHoverMedia() {
    this.gaApp.services.app.browserWindow.detectWindowHoverMedia()
  }

  /**
   * Метод инициализации слушателя события `orientationchange` и `resize`
   */
  initWindowResizeListener() {
    this.gaApp.services.app.browserWindow.bindWindowResizeListeners()
  }

  /**
   * Метод инициализации слушателей для событий
   * keyboard, mousedown, mouseup, click, keydown, focusin
   */
  initKeyboardAndPointerListerner() {
    this.gaApp.services.app.browserWindow.bindKeyboardAndPointerListerners()
  }

  /**
   * На каждый переход провряем, требует ли роут авторизации
   */
  initRouterGuard() {
    this.gaApp.router.beforeEach(async (to, from, next) => {
      return await this.gaApp.services.auth.main.checkRouteAuth(to, from, next)
    })
  }

  /**
   * Инициализация модальных окон
   */
  initModals() {
    this.gaApp.services.modal.advModal.init()
    this.gaApp.services.modal.subscribes.init()
  }

  initCurrentBreakpoint() {
    this.gaApp.services.app.window.setCurrentBreakpoint()
  }

  initEyezon() {
    this.gaApp.services.eyezon.main.init()
  }

  /**
   * Метод предположения локации пользователя
   * необходимо вызывать после получения флага isAuthorized
   * если установка адреса выбросит ошибку - приложение не должно падать
   */
  async getUserLocation() {
    try {
      await this.gaApp.services.location.main.getData()
    } catch {
      const error = new Error(
        'Не удалось установить локацию юзера при инициализации приложения',
      )
      error.name = 'LOCATION_INIT_ERROR'
      this.gaApp.services.app.apm.captureError(error)
    }
  }

  /**
   * Метод получения подтверждения о 18+
   */
  getUserProofAge() {
    // this.gaApp.services.cart.main.fillData()
    this.gaApp.services.user.main.getProofAge()
  }

  /**
   * Метод устанавливает id-сеанса пользователя
   */
  setUserViewGUID() {
    this.gaApp.services.user.main.setUserViewGUID()
  }

  /**
   * Метод инициализации авторизации
   */
  async initAppAuth() {
    // Заупскаем инициализацию авторизации
    try {
      this.gaApp.services.auth.interceptors.register()

      await this.gaApp.services.auth.core.init()
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Метод инициализации аналитик
   */
  initAnalytics() {
    this.gaApp.analytics.libs.initDataLayer()
    this.gaApp.analytics.libs.initAllWithPermissionsChecks()
  }

  /**
   * Метод инициализации дебаг панели
   */
  async initDebugPanel() {
    if (this.gaApp.config.get('isDebugPanelEnabled')) {
      await this.gaApp.services.debugPanel.main.init()
    }
  }

  /**
   * Метод заполнения сторов первичными данными страницы
   */

  async prepareClientData() {
    if (!this.gaApp.services.app.main.isSSR) {
      await this.gaApp.services.featuresToggle.main.init()
      await this.gaApp.services.app.api.getCommonData()
    }

    this.gaApp.services.header.api.fetchNavigation()

    this.gaApp.services.location.main.setLocationDefault()
    // this.getUserCart()
    this.getUserProofAge()
  }

  // eslint-disable-next-line max-statements
  async init() {
    try {
      this.initAppInterceptors()

      this.gaApp.services.app.main.fillDeviceId()

      await this.prepareClientData()

      this.gaApp.services.cache.main.initCacheStorage()

      this.initDebugPanel()

      this.initWindowResizeListener()

      this.initCurrentBreakpoint()

      this.detectHoverMedia()
      this.initKeyboardAndPointerListerner()

      this.setLocale()

      this.setUserViewGUID()

      await this.initAppAuth()
      await this.getUserLocation()
      this.gaApp.services.location.main.setWatchUser()

      this.initCookiePermissions()

      this.initRouterGuard()
      this.initModals()

      this.initAnalytics()
      this.initEyezon()

      this.gaApp.router.afterEach(() => {
        if (!this.gaApp.isInit) {
          this.gaApp.eventBus.publish('module/app/init')

          this.gaApp.stores.app.main.init = true

          this.gaApp.services.app.main.updateAppVersion()

          const { isSSR, withHover } = this.gaApp.stores.app
          const { currentBreakpoint, windowSize } = this.gaApp.stores.app.window

          this.gaApp.services.app.apm.addLabels({
            isSSR,
            withHover,
            currentBreakpoint,
            ...windowSize,
          })
        }
      })
    } catch (error) {
      console.log('ошибка INIT-app', error)

      this.gaApp.stores.app.main.isErrorInit = true

      error.name = 'INIT-app'
      error.fullError = JSON.stringify(error)
      this.gaApp.services.app.apm.captureError(error)
    }
  }
}
