export class PublicService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Получение данных категории
   *
   * @param {object} params - объект параметров
   * @param {string} params.categoryId - id категории
   *
   * @returns {Promise}
   */
  getCategory(params) {
    return this.gaApp.services.plp.api.getCategory(params)
  }

  /**
   * Получение продуктов категории
   *
   * @param {object} params - объект параметров
   * @param {string} params.categoryId - id категории
   * @param {number} params.pageNumber - номер страницы
   * @param {object} params.filters - фильтры
   *
   * @returns {Promise}
   */
  getProductsByCategory(params) {
    return this.gaApp.services.plp.api.getProductsByCategory(params)
  }

  /**
   * Получение продуктов по массиву sku
   *
   * @param {object} params - объект параметров
   * @param {string[]} params.itemIds - массив sku
   *
   * @returns {Promise}
   */
  getProductsBySku(params) {
    return this.gaApp.services.plp.api.getProductsBySku(params)
  }
}
