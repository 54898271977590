import { useContext } from '@nuxtjs/composition-api'

import { computed, onMounted, unref } from 'vue'

import { GaIconAdditionalRefresh } from '@ga/icons'

import { GaAction } from '@ga/ui-components/action'
import { GaButton } from '@ga/ui-components/button'
import { GaButtonWithIcon } from '@ga/ui-components/button-with-icon'

import { useApp } from '~/modules/app'
import { GaProductCardActions } from '~/modules/product-card'

import { GaProductSlider } from '~/components/widgets/product-slider'

import { useCaptureError } from './scripts/composables/capture-error'
import { useSlider } from './scripts/composables/slider'
import { useSliderData } from './scripts/composables/slider-data'
import { URL_QUERY } from './scripts/const'

// @vue/component
export default {
  name: 'ga-app-error',

  components: {
    GaAction,
    GaButtonWithIcon,
    GaIconAdditionalRefresh,
    GaButton,
    GaProductSlider,
    GaProductCardActions,
  },

  props: {
    external: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Number,
      required: true,
    },
    error: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props) {
    const { $gaApp } = useContext()

    const { appWithHover } = useApp()

    const isUnavailableMePdp = $gaApp.features.get('unavailableMePdp')

    const urlQuery = { ...$gaApp.route.query }

    const showCrossCountry =
      isUnavailableMePdp && !!urlQuery[URL_QUERY.CROSS_PDP]

    const isDesktop = computed(() => $gaApp.mq('desktop-small+'))

    const openSearchModal = () => {
      $gaApp.services.search.main.setSearchModalOpened({ value: true })
    }

    const { captureError } = useCaptureError({
      code: props.code,
      error: props.error,
    })

    const maxCountMedia = computed(() => (unref(appWithHover) ? 10 : 1))

    let slider = {}
    if (props.code && props.code < 500) {
      const {
        placementTitle,
        widgetProducts,
        analyticData,
        pending,
        buildCartAnalyticsDetails,
        productCardListeners,
        productSliderListeners,
      } = useSliderData(showCrossCountry)

      const { sliderAttrs, cardSize } = useSlider({
        title: placementTitle,
        products: widgetProducts,
        isLoading: pending,
      })

      slider = {
        sliderAttrs,
        cardSize,
        analyticData,
        buildCartAnalyticsDetails,
        productCardListeners,
        productSliderListeners,
      }
    }

    const onRefreshClick = () => {
      window.location.reload()
    }

    onMounted(() => {
      console.error(props.error)

      $gaApp.stores.app.main.setLayoutVisible({
        header: true,
        main: true,
        footer: true,
      })

      if (showCrossCountry) {
        delete urlQuery[URL_QUERY.CROSS_PDP]

        $gaApp.router.replace({ urlQuery })
      }

      captureError()
    })

    return {
      ...slider,
      maxCountMedia,
      onRefreshClick,

      isDesktop,
      showCrossCountry,
      openSearchModal,
    }
  },
}
