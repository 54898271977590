import { GaIconBnplTabby } from '@ga/icons'

import { GaIcon } from '@ga/ui-components/icon'

import { GaPrice } from '~/components/price'

import { propValidator } from '../../../utils'
import { price as priceSchema } from '../../../utils/schemas'

// @vue/component
export default {
  name: 'ga-bnpl-tabby-badge',

  components: {
    GaPrice,
    GaIcon,
    GaIconBnplTabby,
  },

  props: {
    /** Объект с ценой (за разовый платеж) */
    data: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (value) => propValidator(value, priceSchema),
    },
    /** Количество платежей */
    paymentCount: {
      type: Number,
      default: 4,
    },
  },
}
