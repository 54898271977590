import { MODULE_TYPE } from '@ga/constants/redirect'

export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  getEntityKey(moduleType) {
    switch (moduleType) {
      case MODULE_TYPE.pdp:
        return 'itemId'

      case MODULE_TYPE.plp:
        return 'categoryId'

      default:
        return null
    }
  }

  async requestTicker({ pageType, moduleType, entityId }) {
    const { id: cityId } =
      this.gaApp.services.location.main.getDeliveryAddress()

    const params = {
      pageType,
      moduleType,
      cityId,
      ...(entityId ? { [this.getEntityKey(moduleType)]: entityId } : {}),
    }

    const { data } = await this.gaApp.repositories.ticker.main.fetchTicker({
      params,
    })

    return data
  }
}
