import { EXTERNAL_AUTH_SERVICE } from '../constants'

const DEFAULT_TYPE = 'phone'

export const OPTIONS = {
  EVENT: {
    USER_ACCOUNT: 'user_account',
    USER_AUTHORIZE: 'user_authorize',
    USER_INTERACTION: 'user_interaction',
  },
  CATEGORY: {
    USER_ACCOUNT: 'user_account',
    USER_AUTH: 'user_auth',
  },
  LABEL: {
    MODAL_OPEN: 'modal_open',
    CLICK_LOGIN_METHOD: 'click_login_method',
    SUBMIT_SMS: 'submit_sms',
    SUBMIT_TEL: 'submit_tel',
    RESUBMIT_SMS: 'resubmission_sms',
    LOGIN: 'login',
  },
  ACTION_TYPE: { ERROR: 'error', SIGN_UP: 'sign_up' },
  TYPE: {
    [DEFAULT_TYPE]: 'phone',
    [EXTERNAL_AUTH_SERVICE.YANDEX]: 'yandex',
    [EXTERNAL_AUTH_SERVICE.VK]: 'vkontakte',
  },
}

export default function (analytic) {
  return {
    // Открытие модального окна
    onOpenAuthModal() {
      analytic.send({
        event: OPTIONS.EVENT.USER_AUTHORIZE,
        eventCategory: OPTIONS.CATEGORY.USER_AUTH,
        eventLabel: OPTIONS.LABEL.MODAL_OPEN,
      })
    },

    // Выбор способа авторизации
    onClickLoginMethod(authorizationType = DEFAULT_TYPE) {
      analytic.send({
        event: OPTIONS.EVENT.USER_INTERACTION,
        eventCategory: OPTIONS.CATEGORY.USER_AUTH,
        eventLabel: OPTIONS.LABEL.CLICK_LOGIN_METHOD,
        type: OPTIONS.TYPE[authorizationType],
      })
    },

    // Клик по кнопке "Получить код"
    onSubmitSms() {
      analytic.send({
        event: OPTIONS.EVENT.USER_AUTHORIZE,
        eventCategory: OPTIONS.CATEGORY.USER_AUTH,
        eventLabel: OPTIONS.LABEL.SUBMIT_SMS,
      })
    },

    // Клик по кнопке "Подтверждение номера"
    onSubmitTel() {
      analytic.send({
        event: OPTIONS.EVENT.USER_AUTHORIZE,
        eventCategory: OPTIONS.CATEGORY.USER_AUTH,
        eventLabel: OPTIONS.LABEL.SUBMIT_TEL,
      })
    },

    // Клик по ссылке "Отправить код повторно"
    onResubmitSms() {
      analytic.send({
        event: OPTIONS.EVENT.USER_AUTHORIZE,
        eventCategory: OPTIONS.CATEGORY.USER_AUTH,
        eventLabel: OPTIONS.LABEL.RESUBMIT_SMS,
      })
    },

    // Пользователь успешно залогинился
    onSuccessLogin(authorizationType = DEFAULT_TYPE) {
      analytic.send({
        event: OPTIONS.EVENT.USER_AUTHORIZE,
        eventCategory: OPTIONS.CATEGORY.USER_AUTH,
        eventLabel: OPTIONS.LABEL.LOGIN,
        type: OPTIONS.TYPE[authorizationType],
      })
    },

    // Пользователь успешно зарегистрировался
    onSuccessSignUp(authorizationType = DEFAULT_TYPE) {
      analytic.send({
        event: OPTIONS.EVENT.USER_INTERACTION,
        eventCategory: OPTIONS.CATEGORY.USER_ACCOUNT,
        actionType: OPTIONS.ACTION_TYPE.SIGN_UP,
        type: OPTIONS.TYPE[authorizationType],
      })
    },

    // Ошибка авторизации
    onError(authorizationType = DEFAULT_TYPE) {
      analytic.send({
        event: OPTIONS.EVENT.USER_INTERACTION,
        eventCategory: OPTIONS.CATEGORY.USER_ACCOUNT,
        actionType: OPTIONS.ACTION_TYPE.ERROR,
        type: OPTIONS.TYPE[authorizationType],
      })
    },
  }
}
