export class PaymentMagnatiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  /**
   * Начало оплаты картой magnati
   */
  async startPayment() {
    const { orderData } = this.gaApp.services.giftCards.payment

    const { data } =
      await this.gaApp.services.giftCards.api.paymentOrderCard(orderData)

    const { paymentUrl, salesId } = data

    this.gaApp.services.giftCards.payment.setOrderId(salesId)

    if (!paymentUrl) {
      throw new Error('There is no paymentURL in response')
    }

    window.open(paymentUrl, '_top')
  }
}
