<template>
  <svg fill="none" viewBox="0 0 15 15">
    <path stroke="#000" stroke-width="1.25" d="M6 12 1.5 7.5 6 3M2 7.5h12" />
  </svg>
</template>
<script>
export default {
  name: 'ga-icon-common-15-arrow-mini-medium',
}
</script>
