import { AUTOMATED_TESTING_DATA_TEST_ID } from '@ga/constants/automated-testing'

import { config } from '../config'

export default ({ $gaApp }, inject) => {
  const isAutomatedTesting =
    Boolean($gaApp.automatedTestingConfig?.[AUTOMATED_TESTING_DATA_TEST_ID]) ||
    config.get('devConfig')

  inject('getDataTestId', (value) => {
    return isAutomatedTesting && value
  })
}
