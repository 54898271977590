export class ApiService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async updateUserLanguageV1(body) {
    try {
      const { data } =
        await this.gaApp.repositories.user.main.updateUserLanguage(body)

      return data
    } catch (error) {
      return null
    }
  }

  async updateUserLanguageV2() {
    try {
      const response =
        await this.gaApp.repositories.user.main.updateUserLanguageV2()

      return response
    } catch (error) {
      return null
    }
  }

  /**
   * Обновление языка пользователя (для коммуникаций)
   */
  async updateUserLanguage(body) {
    if (this.gaApp.features.get('updateUserLanguagePlaid')) {
      return await this.updateUserLanguageV2()
    } else {
      return await this.updateUserLanguageV1(body)
    }
  }

  async fetchUserInfoFull() {
    try {
      const cacheData =
        this.gaApp.services.cache.main.getServerData('userInfoFull')

      const { data } =
        cacheData || (await this.gaApp.repositories.user.main.getInfoFull())

      this.gaApp.services.user.main.setUser({ data })

      return { data }
    } catch (error) {}
  }

  async fetchUserInfoFullWithToken() {
    try {
      const { data } = await this.gaApp.repositories.user.main.getInfoFull()

      return { data }
    } catch (error) {}
  }

  async checkPhoneNumber({ phone, country }) {
    const captchaToken = await this.gaApp.services.captcha.main.generateToken()

    const params = { phone, country }

    if (captchaToken) {
      params.captchaToken = captchaToken
    }

    try {
      const { data } =
        await this.gaApp.repositories.user.main.checkPhoneNumber(params)

      return data
    } catch (error) {
      return false
    }
  }
}
