export const RECEIVE_RETURN_PURCHASE_PATH = '/'

/**
 * Страница получения карты: ВЭПК или ЭПК
 * @enum {string}
 * @readonly
 */
export const RECEIVE_PAGE_TYPES = {
  RECEIVE: { storeName: 'digitalReceive', localeName: 'receiveCard' },
  RECEIVE_RETURN: {
    storeName: 'digitalReceiveReturn',
    localeName: 'receiveReturnCard',
  },
}

/**
 * Возможные опции источника при получении ЭПК
 */
export const RECEIVE_SOURCE_LINK_OPTIONS = {
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
  SMS: 'sms',
}

/**
 * Этапы получения ЭПК
 * CARD_PACKED - показывается запакованный конверт
 * CARD_OPENED - показываются данные ЭПК после открытия конверта
 */
export const RECEIVE_STEPS = {
  CARD_PACKED: 'cardPacked',
  CARD_OPENED: 'cardOpened',
}

export const RECEIVE_STEPS_LOCAL_STORAGE_KEY = 'ga-gift-cards-receive-card-step'
