import { computed, toRefs } from 'vue'

import { GaPrice } from '~/components/price'

import { BADGE_SIZE } from '../../../constants'
import { propValidator } from '../../../utils'
import { price as priceSchema } from '../../../utils/schemas'

// @vue/component
export default {
  name: 'ga-bnpl-badge-content-tabby',

  components: {
    GaPrice,
  },

  props: {
    /** Объект с ценой (за разовый платеж) */
    data: {
      type: Object,
      required: true,
      default: () => ({}),
      validator: (value) => propValidator(value, priceSchema),
    },

    /** Размер беджа */
    size: {
      type: String,
      default: BADGE_SIZE.M,
      validator: (value) => Object.values(BADGE_SIZE).includes(value),
    },
  },

  setup(props) {
    const { size } = toRefs(props)

    const mods = computed(() => ({ size: size.value }))

    return { mods }
  },
}
