import isEqual from 'lodash/isEqual'
import objectHash from 'object-hash'

import { URL_PAGE_KEY } from '../constants/keys'

export class OffersService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  async loadListing() {
    await this.gaApp.services.promo.api.initialListing({
      page: Number(this.gaApp.route.query[URL_PAGE_KEY]) || 1,
    })
  }

  async loadNewPage() {
    this.gaApp.services.promo.offers.setLoading(true)

    this.clearUrl()
    await this.gaApp.services.promo.offers.clearPages()
    await this.gaApp.services.promo.offers.loadPromotions(1)

    this.gaApp.services.promo.offers.setLoading(false)
  }

  setOffersCount(count) {
    this.gaApp.stores.promo.offers.offersCount = count
  }

  setPage(page) {
    this.gaApp.stores.promo.offers.pages.push(page)
    this.gaApp.stores.promo.offers.pages.sort((a, b) => a.number - b.number)
  }

  setLoading(isLoading) {
    this.gaApp.stores.promo.offers.isLoading = isLoading
  }

  setShouldReloadPage(shouldReloadPage) {
    this.gaApp.stores.promo.offers.shouldReloadPage = shouldReloadPage
  }

  addViewedId(id) {
    this.gaApp.stores.promo.offers.viewedIds.push(id)
  }

  isViewedSlide(id) {
    return this.gaApp.stores.promo.offers.viewedIds.find(
      (viewedId) => id === viewedId,
    )
  }

  /**
   * Очищает просмотренные карточки в листинге при применении фильтров
   */
  clearViewedIds() {
    this.gaApp.stores.promo.offers.viewedIds = []
  }

  clearPages() {
    this.gaApp.stores.promo.offers.pages = []
  }

  clearUrl() {
    const currentQuery = this.gaApp.route.query

    if (Object.keys(currentQuery).length) {
      this.gaApp.router.replace({ query: {} })
    }
  }

  async changeUrlPage(page) {
    const query = this.gaApp.route.query
    const queryPage = +query[URL_PAGE_KEY] || 1

    if (queryPage === page) {
      return
    }

    await this.gaApp.router.replace({
      query: {
        ...query,
        [URL_PAGE_KEY]: page > 1 ? page : undefined,
      },
    })
  }

  /**
   * Сравнивает хеши фильтров и листинга
   */
  areHashesEqual() {
    const { filtersHash } = this.gaApp.stores.filters.main
    const { offersHash } = this.gaApp.stores.promo.offers

    return isEqual(filtersHash, offersHash)
  }

  /**
   * Сбрасывает и обновляет листинг после применения фильтров
   */
  resetListingAfterFiltersApplied() {
    this.resetListingAndFetchOffers()
    this.clearViewedIds()
  }

  /**
   * Сбрасывает листинг, обновляет фильтры и запрашивает новый листинг.
   * Выполняется только если хеши фильтров и офферов различаются.
   */
  resetListingAndFetchOffers() {
    if (this.areHashesEqual()) {
      return false
    }

    this.resetOffers()
    this.updateFilters()
    this.fetchOffers()
  }

  /**
   * Очищает листинг и скроллит страницу к начальному состоянию.
   */
  resetOffers() {
    requestAnimationFrame(() => {
      window.scrollTo({ top: 0 })
    })

    this.clearPages()
  }

  /**
   * Обновляет URL и сбрасывает номер страницы на первую.
   * Не включает основные фильтры в URL.
   */
  updateFilters() {
    this.gaApp.services.filters.main.pageNumber.updateFilter(1)
    // не прокидываем основные фильтры
    this.gaApp.services.filters.main.updateFiltersURL({
      selected: false,
      quickSelected: this.gaApp.features.get('offersPageNewQuickFilters'),
    })
  }

  /**
   * Запрашивает листинг для указанной страницы (по умолчанию для первой)
   */
  async fetchOffers(page = 1) {
    await this.gaApp.services.promo.api.fetchOffers({ page })
  }

  setOffersHash(value) {
    this.gaApp.stores.promo.offers.offersHash = objectHash(value, {
      unorderedArrays: true,
    })
  }
}
