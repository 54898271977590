import { VIEW } from '../constants'

export class ModalService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.gaApp.eventBus.subscribe('module/modal/close/cart', () => {
      this.resetState()
    })
  }

  get coupon() {
    return this.gaApp.stores.cart.modal.coupon
  }

  get couponState() {
    return this.gaApp.stores.cart.modal.couponState
  }

  get availableCoupons() {
    return this.gaApp.stores.cart.modal.availableCoupons
  }

  get userIsAuthorized() {
    return this.gaApp.services.user.main.isAuthorized
  }

  async updateItemQty({ sku, qty }) {
    const item = this.gaApp.services.cart.main.getProductItem(sku)

    if (!item || item.qty === qty) {
      return
    }

    if (qty < item.qty) {
      this.gaApp.analytics.modules.cart.onDecrementItemCart(item.sku)
    } else if (qty > item.qty) {
      this.gaApp.analytics.modules.cart.onIncrementItemCart(item.sku)
    }

    if (this.coupon.value) {
      this.setCouponLoading(true)
    }

    await this.gaApp.services.cart.api.updateItemQty({ id: item.id, qty })

    this.resetLoading()
  }

  async setCoupon(coupon) {
    const prevValue = this.coupon.value

    this.setCouponLoading(true)
    this.setCouponErrorMessage(null)

    try {
      // Если уже был устоновлен промокод, сначала его удаляем, потом применяем новый
      if (coupon && prevValue) {
        await this.gaApp.services.cart.api.setCoupon(null)
      }

      await this.gaApp.services.cart.api.setCoupon(coupon)

      this.resetLoading()
    } catch (error) {
      this.setCouponLoading(false)
    }
  }

  setCouponErrorMessage(message) {
    this.gaApp.stores.cart.modal.couponState.errorMessage = message
  }

  setCouponLoading(value) {
    this.gaApp.stores.cart.modal.couponState.isLoading = value
  }

  updateCouponData() {
    const value = this.coupon.value
    const error = this.couponState.errorMessage

    if (!value && error) {
      this.clearCouponData()
    }
  }

  clearCouponData() {
    this.setCouponErrorMessage(null)
    this.gaApp.services.cart.main.setCoupon(null)
  }

  toCheckoutWithValidation() {
    if (this.gaApp.stores.cart.main.hasOutOfStockSection) {
      this.gaApp.services.cart.dialog.openDeleteBeforeCheckoutConfirm()
    } else {
      this.toCheckout()
    }
  }

  toCheckout() {
    if (this.userIsAuthorized) {
      this.redirectToCheckout()
    } else {
      this.gaApp.services.cart.dialog.openAuthConfirm()
    }
  }

  redirectToCheckout() {
    if (this.gaApp.features.get('redirectToGoCheckout')) {
      this.gaApp.services.app.router.assign('/gocheckout')
    } else {
      this.gaApp.services.app.router.assign('/checkout')
    }
  }

  resetLoading() {
    this.setCouponLoading(false)
  }

  resetState() {
    this.gaApp.stores.cart.modal.$reset()
  }

  async deleteItems(id) {
    if (!id) {
      return
    }

    const ids = Array.isArray(id) ? id : [id]

    if (!ids.length) {
      return
    }

    this.gaApp.analytics.modules.cart.onRemoveFromCart({
      ids: this.getProductIdsFromMagentoIds(ids),
    })
    await this.gaApp.services.cart.api.deleteItems({ ids })
  }

  deleteSection(sectionName) {
    const ids = this.gaApp.services.cart.main.getSectionIds(sectionName)

    return this.deleteItems(ids)
  }

  getProductIdsFromMagentoIds(magentoIds) {
    const ids = []
    magentoIds.forEach((id) => {
      const idx = this.gaApp.stores.cart.main.itemsMagentoId.indexOf(id)
      if (idx !== -1) {
        const productId = this.gaApp.stores.cart.main.itemsId[idx]

        ids.push(productId)
      }
    })

    return ids
  }

  setFavoriteProducts(products) {
    const ids = products
      .filter(({ favourite }) => Boolean(favourite))
      .map(({ itemId }) => itemId)

    if (ids?.length > 0) {
      this.gaApp.services.favorites.products.setItems(ids)
    }
  }

  async getProductsActual() {
    if (!this.userIsAuthorized) {
      return
    }
    const itemIds = [...this.gaApp.stores.cart.main.itemsId]

    const actualProducts = await this.gaApp.services.cart.api.getProductsActual(
      {
        itemIds,
      },
    )

    if (actualProducts.length > 0) {
      this.setFavoriteProducts(actualProducts)
    }
  }

  async loadValidCartData() {
    await this.gaApp.services.cart.api.loadValidCartData()

    if (this.gaApp.stores.cart.modal.isEmpty) {
      return
    }

    await this.getProductsActual()

    // аналитика
    this.gaApp.analytics.modules.cart.onView({
      ids: this.gaApp.stores.cart.main.itemsId,
      id: this.gaApp.stores.cart.main.id,
      totals: this.gaApp.stores.cart.main.totals,
    })
  }

  async deleteProductsBeforeCheckout() {
    const allItemsIds = [...this.gaApp.stores.cart.main.itemsId]

    this.gaApp.analytics.modules.cart.onDeleteOutOfStockBeforeCheckout({
      ids: allItemsIds,
    })

    await this.deleteSection(VIEW.OUTOFSTOCK)

    this.toCheckout()
  }

  /**
   * Добавляет товары из раздела "Нет в наличии" в избранное, удаляет их из корзины и перенаправляет пользователя на чекаут
   */
  async moveOutOfStockToFavoritesBeforeCheckout() {
    try {
      const outOfStockItemsSkus = this.gaApp.services.cart.main.getSectionSkus(
        VIEW.OUTOFSTOCK,
      )
      await this.gaApp.services.cart.product.addItemsToFavorite(
        outOfStockItemsSkus,
      )
      await this.deleteSection(VIEW.OUTOFSTOCK)
      await this.gaApp.services.cart.main.toCheckoutWithChecking()
    } catch (error) {
      console.error(error)
      this.gaApp.services.notification.main.open(
        this.gaApp.i18n.t('cart.error.addOutOfStockToFavourites'),
      )

      const apmError = new Error(
        'Ошибка добавления товаров не в наличии в избранное перед чекаутом',
      )
      apmError.cause = JSON.stringify(error)
      apmError.name = 'out-of-stocks-to-favorites'
      this.gaApp.services.app.apm.captureError(apmError)
    }
  }

  /**
   * Добавляет товары из раздела "Нет в наличии" в избранное и перенаправляет пользователя на чекаут.
   *
   * Если пользователь неавторизован, то открывается модальное окно авторизации. После авторизации добавляет товары не в наиличии в избранное, удаляет их из корзины и перенаправляет на чекаут.
   */
  async addProductsToFavoriteBeforeCheckout() {
    this.gaApp.analytics.modules.cart.onAddToFavoriteOutOfStockBeforeCheckout({
      ids: [...this.gaApp.stores.cart.main.itemsId],
    })

    if (!this.userIsAuthorized) {
      return this.gaApp.services.cart.dialog.openAuthToCheckout({
        register: this.moveOutOfStockToFavoritesBeforeCheckout.bind(this),
        login: this.moveOutOfStockToFavoritesBeforeCheckout.bind(this),
      })
    }

    await this.moveOutOfStockToFavoritesBeforeCheckout()
  }
}
