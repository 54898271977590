import { computed, toRefs } from 'vue'

import { GaButtonBase } from '../button-base'
import { GaIcon } from '../icon'
import { GaLinkBase } from '../link-base'
import { GaSpinner } from '../spinner'

import { ICON_PLACEMENT, SIZE, STATE, VIEW } from './scripts/const'

// @vue/component
export default {
  name: 'ga-button-icon',

  components: {
    GaIcon,
    GaSpinner,
  },

  props: {
    /** Внешний вид кнопки */
    view: {
      type: String,
      default: VIEW.MAIN,
    },
    /** Размер кнопки */
    size: {
      type: String,
      default: SIZE.M,
      validator: (value) => Object.values(SIZE).includes(value),
    },
    /** Текст кнопки */
    text: {
      type: String,
      required: true,
    },
    /** Меняет расположение иконки */
    iconPlacement: {
      type: String,
      default: ICON_PLACEMENT.END,
      validator: (value) => Object.values(ICON_PLACEMENT).includes(value),
    },
    /** Дает возможность расшириться кнопке на 100% от свободного пространства */
    block: {
      type: Boolean,
      default: false,
    },
    /** Убирает интерактивность */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** Добавляет анимацию загрузки */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Класс для иконки */
    iconClass: {
      type: String,
      default: '',
    },
    rtlIgnore: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { attrs }) {
    const { view, size, iconPlacement, block, disabled, loading, rtlIgnore } =
      toRefs(props)
    const component = computed(() => {
      return attrs.href ? GaLinkBase : GaButtonBase
    })

    const state = computed(() => {
      switch (true) {
        case disabled.value:
          return STATE.DISABLED
        case loading.value:
          return STATE.LOADING
        default:
          return STATE.DEFAULT
      }
    })

    const disabledInternal = computed(() => {
      return [STATE.DISABLED, STATE.LOADING].includes(state.value)
    })

    const isLoading = computed(() => {
      return state.value === STATE.LOADING
    })

    const mods = computed(() => {
      const reverse = iconPlacement.value !== ICON_PLACEMENT.END

      return {
        view: view.value,
        size: size.value,
        block: block.value,
        state: state.value,
        reverse,
        rtl: !rtlIgnore.value,
      }
    })

    return { component, mods, disabledInternal, isLoading }
  },
}
