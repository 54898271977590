export class CommonRepository {
  constructor(gaApp, endpoints) {
    this.gaApp = gaApp

    this.endpoints = gaApp.api.endpoints(endpoints)
  }

  async getDelivery(parameters = {}, requestParams = {}) {
    const body = { ...parameters }
    const { url } = this.endpoints.getDelivery()

    try {
      const response = await this.gaApp.api
        .request(requestParams)
        .post(url, body)
      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async subscribe(data = {}) {
    const { url } = this.endpoints.subscribe()

    try {
      const response = await this.gaApp.api.request().put(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async getProductDescription(parameters = {}) {
    const params = { ...parameters }
    const { url } = this.endpoints.getProductDescription()

    try {
      const response = await this.gaApp.api.request().get(url, {
        params,
      })

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }

  async subscribeOnProductInStock(data = {}) {
    const { url } = this.endpoints.subscribeOnProductInStock()

    try {
      const response = await this.gaApp.api.request().post(url, data)

      return this.gaApp.api.response(response)
    } catch (error) {
      throw this.gaApp.api.error(error)
    }
  }
}
