import isEmpty from 'lodash/isEmpty'

import { computed, watch } from 'vue'

import { areFieldsEqual } from '../../helpers'

export const mainService = (gaApp) => ({
  setLocationDefault() {
    const { value: id, text: locality } = gaApp.services.app.main.getMainCity()

    if (!id || !locality) return

    gaApp.stores.location.main.location = {
      id,
      city: locality,
      isDefaultLocation: true,
    }

    // this.setLocation({
    //   id,
    //   city: locality,
    // })
  },

  setLocation(location) {
    const prevLocation = gaApp.stores.location.main.location
    gaApp.stores.location.main.location = location

    if (
      !isEmpty(prevLocation) &&
      !areFieldsEqual(prevLocation, location, [
        'city',
        'street',
        'house',
        'block',
        'blockType',
        'geoPolygons',
      ])
    ) {
      gaApp.eventBus.publish('module/location/address-change')
    }
  },

  setLocationTemp(location) {
    gaApp.stores.location.main.locationTemp = location
  },

  /**
   * Пишет в стор массив из сохраненных + временного адреса
   * @param {Array} addresses - массив адресов
   */
  setAddresses(addresses) {
    gaApp.stores.location.addresses.mixedAddresses = addresses || []
  },

  setCache(location) {
    this.setLocation(location)
    gaApp.stores.location.main.isCacheData = true
  },

  resetState() {
    gaApp.stores.location.main.$reset()
  },

  openModal(screenType) {
    const isAddressBlockEnabled =
      gaApp.stores.app.common.toggle.addressBlockEnabled
    const address = gaApp.stores.location.main.location
    const mixedAddresses = gaApp.stores.location.addresses.mixedAddresses

    gaApp.analytics.modules.location.onClickChangeAddress(screenType)

    if (!isAddressBlockEnabled) {
      gaApp.services.location.modal.open()
      return
    }

    if (!gaApp.stores.user.main.isAuthorized || !mixedAddresses.length) {
      gaApp.services.location.specify.openEditModal({ screenType })
      return
    }

    if (mixedAddresses.length > 1) {
      gaApp.services.location.specify.openSelectModal(screenType)
      return
    }

    if (address.saveId && address.saveId === mixedAddresses[0].saveId) {
      gaApp.services.location.specify.openEditModal({ screenType })
    } else if (
      address.exactId &&
      address.exactId === mixedAddresses[0].exactId
    ) {
      gaApp.services.location.specify.openEditModal({ screenType })
    } else {
      gaApp.services.location.specify.openSelectModal(screenType)
    }
  },

  getGeoPolygons(location = gaApp.stores.location.main.location) {
    const polygons = {}

    if (
      gaApp.features.get('geoPolygonDeliveryAreas') &&
      location?.geoPolygons
    ) {
      polygons.geoPolygons = location.geoPolygons
    }

    return polygons
  },

  getAddressFormattedName(location = gaApp.stores.location.main.location) {
    return gaApp.stores.app.common.toggle.addressBlockEnabled
      ? gaApp.services.location.formatter.formatLocationDeliveryName(location)
      : location.city
  },

  getDeliveryAddress() {
    const location = gaApp.stores.location.main.location

    const {
      city,
      cityDistrict,
      street,
      house,
      latitude,
      longitude,
      id,
      saveId,
      isManyStores,
      regionId,
    } = location

    return {
      cityDistrict,
      city,
      latitude,
      longitude,
      id,
      saveId,
      isManyStores,
      regionId,

      isStreetOrHouse: Boolean(street || house),
      formattedName: this.getAddressFormattedName(location),
      ...this.getGeoPolygons(location),
    }
  },

  /**
   * В зависимости от ФТ addressParamsUnification
   * Возвращаем данные адреса объектом:
   * либо в формате addressParams: { params }
   * либо { params }
   */
  getDeliveryAddressParams() {
    const { id, cityDistrict, geoPolygons, regionId } =
      this.getDeliveryAddress()

    const addressParams = {
      cityId: id,
      cityDistrict,
      geoPolygons,
      regionId,
    }

    if (gaApp.features.get('addressParamsUnification')) {
      return {
        addressParams,
      }
    }

    return addressParams
  },

  setWatchUser() {
    const isAuthorized = computed(() => gaApp.stores.user.main.isAuthorized)

    watch(isAuthorized, async (value) => {
      // разлогинились
      if (!value) {
        gaApp.services.location.main.setAddresses([])

        return
      }

      // залогинились и адресный блок включен
      if (gaApp.stores.app.common.toggle.addressBlockEnabled) {
        gaApp.services.location.confirm.setAddressConfirmed(false)

        await this.setSelectedAddress(null, false)
        gaApp.services.location.cache.removeGuestSelectedAddress()

        gaApp.services.location.clarification.start()
        return
      }

      // залогинились и адресный блок выключен
      gaApp.services.location.cache.removeGuestSelectedAddress()
    })
  },

  /**
   * Установка адреса юзера
   */
  async setUserLocation() {
    if (gaApp.services.user.main.isAuthorized) {
      // получаем адрес
      // внутри getAddress дергается setLocation
      await gaApp.services.location.api.getAddress()
    } else {
      await this.setUserLocationGuest()
    }
  },

  /**
   * Установка адреса для гостя
   * для гостя адрес хранится в сторадже
   */
  async setUserLocationGuest() {
    // берем ранее сохраненный адрес со стораджа
    const guestSelectedAddressFromCache =
      gaApp.services.location.cache.getSelectedAddress()

    // если сторадж был пустой, то пишем туда дефолтную локацию
    // сторадж не должен быть пустым
    // в этот момент в gaApp.stores.location.main.location дефолтная локация (для РФ это Москва)
    if (!guestSelectedAddressFromCache) {
      gaApp.services.location.cache.saveGuestSelectedAddress(
        gaApp.stores.location.main.location,
      )
    }

    // при смене локали всегда дергаем селект
    if (
      guestSelectedAddressFromCache?.lang &&
      guestSelectedAddressFromCache.lang !== gaApp.i18n.locale.code
    ) {
      // Селектим сохраненный адрес для смены языка у гостя
      // актуально для катара и эмиратов
      await this.changeGuestLocationLanguage(guestSelectedAddressFromCache)
      return
    }

    if (
      guestSelectedAddressFromCache &&
      !guestSelectedAddressFromCache.isDefaultLocation
    ) {
      this.setLocation(guestSelectedAddressFromCache)
      return
    }
    // пробуем получить адрес по ip
    const addressByIp = await gaApp.services.location.api.getLocationByIp()

    if (addressByIp) {
      this.setLocation(addressByIp)
    }
  },

  /**
   * Меняет язык адреса на текущий
   * @param {object} location - текущий адрес
   * @param {object} location.id - фиас города/поселения
   * @param {object} location.exactId - максимально точный фиас (улицы, дома)
   * @param {object} location.saveId - id сохраненного адреса
   */
  async changeGuestLocationLanguage(location) {
    const { id, saveId, exactId } = location

    // передаем только id, остальные отсекаем потому что там есть текста, которые нам надо обновить
    // если ранее были на чекауте, то некоторые данные в selected_address могут потерятся, но
    //  1) на чекауте это не отобразится, бэк подтянет в shipping_address все что нужно.
    //  shipping_address потеряются данные только если при инициализации чекаута мы передадим другой фиас из selected_address
    //  2) на остальном вебе отображается только город, потерю данных не заметим
    // todo: для адресного блока такая реализация не пойдет, сделать лучше (в связке с бэком)
    //  предварительно должно быть что-то такое:
    //    - передавать не фиас, а весь адрес
    //    - передвать флаг freeInput, чтобы бэк понимал что нужно перевести, а что нет
    await this.setSelectedAddress(
      {
        id,
        saveId,
        exactId,
      },
      true,
      true,
    )
  },

  /**
   * Получение адреса юзера и запуск процесса с подтверждением адреса
   * @returns {Promise<void>} - промис установки адреса юзера
   */
  getData() {
    const userLocationIsSet = this.setUserLocation()

    /*
     * TODO: подписываемся сразу, ждем данных
     *  если подписаться позже - можем пропустить событие page-created и коллбэк не отработает
     *  если не ждать данные, то подставятся гео адрес, не дожидаясь пока придет selectedAddress
     * */
    const unsubscribe = gaApp.eventBus.subscribe(
      'module/app/router/page-created',
      async ({ meta }) => {
        await Promise.resolve(userLocationIsSet)
        this.onPageCreated({ meta })
        unsubscribe()
      },
    )

    return userLocationIsSet
  },

  onPageCreated({ meta }) {
    gaApp.services.location.confirm.setDisabled(meta.addressDisabled ?? false)

    gaApp.services.location.clarification.start()
  },

  /**
   * Возвращает функции для хуков открытия/закрытия модалки,
   * которые обеспечивают взаимодействие с лейэаутом приложения
   * @param {object} [data] - выбранный адрес
   * @param {boolean} [shouldConfirm] - нужно ли подтвердить адрес
   * @param {boolean} [forceSetAddress] - форс флаг для ручки селекта
   */
  // todo Подумать над разгрузкой метода
  async setSelectedAddress(
    data,
    shouldConfirm = true,
    forceSetAddress = false,
  ) {
    const address = data || gaApp.stores.location.main.location

    if (!address) {
      return
    }

    // Подразумевается, что если данные были получены в ручках geoCode/reverseGeocode - то isDefaultLocation = false.
    // Не дергаем селект для гостя без дефолтного адреса
    const shouldSkipSetAddress =
      !gaApp.services.user.main.isAuthorized && !address.isDefaultLocation

    // Если флаг forceSetAddress = true, дергаем селект. Пока нужно в кейсе со сменой локали
    if (!shouldSkipSetAddress || forceSetAddress) {
      await gaApp.services.location.api.setAddress(address)
    } else {
      this.setLocation(address)
      gaApp.services.location.cache.saveGuestSelectedAddress(address)

      if (!gaApp.stores.app.common.toggle.addressBlockEnabled) {
        gaApp.services.location.confirm.saveClientLocation(address.id)
      }
    }

    this.setLocationTemp(null)
    gaApp.stores.location.specify.addressIdTemp = null

    // Если addressBlockEnabled выключен, то используется другой механизм проверки
    // на подтверждение, поэтому дергать setAddressConfirmed нет необходимости
    if (shouldConfirm && gaApp.stores.app.common.toggle.addressBlockEnabled) {
      gaApp.services.location.confirm.setAddressConfirmed()
    }
  },
})
