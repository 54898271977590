const MAX_GEOLOCATION_WAIT = 5000

const GEOLOCATION_ERROR_CODE = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
}

export class GeolocationService {
  constructor(gaApp) {
    this.gaApp = gaApp
  }

  setPending(value) {
    this.gaApp.services.location.modal.setGeolocationPending(value)
  }

  resetGeolocation() {
    this.gaApp.services.location.modal.resetGeolocation()
  }

  get isSupported() {
    return Boolean(window.navigator.geolocation)
  }

  getCurrentPosition(options) {
    return new Promise((resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(resolve, reject, options)
    })
  }

  /**
   * Получение координат у браузера
   *
   * @returns {object}
   */
  async getCoordsByBrowser() {
    const options = {
      enableHighAccuracy: true,
      timeout: MAX_GEOLOCATION_WAIT,
      maximumAge: 0,
    }

    const data =
      await this.gaApp.services.location.geolocation.getCurrentPosition(options)

    return data?.coords || {}
  }

  async browserDetection() {
    try {
      this.setPending(true)

      const data = await this.getCoordsByBrowser()

      return data
    } catch (error) {
      console.log(error)
      const { code } = error

      switch (code) {
        case GEOLOCATION_ERROR_CODE.PERMISSION_DENIED:
          this.gaApp.services.notification.main.open(
            this.gaApp.i18n.t('location.geolocation.error.disabled'),
          )
          break

        case GEOLOCATION_ERROR_CODE.POSITION_UNAVAILABLE:
          this.gaApp.services.notification.main.open(
            this.gaApp.i18n.t('location.geolocation.error.default'),
          )
          break

        case GEOLOCATION_ERROR_CODE.TIMEOUT:
          this.gaApp.services.notification.main.open(
            this.gaApp.i18n.t('location.geolocation.error.timeout'),
          )
          break
      }
      return {}
    } finally {
      this.setPending(false)
    }
  }
}
