import { useContext } from '@nuxtjs/composition-api'

import { DEFAULT_ROUTE_OPTIONS } from '../constants'

export const useTickerOptions = () => {
  const { $gaApp } = useContext()

  const setOptions = (options = DEFAULT_ROUTE_OPTIONS) => {
    return $gaApp.services.ticker.main.setTickerRouteOptions(options)
  }

  const resetOptions = () => {
    return $gaApp.services.ticker.main.resetTickerRouteOptions()
  }

  return {
    setOptions,
    resetOptions,
  }
}
