import { APP_EVENT } from '~/modules/app'

import { AUTH_MODAL_REASON_TO_OPEN } from '../constants'

export class UserService {
  constructor(gaApp) {
    this.gaApp = gaApp

    this.subscribeOnUser()
  }

  get isAuthorized() {
    return this.gaApp.services.user.main.isAuthorized
  }

  subscribeOnUser() {
    this.gaApp.eventBus.subscribe(APP_EVENT.LOGIN, this.onUserLogin.bind(this))

    this.gaApp.eventBus.subscribe(
      APP_EVENT.LOGOUT,
      this.onUserLogout.bind(this),
    )
  }

  async onUserLogin({ meta }) {
    const { reason } = meta

    if (reason === AUTH_MODAL_REASON_TO_OPEN.LOGIN_TO_CHECKOUT_BY_CART) {
      await this.gaApp.services.cart.modal.addProductsToFavoriteBeforeCheckout()
    }
  }

  onUserLogout() {
    this.gaApp.services.cart.api.getData()
  }
}
