import { NuxtClient } from '~/init/client'
import { NuxtWebview } from '~/init/webview'

export default async ({ $gaApp }) => {
  // пытаемся найти инфу про вебвью
  const isWebview =
    $gaApp.route.query.webview ||
    $gaApp.cookies.get('ga-webview') ||
    $gaApp.services.app.browserStorage.local.get('ga-webview')

  const client = isWebview ? new NuxtWebview($gaApp) : new NuxtClient($gaApp)

  await client.init()
}
